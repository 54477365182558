import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {CardProps} from '@/atoms/Card/definitions';

export const useStyles = (
  clickable: CardProps['onClick'],
  disabled: CardProps['disabled'],
) => {
  const theme = useTheme();

  const card = useMemo<SxProps<Theme>>(
    () => ({
      overflow: 'hidden',
      borderRadius: theme.spacing(1.2),
      padding: theme.spacing(1.6),
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 7px 20px 0px rgba(78, 76, 76, 0.05)',
      ...(clickable && !disabled && {cursor: 'pointer'}),
      ...(disabled && {
        pointerEvents: 'none',
        opacity: 0.4,
      }),
    }),
    [clickable, disabled, theme],
  );

  return useMemo(
    () => ({
      card,
    }),
    [card],
  );
};
