import {combineReducers} from 'redux';

import {missionsListReducer} from '@/store/loyaltyFilters/missionsList.reducer';
import {pointsHistoryReducer} from '@/store/loyaltyFilters/pointsHistory.reducer';
import {rewardsListReducer} from '@/store/loyaltyFilters/rewardsList.reducer';
import {membersReportFiltersReducer} from '@/store/membersReportFilters/membersReportFilters.reducer';
import {reportDetailFiltersReducer} from '@/store/reportDetailsFilters/reportDetailFiltersReducer';
import {reportFiltersReducer} from '@/store/reportFilters/reportFilters.reducer';

import {AuthenticationActions, authenticationReducer} from '../authentication';

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  pointsHistoryFilters: pointsHistoryReducer,
  rewardsListFilters: rewardsListReducer,
  missionsListFilter: missionsListReducer,
  reportFilters: reportFiltersReducer,
  reportDetailFilters: reportDetailFiltersReducer,
  membersReportFilters: membersReportFiltersReducer,
});

export const appReducer = (state: any, action: any) => {
  if (action.type === AuthenticationActions.loginSuccess) {
    return rootReducer(
      {
        ...state,
        authentication: state.authentication,
      },
      action,
    );
  }
  return rootReducer(state, action);
};
