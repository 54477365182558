import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Stack} from '@mui/material';

import {noop} from '../Utils/noop';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const CommonButtons = ({
  confirmDisabled,
  onClose = noop,
  onConfirm = noop,
  cancelText,
  confirmText,
  gap= 0.8,
}: IProps) => {
  const {t} = useTranslation();
  const styles = useStyle();

  return (
    <Stack flexDirection="row" gap={gap}>
      <Button variant="secondary" sx={styles.commonButtons} onClick={onClose}>
        {cancelText ?? t('Dialog.cancel')}
      </Button>
      <Button
        variant="primary"
        sx={styles.commonButtons}
        onClick={onConfirm}
        autoFocus
        disabled={confirmDisabled}>
        {confirmText ?? t('Dialog.confirm')}
      </Button>
    </Stack>
  );
};

export default React.memo(CommonButtons);
