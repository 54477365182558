import React, {useMemo} from 'react';

import {
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {Nullable} from '@/lib/utils';
import {
  FeedbackConfiguration,
  FeedbackRefusalReasonConfiguration,
  REFUSAL_REASONS,
} from '@/store/feedback/v2';

interface FeedbackRefusalReasonsProps {
  configuration: FeedbackConfiguration<FeedbackRefusalReasonConfiguration>;
  selectedReason: Nullable<REFUSAL_REASONS>;
  onReasonChange: (reason: Nullable<REFUSAL_REASONS>) => unknown;
  taskIsEditable: boolean;
}

const FeedbackRefusalReasons = ({
  configuration,
  selectedReason,
  onReasonChange,
  taskIsEditable,
}: FeedbackRefusalReasonsProps) => {
  const {getTranslationWithValue} = useBaseTranslation(
    'FeedbackBox.REFUSAL_REASON',
  );

  /**
   * @brief Options
   * @note This variable is used to get the options for the refusal reasons
   */
  const options = useMemo(() => {
    if (!configuration) {
      return [];
    }

    return Object.keys(configuration.fieldAcceptedValue!).map(key => {
      return {
        label: key,
        value: configuration.fieldAcceptedValue![key],
      };
    });
  }, [configuration]);

  return (
    <FormControl fullWidth>
      <InputLabel id="refusalReason">
        {getTranslationWithValue(0, selectedReason ? 'title' : 'selectReason')}
      </InputLabel>
      <Select
        disabled={!taskIsEditable}
        fullWidth
        labelId="refusalReason"
        id="refusalReason"
        value={selectedReason || ''}
        {...(taskIsEditable && {
          onChange: event =>
            onReasonChange(event.target.value as REFUSAL_REASONS),
        })}
        input={
          <OutlinedInput
            label={getTranslationWithValue(
              0,
              selectedReason ? 'title' : 'selectReason',
            )}
          />
        }>
        {options?.map(reason => (
          <MenuItem key={reason.value} value={reason.value}>
            {getTranslationWithValue(
              0,
              `REASONS.${reason.value}`,
              {},
              reason.label,
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(FeedbackRefusalReasons);
