import React, {useMemo} from 'react';

import {Stack} from '@mui/material';

import {Headline5} from '@/atoms/Headline5';
import {Modal} from '@/atoms/Modal';
import {Carousel} from '@/molecules/Carousel';
import {CarouselModalProps} from '@/molecules/CarouselModal/interfaces.d';

const CarouselModal = ({
  images,
  initialIndex,
  carouselTitle,
  ...modalProps
}: CarouselModalProps) => {
  const renderCarouselTitle = useMemo(() => {
    if (typeof carouselTitle === 'undefined') return null;

    if (typeof carouselTitle === 'string') {
      return <Headline5 superHeavy>{carouselTitle}</Headline5>;
    }

    return carouselTitle;
  }, [carouselTitle]);

  return (
    <Modal
      {...modalProps}
      maxWidth={96}
      dialogContent={
        <Stack gap={1.2} width="100%">
          {renderCarouselTitle && renderCarouselTitle}
          <Carousel
            images={images.map(img => `${img.split('?')[0]}?imwidth=912`)}
            initialIndex={initialIndex}
          />
        </Stack>
      }
    />
  );
};

export default React.memo(CarouselModal);
