import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const dialog = useMemo(
    () => ({
      '.MuiPaper-root': {
        padding: theme.spacing(2.4),
        width: '600px',
        maxHeight: '520px',
        borderRadius: '16px',
      },
      '.MuiDialogContent-root': {
        marginBottom: theme.spacing(0.8),
      },
      '.MuiDialogActions-root': {
        p: 0,
      },
    }),
    [theme],
  );

  const header = useMemo(
    () => ({
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      gap: theme.spacing(2.4),
      '& .close': {
        cursor: 'pointer',
      },
    }),
    [theme],
  );

  const singleMemberContainer: SxProps<Theme> = useMemo(
    () => ({
      cursor: 'pointer',
      flexFlow: 'row',
      alignItems: 'center',
      gap: theme.spacing(0.8),
      paddingX: theme.spacing(3.2),
    }),
    [theme],
  );

  const contentStores = useMemo(
    () => ({
      width: '100%',
      marginTop: theme.spacing(2),
      overflowY: 'auto',
      height: 250,
      paddingBottom: '40px',
      paddingRight: theme.spacing(0.8),
    }),
    [theme],
  );

  const contentStoreWrapper = useMemo(() => {
    return {
      width: '100%',
      paddingRight: theme.spacing(0.8),
    };
  }, [theme]);

  const bottomGradient = useMemo(
    () => ({
      width: '100%',
      height: '110px',
      backgroundColor: '#ffffff',
      position: 'absolute',
      left: '0px',
      bottom: '30px',
      background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%)',
    }),
    [],
  );

  return useMemo(
    () => ({dialog, header, singleMemberContainer, contentStoreWrapper, bottomGradient, contentStores}),
    [dialog, header, singleMemberContainer, contentStoreWrapper, bottomGradient, contentStores],
  );
};
