import {PropsWithChildren} from 'react';
import {I18nextProvider, initReactI18next} from 'react-i18next';

import i18n from 'i18next';

import {en, it} from './index';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {
      translation: en,
    },
    it: {
      translation: it,
    },
  },
});

const TranslationProvider = ({children}: PropsWithChildren) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default TranslationProvider;
