import React, {useCallback, PropsWithChildren} from 'react';

import {Box, Tooltip} from '@mui/material';

import {Text} from '@/atoms/Typography/Text';

import {IProps} from './interfaces';
import {useFlexStyles, useStyle, useTaskListStyles} from './styles';

const KeyValuePair = ({
  keyLabel,
  value,
  small,
  taskList,
  isFlex,
  flexWrap,
  children,
  tooltip,
}: IProps) => {
  const styles = useStyle(small);
  const taskListStyles = useTaskListStyles();
  const flexStyle = useFlexStyles();
  let style = undefined;
  if (taskList) {
    style = taskListStyles.taskListContainer;
  } else if (isFlex) {
    style = flexStyle.directionRowStyle;
  }

  const Wrapper = useCallback(
    (props: PropsWithChildren<any>) => {
      if (!tooltip) {
        return <>{props.children}</>;
      }

      return (
        <Tooltip title={tooltip} placement="top" arrow enterTouchDelay={0}>
          {props.children}
        </Tooltip>
      );
    },
    [tooltip],
  );

  return (
    <Wrapper>
      <Box sx={style} flexWrap={flexWrap}>
        {keyLabel && (
          <Text heavy sx={taskList ? taskListStyles.key : styles.key}>
            {keyLabel}:
          </Text>
        )}
        {children ? (
          children
        ) : (
          <Text sx={taskList ? taskListStyles.value : styles.value}>
            {value}
          </Text>
        )}
      </Box>
    </Wrapper>
  );
};

export default React.memo(KeyValuePair);
