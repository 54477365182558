import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const container: SxProps<Theme> = useMemo(
    () => ({
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  );

  const signIn: SxProps<Theme> = useMemo(
    () => ({
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(8, 16),
      width: 'fit-content',
      borderRadius: theme.spacing(1.2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(4),
      '& button': {
        width: 'fit-content',
      },
    }),
    [theme],
  );

  const signInText: SxProps<Theme> = useMemo(() => {
    return {
      textAlign: 'center',
      '*': {
        display: 'block',
      },
    };
  }, []);

  return {container, signIn, signInText};
};
