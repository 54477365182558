import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box} from '@mui/material';

import {NavItem} from '@/atoms/NavItem';
import {Modules} from '@/definitions/users';
import {useCurrentModule} from '@/hooks/useCurrentModule';
import {roleToRoute, moduleOrder} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';

import {IFooter, IList} from './interfaces';
import {useStyle} from './styles';

const PageFooter = ({modules, roles, loyaltyProgram}: IFooter) => {
  const {footer, navItems} = useStyle();
  const {t} = useTranslation();
  const {currentModule} = useCurrentModule();
  const {goToPath} = useNavigation();

  const items: IList[] = useMemo(() => {
    if (!modules || !roles) return [];
    const role = roles[0].name;
    const routes = roleToRoute[role];

    return moduleOrder.reduce((acc, module) => {
      if (!modules.includes(module)) {
        return acc;
      }

      return [
        ...acc,
        {
          label:
            module === Modules.LOYALTY ? loyaltyProgram : t(`Footer.${module}`),
          isActive: module === currentModule,
          linkTo: routes[module]!,
          icon: undefined,
        },
      ];
    }, [] as IList[]);
  }, [currentModule, loyaltyProgram, modules, roles, t]);

  const renderItems = useMemo(
    () => (
      <Box sx={navItems}>
        {items.map(({label, isActive, linkTo, icon}) => (
          <NavItem
            key={label}
            id={label}
            label={label}
            active={isActive}
            icon={icon}
            onClick={() => goToPath(linkTo)}
          />
        ))}
      </Box>
    ),
    [goToPath, items, navItems],
  );

  return <Box sx={footer}>{renderItems}</Box>;
};

export default React.memo(PageFooter);
