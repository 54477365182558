import React from 'react';

import {Box, Stack, useTheme} from '@mui/material';

import {DashboardContainerProps} from '@/atoms/DashboardContainer/definitions';
import {Text} from '@/atoms/Typography/Text';

const DashboardContainer = ({
  title,
  subtitle,
  action,
  children,
}: DashboardContainerProps) => {
  const theme = useTheme();

  return (
    <Stack direction="column" gap={1.6}>
      <Stack direction="row" justifyContent="space-between" paddingX={1.6}>
        <Box>
          <Text
            variant="headline"
            lineHeight={theme.typography.headline.fontSize}
            uppercase
            superHeavy>
            {title}
          </Text>
          {
            subtitle && (
              <Text marginLeft={1.6}>{subtitle}</Text>
            )
          }
        </Box>
        {action && action}
      </Stack>
      {children}
    </Stack>
  );
};

export default React.memo(DashboardContainer);
