import React from 'react';

import {Headline2TextProps} from '@/atoms/Headline2/interfaces.d';
import {Text} from '@/atoms/Typography/Text';

const Headline2 = ({children, ...textProps}: Headline2TextProps) => {
  return (
    <Text variant="headline2" lineHeight={1.2} {...textProps}>
      {children}
    </Text>
  );
};

export default Headline2;
