import React, {useMemo, useEffect} from 'react';

import {Box, Stack} from '@mui/material';

import {AppointmentCard} from '@/molecules/AppointmentCard';
import {NoAppointmentsScreen} from '@/molecules/ScheduledAppointments/Screens/index';
import {AppointmentsListProps} from '@/molecules/ScheduledAppointments/Screens/interfaces';
import {useStyles} from '@/molecules/ScheduledAppointments/styles';

const AppointmentsList = ({
  appointments,
  isLoading,
  selectedAppointment,
  onSelectAppointment,
}: AppointmentsListProps) => {
  const styles = useStyles();

  useEffect(() => {
    return () => {
      onSelectAppointment(undefined);
    };
  }, [onSelectAppointment]);

  const renderContent = useMemo(() => {
    if (!isLoading && appointments && appointments?.length > 0) {
      return (
        <Stack direction="column" gap={0.8}>
          {appointments.map(appointment => (
            <AppointmentCard
              key={appointment?.id}
              {...appointment}
              selected={selectedAppointment?.id === appointment?.id}
              onSelect={() => onSelectAppointment(appointment)}
            />
          ))}
        </Stack>
      );
    }

    return <NoAppointmentsScreen isLoading={isLoading} />;
  }, [appointments, isLoading, onSelectAppointment, selectedAppointment?.id]);

  return <Box sx={styles.contentAppointments}>{renderContent}</Box>;
};

export default React.memo(AppointmentsList);
