import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import copy from 'copy-to-clipboard';

import {useToast} from '@/hooks/toast';

export const useClipboard = () => {
  const {showSuccessToast} = useToast();
  const {t} = useTranslation();

  const copyToClipboard = useCallback(
    (value: string, message?: string) => {
      copy(value, {
        format: 'text/plain',
        onCopy: () =>
          showSuccessToast(message ?? t('Generic.copiedToClipboard')),
      });
    },
    [showSuccessToast, t],
  );

  return useMemo(
    () => ({
      copyToClipboard,
    }),
    [copyToClipboard],
  );
};
