import {faker} from '@faker-js/faker';

import {SingleLoyaltyTierInfo, LOYALTY_STATUS} from '@/store/customersInfo';

const loyaltyBoxMock = (status: LOYALTY_STATUS): SingleLoyaltyTierInfo => {
  return {
    email: faker.internet.email(),
    cardNumber: faker.string.numeric(8),
    currentTier: {
      currentBalanceValue: faker.number.int({min: 1000, max: 2000}),
      pointsToNextTier: faker.number.int({min: 1000, max: 2000}),
      lastDateTransaction: faker.date.recent(),
      configuration: {
        minThreshold: faker.number.int({min: 1000, max: 2000}),
        maxThreshold: faker.number.int({min: 2000, max: 3000}),
        tierName: faker.helpers.arrayElement(['Bronze', 'Silver', 'Gold']),
        tierCode: faker.helpers.arrayElement(['B', 'S', 'G']),
      },
    },
    nextTierConfig: {
      tierCode: faker.helpers.arrayElement(['B', 'S', 'G']),
      tierName: faker.helpers.arrayElement(['Bronze', 'Silver', 'Gold']),
      minThreshold: faker.number.int({min: 2000, max: 3000}),
      maxThreshold: faker.number.int({min: 3000, max: 4000}),
    },
    status,
  };
};

const loyaltyBoxMockList = (
  isSubscribing: boolean,
  length: number,
  status: LOYALTY_STATUS,
): SingleLoyaltyTierInfo[] | null => {
  return isSubscribing
    ? null
    : Array.from({length}, () => loyaltyBoxMock(status));
};

export const loyaltyBoxMockListSubscribing = loyaltyBoxMockList(
  true,
  0,
  LOYALTY_STATUS.ACTIVE,
);
export const loyaltyBoxMockListPopulatedActive = loyaltyBoxMockList(
  false,
  10,
  LOYALTY_STATUS.ACTIVE,
);
export const loyaltyBoxMockListPopulatedSuspended = loyaltyBoxMockList(
  false,
  10,
  LOYALTY_STATUS.SUSPENDED,
);
