import {useMemo, useCallback} from 'react';

import {SxProps, Theme, useMediaQuery, useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  const calendarContainer = useCallback(
    (isDisabled: boolean, showMonthSelection: boolean): SxProps<Theme> => ({
      backgroundColor: theme.palette.common.white,
      width: theme.spacing(showMonthSelection ? 32.8 : 24.8),
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(1.6),
      ...(isDisabled && {
        opacity: 0.3,
        pointerEvents: 'none',
      }),
    }),
    [theme],
  );

  const calendarHeaderContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 1fr',
      alignItems: 'center',
      justifyItems: 'center',
    }),
    [],
  );

  const calendarHeaderArrows = useMemo<SxProps<Theme>>(
    () => ({
      width: theme.spacing(2.4),
      height: theme.spacing(2.4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.spacing(0.8),
      cursor: 'pointer',
      padding: theme.spacing(0.2),
      transition: 'all 0.2s linear',
      svg: {
        fill: theme.palette.common.black,
      },
      ':hover': {
        backgroundColor: theme.palette.common.black,
        svg: {
          fill: theme.palette.common.white,
        },
      },
    }),
    [theme],
  );

  const calendarHeaderLabel = useMemo<SxProps<Theme>>(
    () => ({
      paddingY: theme.spacing(0.2),
      width: 'max-content',
      marginX: 'auto',
      textTransform: 'capitalize',
      cursor: 'pointer',
      ':hover': {
        opacity: 0.7,
      },
    }),
    [theme],
  );

  const calendarHeaderDaysContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'contents',
      '.MuiTypography-root': {
        textTransform: 'capitalize',
        paddingBottom: theme.spacing(1.2),
      },
    }),
    [theme],
  );

  const calendarDaysContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gap: theme.spacing(0.4),
      gridTemplateColumns: 'repeat(7, 1fr)',
      justifyItems: 'center',
      alignItems: 'center',
    }),
    [theme],
  );

  const calendarDayContainer = useCallback(
    (
      isCurrentMonth: boolean,
      isSelected: boolean,
      isDisabled: boolean,
      isCurrentDay: boolean,
      isInRange: boolean,
    ): SxProps<Theme> => ({
      position: 'relative',
      cursor: 'pointer',
      borderRadius: theme.spacing(0.8),
      width: theme.spacing(3.2),
      height: theme.spacing(3.2),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `${theme.spacing(0.1)} solid transparent`,
      transition: 'all 0.2s linear',
      ':hover': isDesktop && {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      ...(!isCurrentMonth && {
        opacity: 0.3,
      }),
      ...(isCurrentDay && {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        border: `${theme.spacing(0.1)} solid ${theme.palette.common.black}`,
        borderRadius: theme.spacing(0.8),
        ':hover': {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
      }),
      ...(isInRange &&
        !isDisabled &&
        !isSelected && {
          backgroundColor: theme.palette.primary[200],
        }),
      ...(isSelected && {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        border: `${theme.spacing(0.1)} solid ${theme.palette.common.black}`,
      }),
      ...(isDisabled && {
        pointerEvents: 'none',
        opacity: 0.3,
      }),
    }),
    [theme, isDesktop],
  );

  const calendarDayButton = useCallback(
    (isCurrentDay: boolean, isSelected: boolean): SxProps<Theme> => ({
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.2s linear',
      ...(isCurrentDay && {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        border: `${theme.spacing(0.1)} solid ${theme.palette.common.black}`,
        borderRadius: theme.spacing(0.8),
        ':hover': {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
      }),
    }),
    [theme],
  );

  const monthSelectorContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      rowGap: theme.spacing(1.6),
      columnGap: theme.spacing(0.8),
      gridTemplateColumns: 'repeat(3, 1fr)',
      justifyItems: 'center',
    }),
    [theme],
  );

  const monthSelectorItemContainer = useCallback(
    (
      isCurrentMonth: boolean,
      isSelected: boolean,
      isDisabled: boolean,
    ): SxProps<Theme> => ({
      width: '100%',
      paddingY: theme.spacing(0.4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.spacing(10),
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: `${theme.spacing(0.1)} solid ${theme.palette.common.black}`,
      transition: 'all 0.2s linear',
      textTransform: 'capitalize',
      cursor: 'pointer',
      ':hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      },
      ...(isSelected && {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      }),
      ...(isCurrentMonth && {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
      }),
      ...(isDisabled && {
        pointerEvents: 'none',
        opacity: 0.3,
      }),
    }),
    [theme],
  );

  return useMemo(
    () => ({
      calendarContainer,
      calendarHeaderContainer,
      calendarHeaderArrows,
      calendarHeaderLabel,
      calendarHeaderDaysContainer,
      calendarDaysContainer,
      calendarDayContainer,
      calendarDayButton,
      monthSelectorContainer,
      monthSelectorItemContainer,
    }),
    [
      calendarContainer,
      calendarHeaderContainer,
      calendarHeaderArrows,
      calendarHeaderLabel,
      calendarHeaderDaysContainer,
      calendarDaysContainer,
      calendarDayContainer,
      calendarDayButton,
      monthSelectorContainer,
      monthSelectorItemContainer,
    ],
  );
};
