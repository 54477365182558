import React from 'react';

import {Pagination} from '@mui/material';

import {IPagination} from './interfaces';
import {useStyle} from './styles';

export const CustomPagination = ({
  currentPage,
  totalPages,
  setPage,
}: IPagination) => {
  const {pagination} = useStyle();

  return (
    <Pagination
      sx={pagination}
      count={totalPages}
      page={currentPage}
      variant="outlined"
      shape="rounded"
      onChange={setPage}
    />
  );
};
