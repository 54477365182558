import React, {useCallback, useMemo, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {BsChevronExpand} from 'react-icons/bs';

import {Chip, Box, Stack} from '@mui/material';

import {ChipOrderByProps} from '@/atoms/ChipOrderBy/interfaces';
import {useStyles} from '@/atoms/ChipOrderBy/styles';
import {TinyText} from '@/atoms/TinyText';

const BASE_TRANSLATION_PATH = 'PurchaseList.filters';

const ChipOrderBy = ({value = 'ASC', changeValue}: ChipOrderByProps) => {
  const {t} = useTranslation();
  const styles = useStyles();

  const toggleValue = useCallback(() => {
    changeValue(value === 'ASC' ? 'DESC' : 'ASC');
  }, [changeValue, value]);

  const label = useMemo<ReactNode>(() => {
    return (
      <Stack
        display="grid"
        gridTemplateColumns="1fr max-content"
        gap={0.4}
        alignItems="center">
        <TinyText overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {t(`${BASE_TRANSLATION_PATH}.orderBy.${value}`)}
        </TinyText>
        <BsChevronExpand fontSize={20} />
      </Stack>
    );
  }, [t, value]);

  return (
    <Box onClick={toggleValue} paddingY={0.8}>
      <Chip
        sx={styles.chip}
        disabled={false}
        label={label}
        clickable={true}
        variant="filterDefault"
      />
    </Box>
  );
};

export default React.memo(ChipOrderBy);
