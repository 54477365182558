import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = (isSuspended?: boolean) => {
  const theme = useTheme();

  const infoContainer: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      flexDirection: isSuspended ? 'row' : 'column',
      flex: 1,
      justifyContent: 'center',
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    }),
    [theme, isSuspended],
  );

  const infoColumn: SxProps<Theme> = useMemo(
    () => ({
      width: isSuspended ? '50%' : '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    }),
    [theme, isSuspended],
  );

  const dialog: SxProps<Theme> = useMemo(
    () => ({
      '.MuiPaper-root': {
        maxWidth: '690px',
      },

      '.MuiDialogContent-root': {
        '::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    }),
    [theme],
  );

  const select: SxProps<Theme> = useMemo(
    () => ({
      border: `1px solid ${theme.palette.grey[700]}`,
      borderRadius: theme.spacing(0.8),
      padding: theme.spacing(1),
    }),
    [theme],
  );

  const editForm: SxProps<Theme> = useMemo(
    () => ({
      paddingRight: theme.spacing(1),
      '& .MuiFormHelperText-root': {
        textAlign: 'left',
      },
      '& .MuiInputBase-root': {
        height: '44px',
      },
    }),
    [theme],
  );

  const cardHeader: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    }),
    [theme],
  );

  return {infoContainer, infoColumn, dialog, select, editForm, cardHeader};
};
