import {ReportDetailFiltersReducer} from '@/store/reportDetailsFilters/definitions';

export const selectReportDetailFilters = (
  key: string,
  reportDetailFilters: ReportDetailFiltersReducer,
) => {
  if (key in reportDetailFilters) {
    return reportDetailFilters[key];
  }

  return null;
};
