import React from 'react';

import {BodyTextProps} from '@/atoms/BodyText/interfaces';
import {Text} from '@/atoms/Typography/Text';

const BodyText = ({children, ...textProps}: BodyTextProps) => {
  return (
    <Text variant="body" lineHeight={1.2} {...textProps}>
      {children}
    </Text>
  );
};

export default BodyText;
