import React, {memo, useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';

import {Box} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import isTodayPlugin from 'dayjs/plugin/isToday';
import objectPlugin from 'dayjs/plugin/toObject';
import weekdayPlugin from 'dayjs/plugin/weekday';

import {useStyles} from '@/atoms/Calendar/styles';
import {Text} from '@/atoms/Typography/Text';
import {Colors} from '@/themes/variables';

enum MONTH_CHANGE_ACTION {
  ADD,
  SUBTRACT,
}

enum CHANGE_VALUE {
  MONTH = 'month',
  YEAR = 'year',
}

const now = (selectedDate?: dayjs.Dayjs) => {
  if (!selectedDate) {
    return dayjs();
  }

  return selectedDate;
};

type CalendarProps = {
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  selectedDate?: dayjs.Dayjs;
  onSelect: (date: dayjs.Dayjs) => unknown;
  showGoToToday?: boolean;
  disabled?: boolean;
  selectedStart?: dayjs.Dayjs;
  selectedEnd?: dayjs.Dayjs;
  defaultCurrentMonth?: dayjs.Dayjs;
  wholeMonthSelection?: boolean;
};

const Calendar = ({
  minDate,
  maxDate,
  selectedDate,
  onSelect,
  showGoToToday = false,
  disabled = false,
  selectedStart,
  selectedEnd,
  defaultCurrentMonth,
  wholeMonthSelection,
}: CalendarProps) => {
  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  const {t} = useTranslation();
  const styles = useStyles();

  const [currentMonth, setCurrentMonth] = useState(() =>
    now(defaultCurrentMonth || selectedDate),
  );
  const [showMonthSelection, setShowMonthSelection] = useState(() => {
    if (wholeMonthSelection) {
      return !currentMonth?.isSame(dayjs(), 'month');
    }

    return false;
  });

  const handleSelectionChange = useCallback(
    (action: MONTH_CHANGE_ACTION, value: CHANGE_VALUE) => {
      let updatedValue: dayjs.Dayjs;

      switch (action) {
        case MONTH_CHANGE_ACTION.ADD:
          updatedValue = currentMonth.add(1, value);
          break;
        case MONTH_CHANGE_ACTION.SUBTRACT:
        default:
          updatedValue = currentMonth.subtract(1, value);
          break;
      }

      setCurrentMonth(() => updatedValue);
      if (wholeMonthSelection) {
        setShowMonthSelection(() => {
          const isSameMonthAsToday = updatedValue.isSame(dayjs(), 'month');
          return !isSameMonthAsToday;
        });
      }
    },
    [currentMonth, wholeMonthSelection],
  );

  const handleDateSelect = useCallback(
    (date: dayjs.Dayjs) => {
      onSelect(date);
    },
    [onSelect],
  );

  const handleMonthChange = useCallback(
    (month: number) => {
      const updatedValue = currentMonth.set('month', month);
      setCurrentMonth(() => updatedValue);
      if (wholeMonthSelection) {
        const isSameMonthAsToday = updatedValue.isSame(dayjs(), 'month');
        isSameMonthAsToday
          ? setShowMonthSelection(false)
          : handleDateSelect(updatedValue);
      } else {
        setShowMonthSelection(false);
      }
    },
    [currentMonth, handleDateSelect, wholeMonthSelection],
  );

  const notCurrentDate = useMemo(() => {
    const today = {...now().toObject()};
    const currentSelection = {...currentMonth.toObject()};

    return !showMonthSelection
      ? today.months !== currentSelection?.months ||
          today.years !== currentSelection?.years
      : today.years !== currentSelection?.years;
  }, [currentMonth, showMonthSelection]);

  return (
    <Box
      sx={styles.calendarContainer(disabled, showMonthSelection)}
      aria-modal
      role="dialog">
      <CalendarHeader
        currentMonth={currentMonth}
        handleSelectionChange={handleSelectionChange}
        monthSelectionEnabled={showMonthSelection}
        toggleSelection={() => setShowMonthSelection(prevValue => !prevValue)}
      />
      {showMonthSelection ? (
        <>
          {wholeMonthSelection && (
            <Text variant="body" color={Colors.GreyText}>
              {t('ChipFilters.calendarMonthViewMessage')}
            </Text>
          )}
          <MonthSelector
            currentSelection={selectedDate}
            currentMonth={currentMonth}
            handleMonthChange={handleMonthChange}
            minDate={minDate}
            maxDate={maxDate}
          />
        </>
      ) : (
        <>
          <CalendarDays
            currentMonth={currentMonth}
            onDateSelect={handleDateSelect}
            selectedDate={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
            selectedStart={selectedStart}
            selectedEnd={selectedEnd}
            wholeMonthSelection={wholeMonthSelection}
          />
        </>
      )}
      {showGoToToday && notCurrentDate && (
        <div className="flex justify-center">
          <button
            className="cursor-pointer text-secondary p-2 pb-0 xl:text-lg hover:opacity-70"
            type="button"
            onClick={e => {
              e.stopPropagation();
              setCurrentMonth(now());
            }}
            aria-label={`Go back to current month dates`}>
            {t('filters:dates.backToToday')}
          </button>
        </div>
      )}
    </Box>
  );
};

export default Calendar;

type CalendarHeaderProps = {
  currentMonth: dayjs.Dayjs;
  handleSelectionChange: (
    action: MONTH_CHANGE_ACTION,
    value: CHANGE_VALUE,
  ) => void;
  toggleSelection: () => unknown;
  monthSelectionEnabled: boolean;
};

const CalendarHeader = memo(
  ({
    currentMonth,
    handleSelectionChange,
    toggleSelection,
    monthSelectionEnabled,
  }: CalendarHeaderProps) => {
    const styles = useStyles();

    const dateFormat = useMemo(() => {
      if (monthSelectionEnabled) {
        return 'YYYY';
      } else {
        return 'MMMM YYYY';
      }
    }, [monthSelectionEnabled]);

    const handleChange = useCallback(
      (action: MONTH_CHANGE_ACTION) => {
        const value = monthSelectionEnabled
          ? CHANGE_VALUE.YEAR
          : CHANGE_VALUE.MONTH;
        handleSelectionChange(action, value);
      },
      [handleSelectionChange, monthSelectionEnabled],
    );

    return (
      <Box sx={styles.calendarHeaderContainer}>
        <Box
          sx={styles.calendarHeaderArrows}
          onClick={() => handleChange(MONTH_CHANGE_ACTION.SUBTRACT)}>
          <BsChevronLeft />
        </Box>
        <Text
          sx={styles.calendarHeaderLabel}
          {...(!monthSelectionEnabled && {
            onClick: toggleSelection,
          })}
          aria-live="polite"
          aria-label={`${currentMonth.format(
            dateFormat,
          )} - Click to switch to ${
            monthSelectionEnabled ? 'Month and Day' : 'Year and Month'
          } selection`}>
          {currentMonth.format(dateFormat)}
        </Text>
        <Box
          sx={styles.calendarHeaderArrows}
          onClick={() => handleChange(MONTH_CHANGE_ACTION.ADD)}>
          <BsChevronRight />
        </Box>
      </Box>
    );
  },
);

CalendarHeader.displayName = 'CalendarHeader';

const CalendarDaysName = memo(() => {
  const styles = useStyles();

  const days = useMemo(() => {
    const values = [];

    for (let i = 0; i < 7; i++) {
      const date = now().weekday(i);
      values.push({
        label: date.format('dd').substring(0, 2),
        abbr: date.format('dddd'),
      });
    }

    return values;
  }, []);

  return (
    <Box sx={styles.calendarHeaderDaysContainer}>
      {days.map((day, index) => {
        return (
          <Text
            key={`${day}_${index}`}
            aria-label={day.abbr}
            variant="xs"
            medium
            paddingBottom={0.5}>
            {day.label}
          </Text>
        );
      })}
    </Box>
  );
});

CalendarDaysName.displayName = 'CalendarDaysName';

type CalendarDaysProps = {
  currentMonth: dayjs.Dayjs;
  onDateSelect: (date: dayjs.Dayjs) => void;
  selectedDate?: dayjs.Dayjs;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  selectedStart?: dayjs.Dayjs;
  selectedEnd?: dayjs.Dayjs;
  wholeMonthSelection: CalendarProps['wholeMonthSelection'];
};

const CalendarDays = memo(
  ({
    currentMonth,
    onDateSelect,
    selectedDate,
    minDate,
    maxDate,
    selectedStart,
    selectedEnd,
    wholeMonthSelection = false,
  }: CalendarDaysProps) => {
    const styles = useStyles();

    const [currentHover, setCurrentHover] = useState<Dayjs>();

    const checkRange = useCallback(
      (date: Dayjs) => {
        if (selectedDate && selectedStart) {
          return selectedDate?.isAfter(date)!;
        } else if (selectedDate && selectedEnd) {
          return selectedDate?.isBefore(date)!;
        } else if (selectedStart) {
          return currentHover?.isAfter(date)!;
        } else if (selectedEnd) {
          return currentHover?.isBefore(date)!;
        }

        return false;
      },
      [currentHover, selectedDate, selectedEnd, selectedStart],
    );

    const checkSelected = useCallback(
      (date: Dayjs) => {
        if (selectedDate) {
          return (
            selectedStart?.isSame(date, 'date')! ||
            selectedEnd?.isSame(date, 'date')! ||
            selectedDate?.isSame(date, 'date')!
          );
        } else {
          return (
            selectedStart?.isSame(date, 'date')! ||
            selectedEnd?.isSame(date, 'date')!
          );
        }
      },
      [selectedDate, selectedEnd, selectedStart],
    );

    const formatDateObject = useCallback(
      (date: dayjs.Dayjs) => {
        const clonedObject = {...date.toObject()};

        return {
          day: clonedObject.date,
          month: clonedObject.months + 1,
          year: clonedObject.years,
          formattedDate: date.format('dddd, DD MMMM YYYY'),
          isCurrentMonth: clonedObject.months === currentMonth.month(),
          isCurrentDay: date.isToday(),
          originalValue: date,
          isSelected: checkSelected(date),
          isDisabled:
            (minDate ? date.isBefore(minDate) : false) ||
            (maxDate ? date.isAfter(maxDate) : false) ||
            (wholeMonthSelection && date.isBefore(dayjs(), 'month')) ||
            (wholeMonthSelection && date.isAfter(dayjs(), 'month')),
          originalDate: date,
          isInRange: checkRange(date),
        };
      },
      [
        currentMonth,
        checkSelected,
        minDate,
        maxDate,
        wholeMonthSelection,
        checkRange,
      ],
    );

    const allDays = useMemo(() => {
      let currentDate = currentMonth.startOf('month').weekday(0);
      const nextMonth = currentMonth.add(1, 'month').month();

      const allDates = [];
      let weekDates = [];

      let weekCounter = 1;

      while (currentDate.weekday(0).toObject().months !== nextMonth) {
        const formatted = formatDateObject(currentDate);

        weekDates.push(formatted);

        if (weekCounter === 7) {
          allDates.push({dates: weekDates});
          weekDates = [];
          weekCounter = 0;
        }

        weekCounter++;
        currentDate = currentDate.add(1, 'day');
      }

      return allDates;
    }, [currentMonth, formatDateObject]);

    return (
      <Box sx={styles.calendarDaysContainer}>
        <CalendarDaysName />
        {allDays.map(week => {
          return week.dates.map(date => {
            return (
              <Box
                key={`${date?.day}-${date?.month}`}
                sx={styles.calendarDayContainer(
                  date?.isCurrentMonth,
                  date?.isSelected,
                  date?.isDisabled,
                  date?.isCurrentDay,
                  date?.isInRange,
                )}
                {...(!date?.isDisabled && {
                  onClick: () => onDateSelect(date?.originalValue),
                })}
                onMouseEnter={() => setCurrentHover(date?.originalDate)}
                onMouseLeave={() => setCurrentHover(undefined)}>
                {date?.day}
              </Box>
            );
          });
        })}
      </Box>
    );
  },
);

CalendarDays.displayName = 'CalendarDays';

type MonthSelectorProps = {
  currentSelection: dayjs.Dayjs | undefined;
  currentMonth: dayjs.Dayjs;
  handleMonthChange: (month: number) => void;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
};

const MonthSelector = memo(
  ({
    currentSelection,
    currentMonth,
    handleMonthChange,
    minDate,
    maxDate,
  }: MonthSelectorProps) => {
    const styles = useStyles();

    const today = useRef<dayjs.Dayjs>(now());

    const formatMonth = useCallback(
      (date: dayjs.Dayjs) => {
        return {
          label: date?.format('MMMM'),
          value: date?.month(),
          formattedMonth: date?.format('MMMM YYYY'),
          isCurrentMont:
            date?.month() === today.current?.month() &&
            date?.year() === today.current?.year(),
          isSelected: currentSelection
            ? date?.month() === currentSelection?.month() &&
              date?.year() === currentSelection?.year()
            : false,
          isDisabled:
            (minDate ? date.isBefore(minDate, 'month') : false) ||
            (maxDate ? date.isAfter(maxDate, 'month') : false),
          originalValue: date,
        };
      },
      [currentSelection, minDate, maxDate],
    );

    const monthList = useMemo(() => {
      const list = [];

      for (let i = 0; i < 12; i++) {
        const date = dayjs()
          .set('day', 1)
          .set('month', i)
          .set('year', currentMonth?.year());
        list.push(formatMonth(date));
      }

      return list;
    }, [formatMonth, currentMonth]);

    return (
      <Box sx={styles.monthSelectorContainer}>
        {monthList.map(month => {
          return (
            <Box
              key={month?.label}
              sx={styles.monthSelectorItemContainer(
                month?.isCurrentMont,
                month?.isSelected,
                month?.isDisabled,
              )}
              {...(!month?.isDisabled && {
                onClick: (e: any) => {
                  e.stopPropagation();
                  handleMonthChange(month?.value);
                },
              })}>
              {month?.label}
            </Box>
          );
        })}
      </Box>
    );
  },
);

MonthSelector.displayName = 'MonthSelector';
