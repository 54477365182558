import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, CircularProgress} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Text} from '@/atoms/Typography/Text';

import {useStyles} from '../styles';
import {NoResultScreenProps} from './interfaces';

const NoResultScreen = ({results, isLoading, taskList = false}: NoResultScreenProps) => {
  const {t} = useTranslation();
  const styles = useStyles();

  const content = useMemo(() => {
    if (isLoading) return <CircularProgress />;

    return (
      <>
        <IconSVG
          icon={results ? 'shop' : 'search_no_results'}
          size={45}
          color="#000000"
        />
        <Text variant="body" sx={styles.noResultText}>
          {results ? t('Stores.search.label') : t('Stores.search.notFound')}
        </Text>
      </>
    );
  }, [isLoading, results, t, styles.noResultText]);

  return <Box sx={[styles.contentInitial, taskList && {height: '200px'}]}>{content}</Box>;
};

export default React.memo(NoResultScreen);
