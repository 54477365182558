import React, {useState, useMemo, useCallback} from 'react';
import {PiCaretDownBold} from 'react-icons/pi';

import {Chip, Box, Menu, Stack} from '@mui/material';

import {BodyText} from '@/atoms/BodyText';
import {useStyles} from '@/atoms/ChipNumber/styles';
import {createNumberArray} from '@/lib/utils';

interface ChipNumberProps {
  id: string;
  selectedValue: number;
  onSelected: (selected: number) => unknown;
  startNumber: number;
  endNumber: number;
}

const ChipNumber = ({
  id,
  selectedValue,
  onSelected,
  startNumber = 0,
  endNumber = 100,
}: ChipNumberProps) => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const menuOpen = useMemo<boolean>(() => Boolean(anchorEl), [anchorEl]);

  const toggleMenu = useCallback(
    (event?: React.MouseEvent<HTMLDivElement> | any) => {
      setAnchorEl(event?.currentTarget);
      event?.stopPropagation();
    },
    [],
  );

  const formatNumber = useCallback((number: number) => {
    if (number < 10) {
      return `0${number}`;
    }

    return number.toString();
  }, []);

  const label = useMemo(() => {
    return (
      <Stack className={`chipLabel ${menuOpen ? 'chipLabel__open' : ''}`}>
        <BodyText marginTop={0.4}>{formatNumber(selectedValue)}</BodyText>
        <PiCaretDownBold fontSize={12} className="icon" />
      </Stack>
    );
  }, [formatNumber, menuOpen, selectedValue]);

  const numbersList = useMemo(() => {
    return createNumberArray(startNumber, endNumber);
  }, [endNumber, startNumber]);

  return (
    <>
      <Box onClick={toggleMenu} paddingY={0.8}>
        <Chip sx={styles.chip} label={label} variant="filterDefault" />
      </Box>
      {menuOpen && (
        <Menu
          sx={styles.menu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={true}
          onClose={() => toggleMenu()}>
          <Stack direction="column" sx={{paddingY: '8px'}}>
            {numbersList.map(number => {
              return (
                <Box
                  key={`${id}_${number}`}
                  textAlign="center"
                  className={`singleNumber ${
                    selectedValue === number ? 'singleNumber__active' : ''
                  }`}
                  onClick={() => {
                    onSelected(number);
                    toggleMenu();
                  }}>
                  {formatNumber(number)}
                </Box>
              );
            })}
          </Stack>
        </Menu>
      )}
    </>
  );
};

export default React.memo(ChipNumber);
