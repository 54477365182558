export interface GetBenefitsParams {
  bannerSearch: string;
  countrySearch: string;
  email: string;
}

export enum NOTIFICATION_TYPE {
  PROMO = 'PROMO',
  COMMUNICATION = 'COMMUNICATION',
}

export enum PROMO_TYPE {
  WELCOME = 'WELCOME',
  BIRTHDAY = 'BIRTHDAY',
  ALL_RX = 'ALL_RX',
  SUN_RX = 'SUN_RX',
  CONTACTS = 'CONTACTS',
  ALL = 'ALL',
}

export interface Communication {
  notificationId?: string;
  email?: string;
  notificationType?: NOTIFICATION_TYPE;
  startDate?: string;
  endDate?: string;
  customerName?: string;
  customerSurname?: string;
  title?: string;
  cardTitle?: string;
  cardDescription?: string;
  termsAndConditions?: string;
  promoType?: PROMO_TYPE;
  promoCodeOnline?: string;
  promoCodeRetail?: string;
  promoCodeEquals?: boolean;
}

export interface GetBenefitsResponse {
  benefits: Array<Communication>;
  notifications: Array<Communication>;
}
