import React, {useCallback} from 'react';

import {InputAdornment} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';

import {useStyle} from './styles';

const IconComponent = ({onClick}: {onClick?: () => unknown}) => {
  const styles = useStyle();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClick && onClick();
    },
    [onClick],
  );

  return (
    <InputAdornment position="end" sx={styles.selectIcon} onClick={handleClick}>
      <IconSVG icon="chevron_down" size={10} />
    </InputAdornment>
  );
};

export default React.memo(IconComponent);
