import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = (isFilter: boolean) => {
  const theme = useTheme();

  const paper: SxProps<Theme> = useMemo(
    () => ({
      paddingY: theme.spacing(0.8),
      '.MuiContainer-root': {
        '.MuiBox-root': {
          '.MuiButtonBase-root': {
            paddingY: theme.spacing(0.2),
          },
          '.MuiChip-root': {
            textTransform: 'unset',
            fontSize: theme.typography.headline5.fontSize,
            [theme.breakpoints.up('lg')]: {
              fontSize: theme.typography.headline4.fontSize,
            },
          },
        },
      },
    }),
    [theme],
  );

  const firstLevelTabLabelContainer: SxProps<Theme> = useMemo(
    () => ({
      mb: theme.spacing(0),
    }),
    [theme],
  );

  const tabLabel: SxProps<Theme> = useMemo(
    () => ({
      mr: theme.spacing(2.4),

      '& .MuiChip-label': {
        textTransform: isFilter ? 'uppercase' : 'none',
      },
    }),
    [isFilter, theme],
  );

  const divider: SxProps<Theme> = useMemo(
    () => ({
      mb: theme.spacing(0.8),
      mt: theme.spacing(1.5),
      borderColor: theme.palette.primary[200],
      borderWidth: '0',
      borderBottomWidth: '0.5px',
    }),
    [theme],
  );

  const tabContentContainer: SxProps<Theme> = useMemo(
    () => ({
      mt: theme.spacing(1.5),
    }),
    [theme],
  );

  return {
    paper,
    tabLabel,
    divider,
    firstLevelTabLabelContainer,
    tabContentContainer,
  };
};
