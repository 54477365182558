import {useMemo} from 'react';
import {useTheme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyle = () => {
  const theme = useTheme();

  const container = useMemo(
    () => ({
      backgroundColor: Colors.Black,
      textAlign: 'center',
    }),
    [],
  );

  const content = useMemo(
    () => ({
      flex: 1,
      margin: 'auto',
      width: '100%',
      maxWidth: theme.spacing(86.6),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(8),
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(8),
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3.2),
      },
    }),
    [theme],
  );

  return {container, content};
};
