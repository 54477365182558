export interface Role {
  name: RoleName;
  description: string;
}

export enum RoleName {
  STORE_ASSOCIATE = 'SA',
  STORE_MANAGER = 'SM',
  AREA_MANAGER = 'RM',
  CC_SUPERVISOR = 'CCS',
  CC_AGENT = 'CCA',
}

export enum AuthenticatedUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum Modules {
  MYACCOUNT = 'MYACCOUNT',
  CLIENTELING = 'CLIENTELING',
  LOYALTY = 'LOYALTY',
}
