import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = (screenshot: string | null) => {
  const theme = useTheme();
  const screenshotBox: SxProps<Theme> = useMemo(
    () => ({
      width: 408,
      height: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '> img': {
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: 250,
      },

      svg: {
        color: theme.palette.grey[700],
      },
    }),
    [theme],
  );

  const button: SxProps<Theme> = useMemo(
    () => ({
      fontWeight: 500,
      textTransform: 'initial',
    }),
    [],
  );

  return {screenshotBox, button};
};
