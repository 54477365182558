import {useMemo, useCallback} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const usePaginationStyles = () => {
  const theme = useTheme();
  const iconContainer: SxProps<Theme> = useMemo(
    () => ({
      padding: theme.spacing(0.4),
      border: `${theme.spacing(0.1)} solid ${theme.palette.grey[400]}`,
      borderRadius: theme.spacing(0.4),
    }),
    [theme],
  );

  const iconStatus = useCallback(
    (active: boolean) => ({
      transition: 'all 0.2s linear',
      ...(active
        ? {
            pointerEvents: 'auto',
            cursor: 'pointer',
            backgroundColor: theme.palette.common.white,
            svg: {
              fill: theme.palette.common.black,
            },
            opacity: 1,
            '&:hover': {
              opacity: 0.7,
            },
          }
        : {
            pointerEvents: 'none',
            cursor: 'none',
            backgroundColor: theme.palette.grey[400],
            svg: {
              fill: theme.palette.grey[700],
            },
          }),
    }),
    [theme],
  );

  return useMemo(
    () => ({
      iconContainer,
      iconStatus,
    }),
    [iconContainer, iconStatus],
  );
};
