import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const commonButtons: SxProps<Theme> = useMemo(
    () => ({textTransform: 'uppercase', minWidth: '150px'}),
    [],
  );

  const closeIconButton: SxProps<Theme> = useMemo(
    () => ({
      width: 'fit-content',
      position: 'absolute',
      padding: '30px',
      left: '85%',
      top: 0,
    }),
    [],
  );

  return {commonButtons, closeIconButton};
};
