import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const statusContainer = useCallback(
    (taskIsEditable: boolean): SxProps<Theme> => ({
      display: 'grid',
      gap: theme.spacing(0.8),
      gridTemplateAreas: "'statusLabel statusLabel' 'statusValue statusIcon'",
      backgroundColor: Colors.GreyBackground,
      borderRadius: theme.spacing(0.8),
      paddingTop: theme.spacing(1.2),
      paddingX: theme.spacing(1.6),
      paddingBottom: theme.spacing(1),
      ...(!taskIsEditable && {
        opacity: 0.7,
        pointerEvents: 'none',
      }),
      '.statusLabel': {
        gridArea: 'statusLabel',
        color: Colors.GreyDark,
      },
      '.statusValue': {
        width: 'fit-content',
        gridArea: 'statusValue',
        flexDirection: 'row',
        alignItems: 'end',
        gap: theme.spacing(1.6),
        ...(taskIsEditable && {
          cursor: 'pointer',
          transition: 'all 0.2s linear',
          opacity: 1,
          '&:hover': {
            opacity: 0.7,
          },
        }),
      },
      '.statusIcon': {
        gridArea: 'statusIcon',
        justifySelf: 'end',
        alignSelf: 'end',
      },
    }),
    [theme],
  );

  const menu = useMemo<SxProps<Theme>>(
    () => ({
      '.MuiList-root': {
        width: theme.spacing(28.2),
        border: `${theme.spacing(0.1)} solid ${theme.palette.common.black}`,
        borderRadius: theme.spacing(0.4),
        paddingX: theme.spacing(0),
        paddingTop: theme.spacing(0.8),
        paddingBottom: theme.spacing(0),
        overflow: 'hidden',
        '.statusMenu': {
          flexDirection: 'column',
          '.statusGroup': {
            padding: theme.spacing(0.8, 1.6),
          },
          '.statusItem': {
            padding: theme.spacing(0.8, 1.6),
            paddingLeft: theme.spacing(3.2),
            cursor: 'pointer',
            transition: 'all 0.2s linear',
            backgroundColor: Colors.White,
            '&:last-child': {
              marginBottom: theme.spacing(0.8),
            },
            '&:hover': {
              backgroundColor: Colors.GreyBackground,
            },
            '&__active': {
              cursor: 'default',
              pointerEvents: 'none',
              backgroundColor: Colors.GreyBackground,
            },
          },
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      statusContainer,
      menu,
    }),
    [statusContainer, menu],
  );
};
