import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

const BORDER_RADIUS = '6px';

export const useStyle = (error: boolean) => {
  const theme = useTheme();

  const input: SxProps<Theme> = useMemo(
    () => ({
      width: '100%',
      '& .MuiInputBase-root': {
        position: 'relative',
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${
          error ? theme.palette.error.main : theme.palette.grey['600']
        }`,
        color: error ? theme.palette.error.main : theme.palette.common.black,
        fontSize: theme.typography.body.fontSize,
        width: '100%',
        padding: theme.spacing(1),
        '&:focus': {
          boxShadow: 'none',
          borderColor: theme.palette.primary.main,
          borderRadius: BORDER_RADIUS,
        },
        '&.Mui-disabled': {
          border: `1px solid ${theme.palette.grey['500']}`,
        },
      },
      '&:after': {
        // Remove ::after
        content: 'none',
      },
      '&:before': {
        // Remove ::before
        content: 'none',
      },
      '&.Mui-disabled': {
        fill: theme.palette.grey['700'],
        border: 'none',
      },
    }),
    [theme, error],
  );

  const iconComponent: SxProps<Theme> = useMemo(
    () => ({display: 'flex', mr: theme.spacing(1), mt: theme.spacing(0.3)}),
    [theme],
  );

  const menu: SxProps<Theme> = useMemo(
    () => ({
      marginTop: theme.spacing(0.4),
      borderRadius: BORDER_RADIUS,
      '.MuiPaper-root': {
        borderRadius: BORDER_RADIUS,
      },
      '& .MuiList-root': {
        py: 0,
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    }),
    [theme],
  );

  const menuItem: SxProps<Theme> = useMemo(
    () => ({
      px: theme.spacing(1),
      py: theme.spacing(0.5),
      color: theme.palette.common.black,
      '&:first-of-type': {
        borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
      },
      '&:last-of-type': {
        borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
      },
      '&:focus': {
        backgroundColor: theme.palette.grey[400],
      },
      '&.Mui-selected': {
        opacity: '50%',
        backgroundColor: theme.palette.common.white,
        '&:focus': {
          opacity: '100%',
          backgroundColor: theme.palette.grey[400],
        },
      },
    }),
    [theme],
  );

  return {input, iconComponent, menu, menuItem};
};
