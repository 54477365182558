import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container: SxProps<Theme> = useMemo(
    () => ({
      height: '44px',
      width: '44px',
      borderRadius: '5px',
      border: `2px solid ${theme.palette.common.black}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [theme],
  );

  const disabled: SxProps<Theme> = useMemo(
    () => ({
      pointerEvents: 'none',
      opacity: 0.7,
    }),
    [],
  );

  return {container, disabled};
};
