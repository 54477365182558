import React from 'react';

import {TinyTextProps} from '@/atoms/TinyText/interfaces';
import {Text} from '@/atoms/Typography/Text';

const TinyText = ({children, ...textProps}: TinyTextProps) => {
  return (
    <Text variant="tiny" lineHeight={1.2} {...textProps}>
      {children}
    </Text>
  );
};

export default TinyText;
