import React, {useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Box, Skeleton, Stack} from '@mui/material';

import {Dialog} from '@/atoms/Dialog';
import {CloseIconButton, CommonButtons} from '@/atoms/DialogButtons';
import {Text} from '@/atoms/Typography/Text';

import {useStyle} from '../styles';
import {IBookDialogProps} from './interfaces';

const BookDialog = ({
  promotion,
  isLoading = false,
  barCode,
  onConfirm,
  onClose,
  ...props
}: IBookDialogProps) => {
  const {t} = useTranslation();
  const {bookDialogContent} = useStyle();

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Skeleton
          height={140}
          width="100%"
          animation="wave"
          variant="rounded"
        />
      );
    }

    if (barCode) {
      return (
        <Stack gap={4}>
          <Stack gap={1} alignItems="center">
            <Text variant="headline5" superHeavy>
              {t('CustomerProfile.tables.rewardList.dialog.barCodeTitle')}
            </Text>
            <Text>
              {t('CustomerProfile.tables.rewardList.dialog.barCodeDescription')}
            </Text>
          </Stack>
          <Stack alignItems="center" gap={1.5}>
            <img src={barCode.barCodeBase64BenefitCode} alt="barCode" />
            {/* <Text superHeavy textTransform="capitalize" variant="headline2"> */}
            {/*   {barCode.code} */}
            {/* </Text> */}
          </Stack>
        </Stack>
      );
    }

    return (
      <Box sx={bookDialogContent}>
        <Text variant="headline5">
          <Trans
            i18nKey="CustomerProfile.tables.rewardList.dialog.bookConfirmationText"
            values={{promotion: promotion}}
            tOptions={{interpolation: {escapeValue: false}}}
            components={{italic: <Text italic variant="headline5" />}}
          />
        </Text>
      </Box>
    );
  }, [barCode, bookDialogContent, isLoading, promotion, t]);

  const actions = useMemo(() => {
    if (isLoading || barCode) {
      return <CloseIconButton onClose={onClose} />;
    }

    return (
      <CommonButtons
        onClose={onClose}
        confirmText={t('CustomerProfile.tables.rewardList.actions.claim')}
        onConfirm={onConfirm}
      />
    );
  }, [barCode, isLoading, onClose, onConfirm, t]);

  return <Dialog {...props} dialogContent={content} dialogActions={actions} />;
};

export default React.memo(BookDialog);
