import React, {useState, useCallback, useMemo} from 'react';

import {Stack, Divider, Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Headline5} from '@/atoms/Headline5';
import {IconSVG} from '@/atoms/IconSVG';
import {LabelValue} from '@/atoms/LabelValue';
import {LoadingPage} from '@/atoms/LoadingPage';
import {Modal} from '@/atoms/Modal';
import {TinyText} from '@/atoms/TinyText';
import {useToast} from '@/hooks/toast';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useStyles} from '@/hooks/usePrescriptionModal/styles';
import {capitalizeWords, numberFormatter} from '@/lib/utils';
import {
  usePrescriptions,
  GetPrescriptionByIdParams,
  GetPrescriptionByIdResponse,
  PrescriptionDirection,
} from '@/store/prescription';

const BASE_TRANSLATION = 'Transaction.prescriptionModal';

export const usePrescriptionModal = () => {
  const {showErrorToast} = useToast();
  const {getPrescriptionById} = usePrescriptions();
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);

  const [prescriptionParams, setPrescriptionParams] = useState<
    GetPrescriptionByIdParams | undefined
  >(undefined);

  const showPrescription = useCallback((params?: GetPrescriptionByIdParams) => {
    setPrescriptionParams(params);
  }, []);

  const showModal = useMemo(
    () => typeof prescriptionParams !== 'undefined',
    [prescriptionParams],
  );

  const {data: PrescriptionResponse, isLoading: PrescriptionLoading} = useQuery(
    {
      queryKey: ['prescription', prescriptionParams],
      queryFn: () => getPrescriptionById({...prescriptionParams!}),
      retry: false,
      enabled: showModal,
      refetchOnWindowFocus: false,
      onError: () => {
        setPrescriptionParams(undefined);
        showErrorToast(getTranslationWithValue(0, 'errorToast'));
      },
    },
  );

  const dialogTitle = useMemo(() => {
    const name = capitalizeWords(
      `${PrescriptionResponse?.prescriptionHeader?.patientLastName}`,
    );
    return (
      <Stack direction="row" gap={0.8} alignItems="center">
        <Headline5 marginTop={0.2} heavy>
          {getTranslationWithValue(0, 'modalTitle', {name})}
        </Headline5>
        <IconSVG icon="lens_glasses" size={24} />
      </Stack>
    );
  }, [
    PrescriptionResponse?.prescriptionHeader?.patientLastName,
    getTranslationWithValue,
  ]);

  const prescriptionModal = useMemo(() => {
    if (!PrescriptionResponse && PrescriptionLoading && showModal)
      return <LoadingPage invisible={false} />;

    if (!PrescriptionResponse || !showModal) return null;

    return (
      <Modal
        open={showModal}
        onClose={() => showPrescription()}
        maxWidth={60}
        dialogTitle={dialogTitle}
        dialogContent={
          <PrescriptionModalContent prescription={PrescriptionResponse} />
        }
      />
    );
  }, [
    PrescriptionLoading,
    PrescriptionResponse,
    dialogTitle,
    showModal,
    showPrescription,
  ]);

  return useMemo(
    () => ({
      showPrescription,
      prescriptionModal,
    }),
    [prescriptionModal, showPrescription],
  );
};

interface PrescriptionModalContentProps {
  prescription: GetPrescriptionByIdResponse;
}

const PrescriptionModalContent = React.memo(
  ({prescription}: PrescriptionModalContentProps) => {
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
    const styles = useStyles();
    const {prescriptionHeader, prescriptionData} = prescription;

    const patientName = useMemo(() => {
      const name = `${prescriptionHeader?.patientFirstname} ${prescriptionHeader?.patientLastName}`;
      return capitalizeWords(name);
    }, [
      prescriptionHeader?.patientFirstname,
      prescriptionHeader?.patientLastName,
    ]);

    const examDate = useMemo(() => {
      return prescriptionHeader?.examDate
        ? dayjs(prescriptionHeader?.examDate).format(
            dayjs().locale() === 'it' ? 'D MMM YYYY' : 'Do MMMM YYYY',
          )
        : '-';
    }, [prescriptionHeader?.examDate]);

    const createdByName = useMemo(() => {
      if (!!prescriptionHeader?.doctorFullname) {
        return capitalizeWords(prescriptionHeader?.doctorFullname);
      }

      return undefined;
    }, [prescriptionHeader?.doctorFullname]);

    const createdByCode = useMemo(() => {
      if (!!prescriptionHeader?.doctorId) {
        return prescriptionHeader?.doctorId;
      }

      return undefined;
    }, [prescriptionHeader?.doctorId]);

    const createdBy = useMemo(() => {
      if (!!createdByName && !!createdByCode) {
        return `${createdByName} - (${createdByCode})`;
      }

      if (!!createdByName) {
        return createdByName;
      }

      if (!!createdByCode) {
        return createdByCode;
      }

      return '-';
    }, [createdByCode, createdByName]);

    const getDirection = useCallback(
      (direction: PrescriptionDirection): string => {
        return getTranslationWithValue(0, direction ?? '');
      },
      [getTranslationWithValue],
    );

    const prescriptionInfos = useMemo(() => {
      return [
        {
          id: 1,
          header: [
            {
              label: getTranslationWithValue(0, 'SPH'),
              gridColumn: '2',
              justifySelf: 'center',
            },
            {
              label: getTranslationWithValue(0, 'CYL'),
              gridColumn: '3',
              justifySelf: 'center',
            },
            {
              label: getTranslationWithValue(0, 'axis'),
              gridColumn: '4',
              justifySelf: 'center',
            },
            {
              label: getTranslationWithValue(0, 'add'),
              gridColumn: '5',
              justifySelf: 'center',
            },
          ],
          rows: [
            [
              {
                label: getTranslationWithValue(0, 'odRightEye'),
                gridColumn: '1',
                justifySelf: 'start',
              },
              {
                label:
                  prescriptionData?.odSphereDiopters !== null
                    ? numberFormatter
                        .format(prescriptionData?.odSphereDiopters, {
                          minimumFractionDigits: 2,
                        })
                        ?.toString()
                    : '-',
                gridColumn: '2',
                justifySelf: 'center',
              },
              {
                label:
                  prescriptionData?.odCylinderDiopters !== null
                    ? numberFormatter
                        .format(prescriptionData?.odCylinderDiopters, {
                          minimumFractionDigits: 2,
                        })
                        ?.toString()
                    : '-',
                gridColumn: '3',
                justifySelf: 'center',
              },
              {
                label:
                  prescriptionData?.odAxisDegrees !== null
                    ? prescriptionData?.odAxisDegrees?.toString()
                    : '-',
                gridColumn: '4',
                justifySelf: 'center',
              },
              {
                label:
                  prescriptionData?.odAdd1Diopters !== null
                    ? numberFormatter
                        .format(prescriptionData?.odAdd1Diopters, {
                          minimumFractionDigits: 2,
                        })
                        ?.toString()
                    : '-',
                gridColumn: '5',
                justifySelf: 'center',
              },
            ],
            [
              {
                label: getTranslationWithValue(0, 'osLeftEye'),
                gridColumn: '1',
                justifySelf: 'start',
              },
              {
                label:
                  prescriptionData?.osSphereDiopters !== null
                    ? numberFormatter
                        .format(prescriptionData?.osSphereDiopters, {
                          minimumFractionDigits: 2,
                        })
                        ?.toString()
                    : '-',
                gridColumn: '2',
                justifySelf: 'center',
              },
              {
                label:
                  prescriptionData?.osCylinderDiopters !== null
                    ? numberFormatter
                        .format(prescriptionData?.osCylinderDiopters, {
                          minimumFractionDigits: 2,
                        })
                        ?.toString()
                    : '-',
                gridColumn: '3',
                justifySelf: 'center',
              },
              {
                label:
                  prescriptionData?.osAxisDegrees !== null
                    ? prescriptionData?.osAxisDegrees?.toString()
                    : '-',
                gridColumn: '4',
                justifySelf: 'center',
              },
              {
                label:
                  prescriptionData?.osAdd1Diopters !== null
                    ? numberFormatter
                        .format(prescriptionData?.osAdd1Diopters, {
                          minimumFractionDigits: 2,
                        })
                        ?.toString()
                    : '-',
                gridColumn: '5',
                justifySelf: 'center',
              },
            ],
          ],
        },
        {
          id: 2,
          header: [
            {
              label: (
                <BodyText heavy>{getTranslationWithValue(0, 'prism')}</BodyText>
              ),
              gridColumn: '1',
              justifySelf: 'start',
            },
            {
              label: getTranslationWithValue(0, 'vertical'),
              gridColumn: '4',
              justifySelf: 'center',
            },
            {
              label: getTranslationWithValue(0, 'horizontal'),
              gridColumn: '5',
              justifySelf: 'center',
            },
          ],
          rows: [
            [
              {
                label: getTranslationWithValue(0, 'odRightEye'),
                gridColumn: '1',
                justifySelf: 'start',
              },
              {
                label: `${
                  prescriptionData?.odPrismValue1Diopters ?? '-'
                } ${getDirection(prescriptionData?.odPrismDirection1Code)}`,
                gridColumn: '4',
                justifySelf: 'center',
              },
              {
                label: `${
                  prescriptionData?.odPrismValue2Diopters ?? '-'
                } ${getDirection(prescriptionData?.odPrismDirection2Code)}`,
                gridColumn: '5',
                justifySelf: 'center',
              },
            ],
            [
              {
                label: getTranslationWithValue(0, 'osLeftEye'),
                gridColumn: '1',
                justifySelf: 'start',
              },
              {
                label: `${
                  prescriptionData?.osPrismValue1Diopters ?? '-'
                } ${getDirection(prescriptionData?.osPrismDirection1Code)}`,
                gridColumn: '4',
                justifySelf: 'center',
              },
              {
                label: `${
                  prescriptionData?.osPrismValue2Diopters ?? '-'
                } ${getDirection(prescriptionData?.osPrismDirection2Code)}`,
                gridColumn: '5',
                justifySelf: 'center',
              },
            ],
          ],
        },
        {
          id: 3,
          header: [
            {
              label: getTranslationWithValue(0, 'far'),
              gridColumn: '4',
              justifySelf: 'center',
            },
            {
              label: getTranslationWithValue(0, 'near'),
              gridColumn: '5',
              justifySelf: 'center',
            },
          ],
          rows: [
            [
              {
                label: getTranslationWithValue(0, 'pd'),
                gridColumn: '1',
                justifySelf: 'start',
              },
              {
                label: `${
                  prescriptionData?.odFar ?? prescriptionData?.right_pd ?? '-'
                } / ${
                  prescriptionData?.osFar ?? prescriptionData?.left_pd ?? '-'
                }`,
                gridColumn: '4',
                justifySelf: 'center',
              },
              {
                label: `${prescriptionData?.odNear ?? '-'} / ${
                  prescriptionData?.osNear ?? '-'
                }`,
                gridColumn: '5',
                justifySelf: 'center',
              },
            ],
          ],
        },
      ];
    }, [
      getDirection,
      getTranslationWithValue,
      prescriptionData?.left_pd,
      prescriptionData?.odAdd1Diopters,
      prescriptionData?.odAxisDegrees,
      prescriptionData?.odCylinderDiopters,
      prescriptionData?.odFar,
      prescriptionData?.odNear,
      prescriptionData?.odPrismDirection1Code,
      prescriptionData?.odPrismDirection2Code,
      prescriptionData?.odPrismValue1Diopters,
      prescriptionData?.odPrismValue2Diopters,
      prescriptionData?.odSphereDiopters,
      prescriptionData?.osAdd1Diopters,
      prescriptionData?.osAxisDegrees,
      prescriptionData?.osCylinderDiopters,
      prescriptionData?.osFar,
      prescriptionData?.osNear,
      prescriptionData?.osPrismDirection1Code,
      prescriptionData?.osPrismDirection2Code,
      prescriptionData?.osPrismValue1Diopters,
      prescriptionData?.osPrismValue2Diopters,
      prescriptionData?.osSphereDiopters,
      prescriptionData?.right_pd,
    ]);

    console.log(prescriptionData?.classification?.treatment_type);

    return (
      <Stack sx={styles.prescriptionContentWrapper}>
        <Stack
          className="prescriptionHeader"
          divider={<Divider orientation="horizontal" />}>
          <LabelValue
            value={patientName}
            label={getTranslationWithValue(0, 'patentName')}
          />
          <LabelValue
            value={examDate}
            label={getTranslationWithValue(0, 'createdOn')}
          />
          <LabelValue
            value={createdBy}
            label={getTranslationWithValue(0, 'createdBy')}
          />
        </Stack>
        <Stack>
          <LabelValue
            value={prescriptionData?.classification?.treatment_type ?? '-'}
            label={getTranslationWithValue(0, 'visionNeed')}
          />
        </Stack>
        {prescriptionInfos?.map(prescriptionInfo => {
          return (
            <Stack
              key={prescriptionInfo?.id}
              direction="column"
              className="prescriptionInfoTable">
              <Stack className="prescriptionInfoHeader">
                {prescriptionInfo?.header?.map((header, headerIndex) => {
                  if (typeof header?.label === 'string') {
                    return (
                      <TinyText
                        key={`${prescriptionInfo?.id}_${headerIndex}_header`}
                        sx={styles.prescriptionRowItem(
                          header?.justifySelf,
                          header?.gridColumn,
                        )}
                        medium>
                        {header?.label}
                      </TinyText>
                    );
                  }
                  return (
                    <Box
                      key={`${prescriptionInfo?.id}_${headerIndex}`}
                      sx={styles.prescriptionRowItem(
                        header?.justifySelf,
                        header?.gridColumn,
                      )}
                      className="prescriptionInfoHeaderItem">
                      {header?.label}
                    </Box>
                  );
                })}
              </Stack>
              <Divider orientation="horizontal" />
              {prescriptionInfo?.rows?.map((row, rowIndex) => {
                return (
                  <Stack
                    className="prescriptionInfoRow"
                    key={`${prescriptionInfo?.id}_${rowIndex}_row`}>
                    {row?.map((value, valueIndex) => {
                      return (
                        <TinyText
                          sx={styles.prescriptionRowItem(
                            value?.justifySelf,
                            value?.gridColumn,
                          )}
                          key={`${prescriptionInfo?.id}_${rowIndex}_${valueIndex}_value`}>
                          {value?.label}
                        </TinyText>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          );
        })}
      </Stack>
    );
  },
);

PrescriptionModalContent.displayName = 'PrescriptionModalContent';
