import React from 'react';

import {Headline3TextProps} from '@/atoms/Headline3/interfaces.d';
import {Text} from '@/atoms/Typography/Text';

const Headline3 = ({children, ...textProps}: Headline3TextProps) => {
  return (
    <Text variant="headline3" lineHeight={1.2} {...textProps}>
      {children}
    </Text>
  );
};

export default Headline3;
