import React, {useState, useMemo, useCallback} from 'react';
import {PiCaretDownBold} from 'react-icons/pi';

import {Chip, Box, Menu, Stack} from '@mui/material';

import {BodyText} from '@/atoms/BodyText';
import {useStyles} from '@/atoms/ChipAmPm/styles';

interface ChipAmPmProps {
  selectedValue: string;
  onSelected: (selected: 'AM' | 'PM') => unknown;
}

const ChipAmPm = ({selectedValue, onSelected}: ChipAmPmProps) => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const menuOpen = useMemo<boolean>(() => Boolean(anchorEl), [anchorEl]);

  const toggleMenu = useCallback(
    (event?: React.MouseEvent<HTMLDivElement> | any) => {
      setAnchorEl(event?.currentTarget);
      event?.stopPropagation();
    },
    [],
  );

  const label = useMemo(() => {
    return (
      <Stack className={`chipLabel ${menuOpen ? 'chipLabel__open' : ''}`}>
        <BodyText marginTop={0.4}>{selectedValue}</BodyText>
        <PiCaretDownBold fontSize={12} className="icon" />
      </Stack>
    );
  }, [menuOpen, selectedValue]);

  return (
    <>
      <Box onClick={toggleMenu} paddingY={0.8}>
        <Chip sx={styles.chip} label={label} variant="filterDefault" />
      </Box>
      {menuOpen && (
        <Menu
          sx={styles.menu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={true}
          onClose={() => toggleMenu()}>
          <Stack direction="column" sx={{paddingY: '8px'}}>
            {['AM', 'PM'].map(timezone => {
              return (
                <Box
                  key={timezone}
                  textAlign="center"
                  className={`singleTimezone ${
                    selectedValue === timezone ? 'singleTimezone__active' : ''
                  }`}
                  onClick={() => {
                    onSelected(timezone as 'AM' | 'PM');
                    toggleMenu();
                  }}>
                  {timezone}
                </Box>
              );
            })}
          </Stack>
        </Menu>
      )}
    </>
  );
};

export default React.memo(ChipAmPm);
