import {useCallback} from 'react';

import {SxProps, Theme} from '@mui/material';

import {OrderStatusProps} from '@/organisms/TransactionCard/interfaces';
import {Colors} from '@/themes/variables';

export const useStyle = () => {
  const orderStatus = useCallback(
    (status: OrderStatusProps): SxProps<Theme> => ({
      color: status === 'RETURNED' ? Colors.Error : Colors.Green,
    }),
    [],
  );

  return {orderStatus};
};
