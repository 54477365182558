import React, {useCallback, useMemo, useState} from 'react';

import {Box, Menu, MenuItem, Stack} from '@mui/material';

import {IconSVG} from '../IconSVG';
import {Text} from '../Typography/Text';
import {IProps} from './interfaces';
import {useStyles} from './styles';

const ContextMenu = ({
  label,
  iconName,
  iconSize,
  iconRotate,
  subMenuItems,
  sx,
}: IProps) => {
  const {container, iconContainer, iconContainerRotate, menu} = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorEl(e.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const iconContainerStyle = useMemo(() => {
    if (!iconRotate) return iconContainer;
    return open ? iconContainerRotate : iconContainer;
  }, [iconContainer, iconContainerRotate, iconRotate, open]);

  const handleItemClick = useCallback((cb: () => unknown) => {
    cb();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Box sx={container} onClick={handleClick}>
        {label ? (
          <Box>
            <Text variant="headline5" medium underline>
              {label}
            </Text>
          </Box>
        ) : null}
        {iconName ? (
          <Box sx={iconContainerStyle}>
            <IconSVG icon={iconName} size={iconSize} />
          </Box>
        ) : null}
      </Box>
      <Menu sx={[menu, sx]} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {subMenuItems.map(({id, icon, label, onClick}) => (
          <MenuItem
            key={id}
            divider
            disableTouchRipple
            onClick={() => handleItemClick(onClick)}>
            <Stack flexDirection="row" gap={1}>
              {icon && <IconSVG icon={icon} size={24} />}
              {label}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(ContextMenu);
