import React, {useMemo, useEffect} from 'react';
import ReactGA from 'react-ga4';

import {Box} from '@mui/material';
import dayjs from 'dayjs';

import {PageTitle} from '@/atoms/PageTitle';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {DashboardCampaignCards} from '@/organisms/DashboardCampaignCards';
import {DashboardTaskList} from '@/organisms/DashboardTaskList';
import {DashboardTeamMembers} from '@/organisms/DashboardTeamMembers';
import {useAuthentication} from '@/store/authentication';
import {Base} from '@/templates/Base';

import {useDashboardStyle} from './style';

const Clienteling = () => {
  const {isAreaManager} = useAuthentication();
  const {goToPath} = useNavigation();
  const {mainContainer, container} = useDashboardStyle();

  const startDate = useMemo(
    () => dayjs().startOf('month').format('YYYY-MM-DD'),
    [],
  );
  const endDate = useMemo(() => dayjs().format('YYYY-MM-DD'), []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Clienteling dashboard',
    });
  }, []);

  return (
    <Base>
      <Box sx={container}>
        {isAreaManager && (
          <PageTitle
            enableBack={isAreaManager}
            backClick={() => goToPath(AuthRoutes.CLIENTELING)}
          />
        )}
        <Box sx={mainContainer}>
          <DashboardTaskList startDate={startDate} endDate={endDate} />
          <DashboardCampaignCards startDate={startDate} endDate={endDate} />
          <DashboardTeamMembers />
        </Box>
      </Box>
    </Base>
  );
};

export default React.memo(Clienteling);
