import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const singleQuotation = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(0.8),
      transition: 'all 0.2s linear',
      cursor: 'pointer',
      opacity: 1,
      ':hover': {
        opacity: 0.7,
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      singleQuotation,
    }),
    [singleQuotation],
  );
};
