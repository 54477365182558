import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Slider,
  Stack,
  SxProps,
  Theme,
  Typography,
  Container,
} from '@mui/material';
import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';

import {Badge} from '@/atoms/Badge';
import {ContextMenu} from '@/atoms/ContextMenu';
import {Dialog} from '@/atoms/Dialog';
import {CommonButtons} from '@/atoms/DialogButtons';
import {FiltersChips} from '@/atoms/FiltersChips';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {Link} from '@/atoms/Link';
import LuxCard from '@/atoms/LuxCard';
import {PanelToggle} from '@/atoms/PanelToggle';
import {PurchaseCategory} from '@/atoms/PurchaseCategory';
import {PURCHASE_CATEGORIES} from '@/atoms/PurchaseCategory/interfaces.d';
import {Select} from '@/atoms/Select';
import {SelectOptions, SelectValueType} from '@/atoms/Select/interfaces';
import {StarRating} from '@/atoms/StarRating';
import {Tabs} from '@/atoms/Tabs';
import {Tag} from '@/atoms/Tag';
import {TooltipInfo} from '@/atoms/TooltipInfo';
import {Text} from '@/atoms/Typography/Text';
import {UserAvatar} from '@/atoms/UserAvatar';
import {randomLoggedUser, randomLoggedUsers} from '@/atoms/UserAvatar/mock';
import {ValueCircle} from '@/atoms/ValueCircle';
import {useToast} from '@/hooks/toast';
import {CampaignCard} from '@/molecules/CampaignCard';
import {DatePicker} from '@/molecules/DatePicker';
import {HomepageFormbar} from '@/molecules/HomepageFormbar';
import {HomepageFormBarProps} from '@/molecules/HomepageFormbar/interfaces';
import {KeyValuePair} from '@/molecules/KeyValuePair';
import {NumberInput} from '@/molecules/NumberInput';
import {NumberInputValue} from '@/molecules/NumberInput/interfaces';
import {PageHeader} from '@/molecules/PageHeader';
import {ProgressWheel} from '@/molecules/ProgressWheel';
import {PurchaseInfo} from '@/molecules/PurchaseInfo';
import {Searchbar} from '@/molecules/Searchbar';
import {SearchbarIProps} from '@/molecules/Searchbar/interfaces';
import {TaskListLinks} from '@/molecules/TaskListLinks';
import {TextInput} from '@/molecules/TextInput';
import {Benefits} from '@/organisms/Benefits';
import {FeedbackModal} from '@/organisms/FeedbackModal';
import {LoyaltyBox} from '@/organisms/LoyaltyBox';
import {
  loyaltyBoxMockListSubscribing,
  loyaltyBoxMockListPopulatedActive,
  loyaltyBoxMockListPopulatedSuspended,
} from '@/organisms/LoyaltyBox/mock';
import {PersonalInformation} from '@/organisms/PersonalInformation';
import {randomCustomersResponse} from '@/organisms/PersonalInformation/mock';
import {PointsInput} from '@/organisms/PointsInput';
import {PurchasesDetails} from '@/organisms/PurchasesDetails';
import {lastPurchasesResponseMock} from '@/organisms/PurchasesDetails/mock';
import {ScriptBox} from '@/organisms/ScriptBox';
import {scriptBoxMock} from '@/organisms/ScriptBox/mock';
import {TaskListCard} from '@/organisms/TaskListCard';
import {
  randomTaskListCardInfoTypeBirthday,
  randomTaskListCardInfoTypeEE,
  randomTaskListCardInfoTypeQuote,
  randomTaskListCardInfoTypeTopClients,
} from '@/organisms/TaskListCard/mock';
import {TaskListFilters} from '@/organisms/TaskListFilters';
import {TeamMembers} from '@/organisms/TeamMembers';
import {TransactionCard} from '@/organisms/TransactionCard';
import {TransactionDataProps} from '@/organisms/TransactionCard/interfaces';
import {BenefitsMock} from '@/store/benefit/mock';
import {SingleCampaign} from '@/store/campaigns';
import {CampaignDetailProps} from '@/store/campaigns/interfaces';
import {useLocale} from '@/store/locale';
import {Base} from '@/templates/Base';
import theme from '@/themes/theme';

dayjs.extend(Utc);

/** Component wrapper */
const ComponentBlock = ({
  title,
  children,
  sx,
}: {
  title: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}): JSX.Element => (
  <Box>
    <Box>
      <Typography variant="headline5" gutterBottom>
        {title}
      </Typography>
      <hr />
    </Box>
    <Box pb={7} pt={2} sx={sx}>
      {children}
    </Box>
  </Box>
);

const Components = (): JSX.Element => {
  const {t} = useTranslation();

  // End Data table

  // START Progress Wheel Management
  const [minProgressWheelValue, maxProgressWheelValue] = [0, 142];
  const [progressWheelValue, setProgressWheelValue] = useState(76);
  const handleProgressWheelChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    setProgressWheelValue(newValue as number);
  };
  const progressWheelProps = {
    min: minProgressWheelValue,
    max: maxProgressWheelValue,
    value: progressWheelValue,
  };
  // END Progress Wheel Management

  // START Toast Config
  const {showErrorToast, showSuccessToast} = useToast();
  // END Toast Config

  // START Select Management
  const [emptySelected, setEmptySelected] = useState<SelectValueType>('');
  const options: SelectOptions = [
    {value: '8', label: 'Eight'},
    {value: '3', label: 'Three'},
    {value: '5', label: 'Five'},
  ];
  const [selected, setSelected] = useState(options[0].value);
  const [selected2, setSelected2] = useState(options[2].value);
  // END Select Management

  // START Dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [advancedDialogOpen, setAdvancedDialogOpen] = useState(false);
  const [advancedDialogReady, setAdvancedDialogReady] = useState(false);
  // END Dialog states

  // START Number Input State
  const [numberInput, setNumberInput] = useState<NumberInputValue>('');
  const [numberInput2, setNumberInput2] = useState<NumberInputValue>('');
  // END number Input State

  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const {formatDate} = useLocale();
  // END Data Picker

  // START Points Input
  const [points, setPoints] = useState<number | null>(null);
  console.log('POINTS', points);
  // END Points Input

  // START Home Formbar
  const [freeSearch, setFreeSearch] = useState('');
  const brandOptions: SelectOptions = [
    {value: 'RayBan', label: 'RayBan'},
    {value: 'Luxottica', label: 'Luxottica'},
  ];
  const [brand, setBrand] = useState('');
  const countryOptions: SelectOptions = [
    {value: 'EN-US', label: 'USA'},
    {value: 'FR-CA', label: 'CANADA'},
  ];
  const [country, setCountry] = useState('');
  const homeFormbarProps: HomepageFormBarProps = {
    freeSearch,
    onFreeSearchChange: newValue => setFreeSearch(newValue),
    brand,
    brandOptions,
    onBrandChange: newValue => setBrand(newValue),
    country,
    countryOptions,
    onCountryChange: newValue => setCountry(newValue),
    onSubmit: () => {
      console.log('SEARCH w/:', freeSearch, brand, country);
      setFreeSearch('');
      setBrand('');
      setCountry('');
    },
  };
  // END Home Formbar

  // START Searchbar
  const [searchbarText, setSearchbarText] = useState('');
  const searchbarProps: SearchbarIProps = {
    freeSearch: searchbarText,
    onFreeSearchChange: newValue => setSearchbarText(newValue),
    onReset: () => setSearchbarText(''),
    onSubmit: () => {
      console.log('SEARCH w/:', freeSearch);
      setSearchbarText('');
    },
  };
  // END Searchbar

  const badges = useMemo(
    () => [
      {
        badge: 'newCollection',
      },
      {
        badge: 'birthday',
      },
      {
        badge: 'callback',
      },
      {
        badge: 'quote',
      },
    ],
    [],
  );

  const purchaseCategories: Array<PURCHASE_CATEGORIES> = useMemo(
    () => [
      PURCHASE_CATEGORIES.LENSES,
      PURCHASE_CATEGORIES.APPAREL,
      PURCHASE_CATEGORIES.CONTACTS,
      PURCHASE_CATEGORIES.SUNGLASSES,
    ],
    [],
  );

  const transactionItem = useMemo((): Array<TransactionDataProps | any> => {
    return [
      {
        name: 'Micheal Accessorio Occhiali Ferronato',
        customerId: '000800051858802710000',
        customerPhone: '0267108588',
        store: {
          banner: 'SV',
          city: 'Cremona',
          stateProvince: 'CR',
          address: 'Via Castelleone 108',
          storeName: '248 CR-C.C. CREMONA PO - CREMONA',
          postalCode: '26100',
          phoneNumber: '0372/461775',
        },
        shippingAddress: {
          city: 'Cremona',
          stateProvince: 'CR',
          address: 'Via Castelleone 108',
          postalCode: '26100',
        },
        transactionDate: '2023-05-06T15:59:07',
        transactionId: '0008000510067400100',
        orderId: '0008000516012361910147',
        channel: 'STORE',
        orderStatus: 'DELIVERED',
        totalGrossValue: 0.01,
        totalDiscountValue: 0,
        totalNetValue: 0.01,
        totalTaxValue: 0,
        totalTenderValue: 0.01,
        products: [
          {
            productKind: 'OTHER',
            specifyProductKind: 'ACCESSORIES',
            quantity: '1',
            productType: 'Accessories',
            description: 'LANYARD RB VIBES RED',
            styleName: 'ARB0002ST Ray-Ban Lanyard',
            brand: 'Ray-Ban',
            model: 'ARB0002ST',
            moco: 'ARB0002ST__000001',
            upc: '8056597950268',
            productHierarchy1: 'Eyewear Accessories',
            productHierarchy0: 'Accessories',
            productHierarchy2: 'Spare Parts',
            image:
              'https://webapp-im2.luxottica.com//cdn-record-files-pi/1cb53f65-9486-4dc0-b723-afcf01664ae1/5bd8e78e-2b05-4f0d-9518-afcf01664ba8/ARB0002ST__000001.png?imwidth=231',
          },
        ],
      },
      {
        name: 'Michael Ferronato',
        customerId: '000816000216610351',
        customerPhone: '0267108588',
        store: {
          banner: 'SV',
          city: 'Termoli',
          stateProvince: 'CB',
          address: 'Corso Nazionale 44',
          storeName: '796 Cb-Termoli C.So Nazionale',
          postalCode: '86039',
          phoneNumber: '0875/880804',
        },
        shippingAddress: {
          city: 'Cremona',
          stateProvince: 'CR',
          address: 'Via Castelleone 108',
          postalCode: '26100',
        },
        transactionDate: '2023-03-25T17:11:31',
        transactionId: '000810007840100',
        orderId: '000816000218410351',
        channel: 'STORE',
        orderStatus: 'DELIVERED',
        totalGrossValue: 230.77,
        totalDiscountValue: 115.38462,
        totalNetValue: 115.38,
        totalTaxValue: 4.62,
        totalTenderValue: 120,
        products: [
          {
            productKind: 'LENS_PACKAGES',
            specifyProductKind: 'LENSES',
            quantity: '1',
            productType: 'Rox_lens',
            description: 'GA STANDARD 1.50 CL SV SPH PRM AR BLUE',
            styleName: 'GA STANDARD 1.50 CL SV SPH PRM AR BLUE',
            model: 'Lenses',
            upc: '20500001817958',
            lensColorLabel: 'Blue Filter',
            lensMaterial: 'Plastic 1.50 Clear',
            lensType: 'Optical',
          },
        ],
      },
      {
        name: 'Michael Accessorio CL Ferranato',
        customerId: '0008000516003206510070',
        customerPhone: '0267108588',
        store: {
          banner: 'SV',
          city: 'Genova',
          stateProvince: 'GE',
          address: 'Via Xx Settembre 102/R',
          storeName: '130 GE-GENOVA VIA XX SETTEMBRE',
          postalCode: '16121',
          phoneNumber: '010/541944',
        },
        shippingAddress: {
          city: 'Cremona',
          stateProvince: 'CR',
          address: 'Via Castelleone 108',
          postalCode: '26100',
        },
        transactionDate: '2023-02-04T17:35:41',
        transactionId: '0008000530041450602',
        orderId: '0008000516009344610070',
        channel: 'STORE',
        orderStatus: 'DELIVERED',
        totalGrossValue: 10.66,
        totalDiscountValue: 0,
        totalNetValue: 10.66,
        totalTaxValue: 2.34,
        totalTenderValue: 13,
        products: [
          {
            productKind: 'OTHER',
            specifyProductKind: 'LENSES',
            quantity: '1',
            productType: 'Contact Lenses Accessories',
            description: 'AOSEPT PLUS C/HYDRAGLYDE 360ML',
            styleName: 'Aosept Plus Con Hydraglyde',
            upc: '300650363075',
          },
        ],
      },
      {
        name: 'Giorgia Rausa',
        customerId: '00081834418600000',
        customerPhone: '0267108588',
        store: {
          banner: 'SV',
          city: 'Torino',
          stateProvince: 'TO',
          address: 'Via Roma 33',
          storeName: '135 TO-TORINO VIA ROMA',
          postalCode: '10123',
          phoneNumber: '011/5629062',
        },
        shippingAddress: {
          city: 'Cremona',
          stateProvince: 'CR',
          address: 'Via Castelleone 108',
          postalCode: '26100',
        },
        transactionDate: '2023-02-04T16:27:27',
        transactionId: '000810055190200',
        orderId: '000816012413010075',
        channel: 'STORE',
        orderStatus: 'DELIVERED',
        totalGrossValue: 21.15,
        totalDiscountValue: 5.2884617,
        totalNetValue: 15.87,
        totalTaxValue: 0.63,
        totalTenderValue: 16.5,
        products: [
          {
            productKind: 'CONTACT_LENS',
            specifyProductKind: 'SUN',
            quantity: '1',
            productType: 'Contact Lenses',
            description: 'FODACP30P 087 140 VISI',
            styleName: 'DAILIES® AquaComfort Plus® 30',
            brand: 'DAILIES',
            upc: '630175475862',
            baseCurve: '8.7',
            diameter: 14,
            packageQuantity: '30',
            modalityFacet: 'Daily',
            geometry: 'SPHERIC',
            materialSeg: 'SOFT',
            supplier: 'Alcon',
            brandFamily: 'DAILIES',
          },
        ],
      },
      {
        name: 'Michael Cleaning Kit Ferronato',
        customerId: '000816005319710132',
        customerPhone: '0267108588',
        store: {
          banner: 'SV',
          city: 'Milano',
          stateProvince: 'MI',
          address: 'Via Marco Ulpio Traiano 79',
          storeName: '230 MI-C.C. PORTELLO - MILANO',
          postalCode: '20155',
          phoneNumber: '02/39257194',
        },
        shippingAddress: {
          city: 'Cremona',
          stateProvince: 'CR',
          address: 'Via Castelleone 108',
          postalCode: '26100',
        },
        transactionDate: '2023-02-04T15:59:02',
        transactionId: '000830028180100',
        orderId: '000816005327010132',
        channel: 'STORE',
        orderStatus: 'DELIVERED',
        totalGrossValue: 623.94,
        totalDiscountValue: 414.21033,
        totalNetValue: 209.52,
        totalTaxValue: 9.570001,
        totalTenderValue: 217.9,
        products: [
          {
            productKind: 'FRAME',
            specifyProductKind: 'SUN',
            quantity: '1',
            productType: 'Optical',
            description: '15368 ACTIVE/VIOLA/FUCSIA/42',
            styleName: '15368N',
            brand: 'Centrostyle',
            model: '0SY000022',
            moco: '0SY000022__3820',
            upc: '8058692043333',
            gender: 'Kids',
            productHierarchy1: 'Eyeglasses',
            productHierarchy0: 'Eyewear',
            frameShapeLabel: 'Oval',
            frameType: 'Full Rim',
            frameMaterialLabel: 'Injected',
            sizeOrdinal: 'XXS',
            size: '42',
            bridgeSize: '14',
            modelCodeDisplay: 'SY000022',
            frameMaterial: 'Nylon & Propionate',
            modelStylename: '15368N',
            frameColorLabel: 'Purple Pink',
            faceShape: 'Oval-Square',
          },
          {
            productKind: 'LENS_PACKAGES',
            specifyProductKind: 'SUN',
            quantity: '1',
            productType: 'Rox_lens',
            description: 'GA TOP 1.67 GEN8 AMBER SV PREMIUM AR',
            styleName: 'GA TOP 1.67 GEN8 AMBER SV PREMIUM AR',
            model: 'Lenses',
            upc: '20500001811987',
            lensColorLabel: 'Amber',
            lensMaterial: 'Plastic 1.67 GEN8',
            lensType: 'Optical',
          },
          {
            productKind: 'OTHER',
            specifyProductKind: 'SUN',
            description: 'MOUNTING',
            styleName: 'MOUNTING',
          },
          {
            productKind: 'OTHER',
            specifyProductKind: 'SUN',
            quantity: '1',
            productType: 'Accessories',
            description: 'ANTIMICROB LENS CLEAN 30ML SPR',
            styleName: 'ASV0004CK Antimicrobial Lens Cleaning 30ml',
            brand: 'Salmoiraghi E Vigano',
            model: 'ASV0004CK',
            moco: 'ASV0004CK__000001',
            upc: '8056597393089',
            gender: 'Unisex',
            productHierarchy1: 'Eyewear Accessories',
            productHierarchy0: 'Accessories',
            productHierarchy2: 'Cleaning Kits & Cases',
            image:
              'https://webapp-im2.luxottica.com//cdn-record-files-pi/f2b96cd0-f2cc-49c7-b155-ac4100813925/923863df-fb14-41f2-ae14-ac4100813994/ASV0004CK__000001.png?imwidth=231',
          },
        ],
      },
      {
        name: null,
        customerId: '00081014349371930',
        customerPhone: '0267108588',
        store: {
          banner: 'SV',
          city: 'Milano',
          stateProvince: 'MI',
          address: 'Corso Matteotti 22',
          storeName: '113 MI-MILANO PIAZZA SAN BABILA',
          postalCode: '20122',
          phoneNumber: '02/76000100',
        },
        shippingAddress: {
          city: 'Cremona',
          stateProvince: 'CR',
          address: 'Via Castelleone 108',
          postalCode: '26100',
        },
        transactionDate: '2023-02-04T12:45:09',
        transactionId: '0008330023500100',
        orderId: '00081019366011930',
        channel: 'STORE',
        orderStatus: 'DELIVERED',
        totalGrossValue: 509,
        totalDiscountValue: 125.3,
        totalNetValue: 383.7,
        totalTaxValue: 2.9,
        totalTenderValue: 386.6,
        products: [
          {
            productKind: 'FRAME',
            specifyProductKind: 'SUN',
            quantity: '1',
            productType: 'Sun',
            description: 'OO9188 59 FLAK 2.0 XL',
            styleName: 'OO9188 Flak® 2.0 XL',
            brand: 'Oakley',
            model: '0OO9188',
            moco: '0OO9188__918858',
            upc: '888392225245',
            gender: 'Man',
            productHierarchy1: 'Sunglasses',
            productHierarchy0: 'Eyewear',
            frameShapeLabel: 'Rectangle',
            frameType: 'Semi Rim',
            frameFitting: 'Narrow',
            lensTreatmentLabel: 'Prizm_polarized',
            frameMaterialLabel: 'O_matter',
            lensColorLabel: 'Prizm Deep Water Polarized',
            sizeOrdinal: 'S',
            size: '59',
            bridgeSize: '12',
            modelCodeDisplay: 'OO9188',
            frameMaterial: 'Nylon & Propionate',
            modelStylename: 'FLAK 2.0 XL',
            frameColorLabel: 'Matte Black',
            faceShape: 'Oval-Round',
            image:
              'https://webapp-im2.luxottica.com//cdn-record-files-pi/f4b6e220-05fc-4cba-917b-a599007aa5ab/61d68ba5-ed5d-4847-801d-ad790161a5ab/0OO9188__918858__P21__noshad__qt.png?imwidth=231',
          },
          {
            productKind: 'LENS_PACKAGES',
            specifyProductKind: 'SUN',
            quantity: '1',
            productType: 'Rox_lens',
            description: 'SV OAKLEYTD II 1.59 CLEAR OS',
            styleName: 'Oakley Clear Classic',
            brand: 'ESSENTIALS',
            model: 'Lenses',
            upc: '20500001780931',
            lensMaterial: 'High-Index',
            lensType: 'Optical',
          },
          {
            productKind: 'OTHER',
            specifyProductKind: 'SUN',
            description: 'ONE YEAR EYEWEAR PROTECTION PLAN',
            styleName: 'ONE YEAR EYEWEAR PROTECTION PLAN',
          },
        ],
      },
    ];
  }, []);

  const reportDetail: CampaignDetailProps = useMemo(
    () => ({
      assigneeLuxId: '',
      assigneeFirstname: '',
      assigneeLastname: '',
      sapStoreId: '610',
      active: true,
      country: 'IT',
      code: 'CDS0000543',
      name: 'Demo Campaign DS',
      type: 'Quote_Callback',
      startDate: '2023-06-03T00:00:00',
      endDate: '2023-12-27T00:00:00',
      taskNumber: 34,
      completedTask: 0,
      notCompletedTask: 34,
      statusNew: 2,
      statusContacted: 2,
      statusWaiting: 2,
      statusNoAnswer: 2,
      statusNotContactable: 2,
      eeBooked: 2,
      appointmentBooked: 0,
      statusAlreadyPurchased: 0,
      storeManagerId: 'DBARBALACE',
      storeManagerFirstname: 'David',
      storeManagerLastname: 'Johnson',
      color: 'red',
    }),
    [],
  );

  /*TODO: Call campaign API */
  const api = useMemo(
    () => [
      {
        type: 'birthday',
        userId: 'Store manager',
        startDate: '27th Apr 23',
        endDate: '05th May 23',
        taskNumber: 25,
        completedTask: 19,
      },
    ],
    [],
  );

  /*@TODO: Get Tasks assigned */
  const tasksAssigned = useMemo(
    () => [
      {
        taskNumber: 14,
        label: t('Campaign.taskList.assigned'),
      },
      {
        taskNumber: 56,
        label: t('Campaign.taskList.notAssigned'),
      },
    ],
    [t],
  );

  //@TODO: Get current Page from api (DATA TABLE)
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Base loginNavbar>
      <div>
        <Text paragraph variant="headline1">
          Components
        </Text>

        {/* ------------------------------------- */}
        <ComponentBlock title="Loyalty active">
          <Container maxWidth="sm" disableGutters>
            <LoyaltyBox
              origin={'task'}
              response={loyaltyBoxMockListPopulatedActive}
              loading={false}
              bannerSearch={'SV'}
              countrySearch={'IT'}
              subscribeCB={console.log}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Loyalty suspended">
          <Container maxWidth="sm" disableGutters>
            <LoyaltyBox
              origin={'task'}
              loading={false}
              response={loyaltyBoxMockListPopulatedSuspended}
              bannerSearch={'SV'}
              countrySearch={'IT'}
              subscribeCB={console.log}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Loyalty subscribing">
          <Container maxWidth="sm" disableGutters>
            <LoyaltyBox
              origin={'task'}
              loading={false}
              response={loyaltyBoxMockListSubscribing}
              bannerSearch={'SV'}
              countrySearch={'IT'}
              subscribeCB={console.log}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Benefits">
          <Container maxWidth="sm" disableGutters>
            <Benefits response={BenefitsMock} />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Benefits both empty">
          <Container maxWidth="sm" disableGutters>
            <Benefits response={{benefits: [], notifications: []}} />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Benefits promo empty">
          <Container maxWidth="sm" disableGutters>
            <Benefits
              response={{
                benefits: [],
                notifications: BenefitsMock.notifications,
              }}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Benefits notifications empty">
          <Container maxWidth="sm" disableGutters>
            <Benefits
              response={{
                benefits: BenefitsMock.benefits,
                notifications: [],
              }}
            />
          </Container>
        </ComponentBlock>

        <ComponentBlock title="Script">
          <Container maxWidth="sm" disableGutters>
            <ScriptBox response={scriptBoxMock} />
          </Container>
        </ComponentBlock>
        <ComponentBlock title="Purchases details">
          <Container maxWidth="sm" disableGutters>
            <PurchasesDetails
              response={lastPurchasesResponseMock}
              viewPurchaseHistoryCB={console.log}
            />
          </Container>
        </ComponentBlock>
        <ComponentBlock title="Personal information SM AM RM">
          <Container maxWidth="sm" disableGutters>
            <PersonalInformation
              customersResponse={randomCustomersResponse}
              enableCustomersList
              loading={false}
            />
          </Container>
        </ComponentBlock>
        <ComponentBlock title="Personal information CC">
          <Container maxWidth="sm" disableGutters>
            <PersonalInformation
              customersResponse={randomCustomersResponse}
              loading={false}
            />
          </Container>
        </ComponentBlock>
        <ComponentBlock title="Context Menu">
          <ContextMenu
            label={t('ContextMenu.sortBy')}
            iconName={'chevron_down'}
            iconSize={14}
            subMenuItems={[
              {
                id: 'submenu-item-one',
                label: t('ContextMenu.subMenuItems.newestToOldest'),
                onClick: () =>
                  console.log(
                    'Submenu item 1' /* @TODO: Sorting Newest to Oldest*/,
                  ),
              },
              {
                id: 'submenu-item-two',
                label: t('ContextMenu.subMenuItems.oldestToNewest'),
                onClick: () =>
                  console.log(
                    'Submenu item 2' /* @TODO: Sorting Oldest to Newest*/,
                  ),
              },
            ]}
          />
        </ComponentBlock>

        {/* ------------------------------------- */}

        {/*@TODO: Get tasks assigned from api */}
        <ComponentBlock title="Report Card">
          {tasksAssigned.map(({taskNumber, label}, i) => (
            <TaskListLinks
              key={`${i}`}
              taskNumber={taskNumber}
              label={label}
              onClick={console.log}
            />
          ))}
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Report Card">
          <LuxCard
            campaignColor="red"
            startDate="2023-02-01"
            endDate="2023-07-31"
            numericList={[
              {
                label: t('Campaign.report.taskCompleted'),
                value: `${19}/${25}`,
              },
              {
                label: t('Campaign.report.contacted'),
                value: 14,
              },
              {
                label: t('Campaign.report.positive'),
                value: 4,
              },
              {
                label: t('Campaign.report.appointments'),
                value: 5,
              },
            ]}
          />
        </ComponentBlock>

        {/* ------------------------------------- */}

        {/*@TODO: Get info from api */}
        <ComponentBlock title="Team Member">
          <TeamMembers isLoading={false} members={[]} />
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Campaign Card">
          {api.map(item => (
            <CampaignCard campaign={item as SingleCampaign} />
          ))}
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Badge Campaigns">
          {badges.map(({badge}: any) => (
            <Badge key={badge} variant={'tiny'} />
          ))}
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Badge Task List">
          {badges.map(({badge}: any) => (
            <Badge key={badge} variant={'xxs'} />
          ))}
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Customer Balance Card"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Stack alignItems="center">
            {/*<CustomerBalance {...randomUserBalance} />*/}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Purchase Info">
          <Stack
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
            maxWidth="calc(100% - 40px)"
            marginLeft="auto"
            marginRight="auto">
            <PurchaseInfo
              lastPurchase="2023-04-15T16:47:58.000Z"
              customerSegment="EXPLORER"
              purchaseCategory={[
                PURCHASE_CATEGORIES.FRAMES,
                PURCHASE_CATEGORIES.STORE_SERVICES,
                PURCHASE_CATEGORIES.SUNGLASSES,
              ]}
              myAccount={true}
              email="gloglo.619t@gmail.com"></PurchaseInfo>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Customer Info Card"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Stack alignItems="center">
            {/*<CustomerInfo {...createFakeExtendedUser()} />*/}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Product Card"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Stack alignItems="center" rowGap={3}>
            {transactionItem.map((transaction: TransactionDataProps) => {
              return (
                <TransactionCard
                  key={transaction.orderId}
                  transaction={transaction}
                />
              );
            })}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Panel Toggle"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Stack alignItems="center">
            <PanelToggle summaryText={t('ProductCard.togglePanelShowLens')}>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Text>
            </PanelToggle>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock
          title="Task list - Card"
          sx={{backgroundColor: theme.palette.grey[600]}}>
          <Grid
            container
            rowSpacing={{xs: 1.6, md: 4}}
            columnSpacing={{xs: 1.6, md: 2}}>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeTopClients} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeTopClients} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeEE} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeEE} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeBirthday} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeBirthday} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeBirthday} />
            </Grid>
            <Grid item xs={4} md={12 / 5}>
              <TaskListCard {...randomTaskListCardInfoTypeQuote} />
            </Grid>
          </Grid>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Date Picker">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            maxWidth="30%">
            <Button variant="primary" onClick={() => setOpenDatePicker(true)}>
              Apri il calendario
            </Button>
            {openDatePicker && (
              <DatePicker
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                startDate={formatDate('27-04-2023')}
                endDate={formatDate('08-05-2023')}
                onSave={() => {
                  /* @TODO: Save Date */
                }}
              />
            )}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Task List Filters">
          <TaskListFilters
            allFilterId={''}
            filters={[]}
            selectedFilters={[]}
            onChange={console.log}
            onOrderChange={console.log}
          />
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Chips">
          <Box>
            <FiltersChips
              allFilterId="points-history-tab"
              filters={[
                {
                  id: 'points-history-tab',
                  label: 'Points History',
                },
                {
                  id: 'reward-list-tab',
                  label: 'Reward list',
                },
                {
                  id: 'mission-tab',
                  label: 'Mission',
                },
              ]}
              activeFilters={['points-history-tab']}
              onChange={console.log}
            />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Tabs">
          <Text paragraph variant="headline1" medium>
            Campaigns
          </Text>
          <Box>
            <Tabs
              mainTabId="active-only-tab"
              tabs={[
                {
                  id: 'active-only-tab',
                  label: 'Active only',
                },
                {
                  id: 'all-campaigns-tab',
                  label: 'All campaigns',
                },
              ]}
            />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Nested Tabs">
          <Text paragraph variant="headline1" medium>
            Task List
          </Text>
          <Box>
            <Tabs
              mainTabId="assigned-to-me-tab"
              subTabId="to-do-tab"
              tabs={[
                {
                  id: 'assigned-to-me-tab',
                  label: 'Assigned to me',
                  tabsSecondLevel: [
                    {
                      id: 'to-do-tab',
                      label: 'To do',
                    },
                    {
                      id: 'completed-tab',
                      label: 'Completed',
                    },
                  ],
                },
                {
                  id: 'all-tasks-tab',
                  label: 'All tasks',
                  tabsSecondLevel: [
                    {
                      id: 'not-assigned-tab',
                      label: 'Not assigned',
                    },
                    {
                      id: 'assigned-tab',
                      label: 'Assigned',
                    },
                    {
                      id: 'contacted-tab',
                      label: 'Contacted',
                    },
                  ],
                },
              ]}
            />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Search Result Card">
          <Stack alignItems="center">
            {/* <SearchResult {...randomSearchResult} /> */}
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Key Value Pair">
          <Stack spacing={8} direction="row" justifyContent="center">
            <KeyValuePair keyLabel="Variant" value="Normal" />
            <KeyValuePair keyLabel="Variant" value="Small" small />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Home Formbar">
          <Box>
            <HomepageFormbar {...homeFormbarProps} />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Searchbar">
          <Box>
            <Searchbar {...searchbarProps} />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Points Input">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Box sx={{width: 365}}>
              <PointsInput
                min={0}
                value={points}
                onChange={newValue => setPoints(newValue)}
                currentPoints={100}
                max={50}
              />
            </Box>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Number Input">
          <Stack spacing={2} direction="row" justifyContent="center">
            <Box sx={{width: 400}}>
              <NumberInput
                label="Basic Number Input"
                value={numberInput}
                onChange={newValue => setNumberInput(newValue)}
              />
            </Box>
            <Box sx={{width: 400}}>
              <NumberInput
                label="Number Input with Min/Max values (30-100)"
                min={30}
                max={100}
                value={numberInput2}
                onChange={newValue => setNumberInput2(newValue)}
              />
            </Box>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Select">
          <Stack spacing={2} direction="row">
            <Select
              label="Empty Select"
              options={options}
              value={emptySelected}
              onChange={e => setEmptySelected(e?.target.value ?? '')}
              placeholder="Choose a value"
            />
            <Select
              label="Basic Select"
              options={options}
              value={selected}
              onChange={e => setSelected(e?.target.value ?? '')}
            />
            <Select
              label="Basic Select w/ error"
              options={options}
              value={selected2}
              onChange={e => setSelected2(e?.target.value ?? '')}
              error={selected2 === options[2].value ? 'This is wrong' : ''}
            />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Dialog">
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button onClick={() => setDialogOpen(true)} variant="primary">
              Open Basic Dialog
            </Button>
            <Dialog
              open={dialogOpen}
              dialogTitle="Edit Customer Balance?"
              dialogActions={
                <CommonButtons
                  onClose={() => setDialogOpen(false)}
                  onConfirm={() => setDialogOpen(false)}
                  confirmText="Confirm"
                />
              }
            />
            <Button
              onClick={() => setAdvancedDialogOpen(true)}
              variant="primary">
              Open Advanced Dialog
            </Button>
            <Dialog
              open={advancedDialogOpen}
              dialogTitle="Edit Customer Balance?"
              dialogContent={
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advancedDialogReady}
                      onChange={(_, checked) => setAdvancedDialogReady(checked)}
                    />
                  }
                  label="Everything Ready"
                />
              }
              dialogActions={
                <CommonButtons
                  onClose={() => setAdvancedDialogOpen(false)}
                  onConfirm={() => setAdvancedDialogOpen(false)}
                  confirmText="Confirm"
                  confirmDisabled={!advancedDialogReady}
                />
              }
            />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Page Header">
          <Stack spacing={2}>
            <PageHeader
              title={t('PageHeader.loyaltyProfileManagement')}
              user={randomLoggedUser}
            />
            <PageHeader title="" user={randomLoggedUser} />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="User Avatar">
          <Box
            sx={{
              backgroundColor: 'black',
              height: 60,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Stack
              spacing={2}
              direction="row"
              divider={
                <Divider
                  sx={{backgroundColor: 'white'}}
                  orientation="vertical"
                  flexItem
                />
              }>
              {randomLoggedUsers.map((user, i) => (
                <UserAvatar key={i} user={user} />
              ))}
            </Stack>
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Toast">
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button
              onClick={() =>
                showSuccessToast(
                  'Your edit request has being succesfully processed',
                )
              }
              variant="primary">
              Success Toast
            </Button>
            <Button
              onClick={() =>
                showErrorToast('Something went wrong, please try again')
              }
              variant="primary">
              Error Toast
            </Button>
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Progress Wheel">
          <Stack spacing={2} alignItems="center">
            <Slider
              sx={{width: '200px'}}
              onChange={handleProgressWheelChange}
              {...progressWheelProps}
            />
            <ProgressWheel
              {...progressWheelProps}
              currentMin={0}
              nextMin={100}
              pointsToNextTier={0}
            />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Value Circle">
          <ValueCircle value={5} unit="Tier" />
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Form">
          <Box style={{display: 'flex', gap: 20, alignItems: 'flex-start'}}>
            <Box style={{width: 365}}>
              <TextInput label="Text input" placeholder="Text input" />
            </Box>
            <Box style={{width: 365}}>
              <TextInput
                label="Text input con errore"
                placeholder="Text input con errore"
                error="Campo obbligatorio"
              />
            </Box>
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Buttons">
          <Box style={{display: 'flex', gap: 20, alignItems: 'center'}}>
            <Button variant="primary">Primary</Button>
            <Button
              variant="primary"
              endIcon={
                <IconSVG
                  icon="resend_email"
                  size={22}
                  color={theme.palette.common.white}
                />
              }>
              Primary with icon
            </Button>
            <Button variant="primary" disabled>
              Primary disabled
            </Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="secondary" disabled>
              Secondary disabled
            </Button>
            <Button variant="tertiary">Tertiary</Button>
            <Button variant="tertiary" disabled>
              Tertiary disabled
            </Button>
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Tooltip">
          <Box display="flex" justifyContent="center" alignItems="center">
            <TooltipInfo title="Reason of balance edit" />
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Links">
          <Box style={{display: 'flex', gap: 20, alignItems: 'flex-start'}}>
            <Link linkVariant="dark" textVariant="headline4" href="#">
              Simple link dark
            </Link>
            <Link linkVariant="light" textVariant="headline4" href="#">
              Simple link light
            </Link>
            <Link
              linkVariant="dark"
              textVariant="headline4"
              href="#"
              iconEnd={<IconSVG icon="arrow_right_2" size={24} color="#fff" />}>
              View purchase history
            </Link>
            <Link
              linkVariant="light"
              textVariant="headline4"
              href="#"
              iconEnd={<IconSVG icon="arrow_right_2" size={24} color="#000" />}>
              View purchase history
            </Link>
            <Link
              linkVariant="light"
              textVariant="headline4"
              href="#"
              iconStart={
                <IconSVG icon="arrow_left_2" size={24} color="#000" />
              }>
              Back
            </Link>
            <Link
              linkVariant="dark"
              textVariant="headline4"
              href="#"
              iconStart={
                <IconSVG icon="arrow_left_2" size={24} color="#fff" />
              }>
              Back
            </Link>
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Colors">
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '25px',
            }}>
            {[
              {
                palette: 'common.black',
                name: 'Black',
              },
              {
                palette: 'common.white',
                name: 'White',
              },
              {
                palette: 'primary.main',
                name: 'Primary main',
              },
              {
                palette: 'primary.disabled',
                name: 'Primary disabled',
              },
              {
                palette: 'secondary.main',
                name: 'Secondary main',
              },
              {
                palette: 'tertiary.main',
                name: 'Tertiary main',
              },
              {
                palette: 'error.main',
                name: 'Error main',
              },
              {
                palette: 'success.main',
                name: 'Success main',
              },
              {
                palette: 'grey.900',
                name: 'Grey 900',
              },
              {
                palette: 'grey.800',
                name: 'Grey 800',
              },
              {
                palette: 'grey.700',
                name: 'Grey 700',
              },
              {
                palette: 'grey.600',
                name: 'Grey 600',
              },
              {
                palette: 'grey.500',
                name: 'Grey 500',
              },
              {
                palette: 'grey.300',
                name: 'Grey 300',
              },
            ].map(color => (
              <Box key={color.name}>
                <Paper
                  sx={{
                    backgroundColor: color.palette,
                    width: 100,
                    height: 100,
                  }}
                />
                <Text variant="body">{color.name}</Text>
              </Box>
            ))}
          </Box>
        </ComponentBlock>

        {/* ------------------------------------- */}
        <ComponentBlock title="Feedback Modal">
          <FeedbackModal>
            <Button variant="primary">apri feedback</Button>
          </FeedbackModal>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Tag member/guest">
          <Stack spacing={2}>
            <Tag variant="member" />
            <Tag variant="guest" />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Star rating">
          <Stack spacing={2}>
            <StarRating value={1} />
            <StarRating value={2} />
            <StarRating value={3} />
          </Stack>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Purchase categories">
          <PurchaseCategory categories={purchaseCategories}></PurchaseCategory>
        </ComponentBlock>

        {/* ------------------------------------- */}

        <ComponentBlock title="Typography">
          <Stack spacing={2}>
            <Text paragraph variant="headline1">
              Headline1
            </Text>
            <Text paragraph variant="headline2">
              Headline2
            </Text>
            <Text paragraph variant="headline3">
              Headline3
            </Text>
            <Text paragraph variant="headline5">
              Headline4
            </Text>
            <Text paragraph variant="body">
              Body
            </Text>
          </Stack>
        </ComponentBlock>
        <ComponentBlock title="Icons">
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '25px',
            }}>
            {[
              'view_on',
              'view_off',
              'arrow_left',
              'arrow_right',
              'add',
              'chevron_down',
              'chevron_left',
              'chevron_right',
              'close',
              'deactivate_card',
              'edit',
              'export',
              'filter',
              'remove',
              'resend_card',
              'resend_email',
              'search',
              'alert_circle',
              'tier_benefits',
              'action',
              'reset',
              'messages',
              'shop',
              'logout',
              'cross_circle',
              'search_no_results',
              'card_actions',
              'arrow_left_2',
              'arrow_right_2',
              'star',
              'lens_glasses',
              'lens_sunglasses',
              'clothes',
              'retail',
              'contact',
              'calendar',
              'plus_circle',
              'minus_circle',
              'user',
              'reorder',
              'assign_to_me',
              'accordation_close',
              'accordation_open',
              'clock',
              'customer_information',
              'customer_profile',
              'error',
              'eye_checkup',
              'glasses',
              'lens_contact',
              'lenses',
              'manage_consents',
              'sunglasses',
              'text',
              'web',
            ].map(iconName => (
              <Paper
                key={iconName}
                elevation={0}
                square
                sx={{textAlign: 'center'}}>
                <Text>
                  <IconSVG
                    icon={iconName as IconNames}
                    size={24}
                    color="#000"
                  />
                  <br />
                  {iconName}
                </Text>
              </Paper>
            ))}
          </Box>
        </ComponentBlock>
      </div>
    </Base>
  );
};

export default React.memo(Components);
