export enum PURCHASE_CATEGORIES {
  LENSES = 'lenses',
  OTHER = 'other',
  FRAMES = 'frames',
  APPAREL = 'apparel',
  CONTACTS = 'contacts',
  SUNGLASSES = 'sunglasses',
  OPTICAL_SERVICE = 'optical service',
  STORE_SERVICES = 'store services',
  FOOTWEAR = 'footwear',
  ACCESSORIES = 'accessories',
  CENTRAL_PURCHASING = 'central purchasing',
  RETAIL_ACCESSORIES = 'retail accessories',
  MANUFACTURING_SERVICE = 'manufacturing service',
  READERS = 'readers',
  SUN = 'sun',
  CONTRACTS = 'contracts',
  ONESIGHT = 'onesight',
  MISC = 'misc',
  WELLNESS_CATEGORY = 'wellness category',
  COMPLETE_PAIR_OPTICAL = 'complete pair optical',
}

export interface PurchaseCategoryProps {
  categories: Array<PURCHASE_CATEGORIES>;
  color?: string;
}
