import React, {useMemo, useState, useCallback, ReactNode} from 'react';
import {PiCaretUpBold, PiCaretDownBold} from 'react-icons/pi';

import {
  ChipProps,
  Chip,
  Box,
  Stack,
  Button,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {BodyText} from '@/atoms/BodyText';
import {Headline5} from '@/atoms/Headline5';
import {Modal} from '@/atoms/Modal';
import {TinyText} from '@/atoms/TinyText';
import {XSText} from '@/atoms/XSText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {
  ChipProfilesProps,
  ProfilesListModalProps,
  PersonaBoxProps,
} from '@/molecules/ChipProfiles/interfaces';
import {useStyles} from '@/molecules/ChipProfiles/styles';
import {usePersonas, Persona} from '@/store/personas';
import {Colors} from '@/themes/variables';

const BASE_TRANSLATION_PATH = 'PurchaseList.filters';

const ChipProfiles = ({
  selectedCustomers,
  onCustomerChange,
  request,
}: ChipProfilesProps) => {
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION_PATH);
  const {getIndividualsSearch} = usePersonas();
  const styles = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);

  // Show hide modal
  const toggleModal = useCallback((value?: boolean) => {
    setShowModal(prevValue => {
      if (typeof value === 'boolean') {
        return value;
      }

      return !prevValue;
    });
  }, []);

  // Retrieve personas
  const {data: IndividualsSearch} = useQuery({
    queryKey: ['getIndividualsSearch', request],
    queryFn: () => getIndividualsSearch(request),
    enabled: !!request,
    refetchOnWindowFocus: false,
    retry: false,
  });

  // Check if customers are selected
  const hasCustomers = useMemo<boolean>(() => {
    return selectedCustomers?.length > 0;
  }, [selectedCustomers]);

  // Chip variant based on customers selected
  const variant = useMemo<ChipProps['variant']>(() => {
    return hasCustomers ? 'filterActive' : 'filterDefault';
  }, [hasCustomers]);

  // Chip label based on customers selected
  const chipLabel = useMemo<string>(() => {
    if (!hasCustomers) {
      return getTranslationWithValue(0, `profiles.filterLabel`);
    }

    if (selectedCustomers?.length === 1) {
      return getTranslationWithValue(0, `profiles.allFilterLabel`, {
        customerId: selectedCustomers[0],
      });
    }

    return getTranslationWithValue(0, `profiles.searchPlaceholder`, {
      size: selectedCustomers.length,
    });
  }, [getTranslationWithValue, hasCustomers, selectedCustomers]);

  // Chip label with icon
  const label = useMemo<ReactNode>(() => {
    return (
      <Stack
        display="grid"
        gridTemplateColumns="1fr max-content"
        gap={0.4}
        alignItems="center">
        <TinyText overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {chipLabel}
        </TinyText>
        {showModal ? (
          <PiCaretUpBold fontSize={20} />
        ) : (
          <PiCaretDownBold fontSize={20} />
        )}
      </Stack>
    );
  }, [chipLabel, showModal]);

  // Modal title
  const modalTitle = useMemo(() => {
    return getTranslationWithValue(0, `profiles.modalTitle`, {
      size: IndividualsSearch?.personas?.length ?? 0,
    });
  }, [IndividualsSearch?.personas?.length, getTranslationWithValue]);

  // Handle customer change
  const handleCustomerChange = useCallback(
    (customerIds: Array<string>) => {
      onCustomerChange(customerIds);
      toggleModal(false);
    },
    [onCustomerChange, toggleModal],
  );

  return (
    <>
      <Box onClick={() => toggleModal(true)} paddingY={0.8}>
        <Chip
          sx={styles.chip}
          disabled={false}
          label={label}
          clickable={true}
          variant={variant}
        />
      </Box>
      {showModal && (
        <Modal
          open={showModal}
          onClose={() => toggleModal(false)}
          maxWidth={60}
          dialogTitle={modalTitle}
          sxContent={{scrollbarWidth: 'none'}}
          dialogContent={
            <ProfilesListModal
              selectedCustomers={selectedCustomers}
              personas={IndividualsSearch?.personas ?? []}
              onCustomerChange={handleCustomerChange}
            />
          }
        />
      )}
    </>
  );
};

export default React.memo(ChipProfiles);

const ProfilesListModal = React.memo(
  ({selectedCustomers, personas, onCustomerChange}: ProfilesListModalProps) => {
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION_PATH);
    const styles = useStyles();
    const [selectedIds, setSelectedIds] =
      useState<Array<string>>(selectedCustomers);

    // Check if profiles can be submitted
    const canSubmit = useMemo(() => {
      const differentLength = selectedCustomers?.length !== selectedIds.length;
      const differentValues =
        selectedIds.filter(c => selectedCustomers.includes(c)).length !==
        selectedCustomers.length;

      return differentLength || differentValues;
    }, [selectedCustomers, selectedIds]);

    // Check if all profiles can be selected
    const canSelectAll = useMemo(() => {
      return selectedIds.length !== personas.length;
    }, [personas.length, selectedIds.length]);

    // Check if all profiles can be deselected
    const canDeselectAll = useMemo(() => {
      return selectedIds.length > 0;
    }, [selectedIds.length]);

    // Toggle checkbox on profile
    const toggleCheck = useCallback(
      (customerId: string) => {
        const isChecked = selectedIds.includes(customerId);
        if (isChecked) {
          setSelectedIds(prevState =>
            prevState.filter(id => id !== customerId),
          );
        } else {
          setSelectedIds(prevState => [...prevState, customerId]);
        }
      },
      [selectedIds],
    );

    // Toggle select/deselect all profiles
    const toggleSelectAll = useCallback(
      (type: 'select' | 'deselect') => {
        switch (type) {
          case 'select':
            setSelectedIds(personas.map(persona => persona.customerId!));
            break;
          case 'deselect':
            setSelectedIds([]);
            break;
          default:
            break;
        }
      },
      [personas],
    );

    return (
      <Stack direction="column" gap={3.2} marginTop={3.2} width="100%">
        <Stack direction="column" gap={0.8}>
          <Stack direction="row" gap={1.6}>
            <BodyText
              sx={styles.selectCta(canSelectAll)}
              onClick={() => toggleSelectAll('select')}>
              {getTranslationWithValue(0, `profiles.selectAll`)}
            </BodyText>
            <BodyText
              sx={styles.selectCta(canDeselectAll)}
              onClick={() => toggleSelectAll('deselect')}>
              {getTranslationWithValue(0, `profiles.deselectAll`)}
            </BodyText>
          </Stack>
          {personas?.length > 0 && (
            <Stack sx={styles.profileModalContainer}>
              {personas?.map(persona => {
                return (
                  <PersonaBox
                    key={persona?.customerId}
                    persona={persona}
                    isSelected={selectedIds?.includes(persona?.customerId!)}
                    onSelect={toggleCheck}
                  />
                );
              })}
            </Stack>
          )}
        </Stack>
        <Box textAlign="center">
          <Button
            variant="basePrimary"
            disabled={!canSubmit}
            {...(canSubmit && {onClick: () => onCustomerChange(selectedIds)})}>
            <TinyText>
              {getTranslationWithValue(0, `profiles.selectProfiles`)}
            </TinyText>
          </Button>
        </Box>
      </Stack>
    );
  },
);

ProfilesListModal.displayName = 'ProfilesListModal';

const PersonaBox = React.memo(
  ({persona, onSelect, isSelected}: PersonaBoxProps) => {
    const styles = useStyles();
    const {getTranslationWithValue} = useBaseTranslation(
      `${BASE_TRANSLATION_PATH}.profiles.personaBox`,
    );

    // Format persona name to display
    const personaName = useCallback((persona: Persona) => {
      return `${persona?.firstName ?? ''} ${
        persona?.lastName ?? ''
      }`.toLowerCase();
    }, []);

    return (
      <Stack sx={styles.personaBox}>
        <FormControlLabel
          className="formControlLabel"
          control={
            <Checkbox
              className="checkbox"
              disableRipple
              checked={isSelected}
              onChange={() => onSelect(persona?.customerId!)}
            />
          }
          label={
            <BodyText>
              {getTranslationWithValue(0, 'customerId', {
                customerId: persona?.customerId,
              })}
            </BodyText>
          }
        />
        <Headline5 superHeavy className="personaName">
          {personaName(persona)}
        </Headline5>
        <Stack display="grid" gridTemplateColumns="1fr 1fr" gap={1.6}>
          <Stack
            direction="column"
            gap={1.6}
            divider={<Divider orientation="horizontal" />}>
            <LabelValue
              label={getTranslationWithValue(0, 'email')}
              value={persona?.email ?? '-'}
            />
            <LabelValue
              label={getTranslationWithValue(0, 'taxCode')}
              value={persona?.taxCode ? persona?.taxCode?.toUpperCase() : '-'}
            />
          </Stack>
          <Stack
            direction="column"
            gap={1.6}
            divider={<Divider orientation="horizontal" />}>
            <LabelValue
              label={getTranslationWithValue(0, 'mobile')}
              value={persona?.mobile ?? '-'}
            />
            <Box />
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

PersonaBox.displayName = 'PersonaBox';

const LabelValue = React.memo(
  ({
    label,
    value,
    direction = 'column',
  }: {
    label: string | JSX.Element;
    value: string | JSX.Element;
    direction?: 'row' | 'column';
  }) => {
    const printValue = useMemo(() => {
      if (typeof value === 'string') {
        return <BodyText medium>{value}</BodyText>;
      }

      return value;
    }, [value]);

    const printLabel = useMemo(() => {
      if (typeof label === 'string') {
        return <XSText color={Colors.GreyDark}>{label}</XSText>;
      }

      return label;
    }, [label]);

    return (
      <Stack
        direction={direction}
        gap={0.4}
        {...(direction === 'row' && {gap: 0.8, alignItems: 'center'})}>
        {printValue}
        {printLabel}
      </Stack>
    );
  },
);

LabelValue.displayName = 'LabelValue';
