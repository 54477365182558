import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useApiPath, APIS} from '@/store/api/useApiPath';

import {useAPI} from '../api';
import {PostUserFeedbackParams} from './types';

/**
 * Remove metadata from bae64 image string
 * @param base64
 */
const parseImage = (base64: string) => {
  if (!base64) return '';

  return base64.split('base64,')[1];
};

/**
 * @brief Hook to post users feedback
 */
export const useFeedback = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const canSubmitFeedback = useCallback(
    (note: string, screenshot: string | null) => {
      const noteIsValid = note.trim().length > 0;
      const screenIsValid = !!screenshot;

      return noteIsValid && screenIsValid;
    },
    [],
  );

  /**
   * @brief Post user feedback data
   */
  const postUserFeedback = useCallback(
    async (params: PostUserFeedbackParams) => {
      try {
        await api.post<void>(getApiPath(APIS.FEEDBACK), {
          ...params,
          imageData: parseImage(params?.imageData),
        });
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      canSubmitFeedback,
      postUserFeedback,
    }),
    [canSubmitFeedback, postUserFeedback],
  );
};
