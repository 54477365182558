import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const button: SxProps<Theme> = useMemo(
    () => ({textTransform: 'uppercase', minWidth: '150px'}),
    [],
  );

  const dialog: SxProps<Theme> = useMemo(
    () => ({
      '& .MuiDialogContent-root': {
        alignItems: 'flex-start',
      }
    }),
    [],
  );

  return {button, dialog};
};
