import {Dayjs} from 'dayjs';

export enum ReportFiltersActions {
  changeField = 'change report field',
  changeDates = 'change report dates',
  changePage = 'change report page',
  resetFilters = 'reset report filters',
}

interface ChangeFieldPayload<T> {
  key: keyof T;
  value: any;
}

export interface ReportFilters {
  regions: string[];
  stores: string[];
  status: string[];
  codes: string[];
  startDate: string;
  endDate: string;
  sortBy: string | null;
  orderBy: 'ASC' | 'DESC';
  page: number;
}

export type ReportFiltersAction =
  | {
      type: ReportFiltersActions.changeField;
      payload: ChangeFieldPayload<ReportFilters>;
    }
  | {
      type: ReportFiltersActions.changeDates;
      payload: {
        startDate: Dayjs;
        endDate: Dayjs;
      };
    }
  | {
      type: ReportFiltersActions.changePage;
      payload: number;
    }
  | {
      type: ReportFiltersActions.resetFilters;
      payload: undefined;
    };

export interface CommonReportsFilter {
  startDate?: string | null;
  endDate?: string | null;
  stores?: Array<string | number> | null;
  codes?: string[] | null;
  status?: string[] | null;
  regions?: string[] | null;
  q?: string;
}

/* AREAS FILTER INTERFACES */
export interface GetFilterAreasParams
  extends Omit<CommonReportsFilter, 'regions'> {}

export type GetFilterAreasResponse = string[];

/* STORES FILTER INTERFACES */
export interface GetFilterStoresParams
  extends Omit<CommonReportsFilter, 'stores'> {}

export type GetFilterStoresResponse = {
  storeId: string;
  uuid: string;
  storeName: string;
}[];

/* STATUS FILTER INTERFACES */
export interface GetFilterStatusParams
  extends Omit<CommonReportsFilter, 'status'> {}

export type GetFilterStatusResponse = string[];

/* CAMPAIGNS FILTER INTERFACES */
export interface GetFilterCampaignsParams
  extends Omit<CommonReportsFilter, 'codes'> {}

export type GetFilterCampaignsResponse = {
  code: string;
  name: string;
}[];

/* CAMPAIGN DETAIL FILTER INTERFACES */
export interface GetFilterCampaignDetailParams
  extends Pick<CommonReportsFilter, 'startDate' | 'endDate'> {
  storeId: number | string;
  userUuids?: string[];
}

export type GetFilterCampaignDetailResponse = GetFilterCampaignsResponse;

/* ASSIGNEES FILTER INTERFACES */
export interface GetFilterAssigneesParams
  extends Pick<CommonReportsFilter, 'codes' | 'startDate' | 'endDate'> {
  storeId: number | string;
}

export type GetFilterAssigneesResponse = {
  uuid: string;
  assigneeLuxId: string;
  assigneeFirstname: string;
  assigneeLastname: string;
}[];
