import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {ChipDropdownProps} from '@/atoms/ChipDropdown/interfaces.d';

export const useStyles = (capitalize: ChipDropdownProps['capitalize']) => {
  const theme = useTheme();

  const chip = useMemo<SxProps<Theme>>(
    () => ({
      width: 'fit-content',
      padding: theme.spacing(0.5, 0.8),
      '.MuiChip-label': {
        padding: theme.spacing(0),
      },
    }),
    [theme],
  );

  const menu = useMemo<SxProps<Theme>>(
    () => ({
      '.MuiList-root': {
        padding: theme.spacing(0),
        width: theme.spacing(29),
        '.MuiChip-root': {
          padding: theme.spacing(0.5, 0.8),
          '.MuiChip-label': {
            padding: theme.spacing(0, 0.4, 0, 0),
          },
        },
        '.MuiMenuItem-root': {
          cursor: 'default',
        },
        '.Mui-focusVisible': {
          backgroundColor: 'transparent',
        },
      },
    }),
    [theme],
  );

  const menuItem = useMemo<SxProps<Theme>>(
    () => ({
      padding: theme.spacing(0),
      ':hover': {
        backgroundColor: 'transparent',
      },
    }),
    [theme],
  );

  const menuTextField = useMemo<SxProps<Theme>>(
    () => ({
      fontSize: theme.typography.body.fontSize,
      padding: theme.spacing(0.8),
      border: `${theme.spacing(0.1)} solid ${theme.palette.primary[200]}`,
      borderRadius: theme.spacing(0.8),
      '.MuiInputBase-input': {
        padding: 0,
      },
      ':hover': {
        border: `${theme.spacing(0.1)} solid ${theme.palette.primary[300]}`,
      },
      ':before, :after': {
        display: 'none',
      },
    }),
    [theme],
  );

  const menuSelected = useMemo<SxProps<Theme>>(
    () => ({
      maxHeight: theme.spacing(10),
      paddingX: theme.spacing(1.6),
      gap: theme.spacing(0.4),
      display: 'flex',
      flexFlow: 'row',
      flexWrap: 'wrap',
      overflowY: 'auto',
      overflowX: 'hidden',
    }),
    [theme],
  );

  const menuList = useMemo<SxProps<Theme>>(
    () => ({
      height: theme.spacing(20),
      overflowY: 'auto',
      overflowX: 'hidden',
    }),
    [theme],
  );

  const menuListItem = useMemo<SxProps<Theme>>(
    () => ({
      borderBottom: `${theme.spacing(0.1)} solid ${theme.palette.primary[200]}`,
      '.listItemText': {
        wordBreak: 'break-word',
        ...(capitalize && {
          textTransform: 'capitalize',
        }),
        '.MuiTypography-root': {
          // overflow: 'hidden',
          // textOverflow: 'ellipsis',
          // whiteSpace: 'nowrap',
        },
      },
    }),
    [capitalize, theme],
  );

  return useMemo(
    () => ({
      chip,
      menu,
      menuItem,
      menuTextField,
      menuSelected,
      menuList,
      menuListItem,
    }),
    [chip, menu, menuItem, menuTextField, menuSelected, menuList, menuListItem],
  );
};
