import {
  BasePaginatedListResponse,
  BasePaginationParams,
  BaseSortingParams,
  BaseDateParams,
} from '@/definitions/base';

export interface GetFiltersParams {
  storeId: number | string;
  visibility?: TASK_VISIBILITY;
  status?: TASK_STATUS;
}

export type GetFiltersResponse = SingleCampaign[];

/* CAMPAIGNS interfaces */
export interface GetDashboardCardsParams
  extends BasePaginationParams,
    BaseSortingParams,
    BaseDateParams,
    GetFiltersParams {
  codes?: string[];
  assignee?: string[];
  active?: boolean;
}

export interface SingleCampaign {
  active: boolean;
  appointmentBooked: number;
  code: string;
  color: string;
  completedTask: number;
  country: string;
  eeBooked: number;
  endDate: string;
  name: string;
  positiveContacted: number;
  sapStoreId: string;
  startDate: string;
  statusContacted: number;
  taskNumber: number;
  type: string;
  userId: string;
}

export interface SingleCampaignQuery {
  campaignCode: string;
  campaignStartDate: string;
  campaignEndDate: string;
  status: string;
  owner: string;
}

export interface GetCampaignTableParams {
  limit?: number;
  sortBy?: string | null;
  orderBy?: string;
  page?: number;
  q?: string;
  campaignCode: string;
  storeId: number;
}

export interface GetAllCampaignsTableParams {
  limit?: number;
  sortBy?: string | null;
  orderBy?: string;
  page?: number;
  codes?: string[];
  storeId: number;
  startDate: string;
  endDate: string;
}

export interface GetDashboardCardsResponse extends BasePaginatedListResponse {
  data: SingleCampaign[];
}

/* TASKS interfaces */

export interface GetReportCampaignParams {
  page?: number;
  limit?: number;
  sortBy?: 'STORE' | 'STATUS' | 'FY' | 'FW' | null | string;
  orderBy?: 'ASC' | 'DESC';
  q?: string;
  codes?: string[] | null;
  startDate?: string;
  endDate?: string;
}

export interface GetTasksParams
  extends BasePaginationParams,
    BaseSortingParams,
    GetFiltersParams {
  code?: string[];
  assignee?: string[];
}

export interface GetTasksResponse extends BasePaginatedListResponse {
  data: SingleTask[];
}

export interface SingleTask {
  id: number;
  customerId: string | {label: string; onClick: () => void};
  firstName: string;
  lastName: string;
  mobile: string;
  addressCity: string;
  lastVisitDate: string;
  callTime: string;
  campaignCode: string;
  campaignName: string;
  campaignStartDate: string;
  campaignEndDate: string;
  campaignType: string;
  campaignColor: string;
  feedbackStatus: TASK_STATUS;
  storeAssociate: string;
  saInStore: string;
  assignee: Assignee;
  segment: string;
  expireDate?: string;
  editable?: boolean;
}

export interface CampaignRow {
  id: string;
  code: string;
  color: string;
  name: string;
  taskNumber: number;
  newTask: number;
  newTaskPerc: number;
  completedTask: number;
  completedTaskPerc: number;
  startDate: string;
  endDate: string;
  eeBooked: number;
  eeBookedPerc: number;
  contactedTask: number;
  contactedTaskPerc: number;
  appointmentBooked: number;
  appointmentBookedPerc: number;
  positiveFeedback: number;
  positiveFeedbackPerc: number;
  mom: number;
  appointmentCheckIn: number;
  appointmentCheckInPerc: number;
  momPreMonth: number;
  momCurMonth: number;
  active: boolean;
}

export interface GetCampaignDetailHeader {
  campaignCode: string;
  campaignColor: string;
  campaignName: string;
  campaignType: string;
  campaignEndDate: string;
  campaignStartDate: string;
  campaignStoreName: string;
  campaignStoreId: string;
  status: string;
}

export interface GetCampaignTableResponse {
  data: SingleTask[];
  metadataDTO: {
    page: number;
    size: number;
    total_elements: number;
    total_pages: number;
  };
}

export interface GetAllCampaignsTableResponse {
  data: CampaignRow[];
  metadataDTO: {
    page: number;
    size: number;
    total_elements: number;
    total_pages: number;
  };
}

export interface Assignee {
  id: number;
  luxId: string;
  banner: string;
  country: string;
  firstName: string;
  lastName: string;
}

export enum TASK_VISIBILITY {
  ASSIGNTOME = 'ASSIGNTOME',
  ALL = 'ALL',
  ACTIVEONLY = 'ACTIVEONLY',
}

export enum TASK_STATUS {
  ALL = 'ALL',
  TODO = 'TODO',
  COMPLETED = 'COMPLETED',
  NOTASSIGNED = 'NOTASSIGNED',
  ASSIGNED = 'ASSIGNED',
  CONTACTED = 'CONTACTED',
  ALREADY_PURCHASED = 'ALREADY_PURCHASED',
}

export interface GetCampaignDetailParams {
  storeId: number;
  campaignCode: string;
}

export type CampaignDetailProps = {
  assigneeLuxId: string;
  assigneeFirstname: string;
  assigneeLastname: string;
  sapStoreId: string;
  active: boolean;
  country: string;
  code: string;
  name: string;
  type: string;
  startDate: string;
  endDate: string;
  taskNumber: number;
  completedTask: number;
  notCompletedTask: number;
  statusNew: number;
  statusContacted: number;
  statusWaiting: number;
  statusNoAnswer: number;
  statusNotContactable: number;
  statusAlreadyPurchased: number;
  eeBooked: number;
  appointmentBooked: number;
  storeManagerId: string;
  storeManagerFirstname: string;
  storeManagerLastname: string;
  color: string;
};
export interface GetCampaignDetailResponse {
  general: CampaignDetailProps;
  userList: Array<CampaignDetailProps>;
}
