import React from 'react';
import {useTranslation} from 'react-i18next';

import {Stack, useTheme, Box} from '@mui/material';

import {Card} from '@/atoms/Card';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {Colors} from '@/themes/variables';

import {Text} from '../../atoms/Typography/Text';
import {TaskListLinksProps} from './interfaces';

const DashboardTaskList = ({
  taskNumber,
  label,
  onClick,
  tooltip,
}: TaskListLinksProps) => {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <Card>
      <Stack
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between">
        <Stack
          display="flex"
          direction="row"
          gap={1.2}
          alignItems="center"
          height={34}>
          <Text
            height={24}
            variant="headline2"
            lineHeight={theme.typography.headline2.fontSize}
            superHeavy>
            {taskNumber}
          </Text>
          <Text
            variant="tiny"
            color={theme.palette.grey[700]}
            lineHeight={theme.typography.tiny.fontSize}>
            {label}
          </Text>
          {tooltip && (
            <Box sx={{transform: 'rotate(180deg)'}}>
              <IconSVG
                icon="error"
                size={20}
                color={Colors.GreyDark}
                tooltip={tooltip}
              />
            </Box>
          )}
        </Stack>
        <Link
          position="center"
          linkVariant="dark"
          textVariant="body"
          lineHeight={theme.typography.body.fontSize}
          href="#"
          handleOnClick={onClick}>
          {t('StoreManager.dashboard.viewAll')}
        </Link>
      </Stack>
    </Card>
  );
};

export default React.memo(DashboardTaskList);
