import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const clickable: SxProps<Theme> = useMemo(
    () => ({
      cursor: 'pointer',
      opacity: 1,
      transition: 'all 0.2s linear',
      ':hover': {
        opacity: 0.7,
      },
    }),
    [],
  );

  const inputSearch = useMemo<SxProps<Theme>>(
    () => ({
      fontSize: theme.typography.body.fontSize,
      padding: theme.spacing(1.6),
      border: `${theme.spacing(0.1)} solid ${Colors.Black}`,
      borderRadius: theme.spacing(0.8),
      '.MuiInputBase-input': {
        padding: 0,
      },
      ':before, :after': {
        display: 'none',
      },
    }),
    [theme],
  );

  const contentAppointments: SxProps<Theme> = useMemo(
    () => ({
      width: '100%',
      marginTop: theme.spacing(1.6),
      overflowY: 'auto',
      scrollbarWidth: 'none',
      height: theme.spacing(37),
    }),
    [theme],
  );

  const contentInitial: SxProps<Theme> = useMemo(
    () => ({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(2),
      height: theme.spacing(37),
      fontStyle: 'italic',
      position: 'absolute',
      right: 0,
      left: 0,
    }),
    [theme],
  );

  return useMemo(
    () => ({
      clickable,
      inputSearch,
      contentAppointments,
      contentInitial,
    }),
    [clickable, inputSearch, contentAppointments, contentInitial],
  );
};
