import React, {useCallback, useMemo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {Stack, TextField} from '@mui/material';
import {MobileDatePicker} from '@mui/x-date-pickers';
import {useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {InputWrapper} from '@/atoms/InputWrapper';
import {Select} from '@/atoms/Select';
import {SelectOptions} from '@/atoms/Select/interfaces';
import {Text} from '@/atoms/Typography/Text';
import {useFilterDialogFunctions} from '@/hooks/useFilterDialogFunctions';
import {FilterDialogProps} from '@/hooks/useFilterDialogFunctions/interfaces';
import {PointsHistoryFilter} from '@/store/loyaltyFilters/pointsHistory.reducer';
import {usePoints} from '@/store/points';

import {useStyle} from './styles';

const FilterPointsForm = ({
  filters,
  minPointsRange,
  maxPointsRange,
  formCB,
  validCB,
}: FilterDialogProps<PointsHistoryFilter>) => {
  const {formData, handleChangeField, handleDate} =
    useFilterDialogFunctions<PointsHistoryFilter>(filters);

  const {getActivities} = usePoints();
  const {t} = useTranslation();
  const {numberInput} = useStyle();

  const minDate = useMemo(() => {
    return dayjs().subtract(24, 'month');
  }, []);

  const {data: Activities} = useQuery(['activities'], () => getActivities(), {
    retry: false,
  });

  const activities: SelectOptions = useMemo(() => {
    if (Activities) {
      return Activities.map(activity => ({
        value: activity.eventId,
        label: activity.description,
      }));
    } else {
      return [];
    }
  }, [Activities]);

  const handleInputCharacters = useCallback(
    (
      e: React.KeyboardEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
      >,
    ) => {
      if (
        e.key !== 'Backspace' &&
        e.key !== 'ArrowLeft' &&
        e.key !== 'ArrowRight' &&
        e.key !== 'Tab' &&
        e.key !== '-' &&
        !/[0-9]/.test(e.key)
      ) {
        e.preventDefault();
      }
    },
    [],
  );

  const minPointsError = useMemo(() => {
    if (formData?.pointsMin !== '') {
      return (
        Number(formData?.pointsMin) < minPointsRange! ||
        Number(formData?.pointsMin) > maxPointsRange!
      );
    } else {
      return false;
    }
  }, [formData?.pointsMin, maxPointsRange, minPointsRange]);

  const maxPointsError = useMemo(() => {
    if (formData?.pointsMax !== '') {
      return (
        Number(formData?.pointsMax) < minPointsRange! ||
        Number(formData?.pointsMax) > maxPointsRange!
      );
    } else {
      return false;
    }
  }, [formData?.pointsMax, maxPointsRange, minPointsRange]);

  const pointsRangeError = useMemo(() => {
    if (!minPointsError && !maxPointsError) {
      return parseInt(formData?.pointsMin!) > parseInt(formData?.pointsMax!);
    } else {
      return false;
    }
  }, [
    formData?.pointsMax,
    formData?.pointsMin,
    maxPointsError,
    minPointsError,
  ]);

  const isFormValid = useMemo(() => {
    let valid = false;

    const filtersChanged = JSON.stringify(filters) !== JSON.stringify(formData);
    const hasDates = Boolean(formData?.dateFrom) || Boolean(formData?.dateTo);
    const bothDatesCompiled =
      Boolean(formData?.dateFrom) && Boolean(formData?.dateTo);
    const datesValid = hasDates
      ? dayjs(formData?.dateFrom).isValid() && dayjs(formData?.dateTo).isValid()
      : true;
    const hasPoints = formData?.pointsMin !== '' || formData?.pointsMax !== '';
    const bothPointsCompiled =
      formData?.pointsMin !== '' && formData?.pointsMax !== '';
    const pointsValid = !minPointsError && !maxPointsError && !pointsRangeError;

    if (filtersChanged) {
      if (hasDates && hasPoints) {
        valid =
          bothDatesCompiled && datesValid && bothPointsCompiled && pointsValid;
      } else if (hasDates && !hasPoints) {
        valid = bothDatesCompiled && datesValid;
      } else if (!hasDates && hasPoints) {
        valid = bothPointsCompiled && pointsValid;
      } else if (!hasDates && !hasPoints) {
        valid = true;
      }
    }

    return valid;
  }, [filters, formData, maxPointsError, minPointsError, pointsRangeError]);

  useEffect(() => {
    formCB && formCB(formData);
    validCB && validCB(isFormValid);
  }, [formCB, formData, isFormValid, validCB]);

  return (
    <Stack width="100%" gap={5} paddingRight={0.8}>
      <Stack gap={1.5}>
        <Text superHeavy uppercase>
          {t(
            'CustomerProfile.dataTable.dialog.filterPoints.selectActivityLabel',
          )}
        </Text>
        <Select
          placeholder={t(
            'CustomerProfile.dataTable.dialog.filterPoints.activityPlaceholder',
          )}
          value={formData.activity}
          options={activities}
          onChange={e => handleChangeField(e as any, 'activity')}
        />
      </Stack>
      <Stack gap={1.5}>
        <Text superHeavy uppercase>
          {t('CustomerProfile.dataTable.dialog.filterPoints.datesTitle')}
        </Text>
        <Stack flexDirection="row" gap={1.5}>
          <InputWrapper
            label={t('CustomerProfile.dataTable.dialog.filterPoints.from')}>
            <MobileDatePicker
              value={formData?.dateFrom}
              onAccept={(e: Date | null) => handleDate(e, 'dateFrom')}
              format="MM/DD/YYYY"
              disableFuture
              minDate={minDate as unknown as Date}
              maxDate={
                (formData.dateTo
                  ? dayjs(formData.dateTo)
                  : dayjs()) as unknown as Date
              }
              slotProps={{
                actionBar: {
                  actions: ['cancel', 'accept', 'clear'],
                },
                toolbar: {hidden: true},
              }}
            />
          </InputWrapper>
          <InputWrapper
            label={t('CustomerProfile.dataTable.dialog.filterPoints.to')}>
            <MobileDatePicker
              value={formData?.dateTo}
              onAccept={(e: Date | null) => handleDate(e, 'dateTo')}
              format="MM/DD/YYYY"
              disableFuture
              minDate={
                (formData?.dateFrom
                  ? dayjs(formData.dateFrom)
                  : minDate) as unknown as Date
              }
              maxDate={dayjs() as unknown as Date}
              slotProps={{
                actionBar: {
                  actions: ['cancel', 'accept', 'clear'],
                },
                toolbar: {hidden: true},
              }}
            />
          </InputWrapper>
        </Stack>
      </Stack>
      <Stack gap={1.5}>
        <Text superHeavy uppercase>
          {t('CustomerProfile.dataTable.dialog.filterPoints.pointsTitle')}
        </Text>
        <Stack direction="row" spacing={1.5}>
          <InputWrapper
            label={t(
              'CustomerProfile.dataTable.dialog.filterPoints.pointsMin.label',
            )}>
            <TextField
              sx={numberInput}
              error={minPointsError || pointsRangeError}
              inputProps={{
                name: 'minPoints',
              }}
              placeholder={t(
                'CustomerProfile.dataTable.dialog.filterPoints.pointsMin.placeholder',
              )}
              value={formData.pointsMin}
              onChange={e => handleChangeField(e as any, 'pointsMin')}
              onKeyDown={handleInputCharacters}
            />
          </InputWrapper>
          <InputWrapper
            label={t(
              'CustomerProfile.dataTable.dialog.filterPoints.pointsMax.label',
            )}>
            <TextField
              sx={numberInput}
              error={maxPointsError || pointsRangeError}
              inputProps={{name: 'maxPoints'}}
              placeholder={t(
                'CustomerProfile.dataTable.dialog.filterPoints.pointsMax.placeholder',
              )}
              value={formData.pointsMax}
              onChange={e => handleChangeField(e as any, 'pointsMax')}
              onKeyDown={handleInputCharacters}
            />
          </InputWrapper>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FilterPointsForm;
