import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyle = () => {
  const theme = useTheme();

  const header: SxProps<Theme> = useMemo(
    () => ({
      gap: theme.spacing(1.6),
      marginTop: theme.spacing(2.4),

      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(2.4),
      },
    }),
    [theme],
  );

  const divider: SxProps<Theme> = useMemo(
    () => ({
      marginY: theme.spacing(2.4),
      [theme.breakpoints.up('md')]: {
        marginY: theme.spacing(3.2),
      },
      borderColor: theme.palette.primary[200],
      borderWidth: '0',
      borderBottomWidth: '0.5px',
    }),
    [theme],
  );

  const cardsWrapper: SxProps<Theme> = useMemo(
    () => ({
      paddingY: theme.spacing(2.4),
      rowGap: theme.spacing(2.4),
      [theme.breakpoints.up('md')]: {
        paddingY: theme.spacing(3.2),
        rowGap: theme.spacing(3.2),
      },
    }),
    [theme],
  );

  const row: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }),
    [],
  );

  const headingBox: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginLeft: theme.spacing(2.4),
    }),
    [theme],
  );

  const label: SxProps<Theme> = useMemo(
    () => ({
      color: Colors.GreyDark,
    }),
    [],
  );

  const filterContainer: SxProps<Theme> = useMemo(
    () => ({
      display: 'grid',
      gap: theme.spacing(1.6),
      gridTemplateColumns: 'max-content max-content',
      '.MuiLink-root': {
        alignSelf: 'center !important',
        gap: theme.spacing(0.4),
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr max-content',
        '.MuiLink-root': {
          alignSelf: 'end !important',
          marginBottom: theme.spacing(0.8),
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      header,
      divider,
      cardsWrapper,
      row,
      label,
      headingBox,
      filterContainer,
    }),
    [header, divider, cardsWrapper, row, label, headingBox, filterContainer],
  );
};
