import {
  LoyaltyFiltersAction,
  LoyaltyFiltersActions,
} from '@/store/loyaltyFilters/interfaces.d';
import {MissionsListFilter} from '@/store/loyaltyFilters/missionsList.reducer';
import {PointsHistoryFilter} from '@/store/loyaltyFilters/pointsHistory.reducer';
import {RewardsListFilter} from '@/store/loyaltyFilters/rewardsList.reducer';

export const PointsHistorySetFilters = (
  payload: Partial<PointsHistoryFilter>,
): LoyaltyFiltersAction<PointsHistoryFilter> => ({
  type: LoyaltyFiltersActions.setPointsHistoryFilters,
  payload,
});

export const PointsHistoryReset =
  (): LoyaltyFiltersAction<PointsHistoryFilter> => ({
    type: LoyaltyFiltersActions.resetPointsHistoryFilters,
    payload: undefined,
  });

export const RewardsListSetFilters = (
  payload: Partial<RewardsListFilter>,
): LoyaltyFiltersAction<RewardsListFilter> => ({
  type: LoyaltyFiltersActions.setRewardsListFilters,
  payload,
});

export const RewardsListReset =
  (): LoyaltyFiltersAction<RewardsListFilter> => ({
    type: LoyaltyFiltersActions.resetRewardsListFilters,
    payload: undefined,
  });

export const MissionsListSetFilters = (
  payload: Partial<MissionsListFilter>,
): LoyaltyFiltersAction<MissionsListFilter> => ({
  type: LoyaltyFiltersActions.setMissionsListFilters,
  payload,
});

export const MissionsListReset =
  (): LoyaltyFiltersAction<MissionsListFilter> => ({
    type: LoyaltyFiltersActions.resetMissionsListFilters,
    payload: undefined,
  });
