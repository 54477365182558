import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const loyaltyList = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      gap: theme.spacing(0.8),
      width: '100%',
      '.boxList': {
        flexDirection: 'column',
        gap: theme.spacing(1.6),
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '.progressContainer': {
          position: 'relative',
          display: 'inline-flex',
          width: theme.spacing(11),
          height: theme.spacing(11),
          '.absolute': {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
          },
        },
      },
    }),
    [theme],
  );

  return useMemo(() => ({loyaltyList}), [loyaltyList]);
};
