import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {downloadFile} from '@/lib/utils';

import {useAPI} from '../api';
import {APIS, useApiPath} from '../api/useApiPath';
import {
  ExportRewardsTableBody,
  GetRewardSelectionsParams,
  GetRewardSelectionsResponse,
  RewardBookBody,
  RewardBookResponse,
  RewardsTableBody,
  RewardsTableResponse,
} from './interfaces';

/**
 * @brief Hook to retrieve benefits
 */
export const useBenefits = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  /**
   * @brief Retrieve all the benefits of a specific card-number
   */
  const getBenefitList = useCallback(
    async (body: RewardsTableBody) => {
      try {
        const {data} = await api.post<RewardsTableResponse>(
          getApiPath(APIS.LOYALTY_BENEFITS_HISTORY),
          {...body},
        );

        return data;
      } catch (error) {
        console.error('Benefit list', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  /**
   * @brief Export points history
   */
  const exportRewards = useCallback(
    async ({...body}: ExportRewardsTableBody) => {
      try {
        const {data, headers} = await api.post(
          getApiPath(APIS.LOYALTY_EXPORT_REWARDS),
          {
            ...body,
          },
          {
            headers: {
              Accept:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
          },
        );

        downloadFile(data, headers);

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getRewards = useCallback(
    async ({...params}: GetRewardSelectionsParams) => {
      try {
        const {data} = await api.get<GetRewardSelectionsResponse[]>(
          getApiPath(APIS.LOYALTY_GET_REWARDS),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getStatus = useCallback(
    async ({...params}: GetRewardSelectionsParams) => {
      try {
        const {data} = await api.get<GetRewardSelectionsResponse[]>(
          getApiPath(APIS.LOYALTY_GET_REWARDS_STATUS),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  /**
   * Retrieve book after has been claimed
   */
  const postBookBookingBarcode = useCallback(
    async ({...params}: RewardBookBody) => {
      try {
        const {data} = await api.post<RewardBookResponse>(
          getApiPath(APIS.LOYALTY_BOOK_BOOKING_BARCODE),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  /**
   * Mark book as claimed
   */
  const putBookBookingClick = useCallback(
    async ({...params}: RewardBookBody) => {
      try {
        const {data} = await api.put<RewardBookResponse>(
          getApiPath(APIS.LOYALTY_BOOK_BOOKING_CLICK),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  /**
   * Retrieve book for the first time
   */
  const putBookBooking = useCallback(
    async ({confirmOpening = false, ...params}: RewardBookBody) => {
      try {
        const {data} = await api.put<RewardBookResponse>(
          getApiPath(APIS.LOYALTY_BOOK_BOOKING),
          {
            ...params,
          },
        );

        confirmOpening && (await putBookBookingClick(params));

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath, putBookBookingClick],
  );

  /**
   * @brief Send email benefit
   */
  const postBenefitsSendEmail = useCallback(
    async ({
      confirmOpening = false,
      ...params
    }: Omit<RewardBookBody, 'promocodeRetail'>) => {
      try {
        await api.post(getApiPath(APIS.LOYALTY_BENEFITS_SENDEMAIL), {
          ...params,
        });

        return;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getBenefitList,
      exportRewards,
      getRewards,
      getStatus,
      putBookBooking,
      postBookBookingBarcode,
      postBenefitsSendEmail,
    }),
    [
      getBenefitList,
      exportRewards,
      getRewards,
      getStatus,
      putBookBooking,
      postBookBookingBarcode,
      postBenefitsSendEmail,
    ],
  );
};
