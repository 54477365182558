import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const wrapper = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: theme.palette.common.white,
      paddingTop: theme.spacing(2.4),
      paddingBottom: theme.spacing(1.2),
    }),
    [theme],
  );

  const primaryTabContainer = useMemo<SxProps<Theme>>(
    () => ({
      width: '100%',
      gap: theme.spacing(2.4),
      borderBottom: theme.spacing(0.05),
      borderColor: theme.palette.primary[200],
      borderStyle: 'solid',
    }),
    [theme],
  );

  return useMemo(
    () => ({wrapper, primaryTabContainer}),
    [wrapper, primaryTabContainer],
  );
};
