import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const formControl: SxProps<Theme> = useMemo(
    () => ({
      alignItems: 'start',
      '.MuiCheckbox-root': {
        padding: theme.spacing(0, 0.9, 0, 0),
      },
    }),
    [theme],
  );

  const container: SxProps<Theme> = useMemo(
    () => ({
      backgroundColor: theme.palette.grey[500],
      padding: theme.spacing(3),
    }),
    [theme],
  );

  const warning: SxProps<Theme> = useMemo(
    () => ({
      backgroundColor: theme.palette.warning.yellow,
      padding: theme.spacing(1),
      display: 'flex',
      gap: theme.spacing(1),
    }),
    [theme],
  );

  const button: SxProps<Theme> = useMemo(
    () => ({
      width: '150px',
      textTransform: 'uppercase',
    }),
    [],
  );

  return {formControl, container, warning, button};
};
