import {useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '../api';
import {APIS, useApiPath} from '../api/useApiPath';
import {RecoveryEmailBody, RecoveryEmailResponse, UnsubscribeProfileBody} from './interfaces';

export const useCustomerProfile = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const recoveryEmail = useCallback(
    async ({...body}: RecoveryEmailBody) => {
      try {
        const {data} = await api.post<RecoveryEmailResponse>(
          getApiPath(APIS.LOYALTY_EMAIL),
          {
            ...body,
          },
        );

        return data;

      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const unsubscribeProfile = useCallback(
    async ({...body}: UnsubscribeProfileBody) => {
      try {
        const {data} = await api.post(
          getApiPath(APIS.LOYALTY_UNSUSCRIBE),
          {
            ...body,
          },
        );

        return data;

      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const restoreProfile = useCallback(
    async ({...body}: UnsubscribeProfileBody) => {
      try {
        const {data} = await api.post(
          getApiPath(APIS.LOYALTY_RESTORE),
          {
            ...body,
          },
        );

        return data;

      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return {recoveryEmail, unsubscribeProfile, restoreProfile};
};
