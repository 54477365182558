import React, {useCallback, useEffect, useState} from 'react';

import {Box, Button, Menu} from '@mui/material';

import useScreenSize from '@/hooks/useScreenSize/useScreenSize';

import {useAuthentication} from '../../../store/authentication';
import {Avatar} from '../Avatar';
import {AvatarDropdown} from '../AvatarDropdown';
import {IconSVG} from '../IconSVG';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const UserAvatar = ({user}: IProps) => {
  const styles = useStyle();
  const {logout, namToken} = useAuthentication();
  const {width, height} = useScreenSize();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    setAnchorEl(undefined);
  }, [width, height]);

  const toggleMenu = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event?.currentTarget);
    },
    [],
  );

  const logOut = useCallback(() => {
    logout(namToken);
    toggleMenu();
  }, [logout, namToken, toggleMenu]);

  return (
    <>
      <Button onClick={toggleMenu}>
        <Box sx={styles.container}>
          <Avatar name={user?.firstName} surname={user?.lastName} />
          <IconSVG icon="chevron_down" size={15} color="white" />
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={() => toggleMenu()}
        sx={styles.menu}>
        <Box sx={styles.dropdownItem}>
          <AvatarDropdown user={user} onLogout={logOut} />
        </Box>
      </Menu>
    </>
  );
};

export default React.memo(UserAvatar);
