import {useTheme, useMediaQuery, Breakpoint} from '@mui/material';
import {AxiosResponse} from 'axios';
import dayjs, {Dayjs} from 'dayjs';
import Cookies from 'universal-cookie';

import {Role, Modules} from '@/definitions/users';
import {roleToRoute} from '@/navigation/routes';
import {StoreProps} from '@/organisms/TransactionCard/interfaces';
import {LuxStore} from '@/store/stores';
import {
  SingleTaskAssignee,
  SingleTaskPersonalInformation,
} from '@/store/tasks/types';

export function dec2hex(dec: number) {
  return ('0' + dec.toString(16)).substr(-2);
}

export function generateRandomString(stringLength: number) {
  const array = new Uint32Array(stringLength / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

export function createSearchParams(params: {[key: string]: any}) {
  const parameters = Object.keys(params).reduce((acc, currentKey) => {
    const value = params[currentKey];
    if (value) {
      acc = {
        ...acc,
        [currentKey]: value,
      };
    }

    return acc;
  }, {});

  return new URLSearchParams(parameters).toString();
}

const checkFields = (addressObj: {[key: string]: any}) => {
  return Object.keys(addressObj).some(
    key => !!addressObj[key] && addressObj[key] !== '',
  );
};

export function composeAddress(
  addressObj: Partial<{
    address: string;
    state: string;
    zipCode: string;
    city: string;
  }>,
) {
  const check = checkFields(addressObj);
  if (!check) {
    return '-';
  }
  const {address, state, zipCode, city} = addressObj;
  return `${address ? `${address}, ` : ''}${zipCode ? `${zipCode}, ` : ''}${
    city ?? ''
  }${state ? ` (${state})` : ''}`;
}

export function composeStoreAddress(addressObj: Partial<StoreProps>) {
  const check = checkFields(addressObj);
  if (!check) {
    return '-';
  }
  const {address, city, phoneNumber, postalCode, stateProvince} = addressObj;
  return `${address ? `${address}, ` : ''}${city ?? ''}${
    stateProvince ? ` (${stateProvince}), ` : ''
  }${postalCode ? `${postalCode}` : ''}${
    phoneNumber ? ` - ${phoneNumber}` : ''
  }`;
}

export function formatToAssignee(user: {
  id: string;
  luxId: string;
  firstName: string;
  lastName: string;
}): SingleTaskAssignee {
  return {
    id: user?.id,
    luxId: user?.luxId,
    firstName: user?.firstName,
    lastName: user?.lastName,
  };
}

export function getRouteBasedOnRole(userRole: Role, firstModule: Modules) {
  const routeByRole = roleToRoute[userRole?.name];
  return routeByRole[firstModule];
}

export const calculateCampaignExpirationDays = (endDate: string): number => {
  const today = dayjs();
  const campaignExpiration = dayjs(endDate);
  const differenceInTime = campaignExpiration.diff(today, 'd');

  return differenceInTime;
};

export enum RESOLUTION {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export const useResolution = (desktopBreakpoint: Breakpoint = 'md') => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(desktopBreakpoint));

  return isDesktop ? RESOLUTION.DESKTOP : RESOLUTION.MOBILE;
};

export const downloadFile = (
  data: AxiosResponse['data'],
  headers: AxiosResponse['headers'],
) => {
  /* TODO: Refactor */
  const regex = new RegExp('(\\.\\D+)', 'g');
  const filename = headers['content-disposition']
    ? headers['content-disposition'].split('filename=')[1].replace(regex, '')
    : Date.now().toString();
  const url = window.URL.createObjectURL(
    new Blob([data], {type: headers['content-type'].split(';')[0]}),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const cookie = new Cookies();

export const tabLiteBasicConfiguration = (
  currentStore: Omit<LuxStore, 'configuration'>,
  personalInformation: SingleTaskPersonalInformation,
  locale: string,
): {[key: string]: any} => ({
  selector: '#tabLite',
  linkPrivacyPolicy: '',
  brand: currentStore?.banner,
  storeNumber: currentStore?.storeId,
  lang: locale?.toLowerCase(),
  country: currentStore?.country,
  customerProfileInfo: {
    // firstName: personalInformation?.firstName,
    // lastName: personalInformation?.lastName,
    // birthDate: personalInformation?.birthDate
    //   ? dayjs(personalInformation?.birthDate).format('MM/DD/YYYY')
    //   : null,
    // email: personalInformation?.email,
    // phone: personalInformation?.mobile,
    // prefix: '',
    // address: {
    //   address: personalInformation?.address?.address,
    //   city: personalInformation?.address?.city,
    //   country: '',
    //   postalCode: personalInformation?.address?.zipCode,
    //   state: personalInformation?.address?.state,
    // },
    emailMarketing: true,
  },
  birthdateConfig: {
    required: true,
    visible: true,
  },
  addressConfig: {
    required: false,
    visible: true,
  },
  amPmEnable: false,
  notesConfig: {
    required: false,
    visible: false,
  },
  phoneNumberConfig: {
    required: false,
    visible: true,
  },
  subscriptionKey: '1aa1d06f152245cb85e17621e68df861',
  showAppTypePage: true,
  additionalOptIn: [
    {
      type: 'EMAIL',
      label: 'EMAIL',
      isHidden: true,
      defaultValue: false,
      accepted: false,
    },
    {
      type: 'PROFILING',
      label: 'PROFILE',
      isHidden: true,
      defaultValue: false,
      accepted: false,
    },
    {
      type: 'EMAIL_MARKETING',
      label: 'EMAIL_MARK',
      isHidden: true,
      defaultValue: false,
    },
  ],
});

export const numberFormatter = {
  format: (value: number, opts: Intl.NumberFormatOptions = {}) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      ...opts,
    }).format(value);
  },
};

export const DigitRegex = new RegExp(/^\d+$/);
export const EmailRegex = new RegExp(
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
);

export const testEmail = (val: string) => {
  return EmailRegex.test(val);
};

export const testDigits = (val: string) => {
  return DigitRegex.test(val);
};

export const truncate = (str: string, num: number = 24) => {
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};

export function capitalizeWords(str: string) {
  if (!str) {
    return '-';
  }

  return str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export type Nullable<T> = T | null;

export const setHourMinutesSeconds = (
  date: Dayjs,
  hour: number = 0,
  minute: number = 0,
  second: number = 0,
) => {
  return date.set('hour', hour).set('minute', minute).set('second', second);
};
export function createNumberArray(
  startNumber: number,
  endNumber: number,
): number[] {
  if (startNumber > endNumber) {
    throw new Error('startNumber must be less than or equal to endNumber');
  }

  const result: number[] = [];
  for (let i = startNumber; i <= endNumber; i++) {
    result.push(i);
  }
  return result;
}

export function setHourToDayjs(
  previousUtcDayjs: Dayjs,
  selectedHour: number,
  isPm?: boolean,
): Dayjs {
  const isItalianLocale = dayjs.locale() === 'it';
  let hour = selectedHour;

  // If the locale is not Italian, assume AM/PM format and adjust accordingly
  if (!isItalianLocale) {
    if (isPm && selectedHour < 12) {
      hour += 12;
    } else if (!isPm && selectedHour === 12) {
      hour = 0;
    } else if (!isPm && selectedHour > 12) {
      hour -= 12;
    }
  }

  // Set the new hour
  return previousUtcDayjs.hour(hour);
}
