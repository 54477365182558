import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Skeleton} from '@mui/material';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {DataTable} from '@/atoms/DataTable';
import {BaseRouterParams} from '@/definitions/base';
import {useToast} from '@/hooks/toast';
import {FilterDialog} from '@/molecules/FilterDialog';
import {
  PointsHistorySetFilters,
  PointsHistoryReset,
} from '@/store/loyaltyFilters/actions';
import {PointsHistoryFilter} from '@/store/loyaltyFilters/pointsHistory.reducer';
import {selectorPointsHistory} from '@/store/loyaltyFilters/selectors';
import {usePoints} from '@/store/points';

import {FilterPointsForm} from './FilterPointsForm';
import {useColumnDef} from './columnDef';
import {IPointsHistory} from './interfaces';

const MIN_POINTS_FILTER = -10000;
const MAX_POINTS_FILTER = 10000;

const PointsHistoryTable = ({
  enabledApis,
  updateFlag,
  canExport,
}: IPointsHistory) => {
  const {t} = useTranslation();
  const {showSuccessToast, showErrorToast} = useToast();

  const pointsHistoryFilters = useSelector<
    {pointsHistoryFilters: PointsHistoryFilter},
    PointsHistoryFilter
  >(selectorPointsHistory);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState<Partial<PointsHistoryFilter>>();
  const [formValid, setFormValid] = useState<boolean>(false);

  const {pointHistoryColumnDef} = useColumnDef();

  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);

  const {getPointsHistoryData, exportPointsHistoryData} = usePoints();

  const {cardNumber, banner, country} = useParams<BaseRouterParams>();

  const [exportEnabled, setExportEnabled] = useState(false);

  const toggleDialog = useCallback((value?: boolean) => {
    setOpenFilterDialog(prevState => {
      return typeof value !== 'undefined' ? value : !prevState;
    });
  }, []);

  const apiPayload = useMemo(() => {
    return {
      ...(pointsHistoryFilters.activity !== '' && {
        eventId: pointsHistoryFilters.activity,
      }),
      ...(pointsHistoryFilters.dateFrom && {
        startDate: dayjs(pointsHistoryFilters.dateFrom).format('YYYY-MM-DD'),
      }),
      ...(pointsHistoryFilters.dateTo && {
        endDate: dayjs(pointsHistoryFilters.dateTo).format('YYYY-MM-DD'),
      }),
      ...(pointsHistoryFilters.pointsMin !== '' && {
        minPoints: Number(pointsHistoryFilters.pointsMin),
      }),
      ...(pointsHistoryFilters.pointsMax !== '' && {
        maxPoints: Number(pointsHistoryFilters.pointsMax),
      }),
    };
  }, [
    pointsHistoryFilters.activity,
    pointsHistoryFilters.dateFrom,
    pointsHistoryFilters.dateTo,
    pointsHistoryFilters.pointsMax,
    pointsHistoryFilters.pointsMin,
  ]);

  useQuery(
    ['pointsExport', cardNumber, banner, country, apiPayload],
    () =>
      exportPointsHistoryData({
        cardNumberSearch: cardNumber,
        bannerSearch: banner,
        countrySearch: country,
        ...apiPayload,
      }),
    {
      enabled: exportEnabled,
      retry: false,
      onSuccess: data => {
        showSuccessToast(t('CustomerProfile.dataTable.toast.export.success'));
      },
      onError: () => {
        showErrorToast(t('CustomerProfile.dataTable.toast.export.error'));
      },
      onSettled: () => {
        setExportEnabled(false);
      },
    },
  );

  const handleExport = useCallback(() => {
    setExportEnabled(true);
  }, []);

  const {
    data: pointsHistory,
    fetchNextPage,
    isLoading,
    hasNextPage,
  } = useInfiniteQuery(
    ['pointsHistory', cardNumber, banner, country, apiPayload, updateFlag],
    ({pageParam = 1}) =>
      getPointsHistoryData({
        cardNumberSearch: cardNumber,
        bannerSearch: banner,
        countrySearch: country,
        ...apiPayload,
        page: pageParam,
        limit: 10,
      }),
    {
      enabled: enabledApis,
      getNextPageParam: ({metadataDTO: {page, total_pages}}) =>
        page >= total_pages ? undefined : page + 1,
    },
  );

  const tableData = useMemo(
    () => pointsHistory?.pages.flatMap(({data}) => data),
    [pointsHistory],
  );

  const rows = useMemo(
    () =>
      tableData?.map(item => {
        return {
          ...item,
        };
      }) || [],
    [tableData],
  );

  const applyFilters = useCallback(() => {
    dispatch(PointsHistorySetFilters(formData!));
    toggleDialog(false);
  }, [dispatch, formData, toggleDialog]);

  const handleTableReset = useCallback(() => {
    dispatch(PointsHistoryReset());
  }, [dispatch]);

  const filterNumber = useMemo(() => {
    let filterCopy = {...pointsHistoryFilters};
    // @ts-ignore
    delete filterCopy.pointsMin;
    delete filterCopy.dateTo;

    return Object.values(filterCopy).filter(
      val => val !== '' && typeof val !== 'undefined',
    ).length;
  }, [pointsHistoryFilters]);

  const canResetFilters = useMemo(() => {
    return Boolean(
      Object.values(pointsHistoryFilters).find(
        val => val !== '' && typeof val !== 'undefined',
      ),
    );
  }, [pointsHistoryFilters]);

  return (
    <>
      {isLoading || !rows ? (
        <Skeleton
          height={400}
          width="100%"
          animation="wave"
          variant="rounded"
        />
      ) : (
        <DataTable
          columnDef={pointHistoryColumnDef}
          data={rows ?? []}
          maxHeight="450px"
          handleExport={handleExport}
          handleFilter={() => toggleDialog(true)}
          handleFiltersReset={handleTableReset}
          fetchNextPage={fetchNextPage}
          isLoadingMore={hasNextPage}
          canExport={canExport}
          canResetFilters={canResetFilters}
          filterNumber={filterNumber}
        />
      )}
      {openFilterDialog && (
        <FilterDialog
          open={openFilterDialog}
          onClose={() => toggleDialog(false)}
          onApply={applyFilters}
          dialogContent={
            <FilterPointsForm
              filters={pointsHistoryFilters}
              minPointsRange={MIN_POINTS_FILTER}
              maxPointsRange={MAX_POINTS_FILTER}
              formCB={formState => setFormData(formState)}
              validCB={isValidForm => setFormValid(isValidForm)}
            />
          }
          confirmDisabled={!formValid}
        />
      )}
    </>
  );
};

export default React.memo(PointsHistoryTable);
