import {
  LoyaltyFiltersAction,
  LoyaltyFiltersActions,
} from '@/store/loyaltyFilters/interfaces.d';

export interface MissionsListFilter {
  mission?: string;
  dateFrom?: Date;
  dateTo?: Date;
  status?: string;
}

export const getInitialState = (): MissionsListFilter => ({
  mission: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  status: undefined,
});

export const missionsListReducer = (
  state = getInitialState(),
  action: LoyaltyFiltersAction<MissionsListFilter>,
) => {
  switch (action.type) {
    case LoyaltyFiltersActions.setMissionsListFilters:
      return {
        ...state,
        ...action.payload,
      };
    case LoyaltyFiltersActions.resetMissionsListFilters:
      return getInitialState();
    default:
      return state;
  }
};
