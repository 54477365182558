import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container = useMemo(
    () => ({
      height: '100%',
      width: '100%',
      borderRadius: '100%',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(0.2),
    }),
    [theme],
  );

  const valueText = useMemo<SxProps<Theme>>(
    () => ({
      fontSize: theme.typography.headline5,
      lineHeight: theme.typography.headline5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
    }),
    [theme],
  );

  const unitText = useMemo(
    () => ({
      fontSize: 8,
      lineHeight: theme.spacing(0.8),
      color: theme.palette.common.white,
    }),
    [theme],
  );

  return {container, valueText, unitText};
};
