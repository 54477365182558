import {Dayjs} from 'dayjs';

export enum ReportDetailsFiltersActions {
  changeField = 'change report detail field',
  changeDates = 'change report detail dates',
  changePage = 'change report detail page',
  resetFilters = 'reset report detail filters',
  initializeFilters = 'initialize report detail filters',
}

interface ChangeFieldPayload<T> {
  key: keyof T;
  value: any;
}

export interface ReportDetailFiltersReducer {
  [key: string]: ReportDetailFilters;
}

export interface ReportDetailFilters {
  assignees: Array<string>;
  startDate: string;
  endDate: string;
  sortBy: string | null;
  orderBy: 'ASC' | 'DESC';
  page: number;
}

export type ReportDetailsFiltersAction =
  | {
      type: ReportDetailsFiltersActions.changeField;
      payload: {
        campaignId: string;
        data: ChangeFieldPayload<ReportDetailFilters>;
      };
    }
  | {
      type: ReportDetailsFiltersActions.changeDates;
      payload: {
        campaignId: string;
        data: {
          startDate: Dayjs;
          endDate: Dayjs;
        };
      };
    }
  | {
      type: ReportDetailsFiltersActions.changePage;
      payload: {
        campaignId: string;
        data: number;
      };
    }
  | {
      type: ReportDetailsFiltersActions.initializeFilters;
      payload: {
        campaignId: string;
      };
    }
  | {
      type: ReportDetailsFiltersActions.resetFilters;
      payload: undefined;
    };
