import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container: SxProps<Theme> = useMemo(
    () => ({
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      paddingX: theme.spacing(4),
      marginBottom: theme.spacing(-0.7),
    }),
    [theme],
  );

  const row: SxProps<Theme> = useMemo(
    () => ({
      flexDirection: 'row',
      backgroundColor: theme.palette.grey[500],
      justifyContent: 'space-between',
      width: '100%',
      paddingX: theme.spacing(4),
      paddingY: theme.spacing(2),
    }),
    [theme],
  );

  return {container, row};
};
