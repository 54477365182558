import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = (isTaskEditable?: boolean) => {
  const theme = useTheme();

  const assigneeValue = useMemo<SxProps<Theme>>(() => {
    return {
      color: theme.palette.common.black,
      fontStyle: 'normal',
      textTransform: 'capitalize',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  }, [theme]);

  const assigneeActionContainer: SxProps<Theme> = useMemo(
    () => ({
      alignItems: 'center',
      ...(isTaskEditable
        ? {
            cursor: 'pointer',
            pointerEvents: 'auto',
            opacity: 1,
            transition: 'all 0.2s linear',
            ':hover': {
              opacity: 0.7,
            },
          }
        : {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.7,
          }),
    }),
    [isTaskEditable],
  );

  return useMemo(
    () => ({
      assigneeValue,
      assigneeActionContainer,
    }),
    [assigneeValue, assigneeActionContainer],
  );
};
