import React from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Button} from '@mui/material';

import {useParamsState} from '@/hooks/useParamsState';

import {useNavigation} from '../../../navigation';
import {IconSVG} from '../../atoms/IconSVG';
import {useStyles} from './styles';

const BackToSearchButton = () => {
  const {t} = useTranslation();
  const {goBack} = useNavigation();
  const {wrapper, icon, button} = useStyles();
  const {paramsState} = useParamsState<{
    origin: 'search' | 'customer' | 'task';
  }>({origin: 'search'}, [], 'origin');

  return (
    <Box sx={wrapper} onClick={goBack}>
      <Box sx={icon}>
        <IconSVG icon="arrow_left" size={23} />
      </Box>
      <Button variant="tertiary" sx={button}>
        {t(`CustomerProfile.backLabel.${paramsState?.origin}`)}
      </Button>
    </Box>
  );
};

export default React.memo(BackToSearchButton);
