import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, MenuItem, Select as MUISelect, Stack} from '@mui/material';

import {numberFormatter} from '@/lib/utils';

import {IconSVG} from '../IconSVG';
import {Text} from '../Typography/Text';
import {CustomSelectProps} from './interfaces';
import {useStyle} from './styles';

const CustomerBalanceSelect = ({
  error,
  options,
  value,
  onChange,
  sx,
  placeholder,
  setDescription,
  setMinPoints,
  setMaxPoints,
  description,
  minPoints,
  maxPoints,
  onClick,
  disabled,
  reason,
}: CustomSelectProps) => {
  const {t} = useTranslation();
  const styles = useStyle(!!error);

  const IconComponent = useCallback(
    ({className}: {className: string}) => (
      <Box className={className} sx={styles.iconComponent}>
        <IconSVG icon="chevron_down" size={10} />
      </Box>
    ),
    [styles.iconComponent],
  );

  const renderValue = useCallback(
    (value: any) => {
      return (
        options?.find(option => option.reasonId === value)?.description ??
        placeholder
      );
    },
    [options, placeholder],
  );

  const menuProps = useMemo(
    () => ({
      sx: styles.menu,
    }),
    [styles.menu],
  );

  const setPointRange = useCallback(
    (description: string, minPoints: number, maxPoints: number) => {
      setDescription(description);
      setMinPoints(minPoints);
      setMaxPoints(maxPoints);
    },
    [setDescription, setMaxPoints, setMinPoints],
  );

  return (
    <Stack gap={0.5}>
      <MUISelect
        {...(onClick && {onClick: onClick})}
        disabled={disabled}
        sx={[styles.input, sx]}
        value={value}
        onChange={onChange}
        IconComponent={IconComponent}
        // Display placeholder when no value is selected
        displayEmpty
        renderValue={renderValue}
        MenuProps={menuProps}>
        {options?.map((item, i) => (
          <MenuItem
            key={item.reasonId + i}
            sx={styles.menuItem}
            onClick={() =>
              setPointRange(item.description, item.minPoints, item.maxPoints)
            }
            value={item.reasonId}>
            {item.description}
          </MenuItem>
        ))}
      </MUISelect>
      {reason && (
        <Text>
          {t('CustomerBalance.editBalanceMessage', {
            description: description,
            minPoints: numberFormatter.format(minPoints),
            maxPoints: numberFormatter.format(maxPoints),
            interpolation: {escapeValue: false},
          })}
        </Text>
      )}
    </Stack>
  );
};

export default React.memo(CustomerBalanceSelect);
