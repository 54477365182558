import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {useForm, SubmitHandler, Controller} from 'react-hook-form';
import {FaCaretDown} from 'react-icons/fa';

import {
  Box,
  TextField,
  Stack,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {HeadlineText} from '@/atoms/HeadlineText';
import {Modal} from '@/atoms/Modal';
import {TinyText} from '@/atoms/TinyText';
import {Gender} from '@/definitions/user';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {convertToLoyaltySubscriptionPayload} from '@/hooks/useLoyaltySubscribeModal/functions';
import {useStyles} from '@/hooks/useLoyaltySubscribeModal/styles';
import {EmailRegex} from '@/lib/utils';
import {LoyaltyBoxProps} from '@/organisms/LoyaltyBox/LoyaltyBox';
import {Colors} from '@/themes/variables';

const BASE_TRANSLATION = 'LoyaltyBox.subscribeModal';

interface UseLoyaltySubscribeModalProps
  extends Omit<LoyaltyBoxProps, 'response' | 'origin' | 'loading'> {}

export const useLoyaltySubscribeModal = (
  props: UseLoyaltySubscribeModalProps,
) => {
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = useCallback((value?: boolean) => {
    setIsOpen(prevState => value ?? !prevState);
  }, []);

  const dialogTitle = useMemo(() => {
    return (
      <HeadlineText heavy>{getTranslationWithValue(0, 'title')}</HeadlineText>
    );
  }, [getTranslationWithValue]);

  const loyaltyModal = useMemo(() => {
    return (
      <Modal
        open={isOpen}
        onClose={() => toggleModal(false)}
        maxWidth={40}
        dialogTitle={dialogTitle}
        dialogContent={
          <LoyaltySubscribeForm
            toggleModal={toggleModal}
            modalProps={props}
            defaultFields={{
              firstName: props?.customer?.firstName ?? '',
              lastName: props?.customer?.lastName ?? '',
              email: props?.customer?.email ?? '',
              dayOfBirth: props?.customer?.birthDate
                ? dayjs(props?.customer?.birthDate).get('date')
                : '',
              monthOfBirth: props?.customer?.birthDate
                ? dayjs(props?.customer?.birthDate).get('month')
                : '',
              yearOfBirth: props?.customer?.birthDate
                ? dayjs(props?.customer?.birthDate).get('year')
                : '',
            }}
          />
        }
      />
    );
  }, [dialogTitle, isOpen, props, toggleModal]);

  return useMemo(
    () => ({
      loyaltyModal,
      toggleModal,
    }),
    [loyaltyModal, toggleModal],
  );
};

export type LoyaltySubscribeFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  dayOfBirth?: number | string;
  monthOfBirth?: number | string;
  yearOfBirth?: number | string;
  gender?: Gender | string;
  communications: boolean;
};

interface LoyaltySubscribeFormProps {
  toggleModal: (value?: boolean) => void;
  defaultFields?: Partial<LoyaltySubscribeFormInputs>;
  modalProps: UseLoyaltySubscribeModalProps;
}

const LoyaltySubscribeForm = React.memo(
  ({toggleModal, defaultFields, modalProps}: LoyaltySubscribeFormProps) => {
    const {subscribeCB, bannerSearch, countrySearch} = modalProps;
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
    const styles = useStyles();
    const {
      register,
      handleSubmit,
      watch,
      getValues,
      setValue,
      control,
      formState: {errors, isValid},
    } = useForm<LoyaltySubscribeFormInputs>({
      defaultValues: {
        firstName: defaultFields?.firstName ?? '',
        lastName: defaultFields?.lastName ?? '',
        email: defaultFields?.email ?? '',
        dayOfBirth: defaultFields?.dayOfBirth ?? '',
        monthOfBirth: defaultFields?.monthOfBirth ?? '',
        yearOfBirth: defaultFields?.yearOfBirth ?? '',
        gender: defaultFields?.gender ?? '',
        communications:
          typeof defaultFields?.communications !== 'undefined'
            ? defaultFields?.communications
            : process.env.REACT_APP_LOYALTY_PROGRAM === 'true',
      },
    });

    const selectedMonth = watch('monthOfBirth');
    const selectedDay = watch('dayOfBirth');

    useEffect(() => {
      if (typeof selectedMonth === 'number') {
        const daysInMonth = dayjs().set('month', selectedMonth).daysInMonth();

        if (typeof selectedDay === 'number' && selectedDay > daysInMonth) {
          setValue('dayOfBirth', '');
        }
      } else if (selectedMonth === '') {
        setValue('dayOfBirth', '');
        setValue('yearOfBirth', '');
      }

      if (selectedDay === '') {
        setValue('yearOfBirth', '');
      }
    }, [selectedDay, selectedMonth, setValue]);

    const onSubmit: SubmitHandler<LoyaltySubscribeFormInputs> = data => {
      const payload = convertToLoyaltySubscriptionPayload(
        bannerSearch,
        countrySearch,
        data,
      );
      subscribeCB(payload);
      toggleModal(false);
    };

    const genderOptions = useMemo(
      () => [
        {
          label: getTranslationWithValue(0, 'genderOptions.unset'),
          value: '',
        },
        {
          label: getTranslationWithValue(0, 'genderOptions.male'),
          value: Gender.MALE,
        },
        {
          label: getTranslationWithValue(0, 'genderOptions.female'),
          value: Gender.FEMALE,
        },
        {
          label: getTranslationWithValue(0, 'genderOptions.other'),
          value: Gender.OTHER,
        },
      ],
      [getTranslationWithValue],
    );

    const monthOptions = useMemo(() => {
      return [
        {
          label: 'MM',
          value: '',
        },
        ...dayjs.monthsShort().map((month, index) => {
          return {
            label: month,
            value: index,
          };
        }, []),
      ];
    }, []);

    const dayOptions = useMemo(() => {
      const daysInMonth =
        typeof selectedMonth === 'number'
          ? dayjs().set('month', selectedMonth).daysInMonth()
          : 0;

      return [
        {
          label: dayjs.locale() === 'it' ? 'GG' : 'DD',
          value: '',
        },
        ...new Array(daysInMonth).fill(' ').map((_, index) => {
          return {
            label: `${index + 1}`,
            value: index + 1,
          };
        }),
      ];
    }, [selectedMonth]);

    const yearOptions = useMemo(() => {
      const currentYear = dayjs().year();

      return [
        {
          label: dayjs.locale() === 'it' ? 'AAAA' : 'YYYY',
          value: '',
        },
        ...new Array(100).fill(' ').map((_, index) => {
          return {
            label: `${currentYear - index}`,
            value: currentYear - index,
          };
        }),
      ];
    }, []);

    return (
      <>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          {...(isValid && {
            onSubmit: handleSubmit(onSubmit),
          })}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: '100%',
            marginTop: '24px',
            maxWidth: '320px',
          }}>
          <TinyText color={Colors.GreyText} alignSelf="end">
            {getTranslationWithValue(0, 'requiredFields')}
          </TinyText>
          <TextField
            fullWidth
            variant="outlined"
            required
            placeholder={getTranslationWithValue(0, 'name')}
            label={getTranslationWithValue(0, 'name')}
            type="text"
            {...register('firstName', {
              required: true,
              pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ ]*$/,
            })}
            error={!!errors?.firstName}
          />
          <TextField
            fullWidth
            variant="outlined"
            required
            placeholder={getTranslationWithValue(0, 'surname')}
            label={getTranslationWithValue(0, 'surname')}
            type="text"
            {...register('lastName', {
              required: true,
              pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ ]*$/,
            })}
            error={!!errors?.lastName}
          />
          <TextField
            fullWidth
            variant="outlined"
            required
            placeholder={getTranslationWithValue(0, 'email')}
            label={getTranslationWithValue(0, 'email')}
            type="email"
            {...register('email', {
              required: true,
              pattern: EmailRegex,
            })}
            error={!!errors?.email}
          />
          <Stack
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            gap={0.8}
            alignItems="center">
            <Controller
              name="monthOfBirth"
              control={control}
              rules={{
                required: false,
                validate: value => {
                  if (value === '') {
                    return true;
                  }
                  return typeof value === 'number';
                },
              }}
              render={({field}) => (
                <Select
                  sx={{width: '100%'}}
                  variant="outlined"
                  displayEmpty
                  id="monthOfBirth"
                  MenuProps={{
                    sx: {
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflowY: 'auto',
                      },
                    },
                  }}
                  {...field}>
                  {monthOptions?.map(option => {
                    return (
                      <MenuItem key={option.label} value={option.value}>
                        <BodyText
                          italic={option.value === ''}
                          sx={{textTransform: 'capitalize'}}>
                          {option.label}
                        </BodyText>
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            <Controller
              name="dayOfBirth"
              control={control}
              rules={{
                required: typeof selectedMonth === 'number',
              }}
              disabled={typeof selectedMonth !== 'number'}
              render={({field}) => (
                <Select
                  sx={{width: '100%'}}
                  variant="outlined"
                  displayEmpty
                  id="dayOfBirth"
                  MenuProps={{
                    sx: {
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflowY: 'auto',
                      },
                    },
                  }}
                  {...field}>
                  {dayOptions?.map(option => {
                    return (
                      <MenuItem key={option.label} value={option.value}>
                        <BodyText italic={option.value === ''}>
                          {option.label}
                        </BodyText>
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            <Controller
              name="yearOfBirth"
              control={control}
              disabled={!selectedDay}
              rules={{
                required: false,
                deps: ['monthOfBirth', 'dayOfBirth'],
                validate: (value, formValues) => {
                  if (!value) {
                    return true;
                  }
                  return (
                    typeof formValues.monthOfBirth === 'number' &&
                    typeof formValues.dayOfBirth === 'number'
                  );
                },
              }}
              render={({field}) => (
                <Select
                  sx={{width: '100%'}}
                  variant="outlined"
                  displayEmpty
                  id="yearOfBirth"
                  MenuProps={{
                    sx: {
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflowY: 'auto',
                      },
                    },
                  }}
                  {...field}>
                  {yearOptions?.map(option => {
                    return (
                      <MenuItem key={option.label} value={option.value}>
                        <BodyText italic={option.value === ''}>
                          {option.label}
                        </BodyText>
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </Stack>
          <Controller
            name="gender"
            control={control}
            rules={{required: false}}
            render={renderField => (
              <Select
                {...register('gender')}
                id="gender"
                variant="outlined"
                displayEmpty
                renderValue={val => {
                  let displayedValue = getTranslationWithValue(
                    0,
                    'genderOptions.pickOne',
                  );
                  if (val) {
                    displayedValue = genderOptions?.find(
                      option => option.value === val,
                    )?.label;
                  }
                  return <BodyText>{displayedValue}</BodyText>;
                }}
                IconComponent={({className}: {className: string}) => {
                  return (
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      gap={0.8}
                      paddingRight={0.8}
                      sx={styles.customIconComponent}
                      className={className}>
                      <FaCaretDown fontSize={16} />
                      <TinyText>
                        {getTranslationWithValue(0, 'gender')}
                      </TinyText>
                    </Stack>
                  );
                }}
                {...renderField.field}>
                {genderOptions?.map(option => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      <BodyText italic={!option.value}>{option.label}</BodyText>
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
          <FormControlLabel
            sx={{
              alignItems: 'start',
              '.MuiCheckbox-root': {
                padding: '0 8px',
              },
            }}
            control={
              <Checkbox
                disableRipple={true}
                {...register('communications', {
                  required: false,
                })}
                checked={watch('communications')}
              />
            }
            label={
              <TinyText>
                {getTranslationWithValue(0, 'communications')}
              </TinyText>
            }
          />
          <Stack
            width="100%"
            gap={1.6}
            alignItems="center"
            direction="row"
            marginTop={2.4}>
            <Button
              variant="baseSecondary"
              fullWidth
              onClick={() => toggleModal(false)}>
              {getTranslationWithValue(0, 'ctas.cancel')}
            </Button>
            <Button
              variant="basePrimary"
              fullWidth
              type="submit"
              disabled={!isValid}>
              {getTranslationWithValue(0, 'ctas.subscribe')}
            </Button>
          </Stack>
        </Box>
      </>
    );
  },
);

LoyaltySubscribeForm.displayName = 'LoyaltySubscribeForm';
