import {useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {downloadFile} from '@/lib/utils';

import {useAPI} from '../api';
import {APIS, useApiPath} from '../api/useApiPath';
import {GetActivitiesResponse, GetPointsHistoryBody, GetPointsHistoryResponse} from './interfaces';

export const usePoints = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getPointsHistoryData = useCallback(
    async ({...body}: GetPointsHistoryBody) => {
      try {
        const {data} = await api.post<GetPointsHistoryResponse>(
          getApiPath(APIS.LOYALTY_POINTS_HISTORY),
          {
            ...body,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const exportPointsHistoryData = useCallback(
    async ({...body}: GetPointsHistoryBody) => {
      try {
        const {data, headers} = await api.post(
          getApiPath(APIS.LOYALTY_EXPORT_POINTS_HISTORY),
          {
            ...body,
          },
          {
            headers: {
              Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
          },
        );

        downloadFile(data, headers);

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getActivities = useCallback(
    async () => {
      try {
        const {data} = await api.get<GetActivitiesResponse[]>(
          getApiPath(APIS.LOYALTY_POINTS_HISTORY_ACTIVITIES)
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return {getPointsHistoryData, exportPointsHistoryData, getActivities};
};
