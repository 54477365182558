import {GetReportsHeaderResponse} from '@/store/reports/interfaces';

const headerKeys = [
  'taskNumber',
  'newTask',
  'contactedTask',
  'feedbackRating',
  'waitingForCallback',
  'noAnswer',
  'alreadyPurchased',
  'notContactable',
  'appointmentBooked',
  'appointmentCheckIn',
];

export const formatReportHeader = (
  data: GetReportsHeaderResponse,
): {title: string; value: string}[] => {
  return headerKeys.map(headerKey => {
    let percentage: number | undefined;
    if (`${headerKey}Perc` in data) {
      percentage =
        (data[
          `${headerKey}Perc` as keyof GetReportsHeaderResponse
        ] as number) || 0;
    }

    let value = `${data[headerKey as keyof GetReportsHeaderResponse] || 0}`;

    if (typeof percentage !== 'undefined') {
      value = `${value} (${Math.round(percentage)}%)`;
    }

    return {
      title: headerKey,
      value: value,
    };
  });
};
