import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      position: 'relative',
      paddingBottom: theme.spacing(2.4),
    }),
    [theme],
  );

  const campaignExpiration = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridAutoFlow: 'column',
      gridAutoColumns: 'max-content',
      alignItems: 'center',
      textAlign: 'center',
      gap: theme.spacing(0.8),
      svg: {
        flexShrink: 0,
      },
      span: {
        lineHeight: theme.spacing(1.4),
      },
    }),
    [theme],
  );

  const pagination = useMemo(
    () => ({
      '.MuiButtonBase-root': {
        border: `1px solid ${theme.palette.primary[200]}`,
        color: theme.palette.grey[700],

        svg: {
          color: theme.palette.common.black,
        },

        ':hover': {
          backgroundColor: 'transparent',
        },
      },
      '.Mui-disabled': {
        opacity: 0.5,
        backgroundColor: theme.palette.primary[200],
      },
      '.Mui-selected': {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: theme.palette.common.black,
        border: `1px solid ${theme.palette.common.black}`,
      },
    }),
    [theme],
  );

  return {
    container,
    campaignExpiration,
    pagination,
  };
};
