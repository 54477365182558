import React from 'react';
import {useTranslation} from 'react-i18next';

import {DialogTitle, List, ListItem, Stack} from '@mui/material';

import {Dialog} from '@/atoms/Dialog';
import {CloseIconButton} from '@/atoms/DialogButtons';
import {Text} from '@/atoms/Typography/Text';

import {IProps} from './interfaces';
import {useStyle} from './styles';

const tiers = [
  {
    name: 'tierOne',
    code: 1,
    benefits: [
      'specialOne',
      'specialTwo',
      'specialThree',
      'avantOne',
      'avantTwo',
      'giftOne',
      'exclusiveOne',
      'exclusiveTwo',
    ],
  },
  {
    name: 'tierTwo',
    code: 2,
    benefits: [
      'specialOne',
      'specialTwo',
      'specialThree',
      'avantOne',
      'avantTwo',
      'tierWelcome',
      'giftThree',
      'exclusiveOne',
      'exclusiveThree',
    ],
  },
  {
    name: 'tierThree',
    code: 3,
    benefits: [
      'specialOne',
      'specialTwo',
      'specialThree',
      'avantOne',
      'avantTwo',
      'tierWelcome',
      'giftFive',
      'giftSix',
      'giftSeven',
      'exclusiveOne',
      'exclusiveThree',
    ],
  },
  {
    name: 'tierFour',
    code: 4,
    benefits: [
      'specialOne',
      'specialTwo',
      'specialThree',
      'avantOne',
      'avantTwo',
      'tierWelcome',
      'giftThree',
      'giftNine',
      'giftTen',
      'giftEleven',
      'exclusiveFour',
      'exclusiveFive',
      'giftSeven',
      'exclusiveSix',
    ],
  },
];

const TierBenefits = ({open, onClose}: IProps) => {
  const styles = useStyle();

  const {t} = useTranslation();

  return (
    <Dialog
      open={open}
      sx={styles.dialog}
      dialogTitle={t('CustomerBalance.tierBenefitsTitle')}
      dialogContent={
        <Stack gap={3} width="90%">
          {tiers.map(item => (
            <Stack key={item.code}>
              <DialogTitle sx={styles.paragraphTitle}>
                <Text variant="headline5" heavy uppercase>
                  {`tier ${item.code} - ${t(
                    `CustomerBalance.tierBenefitsVoices.${item.name}`,
                  )}`}
                </Text>
              </DialogTitle>
              <List disablePadding sx={styles.list}>
                {item.benefits.map((item: string) => (
                  <ListItem disablePadding key={item}>
                    {t(`CustomerBalance.tierBenefitsVoices.${item}`)}
                  </ListItem>
                ))}
              </List>
            </Stack>
          ))}
        </Stack>
      }
      dialogActions={<CloseIconButton onClose={onClose} />}
    />
  );
};

export default React.memo(TierBenefits);
