import React from 'react';
import {PiInfoLight} from 'react-icons/pi/index';

import {ClickAwayListener, IconButton, Tooltip} from '@mui/material';

import {CellProps} from '../interfaces';
import {useStyles} from './styles';

const TooltipCell = ({getValue, placement = 'top-end'}: CellProps) => {
  const value = getValue();
  const {tooltip} = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (value) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          componentsProps={{tooltip: {sx: tooltip}}}
          title={value}
          arrow
          placement={placement}
          enterTouchDelay={0}>
          <IconButton onClick={handleTooltipOpen}>
            <PiInfoLight fontSize={30} color="black" />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    );
  }

  return null;
};

export default React.memo(TooltipCell);
