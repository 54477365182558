import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const transactionCardContainer = useCallback(
    (isQuote: boolean): SxProps<Theme> => ({
      ...(!isQuote && {marginBottom: theme.spacing(2.4)}),
      '.transactionCard': {
        ...(isQuote && {boxShadow: 'none'}),
        padding: 0,
      },
    }),
    [theme],
  );

  const transactionCardHeaderContainer = useCallback(
    (isReturned: boolean, isQuote: boolean): SxProps<Theme> => ({
      ...(isQuote && {
        borderRadius: theme.spacing(1.2),
      }),
      backgroundColor: Colors.BackgroundDark,
      padding: theme.spacing(1.6),
      gap: theme.spacing(1.6),
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '1fr',
      '.headerInfo': {
        alignItems: 'center',
        gap: theme.spacing(4),
        ...(!isQuote
          ? {
              display: 'flex',
              flexFlow: 'row',
              [theme.breakpoints.up('md')]: {
                display: 'contents',
              },
            }
          : {
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
            }),
        '.headerText, .tenderValue': {
          color: Colors.White,
        },
      },
      ...(isReturned && {
        '.tenderValue': {
          backgroundColor: Colors.Error,
          width: 'fit-content',
        },
      }),
      ...(!isQuote && {
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
      }),
    }),
    [theme],
  );

  const transactionCardInfoHeaderContainer = useCallback(
    (isQuote?: boolean): SxProps<Theme> => ({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(1.6),
      '.headerInfo': {
        '.columnInfo': {
          '.labelValue': {
            minHeight: theme.spacing(3.6),
          },
        },
      },
      ...(!isQuote && {
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
          '.headerInfo': {
            gridColumn: 'span 2',
          },
        },
      }),
    }),
    [theme],
  );

  const smallCardList = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: theme.spacing(1.6),
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    }),
    [theme],
  );

  const smallCardContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
      alignItems: 'center',
      gap: theme.spacing(0.8),
      padding: theme.spacing(0.8),
      backgroundColor: Colors.Grey300,
      borderRadius: theme.spacing(0.8),
    }),
    [theme],
  );

  const productImageContainer = useMemo<SxProps<Theme>>(
    () => ({
      height: theme.spacing(4.8),
      width: theme.spacing(4.8),
      display: 'grid',
      placeItems: 'center',
    }),
    [theme],
  );

  const bigCardContainer = useCallback(
    (isQuote: boolean): SxProps<Theme> => ({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(0.8),
      padding: theme.spacing(1.6),
      backgroundColor: Colors.Grey300,
      borderRadius: theme.spacing(0.8),
      ...(!isQuote && {
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
      }),
      '.brandQuantityContainer': {
        display: 'grid',
        alignItems: 'start',
        gap: theme.spacing(0.8),
        gridTemplateColumns: 'repeat(2, 1fr)',
        ...(!isQuote && {
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 110px',
          },
        }),
        '.brandContainer': {
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: 'max-content 1fr',
          gap: theme.spacing(0.8),
        },
      },
      '.infoImageContainer': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: theme.spacing(0.8),
        '.infoContainer': {
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: theme.spacing(1.6),
          paddingLeft: theme.spacing(5.6),
          ...(!isQuote && {
            [theme.breakpoints.up('md')]: {
              paddingLeft: theme.spacing(0),
            },
          }),
          '.detailsCTA': {
            cursor: 'pointer',
            transition: 'all 0.2s linear',
            opacity: 1,
            ':hover': {
              opacity: 0.7,
            },
            '.MuiTypography-root': {
              marginTop: theme.spacing(0.2),
            },
          },
        },
        '.imageContainer': {
          ...(!isQuote && {
            cursor: 'pointer',
            opacity: 1,
            transition: 'all 0.2s linear',
            ':hover': {
              opacity: 0.7,
            },
          }),
          display: 'grid',
          position: 'relative',
          width: '100%',
          height: '100%',
          placeItems: 'center',
          '.imageCounter': {
            position: 'absolute',
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
            alignItems: 'center',
            gap: theme.spacing(0.4),
            bottom: theme.spacing(0),
            right: theme.spacing(0),
            padding: theme.spacing(0.3, 0.6),
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            color: Colors.White,
            borderRadius: theme.spacing(0.4),
            '.MuiTypography-root': {
              marginTop: theme.spacing(0.2),
            },
          },
        },
      },
    }),
    [theme],
  );

  const bigImage = useMemo<SxProps<Theme>>(
    () => ({
      height: theme.spacing(16.3),
      width: '100%',
    }),
    [theme],
  );

  const productDetailsModalContent = useCallback(
    (isQuote: boolean): SxProps<Theme> => ({
      display: 'grid',
      gap: theme.spacing(3.2),
      gridTemplateColumns: '1fr',
      width: '100%',
      '.content': {
        width: '100%',
        display: 'grid',
        gap: theme.spacing(2.4),
        gridTemplateColumns: '1fr',
        '.infoContent': {
          width: '100%',
          display: 'grid',
          gap: theme.spacing(1.6),
          alignItems: 'center',
          ...(!isQuote
            ? {
                gridTemplateColumns: 'repeat(3, 1fr)',
              }
            : {
                gridTemplateColumns: 'repeat(2, 1fr)',
                [theme.breakpoints.up('md')]: {
                  gridTemplateColumns: 'repeat(3, 1fr)',
                },
              }),
          '.singleBox': {
            height: '100%',
            ...(!isQuote && {
              paddingBottom: theme.spacing(1.2),
              borderBottom: `${theme.spacing(0.1)} solid ${Colors.Grey400}`,
            }),
            '.labelValue': {
              height: '100%',
              justifyContent: 'space-between',
            },
          },
        },
      },
    }),
    [theme],
  );

  const transactionDocumentsWrapper = useCallback(
    (hasManyDocuments: boolean): SxProps<Theme> => ({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(1.6),
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        alignItems: 'center',
        gap: theme.spacing(0),
        gridTemplateColumns: `repeat(${hasManyDocuments ? '3' : '4'}, 1fr)`,
        '.firstTransactionDocument': {
          gridColumn: 'span 2',
        },
      },
    }),
    [theme],
  );

  const transactionDocumentAccordionContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gap: theme.spacing(1.6),
      gridTemplateColumns: '1fr',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(0.8),
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridAutoFlow: 'row',
        '.singleDocument': {
          gridColumnStart: 2,
          gridColumnEnd: 4,
        },
      },
    }),
    [theme],
  );

  const transactionDocumentContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: Colors.Grey300,
      borderRadius: theme.spacing(0.8),
      gap: theme.spacing(0.8),
      padding: theme.spacing(1.6),
      cursor: 'pointer',
      transition: 'all 0.2s linear',
      opacity: 1,
      ':hover': {
        opacity: 0.7,
      },
      '.iconLabel': {
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        gap: theme.spacing(1.2),
        '.label': {
          marginTop: theme.spacing(0.2),
          textTransform: 'capitalize',
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      transactionCardContainer,
      transactionCardHeaderContainer,
      transactionCardInfoHeaderContainer,
      smallCardList,
      smallCardContainer,
      productImageContainer,
      bigCardContainer,
      bigImage,
      productDetailsModalContent,
      transactionDocumentsWrapper,
      transactionDocumentAccordionContainer,
      transactionDocumentContainer,
    }),
    [
      transactionCardContainer,
      transactionCardHeaderContainer,
      transactionCardInfoHeaderContainer,
      smallCardList,
      smallCardContainer,
      productImageContainer,
      bigCardContainer,
      bigImage,
      productDetailsModalContent,
      transactionDocumentsWrapper,
      transactionDocumentAccordionContainer,
      transactionDocumentContainer,
    ],
  );
};
