import {useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '../api';
import {APIS, useApiPath} from '../api/useApiPath';
import {
  GetReasonsParams,
  GetReasonsResponse,
  PatchPointsBody,
  GetCustomerTierBody,
  GetCustomerTierResponse,
  GetTierInfoResponse,
} from './interfaces';

export const useCustomerBalance = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getReasons = useCallback(
    async ({...params}: GetReasonsParams) => {
      try {
        const {data} = await api.get<GetReasonsResponse[]>(
          getApiPath(APIS.LOYALTY_GET_REASONS),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const updatePoints = useCallback(
    async (body: PatchPointsBody) => {
      try {
        const {data} = await api.patch(
          getApiPath(APIS.LOYALTY_CUSTOMER_BALANCE_UPDATE),
          {
            ...body,
          },
        );

        return data;
      } catch (error) {
        console.error('Costumer info', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getCustomerTier = useCallback(
    async (body: GetCustomerTierBody) => {
      try {
        const {data} = await api.post<GetCustomerTierResponse>(
          getApiPath(APIS.LOYALTY_CUSTOMER_BALANCE_TIER),
          {
            ...body,
          },
        );

        return data;
      } catch (error) {
        console.error('Costumer info', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getTierInfo = useCallback(
    async (body: GetCustomerTierBody) => {
      try {
        const {data} = await api.post<GetTierInfoResponse[]>(
          getApiPath(APIS.LOYALTY_TIER_INFO),
          {
            ...body,
          },
        );

        return data;
      } catch (error) {
        console.error('Tier info', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return {
    getReasons,
    updatePoints,
    getCustomerTier,
    getTierInfo,
  };
};
