import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container = useMemo(
    () => ({
      maxWidth: 60,
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    }),
    [],
  );

  const avatar = useMemo(
    () => ({
      width: 40,
      height: 40,
      backgroundColor: theme.palette.common.white,
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.black,
    }),
    [theme],
  );

  const dropdownItem = useMemo(
    () => ({
      padding: 0,

      ':hover': {
        backgroundColor: 'transparent',
      },
    }),
    [],
  );

  const menu = useMemo(
    () => ({
      '& .MuiPaper-root': {
        borderRadius: '12px',
        boxShadow: '0px 2px 4px 0px #00000040',
        top: '63px !important',
      },
      '& .MuiList-root': {
        padding: theme.spacing(2.4),
        width: '260px',

        '& .Mui-focusVisible': {
          backgroundColor: 'transparent',
        },
      },
    }),
    [theme],
  );

  return {container, avatar, dropdownItem, menu};
};
