import React, {FormEvent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, IconButton, InputAdornment, TextField} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Select} from '@/atoms/Select';
import {SwitchButton} from '@/atoms/SwitchButton';
import {Text} from '@/atoms/Typography/Text';
import {Colors} from '@/themes/variables';

import {SearchButton} from './SearchButton';
import {MyAccountSearchBarProps} from './interfaces';
import {useStyle} from './styles';

const MyAccountSearchBar = ({
  freeSearch,
  onFreeSearchChange,
  onReset,
  onSubmit,
  minChars = 3,
  personas,
  onlyInThisStoreVisible = false,
  onlyInThisStoreValue = false,
  handleToggleOnlyInThisStore,
  showPickers = false,
  disablePickers = false,
  banners = [],
  selectedBanner,
  setSelectedBanner,
  countries = [],
  selectedCountry,
  loading = false,
  setSelectedCountry,
}: MyAccountSearchBarProps) => {
  const styles = useStyle();
  const {t} = useTranslation();

  const [currentSearch, setCurrentSearch] = useState<string>(freeSearch);
  const [tabs, setTabs] = useState<{key: string; selected: boolean}[]>([
    {
      key: 'CUSTOMER_DATA',
      selected: true,
    },
  ]);

  const selectedTabKey = useMemo(() => {
    return tabs.find(tab => tab.selected)?.key || 'CUSTOMER_DATA';
  }, [tabs]);

  const handleFreeSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onFreeSearchChange(e.target.value);
    },
    [onFreeSearchChange],
  );

  const canSubmit = freeSearch?.length >= minChars;

  const freeSearchInputProps = useMemo(
    () => ({
      multiline: false,
      endAdornment: freeSearch && (
        <InputAdornment position="end">
          <IconButton onClick={onReset}>
            <IconSVG icon="cross_circle" size={24} />
          </IconButton>
        </InputAdornment>
      ),
    }),
    [freeSearch, onReset],
  );

  const placeholder = useMemo(() => {
    return t(`MyAccount.Search.Tabs.${selectedTabKey}_PLACEHOLDER`);
  }, [t, selectedTabKey]);

  const renderTabs = useMemo(() => {
    return tabs.map((tab, i) => {
      return (
        <Box key={`${tab.key}-${i}`}>
          {tab.selected ? (
            <Box style={styles.tabSelected}>
              <Text variant="body" superHeavy color={Colors.White}>
                {t(`MyAccount.Search.Tabs.${tab.key}`)}
              </Text>
            </Box>
          ) : (
            <Text>{t(`MyAccount.Search.Tabs.${tab.key}`)}</Text>
          )}
        </Box>
      );
    });
  }, [tabs, t, styles.tabSelected]);

  const handleSubmit = useCallback(() => {
    setCurrentSearch(freeSearch);
    onSubmit();
  }, [freeSearch, onSubmit]);

  const bannersOptions = useMemo(() => {
    return banners
      ?.map(banner => {
        return {
          label: banner.name,
          value: banner.name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [banners]);

  const countriesOptions = useMemo(() => {
    return countries
      ?.map(country => {
        return {
          label: country.name,
          value: country.name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [countries]);

  return (
    <>
      <Box sx={styles.tabs}>
        <Box>{renderTabs}</Box>
        {onlyInThisStoreVisible ? (
          <Box sx={styles.switchContainer}>
            <SwitchButton
              checked={onlyInThisStoreValue}
              onChange={handleToggleOnlyInThisStore}
            />
            <Text
              style={{marginTop: '4px'}}
              variant="body"
              color={Colors.GreyText}>
              {t('MyAccount.Search.onlyInThisStore')}
            </Text>
          </Box>
        ) : (
          <></>
        )}
        {showPickers && setSelectedBanner && setSelectedCountry ? (
          <Box sx={styles.switchContainer}>
            <Select
              sx={styles.select}
              disabled={bannersOptions?.length === 0 || disablePickers}
              value={selectedBanner}
              options={bannersOptions || []}
              {...(!disablePickers && {
                onChange: e => setSelectedBanner(`${e.target.value}`),
              })}
            />
            <Select
              sx={styles.select}
              disabled={countriesOptions?.length === 0 || disablePickers}
              value={selectedCountry}
              options={countriesOptions || []}
              {...(!disablePickers && {
                onChange: e => setSelectedCountry(`${e.target.value}`),
              })}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box
        component="form"
        sx={styles.formContainerBasic}
        noValidate
        autoComplete="off"
        onSubmit={(event: FormEvent) => {
          event.preventDefault();
          if (canSubmit) {
            handleSubmit();
          }
        }}>
        <TextField
          autoComplete="off"
          type="text"
          inputProps={{
            spellCheck: 'false',
            autoCorrect: 'off',
          }}
          sx={styles.freeSearch}
          value={freeSearch}
          placeholder={placeholder}
          onChange={handleFreeSearchChange}
          InputProps={freeSearchInputProps}
          focused={false}
          fullWidth={true}
        />
        <SearchButton
          onClick={handleSubmit}
          disabled={!canSubmit}
          loading={loading}
        />
      </Box>
      {personas && personas.size < 1 ? (
        <Box sx={styles.noResults}>
          <Text
            variant="headline"
            superHeavy
            textAlign="center"
            color="#4D4D4D">
            {`${t('MyAccount.Search.noResults')}`}
            <br />
            {`“${currentSearch}“`}
          </Text>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(MyAccountSearchBar);
