import dayjs from 'dayjs';

import {
  ReportFilters,
  ReportFiltersAction,
  ReportFiltersActions,
} from '@/store/reportFilters/interfaces.d';

export const reportFiltersInitialStartDate = dayjs().startOf('month');
export const reportFiltersInitialEndDate = dayjs();

export const getInitialState = (): ReportFilters => ({
  regions: [],
  stores: [],
  status: [],
  codes: [],
  startDate: reportFiltersInitialStartDate.format('YYYY-MM-DD'),
  endDate: reportFiltersInitialEndDate.format('YYYY-MM-DD'),
  page: 1,
  orderBy: 'ASC',
  sortBy: null,
});

export const reportFiltersReducer = (
  state = getInitialState(),
  action: ReportFiltersAction,
) => {
  switch (action.type) {
    case ReportFiltersActions.changeField:
      const {key, value} = action?.payload;
      return {
        ...state,
        [key]: value,
        ...(key === 'regions' && {
          stores: [],
        }),
        page: 1,
      };
    case ReportFiltersActions.changeDates:
      const hasDiffStart = !dayjs(state.startDate).isSame(
        action?.payload?.startDate,
        'date',
      );
      const hasDiffEnd = !dayjs(state.endDate).isSame(
        action?.payload?.endDate,
        'date',
      );
      return {
        ...state,
        ...((hasDiffStart || hasDiffEnd) && {
          codes: [],
        }),
        startDate: action?.payload?.startDate?.format('YYYY-MM-DD'),
        endDate: action?.payload?.endDate?.format('YYYY-MM-DD'),
        page: 1,
      };
    case ReportFiltersActions.changePage:
      return {
        ...state,
        page: action?.payload,
      };
    case ReportFiltersActions.resetFilters:
      return getInitialState();
    default:
      return state;
  }
};
