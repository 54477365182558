import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const card = useMemo(
    () => ({
      width: '100%',
      backgroundColor: theme.palette.primary[700],
      borderRadius: theme.spacing(0.6),
      padding: theme.spacing(1.6),
    }),
    [theme],
  );

  const info = useMemo(
    () => ({
      textAlign: 'center',
    }),
    [],
  );

  const button = useMemo(
    () => ({
      margin: 'auto',
      marginTop: '16px',
      padding: '8px 16px 8px 16px',
      textAlign: 'center',
      border: '1px solid black',
      borderRadius: '6px',
      width: 'fit-content',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    [],
  );

  return {card, info, button};
};
