import React, {useMemo} from 'react';

import {Box, Button, Container} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Text} from '@/atoms/Typography/Text';
import {UserAvatar} from '@/atoms/UserAvatar';
import {FeedbackModal} from '@/organisms/FeedbackModal';
import {ReactComponent as Logo} from '@/themes/assets/essilorluxottica-logo.svg';

import {PageHeaderProps} from './interfaces';
import {useStyle} from './styles';

const PageHeader = ({
  banner,
  title,
  user,
  extended,
  login = false,
  noBanner = false,
}: PageHeaderProps) => {
  const styles = useStyle({title, extended});

  const renderBanner = useMemo(() => {
    if (!banner || noBanner)
      return (
        <Box sx={styles.logo}>
          <Logo height="100%" width="100%" />
        </Box>
      );

    return (
      <Text variant="headline3" heavy>
        {banner}
      </Text>
    );
  }, [banner, noBanner, styles.logo]);

  const showTitle = title && !extended && !login;
  const showAvatar = !login && user;

  return (
    <>
      <Box sx={styles.navbar}>
        <Container sx={styles.wrapper} disableGutters maxWidth="xl">
          {renderBanner}
          {showTitle && (
            <Text variant="headline3" medium justifySelf="center">
              {title}
            </Text>
          )}
          {showAvatar && (
            <Box sx={styles.iconUser}>
              <FeedbackModal>
                <Button>
                  <IconSVG icon="messages" size={24} />
                </Button>
              </FeedbackModal>
              <UserAvatar user={user} />
            </Box>
          )}
        </Container>
      </Box>
      {extended && (
        <Box sx={styles.extendedNavbar}>
          <Text variant="headline1" medium sx={styles.extendedNavbarTitle}>
            {title}
          </Text>
        </Box>
      )}
    </>
  );
};

export default React.memo(PageHeader);
