import {useMemo, useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {
  GetCountriesParams,
  GetCountriesResponse,
} from '@/store/countries/interfaces';

import {APIS, useApiPath} from '../api/useApiPath';

export const useCountries = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getCountries = useCallback(
    async ({...params}: GetCountriesParams) => {
      try {
        const {data} = await api.get<GetCountriesResponse[]>(
          getApiPath(APIS.LOYALTY_COUNTRIES, {
            ...params,
          }),
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        return undefined;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getCountries,
    }),
    [getCountries],
  );
};
