import {useMemo} from 'react';

import {SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const box: SxProps<Theme> = useMemo(
    () => ({
      transform: 'rotate(180deg)',
      marginLeft: '4px',
    }),
    [],
  );

  return {
    box,
  };
};
