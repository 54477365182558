import React, {
  useMemo,
  useCallback,
  FormEvent,
  useState,
  useEffect,
} from 'react';

import {
  Stack,
  useMediaQuery,
  TextField,
  Box,
  InputAdornment,
  IconButton,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {ChipDatepickerRange} from '@/atoms/ChipDatepickerRange';
import {ChipDropdown} from '@/atoms/ChipDropdown';
import {DropdownOption} from '@/atoms/ChipDropdown/interfaces';
import {ChipOrderBy} from '@/atoms/ChipOrderBy';
import {Headline2} from '@/atoms/Headline2';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {capitalizeWords} from '@/lib/utils';
import {ChipProfiles} from '@/molecules/ChipProfiles';
import {SearchButton} from '@/molecules/MyAccountSearchBar/SearchButton';
import {PurchaseListFiltersProps} from '@/organisms/PurchaseListFilters/interfaces';
import {useStyles} from '@/organisms/PurchaseListFilters/styles';
import theme from '@/themes/theme';

const BASE_TRANSLATION_PATH = 'PurchaseList.filters';

const PurchaseListFilters = ({
  isLoading = false,
  params,
  orderCount,
  currentFilters,
  updateFilters,
}: PurchaseListFiltersProps) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION_PATH);
  const styles = useStyles();

  // Free search state for input
  const [freeSearchState, setFreeSearchState] = useState<string>(
    currentFilters?.q ?? '',
  );

  // Update state with currentFilters
  useEffect(() => {
    setFreeSearchState(currentFilters?.q ?? '');
  }, [currentFilters?.q]);

  // Func to update filter object
  const updateFilterObject = useCallback(
    (
      curFilters: PurchaseListFiltersProps['currentFilters'],
      key: keyof PurchaseListFiltersProps['currentFilters'],
      value: any,
    ): PurchaseListFiltersProps['currentFilters'] => {
      const updatedFilters = {...curFilters};

      if (typeof value === 'undefined') {
        delete updatedFilters[key];
      } else {
        updatedFilters[key] = value;
      }

      return updatedFilters;
    },
    [],
  );

  // Handle filter change for dropdowns
  const handleSelectFilterChange = useCallback(
    (
      key: keyof PurchaseListFiltersProps['currentFilters'],
      value: Array<any>,
    ) => {
      const curValue = value?.length > 0 ? value : undefined;
      const updatedFilters = updateFilterObject(currentFilters, key, curValue);
      updateFilters({...updatedFilters});
    },
    [currentFilters, updateFilterObject, updateFilters],
  );

  // Handle filter change for plain fields
  const handleFilterChange = useCallback(
    (key: keyof PurchaseListFiltersProps['currentFilters'], value: any) => {
      const curValue = value || undefined;
      const updatedFilters = updateFilterObject(currentFilters, key, curValue);
      updateFilters({...updatedFilters});
    },
    [currentFilters, updateFilterObject, updateFilters],
  );

  // Handle filter change for date range
  const handleDateChange = useCallback(
    (startDate?: Dayjs, endDate?: Dayjs) => {
      const curStartDate = startDate
        ? startDate.format('YYYY-MM-DD')
        : undefined;
      let updatedFilters = updateFilterObject(
        currentFilters,
        'startDate',
        curStartDate,
      );
      const curEndDate = endDate ? endDate.format('YYYY-MM-DD') : undefined;
      updatedFilters = updateFilterObject(
        updatedFilters,
        'endDate',
        curEndDate,
      );

      updateFilters({...updatedFilters});
    },
    [currentFilters, updateFilterObject, updateFilters],
  );

  // Check if filters are present
  const canResetFilters = useMemo(() => {
    return (
      Object.keys(currentFilters).filter(k => k !== 'orderBy' && k !== 'q')
        .length > 0
    );
  }, [currentFilters]);

  // Reset filters
  const handleFiltersReset = useCallback(() => {
    updateFilters({
      orderBy: currentFilters?.orderBy,
      ...(currentFilters?.q && {q: currentFilters?.q}),
    });
  }, [currentFilters?.orderBy, currentFilters?.q, updateFilters]);

  // Dropdown options for categories
  const categoriesOptions = useMemo<Array<DropdownOption>>(
    () => [
      {
        label: getTranslationWithValue(0, 'categories.values.SUN'),
        value: 'SUN',
      },
      {
        label: getTranslationWithValue(0, 'categories.values.OPTICAL'),
        value: 'OPTICAL',
      },
      {
        label: getTranslationWithValue(0, 'categories.values.CONTACTS'),
        value: 'CONTACTS',
      },
      {
        label: getTranslationWithValue(0, 'categories.values.READERS'),
        value: 'READERS',
      },
      {
        label: getTranslationWithValue(0, 'categories.values.ACCESSORIES'),
        value: 'ACCESSORIES',
      },
    ],
    [getTranslationWithValue],
  );

  // Dropdown options for channels
  const channelsOptions = useMemo<Array<DropdownOption>>(
    () => [
      {
        label: getTranslationWithValue(0, 'channels.values.STORE'),
        value: 'STORE',
      },
      {
        label: getTranslationWithValue(0, 'channels.values.ONLINE'),
        value: 'ONLINE',
      },
    ],
    [getTranslationWithValue],
  );

  // Submit freeSearch
  const handleFreeSearchSubmit = useCallback(() => {
    handleFilterChange('q', freeSearchState);
  }, [freeSearchState, handleFilterChange]);

  // Handle free search change state
  const handleFreeSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFreeSearchState(e.target.value);
    },
    [],
  );

  // Reset free search and update filter if Q was set before
  const resetFreeSearch = useCallback(() => {
    setFreeSearchState('');
    if (!!currentFilters?.q) {
      handleFilterChange('q', undefined);
    }
  }, [currentFilters?.q, handleFilterChange]);

  // Input props for free search to enable clear button
  const freeSearchInputProps = useMemo(
    () => ({
      multiline: false,
      endAdornment: freeSearchState && (
        <InputAdornment position="end">
          <IconButton onClick={resetFreeSearch}>
            <IconSVG icon="cross_circle" size={24} />
          </IconButton>
        </InputAdornment>
      ),
    }),
    [freeSearchState, resetFreeSearch],
  );

  // Check if free search can be submitted
  const canSubmit = freeSearchState?.length >= 3;

  return (
    <Stack direction="column" gap={0.8}>
      <Headline2 superHeavy>
        {getTranslationWithValue(0, 'title', {
          name: capitalizeWords(params.name),
        })}
      </Headline2>
      <Stack direction="column" gap={1.6}>
        <Stack sx={styles.filterRowContainer}>
          <Stack direction="row" gap={0.8}>
            <ChipDropdown
              disableDefaultCount
              filterLabel={getTranslationWithValue(0, 'categories.filterLabel')}
              allFilterLabel={getTranslationWithValue(
                0,
                'categories.allFilterLabel',
              )}
              searchPlaceholder={getTranslationWithValue(
                0,
                'categories.searchPlaceholder',
              )}
              selectedValues={currentFilters?.category ?? []}
              options={categoriesOptions}
              onApply={categories =>
                handleSelectFilterChange('category', categories)
              }
              disabled={false}
            />
            <ChipDropdown
              disableDefaultCount
              filterLabel={getTranslationWithValue(0, `channels.filterLabel`)}
              allFilterLabel={getTranslationWithValue(
                0,
                `channels.allFilterLabel`,
              )}
              searchPlaceholder={getTranslationWithValue(
                0,
                `channels.searchPlaceholder`,
              )}
              selectedValues={currentFilters?.channel ?? []}
              options={channelsOptions}
              onApply={channels =>
                handleSelectFilterChange('channel', channels)
              }
              disabled={false}
              capitalize
            />
            <ChipDatepickerRange
              startDate={
                currentFilters?.startDate
                  ? dayjs(currentFilters.startDate)
                  : undefined
              }
              endDate={
                currentFilters?.endDate
                  ? dayjs(currentFilters.endDate)
                  : undefined
              }
              minDate={dayjs().subtract(3, 'years')}
              maxDate={dayjs()}
              onSetDateRange={handleDateChange}
            />
            <ChipProfiles
              selectedCustomers={currentFilters?.customerId ?? []}
              onCustomerChange={customerIds =>
                handleSelectFilterChange('customerId', customerIds)
              }
              request={params}
            />
            <Link
              position="center"
              linkVariant="dark"
              textVariant="body"
              href="#"
              iconStart={<IconSVG icon="reset" size={16} />}
              {...(canResetFilters && {handleOnClick: handleFiltersReset})}
              disabled={!canResetFilters}
              noDecoration>
              {isDesktop && getTranslationWithValue(0, 'reset')}
            </Link>
          </Stack>
          <Box
            component="form"
            sx={styles.formContainerBasic}
            noValidate
            autoComplete="off"
            onSubmit={(event: FormEvent) => {
              event.preventDefault();
              if (canSubmit) {
                handleFreeSearchSubmit();
              }
            }}>
            <TextField
              sx={styles.freeSearch}
              autoComplete="off"
              type="text"
              inputProps={{
                spellCheck: 'false',
                autoCorrect: 'off',
              }}
              value={freeSearchState}
              placeholder={getTranslationWithValue(0, `freeSearch.placeholder`)}
              onChange={handleFreeSearchChange}
              InputProps={freeSearchInputProps}
              focused={false}
              fullWidth={true}
            />
            <SearchButton
              onClick={handleFreeSearchSubmit}
              disabled={!canSubmit}
              loading={isLoading}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <BodyText medium>
            {getTranslationWithValue(0, `purchasesList`, {
              size: orderCount ?? 0,
            })}
          </BodyText>
          <ChipOrderBy
            value={currentFilters?.orderBy}
            changeValue={orderBy => handleFilterChange('orderBy', orderBy)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default React.memo(PurchaseListFilters);
