import React, { useCallback } from 'react';

import {Box} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Text} from '@/atoms/Typography/Text';
import {Colors} from '@/themes/variables';

import {TableColumnHeaderTooltipProps} from './interfaces';
import {useStyle} from './styles';

const TableColumnHeaderTooltip = ({
  text,
  tooltip,
  align = 'center',
}: TableColumnHeaderTooltipProps) => {
  const styles = useStyle();

  const stopPropagation = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  return (
    <Box display="flex" flexDirection="row">
      <Text variant="tiny" medium align={align}>
        {text}
      </Text>
      {tooltip ? (
        <Box sx={styles.box}>
          <IconSVG
            icon="error"
            size={16}
            color={Colors.GreyDark}
            tooltip={tooltip}
            onClick={stopPropagation}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default React.memo(TableColumnHeaderTooltip);
