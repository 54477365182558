import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const headerContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'start',
      gap: theme.spacing(2.4),
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: '1fr max-content',
        alignItems: 'center',
      },
    }),
    [theme],
  );

  const avatarContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr',
      alignItems: 'center',
      gap: theme.spacing(1.6),
      wordBreak: 'break-word',
      '.nameCustomerId': {
        height: '100%',
        justifyContent: 'space-around',
        '.customerId': {
          color: Colors.GreyText2,
        },
      },
    }),
    [theme],
  );

  const taskInfoContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'max-content repeat(2, 1fr)',
      alignItems: 'center',
      gap: theme.spacing(3.2),
      '.campaignAssignContainer': {
        maxWidth: theme.spacing(24),
      },
      '.campaignBadgeContainer': {
        maxWidth: theme.spacing(16),
        '.value': {
          paddingLeft: theme.spacing(0.8),
        },
      },
      '.campaignEndDateContainer': {
        width: 'max-content',
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({headerContainer, avatarContainer, taskInfoContainer}),
    [headerContainer, avatarContainer, taskInfoContainer],
  );
};
