import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = ({
  withPadding,
  clickable,
  alignment,
}: {
  withPadding: boolean;
  clickable: boolean;
  alignment: 'center' | 'flex-start' | 'flex-end';
}) => {
  const theme = useTheme();

  const singleMemberContainer: SxProps<Theme> = useMemo(
    () => ({
      cursor: clickable ? 'pointer' : 'default',
      flexFlow: 'row',
      alignItems: alignment,
      gap: theme.spacing(0.8),
      paddingX: theme.spacing(withPadding ? 3.2 : 0),
    }),
    [clickable, theme, withPadding, alignment],
  );

  return useMemo(
    () => ({
      singleMemberContainer,
    }),
    [singleMemberContainer],
  );
};
