import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {useMutation} from '@tanstack/react-query';

import {useCustomerProfile} from '@/store/customerProfile';

import {BaseRouterParams} from '../../../definitions/base';
import {useToast} from '../../../hooks';
import {Dialog} from '../../atoms/Dialog';
import {CommonButtons} from '../../atoms/DialogButtons';
import {IconSVG} from '../../atoms/IconSVG';
import {IconNames} from '../../atoms/IconSVG/interfaces';
import {CustomerProfileActionsProps} from './interfaces';
import {useStyles} from './styles';

const CustomerProfileActions = ({
  onCardResend,
  isSuspended,
  isCardLoading,
  refetch,
}: CustomerProfileActionsProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const {t} = useTranslation();
  const {wrapper, dialogTitle} = useStyles();
  const {showSuccessToast, showErrorToast} = useToast();

  const {cardNumber, banner, country} = useParams<BaseRouterParams>();

  const {unsubscribeProfile, restoreProfile} = useCustomerProfile();

  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);

  const openDeactivateDialog = useCallback(() => {
    setDeactivateDialogOpen(true);
  }, []);

  const closeDeactivateDialog = useCallback(() => {
    setDeactivateDialogOpen(false);
  }, []);

  const {mutate: unsubscribeCustomer} = useMutation(unsubscribeProfile, {
    onSuccess: () => {
      showSuccessToast(
        t('CustomerProfile.actions.deactivateCardToast.success'),
      );
      refetch();
    },
    onError: () =>
      showErrorToast(t('CustomerProfile.actions.deactivateCardToast.error')),
  });

  const onDeactivation = useCallback(() => {
    unsubscribeCustomer({
      cardNumberSearch: cardNumber,
      bannerSearch: banner,
      countrySearch: country,
    });
    closeDeactivateDialog();
  }, [cardNumber, closeDeactivateDialog, unsubscribeCustomer, banner, country]);

  const {mutate: restoreCustomer} = useMutation(restoreProfile, {
    onSuccess: () => {
      showSuccessToast(t('CustomerProfile.actions.restoreCardToast.success'));
      refetch();
    },
    onError: () =>
      showErrorToast(t('CustomerProfile.actions.restoreCardToast.error')),
  });

  const onRestore = useCallback(() => {
    restoreCustomer({
      cardNumberSearch: cardNumber,
      bannerSearch: banner,
      countrySearch: country,
    });
    closeDeactivateDialog();
  }, [cardNumber, closeDeactivateDialog, restoreCustomer, banner, country]);

  const actions = useMemo(
    (): Array<{
      id: number;
      icon: IconNames;
      text: string;
      disabled: boolean;
      onClick: () => void;
    }> => [
      {
        id: 0,
        icon: 'resend_card',
        text: t('CustomerProfile.actions.resendCard'),
        disabled: isSuspended || isCardLoading,
        onClick: onCardResend,
      },
      {
        id: 1,
        icon: 'deactivate_card',
        disabled: false,
        text: isSuspended
          ? t('CustomerProfile.actions.restoreCustomer')
          : t('CustomerProfile.actions.unsubscribeCustomer'),
        onClick: openDeactivateDialog,
      },
    ],
    [isSuspended, isCardLoading, onCardResend, openDeactivateDialog, t],
  );

  // Deactivation modal
  const deactivationDialog = useMemo(
    () => (
      <Dialog
        open={deactivateDialogOpen}
        sx={dialogTitle}
        dialogTitle={
          isSuspended
            ? t('CustomerProfile.actions.restoreCardDialog.title')
            : t('CustomerProfile.actions.deactivateCardDialog.title')
        }
        titleUppercase={false}
        onClose={closeDeactivateDialog}
        dialogActions={
          <CommonButtons
            onConfirm={isSuspended ? onRestore : onDeactivation}
            gap={2.1}
            onClose={closeDeactivateDialog}
            confirmText={
              isSuspended
                ? t('CustomerProfile.actions.restoreCardDialog.button', {
                    context: 'confirm',
                  })
                : t('CustomerProfile.actions.deactivateCardDialog.button', {
                    context: 'confirm',
                  })
            }
          />
        }
      />
    ),
    [
      closeDeactivateDialog,
      deactivateDialogOpen,
      dialogTitle,
      isSuspended,
      onDeactivation,
      onRestore,
      t,
    ],
  );

  // Mobile menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  if (isDesktop) {
    return (
      <>
        <Box sx={wrapper}>
          {actions.map(({id, icon, text, disabled, onClick}) => (
            <Button
              key={id}
              variant="primary"
              onClick={onClick}
              disabled={disabled}
              endIcon={
                <IconSVG
                  icon={icon}
                  size={23}
                  color={theme.palette.common.white}
                />
              }>
              {text}
            </Button>
          ))}
        </Box>
        {deactivationDialog}
      </>
    );
  }

  return (
    <>
      <IconButton
        aria-label="actions"
        id="actions-button"
        aria-controls={menuOpen ? 'actions-menu' : undefined}
        aria-expanded={menuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}>
        <IconSVG icon="action" size={23} />
      </IconButton>
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}>
        {actions.map(({id, disabled, onClick, text}) => {
          const onMenuItemClick = () => {
            onClick();
            handleMenuClose();
          };
          return (
            <MenuItem key={id} onClick={onMenuItemClick} disabled={disabled}>
              {text}
            </MenuItem>
          );
        })}
      </Menu>
      {deactivationDialog}
    </>
  );
};

export default React.memo(CustomerProfileActions);
