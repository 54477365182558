import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const button: SxProps<Theme> = useMemo(
    () => ({
      alignSelf: 'center',
      minWidth: '102px',
      height: '33px',
      borderRadius: '6px',
    }),
    [],
  );

  return {button};
};
