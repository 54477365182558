import React, {useCallback, useMemo} from 'react';

import {Box, MenuItem, Select as MUISelect} from '@mui/material';

import {IconSVG} from '../IconSVG';
import {CustomSelectProps} from './interfaces';
import {useStyle} from './styles';

const Select = ({
  error,
  options,
  value,
  onChange,
  placeholder,
  onClick,
  disabled,
  sx,
  menuStyle,
  displayEmpty = true,
}: CustomSelectProps) => {
  const styles = useStyle(!!error);

  const IconComponent = useCallback(
    ({className}: {className: string}) => (
      <Box className={className} sx={styles.iconComponent}>
        <IconSVG icon="chevron_down" size={10} />
      </Box>
    ),
    [styles.iconComponent],
  );

  const renderValue = useCallback(
    (value: any) =>
      options?.find(option => option.value === value)?.label ?? placeholder,
    [options, placeholder],
  );

  const menuProps = useMemo(
    () => ({
      sx: [styles.menu, menuStyle],
    }),
    [menuStyle, styles.menu],
  );

  return (
    <MUISelect
      {...(onClick && {onClick: onClick})}
      disabled={disabled}
      sx={[styles.input, sx]}
      value={value}
      error={!!error}
      onChange={onChange}
      IconComponent={IconComponent}
      // Display placeholder when no value is selected
      displayEmpty={displayEmpty}
      renderValue={renderValue}
      MenuProps={menuProps}>
      {options?.map(({value, label}) => (
        <MenuItem
          key={`${label}_${value}`}
          sx={styles.menuItem(value)}
          value={value}>
          {label}
        </MenuItem>
      ))}
    </MUISelect>
  );
};

export default React.memo(Select);
