import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {FOOTER_HEIGHT} from '@/molecules/PageFooter/styles';

export const useStyles = () => {
  const theme = useTheme();

  const selectedItemsToast = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxWidth: theme.spacing(63.1),
      paddingX: theme.spacing(3.2),
      paddingY: theme.spacing(1.6),
      borderRadius: theme.spacing(1.2),
      position: 'fixed',
      bottom: `calc(${FOOTER_HEIGHT} + ${theme.spacing(
        8.4,
      )} + env(safe-area-inset-bottom))`,
      left: '50%',
      transform: 'translate(-50%, 0)',
    }),
    [theme],
  );

  return useMemo(
    () => ({
      selectedItemsToast,
    }),
    [selectedItemsToast],
  );
};
