import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box,
  Button,
  StandardTextFieldProps,
  TextField,
  useTheme,
} from '@mui/material';

import {numberFormatter} from '@/lib/utils';

import {IconSVG} from '../../atoms/IconSVG';
import {InputWrapper} from '../../atoms/InputWrapper';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const PointsInput = ({
  value,
  max,
  min,
  onChange,
  error,
  isReason,
  onError,
  currentPoints,
}: IProps) => {
  const styles = useStyle();
  const theme = useTheme();
  const {t} = useTranslation();

  const [naturalValue, setNaturalValue] = useState<number | null>(null);

  const [sign, setSign] = useState<-1 | 0 | 1>(0);
  const noSign = useMemo(() => sign === 0, [sign]);

  const setNegative = useCallback(() => setSign(-1), []);
  const setPositive = useCallback(() => setSign(1), []);
  const resetSign = useCallback(() => setSign(0), []);

  const updateInternalState = useCallback(
    (value: number | null) => {
      if (value === null) {
        setNaturalValue(null);
      } else {
        setNaturalValue(Math.abs(value));
        if (value < 0) {
          setNegative();
        }
        // Check if value is positive and a sign is alredy set
        // Sign must be explicitly set
        else if (value > 0 && sign === 1) {
          setPositive();
        } else if (value === 0) {
          resetSign();
        }
      }

      return value;
    },
    [resetSign, setNegative, setPositive, sign],
  );

  // Update internal state from value prop
  useEffect(() => {
    updateInternalState(value);
  }, [updateInternalState, value]);

  const updateValueFromInput = useCallback(
    (input: string) => {
      if (input === '') {
        resetSign();
        onChange(null);
      } else if (input === '-') {
        // Only minus sign in input
        setNegative();
      } else if (input === '+') {
        // Only plus sign in input
        setPositive();
      } else {
        const parsedNumber = parseInt(input);
        if (Number.isNaN(parsedNumber)) {
          // Invalid input
          return;
        } else if (input.charAt(0) === '-') {
          // Explicitly placed minus sign before number
          onChange(parsedNumber);
        } else if (input.charAt(0) === '+') {
          // Explicitly placed plus sign before number
          setPositive();
          onChange(parsedNumber);
        } else if (sign) {
          onChange(parsedNumber * sign);
        } else {
          onChange(parsedNumber);
        }
      }
    },
    [onChange, resetSign, setNegative, setPositive, sign],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputValue = event.target.value;

      updateValueFromInput(inputValue);
    },
    [updateValueFromInput],
  );

  // Used to reset form if naturalValue is null
  const inputValue = naturalValue ?? '';

  const inputStartAdornment = useMemo(
    () => (
      <>
        {noSign ? (
          ''
        ) : sign === -1 ? (
          <IconSVG icon="remove" size={15} color={theme.palette.error.main} />
        ) : (
          <IconSVG icon="add" size={15} color={theme.palette.success.main} />
        )}
      </>
    ),
    [noSign, sign, theme],
  );

  const inputProps: StandardTextFieldProps['InputProps'] = useMemo(
    () => ({
      startAdornment: inputStartAdornment,
    }),
    [inputStartAdornment],
  );

  const changeToNegative = useCallback(() => {
    if (naturalValue === null || naturalValue === 0) {
      onChange(-10);
    }

    if (naturalValue) {
      if (sign === -1) {
        onChange(-naturalValue - 10);
      } else {
        onChange(naturalValue - 10);
      }
    }
  }, [naturalValue, onChange, sign]);

  const changeToPositive = useCallback(() => {
    if (naturalValue === null || naturalValue === 0) {
      onChange(+10);
      setPositive();
    }

    if (naturalValue) {
      if (sign === -1) {
        onChange(naturalValue - 10);
      } else {
        onChange(naturalValue + 10);
        setPositive();
      }
    }
  }, [naturalValue, onChange, setPositive, sign]);

  const localValidation = useMemo(() => {
    if (naturalValue && noSign) {
      return t('CustomerBalance.editBalanceErrorSign');
    } else if (
      (naturalValue && naturalValue * sign > max) ||
      (naturalValue && naturalValue * sign < min)
    ) {
      return t('CustomerBalance.editBalanceErrorPoints', {
        min: numberFormatter.format(min),
        max: numberFormatter.format(max),
      });
    }

    if (naturalValue! > currentPoints && sign === -1) {
      return t('CustomerBalance.editBalanceErrorExceedNumber');
    }
    return '';
  }, [currentPoints, max, min, naturalValue, noSign, sign, t]);

  const displayedError = error || localValidation;

  onError && onError(!!displayedError);

  return (
    <InputWrapper
      label={t('CustomerBalance.pointsInput')}
      sx={styles.inputWrapper}
      error={displayedError}>
      <Box sx={styles.container}>
        <Box sx={[styles.button, styles.removeButton]}>
          <Button onClick={changeToNegative} disabled={!isReason}>
            <IconSVG
              icon="remove"
              size={15}
              color={theme.palette.common.black}
            />
          </Button>
        </Box>
        <TextField
          disabled={!isReason}
          error={!!displayedError}
          sx={styles.textField}
          size="small"
          fullWidth
          value={inputValue}
          onChange={handleChange}
          placeholder="0"
          InputProps={inputProps}
        />
        <Box sx={[styles.button, styles.addButton]}>
          <Button onClick={changeToPositive} disabled={!isReason}>
            <IconSVG icon="add" size={15} color={theme.palette.common.black} />
          </Button>
        </Box>
      </Box>
    </InputWrapper>
  );
};

export default React.memo(PointsInput);
