import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyle = (
  enableBack: boolean | undefined = false,
  subtitle: any = undefined,
) => {
  const theme = useTheme();

  const titleContainer = useMemo<SxProps<Theme>>(
    () => ({
      marginBottom: subtitle ? theme.spacing(2.4) : 0,
      display: 'grid',
      gridTemplateColumns: enableBack ? '1fr max-content 1fr' : '1fr',
      '.MuiLink-root': {
        alignSelf: 'center',
      },
    }),
    [subtitle, theme, enableBack],
  );

  const titleText = useMemo<SxProps<Theme>>(
    () => ({
      justifySelf: enableBack ? 'center' : 'start',
      lineHeight: theme.typography.headline3.fontSize,
      fontSize: theme.typography.headline3.fontSize,
      [theme.breakpoints.up('lg')]: {
        lineHeight: theme.typography.headline1.fontSize,
        fontSize: theme.typography.headline1.fontSize,
      },
    }),
    [theme, enableBack],
  );

  return {titleContainer, titleText};
};
