// SuspenseBackgroundImage.tsx
import React, {ReactNode} from 'react';

import {Box, SxProps, Theme} from '@mui/material';

import loadImage from '@/lib/loadImage';

interface SuspenseBackgroundImageProps {
  src: string;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

const SuspenseBackgroundImage: React.FC<SuspenseBackgroundImageProps> = ({
  src,
  children,
  sx,
}) => {
  loadImage(src); // This will throw a promise if the image is not yet loaded

  const style = {
    backgroundImage: `url(${src})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
  };

  return (
    <Box sx={sx} style={style}>
      {children}
    </Box>
  );
};

export default React.memo(SuspenseBackgroundImage);
