import React, {ChangeEvent, useCallback} from 'react';

import {Box, TextField} from '@mui/material';

import {NoteInputProps} from './interfaces';

const NoteInput = ({
  label,
  characterLimit,
  placeholder,
  size,
  onChange,
  value,
  disabled = false,
}: NoteInputProps) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <Box>
      <TextField
        disabled={disabled}
        InputLabelProps={{shrink: true}}
        inputProps={{
          maxLength: characterLimit,
        }}
        minRows={size}
        maxRows={size}
        fullWidth
        multiline
        helperText={`${value.length.toLocaleString()}/${characterLimit.toLocaleString()}`}
        placeholder={placeholder}
        label={label}
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
};

export default React.memo(NoteInput);
