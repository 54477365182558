import {useMemo} from 'react';

import {useTheme} from '@mui/material';

import {
  MOBILE_SEARCHBAR_HEIGHT,
  SEARCHBAR_HEIGHT,
} from '@/molecules/HomepageFormbar/styles';
import {FOOTER_HEIGHT} from '@/molecules/PageFooter/styles';

export const useStyle = () => {
  const theme = useTheme();

  const searchBar = useMemo(
    () => ({
      position: 'absolute',
      top: `calc((50dvh - env(safe-area-inset-bottom)) - (${SEARCHBAR_HEIGHT}/2))`,
      '@-moz-document url-prefix()': {
        top: `calc(50vh - env(safe-area-inset-bottom) - (${SEARCHBAR_HEIGHT}/2))`,
      },
      mx: 'auto',
      left: 0,
      right: 0,
      zIndex: 999,
      [theme.breakpoints.down('md')]: {
        top: `calc((50dvh - env(safe-area-inset-bottom)) - (${MOBILE_SEARCHBAR_HEIGHT}/2))`,
        '@-moz-document url-prefix()': {
          top: `calc((50vh - env(safe-area-inset-bottom)) - (${MOBILE_SEARCHBAR_HEIGHT}/2))`,
        },
      },
    }),
    [theme],
  );

  const resultsContainer = useMemo(
    () => ({
      height: `calc((50dvh - env(safe-area-inset-bottom)) - ${FOOTER_HEIGHT}))`,
      '@-moz-document url-prefix()': {
        height: `calc((50vh - env(safe-area-inset-bottom)) - ${FOOTER_HEIGHT})`,
      },
      background: theme.palette.grey[600],
      paddingTop: `calc(${MOBILE_SEARCHBAR_HEIGHT}/2)`,
      [theme.breakpoints.up('md')]: {
        paddingTop: `calc(${SEARCHBAR_HEIGHT}/2)`,
      },
    }),
    [theme],
  );

  const results = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3.2),
      height: '100%',
      alignItems: 'center',
    }),
    [theme],
  );

  return {searchBar, resultsContainer, results};
};
