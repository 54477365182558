import React from 'react';

import {Box, Typography} from '@mui/material';

import {numberFormatter} from '@/lib/utils';

import {IProps} from './definitions';
import {useStyle} from './styles';

const ValueCircle = ({value, unit}: IProps) => {
  const styles = useStyle();

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.valueText} variant="caption" component="div">
        {numberFormatter.format(value)}
      </Typography>
      <Typography sx={styles.unitText} variant="caption" component="div">
        {unit}
      </Typography>
    </Box>
  );
};

export default React.memo(ValueCircle);
