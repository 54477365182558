import React, {useMemo} from 'react';

import {
  Box,
  DialogTitle,
  DialogContent,
  Dialog as MuiDialog,
} from '@mui/material';

import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {ModalProps} from '@/atoms/Modal/interfaces';
import {useStyles} from '@/atoms/Modal/styles';

const Modal = ({
  open,
  onClose,
  dialogTitle,
  dialogContent,
  sx,
  sxContent,
  maxWidth,
}: ModalProps) => {
  const styles = useStyles(maxWidth);

  const printTitle = useMemo(() => {
    return <HeadlineText superHeavy>{dialogTitle ?? ' '}</HeadlineText>;
  }, [dialogTitle]);

  return (
    <MuiDialog sx={[styles.dialog, sx]} open={open} onClose={onClose}>
      <Box sx={styles.closeIcon} className="closeIconDialog" onClick={onClose}>
        <IconSVG icon="close" size={16} />
      </Box>
      <DialogTitle sx={styles.dialogTitle}>{printTitle}</DialogTitle>
      <DialogContent sx={[styles.dialogContent, sxContent]}>{dialogContent}</DialogContent>
    </MuiDialog>
  );
};

export default Modal;
