import React, {useMemo, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';

import {Stack, MenuItem, Select} from '@mui/material';
import dayjs from 'dayjs';

import {useStyles} from '@/molecules/dropdownPicker/styles';
import {DropdownPickerProps} from '@/molecules/dropdownPicker/types';
import theme from '@/themes/theme';

const DropdownPicker = ({
  initialDate,
  onChange,
  validCB,
}: DropdownPickerProps) => {
  const {customSelect, selectList} = useStyles();
  const {
    control,
    watch,
    setValue,
    formState: {isValid},
  } = useForm<{
    day?: number | string;
    month?: number | string;
  }>({
    defaultValues: {
      day: initialDate ? Number(initialDate?.split('/')[1]) : '',
      month: initialDate ? Number(initialDate?.split('/')[0]) - 1 : '',
    },
    mode: 'all',
  });

  const selectedMonth = watch('month');
  const selectedDay = watch('day');

  useEffect(() => {
    if (typeof selectedMonth === 'number') {
      const daysInMonth = dayjs().set('month', selectedMonth).daysInMonth();

      if (typeof selectedDay === 'number' && selectedDay > daysInMonth) {
        setValue('day', '');
      }
    } else if (selectedMonth === '') {
      setValue('day', '');
    }
  }, [selectedDay, selectedMonth, setValue]);

  const monthOptions = useMemo(() => {
    return [
      {
        label: 'MM',
        value: '',
      },
      ...dayjs.monthsShort().map((month, index) => {
        return {
          label: month,
          value: index,
        };
      }, []),
    ];
  }, []);

  const dayOptions = useMemo(() => {
    const daysInMonth =
      typeof selectedMonth === 'number'
        ? dayjs().set('month', selectedMonth).daysInMonth()
        : 0;

    return [
      {
        label: 'DD',
        value: '',
      },
      ...new Array(daysInMonth).fill(' ').map((_, index) => {
        return {
          label: `${index + 1}`,
          value: index + 1,
        };
      }),
    ];
  }, [selectedMonth]);

  useEffect(() => {
    if (onChange) {
      const [date, month] = Object.values(watch());
      if (typeof date !== 'number' || typeof month !== 'number') {
        onChange(null);
      } else {
        const newDate = dayjs()
          .set('month', month as number)
          .set('date', date as number);
        onChange(newDate);
      }
    }
  }, [onChange, watch]);

  useEffect(() => {
    if (validCB) {
      validCB(isValid);
    }
  }, [isValid, validCB]);

  return (
    <Stack
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      direction="row"
      alignItems="center"
      gap={1.5}>
      <Controller
        name="month"
        control={control}
        rules={{
          required: false,
        }}
        render={({field}) => (
          <Select
            sx={{width: '100%'}}
            variant="outlined"
            displayEmpty
            id="month"
            MenuProps={{
              sx: customSelect,
            }}
            {...field}>
            {monthOptions?.map(option => {
              return (
                <MenuItem
                  key={option.label}
                  value={option.value}
                  sx={selectList(option.value)}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      <Controller
        name="day"
        control={control}
        rules={{
          required: typeof selectedMonth === 'number',
        }}
        disabled={typeof selectedMonth !== 'number'}
        render={({field}) => (
          <Select
            error={
              typeof selectedMonth === 'number' &&
              typeof field.value !== 'number'
            }
            variant="outlined"
            displayEmpty
            id="day"
            sx={{
              width: '100%',
              '&.Mui-focused': {
                fieldset: {
                  border: `1px solid ${
                    typeof selectedMonth === 'number' &&
                    typeof field.value !== 'number'
                      ? theme.palette.error.main
                      : theme.palette.grey['700']
                  } !important`,
                },
              },
            }}
            MenuProps={{
              sx: customSelect,
            }}
            {...field}>
            {dayOptions?.map(option => {
              return (
                <MenuItem
                  key={option.label}
                  value={option.value}
                  sx={selectList(option.value)}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </Stack>
  );
};

export default DropdownPicker;
