import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Menu, MenuItem} from '@mui/material';

import LuxCard from '@/atoms/LuxCard';
import {ActionOption} from '@/atoms/LuxCard/types';
import {useStyle} from '@/molecules/CampaignCard/hook';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {TASK_VISIBILITY, TASK_STATUS} from '@/store/campaigns';

import {CampaignCardProps} from './interfaces';

const CampaignCard = ({campaign}: CampaignCardProps) => {
  const {t} = useTranslation();
  const {goToCampaignsReportDetail, goToPath} = useNavigation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {menu} = useStyle();

  const toggleMenu = useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    setAnchorEl(e?.currentTarget ?? null);
  }, []);

  const actionsOptions = useMemo(
    (): ActionOption[] => [
      {
        label: t('Campaign.card.menu.details'),
        action: () =>
          goToPath(
            AuthRoutes.CLIENTELING_TASK_LIST,
            `?visibility=${TASK_VISIBILITY.ALL}&status=${TASK_STATUS.NOTASSIGNED}&code=${campaign?.code}`,
          ),
      },
      {
        label: t('Campaign.card.menu.report'),
        action: () => goToCampaignsReportDetail(campaign.code),
      },
    ],
    [campaign.code, goToCampaignsReportDetail, goToPath, t],
  );

  return (
    <>
      <LuxCard
        disabled={!campaign?.active}
        onClick={() => goToCampaignsReportDetail(campaign.code)}
        startDate={campaign?.startDate}
        endDate={campaign?.endDate}
        campaignName={campaign?.name}
        campaignColor={campaign?.color}
        progressData={Math.round(
          campaign?.completedTask / (campaign?.taskNumber / 100),
        )}
        actionCallback={toggleMenu}
        numericList={[
          {
            label: t('Campaign.report.taskCompleted'),
            value: `${campaign?.completedTask ?? 0}/${
              campaign?.taskNumber ?? 0
            }`,
          },
          {
            label: t('Campaign.report.contacted'),
            value: campaign?.statusContacted ?? 0,
          },
          {
            label: t('Campaign.report.positive'),
            value: campaign?.positiveContacted ?? 0,
          },
          {
            label: t('Campaign.report.appointments'),
            value: campaign?.appointmentBooked ?? 0,
          },
        ]}
      />
      {anchorEl && (
        <Menu
          sx={menu}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => toggleMenu()}>
          {actionsOptions.map(({label, action}) => (
            <MenuItem key={label} divider disableTouchRipple onClick={action}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default React.memo(CampaignCard);
