import React, {useState, useMemo, useCallback, useEffect, useRef} from 'react';
import ReactGA from 'react-ga4';

import {Box, Skeleton} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {SelectOptions} from '@/atoms/Select/interfaces';
import {RoleName} from '@/definitions/users';
import {EmptySearchResult} from '@/molecules/EmptySearchResult';
import {HomepageFormbar} from '@/molecules/HomepageFormbar';
import {HomepageFormBarProps} from '@/molecules/HomepageFormbar/interfaces';
import {SearchResult} from '@/organisms/SearchResult';
import {useStyle} from '@/pages/Loyalty/styles';
import {useAuthentication} from '@/store/authentication';
import {useBrands} from '@/store/brands';
import {useCountries} from '@/store/countries';
import {useCustomerInfo} from '@/store/customersInfo';
import {PostCustomerInfoBody} from '@/store/customersInfo/interfaces';
import {Base} from '@/templates/Base';

const Loyalty = () => {
  const styles = useStyle();
  const {userData, checkRoles, userStoreId} = useAuthentication();
  const currentStoreId = useRef<number | undefined>(userStoreId);

  /* Query to get user data  */
  const {postCustomerInfo} = useCustomerInfo();
  const {getBrands} = useBrands();
  const {getCountries} = useCountries();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Loyalty',
    });
  }, []);

  const hasDefaultBrandCountry = useMemo(() => {
    return checkRoles([
      RoleName.STORE_ASSOCIATE,
      RoleName.STORE_MANAGER,
      RoleName.AREA_MANAGER,
    ]);
  }, [checkRoles]);

  const storeChanged = useMemo(() => {
    return currentStoreId.current !== userStoreId;
  }, [userStoreId]);

  const [localState, setLocalState] = useState<
    Pick<HomepageFormBarProps, 'freeSearch' | 'brand' | 'country'>
  >(() => {
    const sessionData = sessionStorage.getItem('loyaltyPayload');

    if (sessionData) {
      return JSON.parse(sessionData);
    }

    let state = {
      freeSearch: '',
      brand: '',
      country: '',
    };

    if (hasDefaultBrandCountry) {
      state = {
        ...state,
        brand: userData?.banner!,
        country: userData?.country!,
      };
    }

    return state;
  });

  useEffect(() => {
    if (hasDefaultBrandCountry) {
      setLocalState(prevState => ({
        freeSearch: '',
        brand: userData?.banner!,
        country: userData?.country!,
      }));
    }
  }, [hasDefaultBrandCountry, userData?.banner, userData?.country]);

  const [payload, setPayload] = useState<
    Partial<PostCustomerInfoBody> | undefined
  >(() => {
    const sessionData = sessionStorage.getItem('loyaltyPayload');
    if (!sessionData) return undefined;
    const parsedData = JSON.parse(sessionData);
    return {
      q: parsedData?.freeSearch,
      countrySearch: parsedData?.country,
      bannerSearch: parsedData?.brand,
    };
  });

  useEffect(() => {
    if (storeChanged) {
      currentStoreId.current = userStoreId;
      sessionStorage.removeItem('loyaltyPayload');
      setLocalState(() => {
        let state = {
          freeSearch: '',
          brand: '',
          country: '',
        };

        if (hasDefaultBrandCountry) {
          state = {
            ...state,
            brand: userData?.banner!,
            country: userData?.country!,
          };
        }
        return state;
      });
      setPayload(() => undefined);
    }
  }, [
    hasDefaultBrandCountry,
    storeChanged,
    userData?.banner,
    userData?.country,
    userStoreId,
  ]);

  const {
    data: CustomersInfo,
    isFetching: CustomersInfoFetching,
    isFetched: CustomersInfoFetched,
  } = useQuery(
    ['customers', payload, userStoreId],
    () => postCustomerInfo(payload!),
    {
      retry: false,
      enabled: Boolean(payload),
      networkMode: 'online',
      refetchOnWindowFocus: false,
    },
  );

  const {data: BrandsData, isLoading: BrandsLoading} = useQuery({
    queryKey: ['user_brands'],
    queryFn: () => getBrands(),
    onSuccess: data => {
      if (data?.length === 1 && !payload) {
        handleValueChange('brand', data[0].brandId);
      }
    },
    enabled: !hasDefaultBrandCountry,
    refetchOnWindowFocus: false,
  });

  const brandOptions = useMemo<SelectOptions>(() => {
    if (hasDefaultBrandCountry) {
      return [
        {
          label: localState?.brand,
          value: localState?.brand,
        },
      ];
    }

    if (!BrandsData || BrandsLoading) {
      return [];
    }

    return BrandsData.map(brand => ({
      label: brand?.brandId,
      value: brand?.brandId,
    }));
  }, [BrandsData, BrandsLoading, hasDefaultBrandCountry, localState?.brand]);

  const {data: CountriesData, isLoading: CountriesLoading} = useQuery({
    queryKey: ['user_countries', localState?.brand],
    queryFn: () => getCountries({banner: localState?.brand}),
    onSuccess: data => {
      if (data?.length === 1 && !payload) {
        handleValueChange('country', data[0].code);
      }
    },
    enabled: !hasDefaultBrandCountry && Boolean(localState?.brand),
    refetchOnWindowFocus: false,
  });

  const countryOptions = useMemo<SelectOptions>(() => {
    if (hasDefaultBrandCountry) {
      return [
        {
          label: localState?.country,
          value: localState?.country,
        },
      ];
    }

    if (!CountriesData || CountriesLoading) {
      return [];
    }

    return CountriesData.map(country => ({
      label: country?.code,
      value: country?.code,
    }));
  }, [
    CountriesData,
    CountriesLoading,
    hasDefaultBrandCountry,
    localState?.country,
  ]);

  useEffect(() => {
    sessionStorage.removeItem('loyaltyPayload');
  }, []);

  const submitSearch = useCallback(() => {
    setPayload({
      q: localState?.freeSearch,
      bannerSearch: localState?.brand,
      countrySearch: localState?.country,
    });
  }, [localState]);

  const updateSessionStorage = useCallback(() => {
    sessionStorage.setItem('loyaltyPayload', JSON.stringify(localState));
  }, [localState]);

  const handleValueChange = useCallback(
    (
      key: keyof Pick<HomepageFormBarProps, 'freeSearch' | 'brand' | 'country'>,
      val: string,
    ) => {
      setLocalState(prevState => ({
        ...prevState,
        [key]: val,
        ...(key === 'brand' && {country: ''}),
      }));
    },
    [],
  );

  const homeFormbarProps: HomepageFormBarProps = useMemo(() => {
    return {
      freeSearch: localState?.freeSearch,
      onFreeSearchChange: newValue => handleValueChange('freeSearch', newValue),
      brand: localState?.brand,
      brandOptions,
      onBrandChange: newValue => handleValueChange('brand', newValue),
      country: localState?.country,
      countryOptions,
      onCountryChange: newValue => handleValueChange('country', newValue),
      onSubmit: submitSearch,
      disableSelect: hasDefaultBrandCountry,
    };
  }, [
    brandOptions,
    countryOptions,
    handleValueChange,
    localState?.brand,
    localState?.country,
    localState?.freeSearch,
    submitSearch,
    hasDefaultBrandCountry,
  ]);

  const renderData = useMemo(() => {
    if (CustomersInfoFetching) {
      return (
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{
            width: '100%',
            height: '90%',
            maxWidth: {
              xs: '705px',
              md: '930px',
            },
          }}
        />
      );
    }

    if (CustomersInfoFetched && !CustomersInfo) {
      return <EmptySearchResult />;
    }

    if (CustomersInfo) {
      return (
        <SearchResult {...CustomersInfo} callback={updateSessionStorage} />
      );
    }

    return null;
  }, [
    CustomersInfo,
    CustomersInfoFetched,
    CustomersInfoFetching,
    updateSessionStorage,
  ]);

  return (
    <Base extendedNavbar>
      <Box sx={styles.searchBar}>
        <HomepageFormbar {...homeFormbarProps} />
      </Box>
      <Box sx={styles.resultsContainer}>
        <Box sx={styles.results}>{renderData}</Box>
      </Box>
    </Base>
  );
};

export default React.memo(Loyalty);
