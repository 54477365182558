import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

import {IDialogProps} from '@/atoms/Dialog/interfaces';

export const useStyle = (fullWidth: IDialogProps['fullWidth']) => {
  const theme = useTheme();

  const dialog: SxProps<Theme> = useMemo(
    () => ({
      // OVERLAY
      '.MuiBackdrop-root': {
        backgroundColor: theme.palette.dialog.main,
      },
      '.MuiDialog-paper': {
        borderRadius: '0px',
        p: theme.spacing(6),
        maxWidth: fullWidth ? 'calc(100vw - 64px)' : theme.spacing(60),
        width: fullWidth ? 'auto' : theme.spacing(70),
      },
    }),
    [theme, fullWidth],
  );

  const dialogActions: SxProps<Theme> = useMemo(
    () => ({
      justifyContent: 'center',
      //gap: theme.spacing(30),
    }),
    [],
  );

  const dialogTitle: SxProps<Theme> = useMemo(
    () => ({
      textAlign: 'center',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    }),
    [],
  );

  const dialogContent: SxProps<Theme> = useMemo(
    () => ({
      padding: 0,
      marginBottom: theme.spacing(2),
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowX: 'hidden',
    }),
    [theme],
  );

  const closeIcon: SxProps<Theme> = useMemo(
    () => ({
      position: 'absolute',
      right: theme.spacing(1.6),
      top: theme.spacing(1.6),
      cursor: 'pointer',
    }),
    [theme],
  );

  return {dialog, dialogActions, dialogTitle, dialogContent, closeIcon};
};
