import {
  AuthenticationAction,
  AuthenticationActions,
  AuthenticationState,
} from './types.d';

export const getInitialState = (): AuthenticationState => ({
  isFetched: false,
  isFetching: false,
  user: undefined,
});

export const authenticationReducer = (
  state = getInitialState(),
  action: AuthenticationAction,
) => {
  switch (action.type) {
    case AuthenticationActions.loginLoading:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case AuthenticationActions.loginSuccess:
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        error: undefined,
        user: action.payload,
      };
    case AuthenticationActions.loginFail:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case AuthenticationActions.logout:
      return getInitialState();
    default:
      return state;
  }
};
