import React from 'react';

import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import {IconSVG} from '../IconSVG';
import {Text} from '../Typography/Text';
import {IDialogProps} from './interfaces';
import {useStyle} from './styles';

const Dialog = ({
  open,
  dialogTitle,
  dialogSubTitle,
  titleUppercase = true,
  dialogContent,
  dialogActions,
  closeIcon,
  onClose,
  sx,
  fullWidth = false,
}: IDialogProps) => {
  const styles = useStyle(fullWidth);

  return (
    <MuiDialog sx={[styles.dialog, sx]} open={open} onClose={onClose}>
      {closeIcon && (
        <Box
          sx={styles.closeIcon}
          className="closeIconDialog"
          onClick={onClose}>
          <IconSVG icon={closeIcon} size={16} />
        </Box>
      )}
      {dialogTitle && (
        <DialogTitle sx={styles.dialogTitle}>
          <Text variant="headline4" heavy uppercase={titleUppercase}>
            {dialogTitle}
          </Text>
          {dialogSubTitle && <Text variant="tiny">{dialogSubTitle}</Text>}
        </DialogTitle>
      )}
      <DialogContent sx={styles.dialogContent}>{dialogContent}</DialogContent>
      {dialogActions && (
        <DialogActions sx={styles.dialogActions}>{dialogActions}</DialogActions>
      )}
    </MuiDialog>
  );
};

export default React.memo(Dialog);
