import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const container: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      cursor: 'pointer',
    }),
    [],
  );

  const iconContainer: SxProps<Theme> = useMemo(
    () => ({
      transform: 'rotate(0deg)',
      transition: 'all 0.2s linear',
    }),
    [],
  );

  const iconContainerRotate: SxProps<Theme> = useMemo(
    () => ({
      transform: 'rotate(180deg)',
      transition: 'all 0.2s linear',
    }),
    [],
  );

  const menu = useMemo(
    () => ({
      '& .MuiPaper-root': {
        boxShadow: '0px 7px 20px rgba(78, 76, 76, 0.05)',
        border: `1px solid ${theme.palette.primary[200]}`,
        borderRadius: theme.spacing(1.2),

        '& .MuiMenuItem-divider': {
          backgroundColor: 'transparent',
          ':last-child': {
            border: 'none',
          },
        },
      },
    }),
    [theme],
  );

  return {
    container,
    iconContainer,
    iconContainerRotate,
    menu,
  };
};
