import {Dayjs} from 'dayjs';

import {
  ReportDetailsFiltersAction,
  ReportDetailFilters,
  ReportDetailsFiltersActions,
} from '@/store/reportDetailsFilters/definitions';

export const ReportDetailsFiltersChangeField = (
  campaignId: string,
  key: keyof ReportDetailFilters,
  value: any,
): ReportDetailsFiltersAction => ({
  type: ReportDetailsFiltersActions.changeField,
  payload: {
    campaignId,
    data: {
      key,
      value,
    },
  },
});

export const ReportDetailsFiltersChangeDates = (
  campaignId: string,
  startDate: Dayjs,
  endDate: Dayjs,
): ReportDetailsFiltersAction => ({
  type: ReportDetailsFiltersActions.changeDates,
  payload: {
    campaignId,
    data: {
      startDate,
      endDate,
    },
  },
});

export const ReportDetailsFiltersChangePages = (
  campaignId: string,
  page: number,
): ReportDetailsFiltersAction => ({
  type: ReportDetailsFiltersActions.changePage,
  payload: {
    campaignId,
    data: page,
  },
});

export const ReportDetailsFiltersInitialize = (
  campaignId: string,
): ReportDetailsFiltersAction => ({
  type: ReportDetailsFiltersActions.initializeFilters,
  payload: {
    campaignId,
  },
});

export const ReportDetailsFiltersReset = (): ReportDetailsFiltersAction => ({
  type: ReportDetailsFiltersActions.resetFilters,
  payload: undefined,
});
