import React from 'react';

import {Box, Divider} from '@mui/material';

import {IconSVG} from '../IconSVG';
import {Text} from '../Typography/Text';
import {IProps} from './interfaces';
import {useStyle} from './style';

const NavItem = ({id, label, active, icon, onClick}: IProps) => {
  const {navItem, navItem__active} = useStyle();

  return (
    <Box id={id} sx={active ? navItem__active : navItem} onClick={onClick}>
      {/* @TODO: Border Top of active NavItem */ active && <Divider />}
      <Text variant="headline5">{label}</Text>
      {icon && <IconSVG icon={icon} size={10} />}
    </Box>
  );
};

export default React.memo(NavItem);
