import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyle = () => {
  const theme = useTheme();

  const card: SxProps<Theme> = useMemo(
    () => ({
      overflow: 'unset',
      borderRadius: theme.spacing(1.2),
      boxShadow: 'none',
      paddingX: theme.spacing(1.6),
      paddingY: theme.spacing(1.2),
      [theme.breakpoints.down('lg')]: {
        paddingY: theme.spacing(1.6),
      },
    }),
    [theme],
  );

  const cardsGrid: SxProps<Theme> = useMemo(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'repeat(10, 1fr)',
      gap: theme.spacing(3.2),
      'div:nth-of-type(7)': {
        position: 'relative',
        borderRight: `${theme.spacing(0.1)} solid ${Colors.GreyLight}`,
        paddingRight: theme.spacing(1.6),
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
        // Display a separator line using border bottom of first 5 elements
        gap: theme.spacing(0),
        'div:nth-of-type(-n + 5)': {
          position: 'relative',
          borderBottom: `${theme.spacing(0.1)} solid ${Colors.GreyLight}`,
          paddingBottom: theme.spacing(1.6),
          marginBottom: theme.spacing(1.6),
        },
        'div:nth-of-type(7)': {
          marginRight: theme.spacing(1.6),
        },
      },
    }),
    [theme],
  );

  return {card, cardsGrid};
};
