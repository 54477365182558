import {useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {downloadFile} from '@/lib/utils';

import {useAPI} from '../api';
import {APIS, useApiPath} from '../api/useApiPath';
import {ExportMissionTableBody, GetMissionSelectionsParams, GetMissionSelectionsResponse, MissionTableBody, MissionTableResponse} from './interfaces';

/**
 * @brief Hook to retrieve benefits
 */
export const useMission = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  /**
   * @brief Retrieve all the benefits of a specific card-number
   */
  const getMissionList = useCallback(
    async (body: MissionTableBody) => {
      try {
        const {data} = await api.post<MissionTableResponse>(
          getApiPath(APIS.LOYALTY_MISSION_TABLE),
          {...body},
        );

        return data;
      } catch (error) {
        console.error('Benefit list', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  /**
   * @brief Export points history
   */
  const exportMission = useCallback(
    async ({...body}: ExportMissionTableBody) => {
      try {
        const {data, headers} = await api.post(
          getApiPath(APIS.LOYALTY_EXPORT_MISSION),
          {
            ...body,
          },
          {
            headers: {
              Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
          },
        );

        downloadFile(data, headers);

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getMissions = useCallback(
    async ({...params}: GetMissionSelectionsParams) => {
      try {
        const {data} = await api.get<GetMissionSelectionsResponse[]>(
          getApiPath(APIS.LOYALTY_GET_MISSION),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getStatus = useCallback(
    async ({...params}: GetMissionSelectionsParams) => {
      try {
        const {data} = await api.get<GetMissionSelectionsResponse[]>(
          getApiPath(APIS.LOYALTY_GET_MISSION_STATUS),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return {getMissionList, exportMission, getMissions, getStatus};
};
