import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, LinearProgress, Stack, useTheme} from '@mui/material';

import {Badge} from '@/atoms/Badge';
import {Card} from '@/atoms/Card';
import {IconSVG} from '@/atoms/IconSVG';
import {LuxCardProps} from '@/atoms/LuxCard/types';
import {Text} from '@/atoms/Typography/Text';

import {useStyles} from './styles';

const LuxCard = ({
  onClick,
  campaignColor,
  campaignName,
  cardInfo,
  progressData,
  headerIcon = 'card_actions',
  actionCallback,
  numericList,
  disabled = false,
}: LuxCardProps) => {
  const {progressBar, labelStyle} = useStyles(Boolean(onClick));
  const theme = useTheme();
  const {t} = useTranslation();

  const handleValueSlash = useCallback(
    (value: string) => {
      if (value.includes('/')) {
        const [completed, all] = value.split('/');

        return (
          <Text>
            <Text
              superHeavy
              variant="headline"
              lineHeight={theme.typography.headline.fontSize}>
              {completed}
            </Text>
            <Text
              variant="body"
              lineHeight={theme.typography.body.fontSize}>{`/${all}`}</Text>
          </Text>
        );
      } else {
        return (
          <Text
            superHeavy
            variant="headline"
            lineHeight={theme.typography.headline.fontSize}>
            {value}
          </Text>
        );
      }
    },
    [theme.typography.body.fontSize, theme.typography.headline.fontSize],
  );

  return (
    <Card disabled={disabled} {...(onClick && !disabled && {onClick: onClick})}>
      <Stack
        direction="column"
        gap={0.8}
        height="100%"
        justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={0.8}>
          <Badge
            variant="tiny"
            color={campaignColor}
            {...(campaignName && {badgeLabel: campaignName})}
          />
          {actionCallback && (
            <Box sx={{cursor: disabled ? 'default' : 'pointer'}}>
              <IconSVG
                icon={headerIcon}
                size={24}
                {...(!disabled && {onClick: actionCallback})}
              />
            </Box>
          )}
        </Stack>
        <Stack direction="column" spacing={2.4}>
          {cardInfo &&
            cardInfo.map(({label, value}) => (
              <Box key={label}>
                <Text variant="xs" superHeavy>
                  {`${label}: `}
                </Text>
                <Text variant="xs">{value}</Text>
              </Box>
            ))}
          {numericList && (
            <Box display="flex" paddingTop={1.6} columnGap={0.9} justifyContent="space-between">
              {numericList.map(({label, value}) => (
                <Box key={label} display="flex" flexDirection="column">
                  {handleValueSlash(String(value))}
                  <Text sx={labelStyle} variant="xs">
                    {label}
                  </Text>
                </Box>
              ))}
            </Box>
          )}
          {typeof progressData !== 'undefined' && (
            <Stack display="flex" direction="row" alignItems="center" gap={0.8}>
              <Stack
                display="flex"
                direction="row"
                alignItems="center"
                gap={0.4}>
                <Text
                  variant="tiny"
                  lineHeight={theme.typography.tiny.fontSize}
                  color={theme.palette.grey[700]}>
                  {t('Campaign.card.completed')}
                </Text>
                <Text
                  variant="tiny"
                  lineHeight={theme.typography.tiny.fontSize}
                  color={theme.palette.common.black}>
                  {progressData}%
                </Text>
              </Stack>
              <LinearProgress
                title="prova"
                sx={progressBar}
                variant="determinate"
                value={progressData}
                color="inherit"
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default LuxCard;
