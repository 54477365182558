import React from 'react';

import {Backdrop, CircularProgress} from '@mui/material';

const LoadingPage = ({invisible = true}: {invisible?: boolean}) => {
  return (
    <Backdrop invisible={invisible} open sx={{zIndex: 999}}>
      <CircularProgress disableShrink color="inherit" />
    </Backdrop>
  );
};

export default React.memo(LoadingPage);
