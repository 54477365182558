import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const emptyAppointmentContainer = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: Colors.GreyBackground,
      borderRadius: theme.spacing(1.2),
      border: `${theme.spacing(0.1)} solid ${Colors.Black}`,
      padding: theme.spacing(1.6),
      flexDirection: 'column',
      gap: theme.spacing(2.4),
    }),
    [theme],
  );

  const tabLite = useMemo<SxProps<Theme>>(
    () => ({
      width: 'calc(100vw - 112px)',
      height: 'calc(100dvh - 128px - env(safe-area-inset-bottom))',
      '@-moz-document url-prefix()': {
        height: 'calc(100vh - 128px - env(safe-area-inset-bottom))',
      },
      position: 'relative',
      '.scheduler-widget': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        '.page-content': {
          minHeight: `calc(100dvh - 188px - env(safe-area-inset-bottom))`,
          '@-moz-document url-prefix()': {
            minHeight: `calc(100vh - 188px - env(safe-area-inset-bottom))`,
          },
        },
        '.confirmation-page': {
          maxHeight: `calc(100dvh - 188px - env(safe-area-inset-bottom))`,
          '@-moz-document url-prefix()': {
            maxHeight: `calc(100vh - 188px - env(safe-area-inset-bottom))`,
          },
        },
      },
    }),
    [],
  );

  return useMemo(
    () => ({emptyAppointmentContainer, tabLite}),
    [emptyAppointmentContainer, tabLite],
  );
};
