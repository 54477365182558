import {useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {useApiPath, APIS} from '@/store/api/useApiPath';

import {useAPI} from '../api';
import {
  GetStoresParams,
  GetStoresResponse,
  PatchStoreByStoreIdResponse,
} from './interfaces';

/**
 * @brief Hook to post/get stores relative api
 */
export const useStores = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getStores = useCallback(
    async ({...params}: GetStoresParams) => {
      try {
        const {data} = await api.get<GetStoresResponse>(
          getApiPath(APIS.STORES),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const patchStoreByStoreId = useCallback(
    async (storeId?: string | number) => {
      try {
        const {data} = await api.patch<PatchStoreByStoreIdResponse>(
          getApiPath(APIS.STORES_CURRENT_STOREID, {storeId}),
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return {
    getStores,
    patchStoreByStoreId,
  };
};
