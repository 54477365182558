import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Stack, useMediaQuery} from '@mui/material';

import {BodyText} from '@/atoms/BodyText';
import {Headline5} from '@/atoms/Headline5';
import {KeyValuePair} from '@/molecules/KeyValuePair';
import theme from '@/themes/theme';

import {CardProps, ICardTag} from './interfaces';
import {useStyle} from './styles';

export const Card = React.memo(({title, actions, children}: CardProps) => {
  const styles = useStyle();

  return <Box sx={styles.container}>{children}</Box>;
});

Card.displayName = 'Card';

export const CardTitle = React.memo(({title}: {title: string}) => {
  return (
    <Headline5 heavy uppercase>
      {title}
    </Headline5>
  );
});

CardTitle.displayName = 'CardTitle';

export const CardTag = React.memo(
  ({communications, variant = 'communications', value}: ICardTag) => {
    const {t} = useTranslation();
    const styles = useStyle(communications);

    return (
      <Box sx={styles.tag}>
        {variant === 'communications' ? (
          <KeyValuePair
            keyLabel={t('CustomerInfo.communications.title')}
            value={t(
              `CustomerInfo.communications.values.${
                communications ? 'ON' : 'OFF'
              }`,
            )}
          />
        ) : (
          <BodyText superHeavy>{value}</BodyText>
        )}
      </Box>
    );
  },
);

CardTag.displayName = 'CardTag';

export const CardHeader = React.memo(
  ({title, action}: {title: string | JSX.Element; action?: JSX.Element}) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const titleIsString = useMemo(() => typeof title === 'string', [title]);
    const alignment = useMemo<string>(() => {
      let value = 'center';

      if (!isDesktop && !titleIsString) {
        value = 'start';
      }

      return value;
    }, [isDesktop, titleIsString]);

    return (
      <Stack
        display="grid"
        direction="row"
        gridTemplateColumns="1fr"
        gridAutoFlow="column"
        gridAutoColumns="max-content"
        gap={3.2}
        alignItems={alignment}>
        {titleIsString ? (
          <CardTitle title={title as string} />
        ) : (
          <Box paddingTop={alignment === 'start' ? 1 : 0}>{title}</Box>
        )}
        {action && action}
      </Stack>
    );
  },
);

CardHeader.displayName = 'CardHeader';
