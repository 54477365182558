import React, {useMemo} from 'react';

import {Box, useTheme} from '@mui/material';

const ExpirationDot = ({remainingDays}: {remainingDays: number}) => {
  const theme = useTheme();

  const color = useMemo(() => {
    return remainingDays < 0
      ? theme.palette.error.main
      : theme.palette.warning.dark;
  }, [remainingDays, theme.palette.error.main, theme.palette.warning.dark]);

  return (
    <Box
      width={16}
      height={16}
      borderRadius="100%"
      border={2}
      borderColor={theme.palette.common.black}
      bgcolor={color}
    />
  );
};

export default React.memo(ExpirationDot);
