import {faker} from '@faker-js/faker';

import {CustomerResponse} from '@/store/personas';

const createRandomCustomer = (): CustomerResponse => {
  return {
    customerId: faker.string.alpha(12),
    channel: faker.helpers.arrayElement(['STORE', 'ONLINE']),
    email: faker.helpers.arrayElement([faker.internet.email(), null]),
    firstName: faker.helpers.arrayElement([faker.name.firstName(), null]),
    lastName: faker.helpers.arrayElement([faker.name.lastName(), null]),
    emailGrant: faker.helpers.arrayElement([null, true, false]),
    myAccountActive: faker.helpers.arrayElement([null, true, false]),
    myAccountQRCode: faker.helpers.arrayElement([
      faker.image.dataUri({width: 200, height: 200}),
      null,
    ]),
    mobile: faker.helpers.arrayElement([faker.phone.number(), null]),
    mobileGrant: faker.helpers.arrayElement([null, true, false]),
    address: faker.helpers.arrayElement([
      {
        address: faker.address.streetAddress(),
        zipCode: faker.address.zipCode(),
        city: faker.address.city(),
        state: faker.address.state(),
      },
      null,
    ]),
    addressGrant: faker.helpers.arrayElement([null, true, false]),
    taxCode: faker.helpers.arrayElement([faker.random.alphaNumeric(10), null]),
    birthDate: faker.helpers.arrayElement([
      faker.date.past().toISOString(),
      null,
    ]),
  };
};

const createRandomCustomersResponse = (): Array<CustomerResponse> => {
  const randomCustomers = Array.from({length: 10}, () =>
    createRandomCustomer(),
  );
  return randomCustomers;
};

export const randomCustomersResponse = createRandomCustomersResponse();
