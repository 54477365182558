import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {GetYourTeamParams, GetYourTeamResponse} from '@/store/members/types';

export const useMembers = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getYourTeam = useCallback(
    async ({storeId, ...params}: GetYourTeamParams) => {
      try {
        const {data} = await api.get<GetYourTeamResponse>(
          getApiPath(APIS.STORES_STOREID_YOURTEAM, {storeId}),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getYourTeam,
    }),
    [getYourTeam],
  );
};
