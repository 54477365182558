import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {Modules} from '@/definitions/users';
import {moduleToRoute} from '@/navigation/routes';

const useCurrentModule = () => {
  const location = useLocation();

  const currentModule = useMemo(() => {
    return Object.values(Modules).find(
      key => location?.pathname.indexOf(moduleToRoute[key]) !== -1,
    );
  }, [location?.pathname]);

  return {
    currentModule,
  };
};

export default useCurrentModule;
