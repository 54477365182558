import {AuthenticationState} from './types';

export const selectorIsLoggedIn = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => Boolean(authentication.user);

export const selectorRoles = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication?.user?.roles;

export const selectorUser = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication?.user;

export const selectorNamToken = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication?.user?.namToken;

export const selectorError = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.error;

export const selectorIsFetching = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication.isFetching;

export const selectorModules = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication?.user?.configuration?.moduleActive;

export const selectorBanner = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication?.user?.banner;

export const selectorStoreId = ({
  authentication,
}: {
  authentication: AuthenticationState;
}) => authentication?.user?.currentStore?.id;
