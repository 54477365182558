import {useMemo, useCallback} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

import {AppointmentCardProps} from '@/molecules/AppointmentCard/interfaces';
import {Colors} from '@/themes/variables';

export const useStyles = (selectable: AppointmentCardProps['onSelect']) => {
  const theme = useTheme();

  const card = useCallback(
    (selected?: boolean): SxProps<Theme> => ({
      padding: theme.spacing(1.2, 1.6),
      borderRadius: theme.spacing(1.2),
      backgroundColor: Colors.GreyBackground,
      ...(selectable
        ? {
            cursor: 'pointer',
            transition: 'all 0.2s linear',
            border: `${theme.spacing(0.1)} solid transparent`,
            ':hover': {
              border: `${theme.spacing(0.1)} solid ${Colors.Black}`,
            },
          }
        : {
            border: `${theme.spacing(0.1)} solid ${Colors.Black}`,
          }),
      ...(typeof selected !== 'undefined' &&
        selected && {
          border: `${theme.spacing(0.1)} solid ${Colors.Black}`,
        }),
    }),
    [selectable, theme],
  );

  const clickable: SxProps<Theme> = useMemo(
    () => ({
      cursor: 'pointer',
      ':hover': {opacity: 0.7},
    }),
    [],
  );

  const date: SxProps<Theme> = useMemo(
    () => ({
      textTransform: 'capitalize',
    }),
    [],
  );

  const ctaContainer: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1.6),
    }),
    [theme],
  );

  const cta: SxProps<Theme> = useMemo(
    () => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(0.8, 0),
      borderRadius: theme.spacing(1.2),
      cursor: 'pointer',
      transition: 'all 0.2s linear',
      backgroundColor: 'inherit',
      ':hover': {
        backgroundColor: theme.palette.grey[400],
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      card,
      clickable,
      date,
      ctaContainer,
      cta,
    }),
    [card, clickable, date, ctaContainer, cta],
  );
};
