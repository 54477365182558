import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const chip = useMemo<SxProps<Theme>>(
    () => ({
      width: 'fit-content',
      height: theme.spacing(3.2),
      padding: theme.spacing(0.4, 1.2),
      '.MuiChip-label': {
        padding: theme.spacing(0),
        '.chipLabel': {
          display: 'grid',
          gridTemplateColumns: '1fr max-content',
          alignItems: 'center',
          gap: theme.spacing(0.8),
          transition: 'all 0.2s linear',
          cursor: 'pointer',
          '.icon': {
            transform: 'rotate(0deg)',
          },
          '&__open': {
            '.icon': {
              transform: 'rotate(180deg)',
            },
          },
        },
      },
    }),
    [theme],
  );

  const menu = useMemo<SxProps<Theme>>(
    () => ({
      '.MuiList-root': {
        padding: theme.spacing(0),
        width: theme.spacing(6.2),
        maxHeight: theme.spacing(26.8),
        overflowY: 'auto',
        overflowX: 'hidden',
        border: `${theme.spacing(0.1)} solid ${theme.palette.common.black}`,
        borderRadius: theme.spacing(0.4),
        scrollbarWidth: 'none',
        '.singleNumber': {
          cursor: 'pointer',
          padding: theme.spacing(0.8, 1.2),
          transition: 'all 0.2s linear',
          backgroundColor: Colors.White,
          '&:hover': {
            backgroundColor: Colors.GreyBackground,
          },
          '&__active': {
            cursor: 'default',
            pointerEvents: 'none',
            backgroundColor: Colors.GreyBackground,
          },
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      chip,
      menu,
    }),
    [chip, menu],
  );
};
