import {Box} from '@mui/material';
import React, { useMemo } from 'react';
import {useTranslation} from 'react-i18next';

import {Avatar} from '../Avatar';
import {AvatarProps} from '../Avatar/interfaces';
import {Text} from '../Typography/Text';
import {Colors} from '@/themes/variables';

import {StoreRowProps} from './types';
import {useStyle} from './styles';

const StoreAssociateRow = ({isSelected, onClick, isMe, withId, withPadding, ...member}: StoreRowProps) => {
  const {select, select__active, bullet, bulletActive} = useStyle();

  const {t} = useTranslation();

  /* Compose avatar props based on isMe and isSelected props */
  const avatarProps: AvatarProps = useMemo(() => {
    let props: AvatarProps = {
      variant: 'dark', //isSelected ? 'green' : 'dark',
    };

    if (isMe) {
      props = {
        ...props,
        icon: 'customer_profile', //isSelected ? 'check_circle' : 'customer_profile',
      };

      return props;
    }

    props = {
      ...props,
      variant: 'dark',
      name: member?.firstName,
      surname: member?.lastName,
    };

    return props;
  }, [isMe, member]);

  /* Display member data based on isMe prop  */
  const memberData = useMemo(() => {
    return (
      <>
        <Text
          variant="body"
          medium
          lineHeight="120%"
          textTransform="capitalize">
          {isMe
            ? 'Me'
            : `${member?.firstName?.toLocaleLowerCase()} ${member?.lastName?.toLocaleLowerCase()}`}
        </Text>
        <Text sx={{marginTop: '4px'}} variant="xs" lineHeight="120%" color={Colors.Grey700}>
          {withId && (
            <Text
              variant="xs"
              uppercase
              lineHeight="120%"
              color={Colors.Grey700}>
              {t('MembersModal.memberId', {
                ...member,
              })}
            </Text>
          )}
          {t('MembersModal.memberInfo', {
            ...member,
          })}
        </Text>
      </>
    );
  }, [isMe, member, t, withId]);

  return (
    <Box sx={isSelected ? select__active : select} onClick={onClick}>
      <Box sx={bullet}>
        {isSelected ? <Box sx={bulletActive} /> : <></>}
      </Box>
      <Box sx={{marginRight: '16px'}}>
        <Avatar {...avatarProps} />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        {memberData}
      </Box>
    </Box>
  );
};

export default React.memo(StoreAssociateRow);
