import React, {forwardRef} from 'react';

import {TooltipProps, Tooltip, tooltipClasses} from '@mui/material';
import {styled} from '@mui/material/styles';

const CustomTooltip = (
  {className, children, ...props}: TooltipProps,
  ref: React.Ref<unknown>,
) => (
  <Tooltip {...props} ref={ref} arrow classes={{popper: className}}>
    {children}
  </Tooltip>
);

const BlackTooltip = styled(forwardRef<unknown, TooltipProps>(CustomTooltip))(
  ({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: theme.spacing(20),
      width: theme.spacing(20),
      padding: theme.spacing(1.2),
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: theme.typography.tiny.fontSize,
      lineHeight: 1.2,
      textAlign: 'center',
    },
  }),
);

export default BlackTooltip;
