import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const navItem = useMemo(
    () => ({
      width: 'fit-content',
      cursor: 'pointer',
      paddingY: theme.spacing(1.6),
      paddingX: theme.spacing(2),
    }),
    [theme],
  );

  const navItem__active = useMemo(
    () => ({
      ...navItem,
      backgroundColor: theme.palette.grey[500],
      position: 'relative',
      borderBottom: `4px solid ${theme.palette.common.black}`,

      span: {
        fontWeight: 800,
      },

      '.MuiDivider-root': {
        width: '100%',
        borderBottomWidth: '1px',
        position: 'absolute',
        left: 0,
        top: '-1px',
        borderColor: theme.palette.grey[500],
      },
    }),
    [navItem, theme],
  );

  return {navItem, navItem__active};
};
