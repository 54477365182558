import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {GetBrandsResponse} from '@/store/brands/interfaces';

import {APIS, useApiPath} from '../api/useApiPath';

export const useBrands = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getBrands = useCallback(async () => {
    try {
      const {data} = await api.get<GetBrandsResponse[]>(
        getApiPath(APIS.LOYALTY_BANNERS),
      );

      return data;
    } catch (error) {
      Sentry.captureException(error);
      return undefined;
    }
  }, [api, getApiPath]);

  return useMemo(
    () => ({
      getBrands,
    }),
    [getBrands],
  );
};
