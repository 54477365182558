import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {ColumnDef} from '@tanstack/react-table';

import {Data} from '@/atoms/DataTable/interfaces';
import {
  BaseCell,
  DateCell,
  PointsCell,
  TooltipCell,
} from '@/molecules/TableCell';

import {useStyle} from './styles';

export const useColumnDef = () => {
  const {t} = useTranslation();
  const {baseCell} = useStyle();

  const pointHistoryColumnDef = useMemo<ColumnDef<Data>[]>(
    () => [
      {
        accessorKey: 'eventDescription',
        header: () => t('CustomerProfile.tables.pointsHistory.header.activity'),
        cell: info => (
          <BaseCell
            sx={baseCell}
            title={info.row.original.originalEventType}
            {...info}
          />
        ),
      },
      {
        accessorKey: 'points',
        header: () => t('CustomerProfile.tables.pointsHistory.header.points'),
        cell: info => <PointsCell {...info} />,
      },
      {
        accessorKey: 'eventDate',
        header: () =>
          t('CustomerProfile.tables.pointsHistory.header.activityDates'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'expirationDate',
        header: () =>
          t('CustomerProfile.tables.pointsHistory.header.expirationDate'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'reasonDescription',
        header: () => '',
        cell: info => {
          return Boolean(info?.row?.original?.reason) ? (
            <TooltipCell {...info} />
          ) : null;
        },
      },
    ],
    [baseCell, t],
  );

  return {pointHistoryColumnDef};
};
