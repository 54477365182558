import {PURCHASE_CATEGORIES} from '@/atoms/PurchaseCategory/interfaces';
import {BaseDateParams} from '@/definitions/base';
import {TransactionDataProps} from '@/organisms/TransactionCard/interfaces';
import {Appointment} from '@/store/appointments';
import {GetFiltersParams} from '@/store/campaigns';
import {FeedbackDetails} from '@/store/feedback';
import {GetOrdersQuotesResponse} from '@/store/order';

/* TASK DETAILS */

export type GetSingleTaskParams = Pick<GetFiltersParams, 'storeId'> & {
  taskId: string;
};

export type GetSingleTaskResponse = SingleTaskData;

export type SingleTaskData = {
  errorApi: boolean;
  header: SingleTaskHeader;
  eyeExamInformation: SingleEyeExamInformation;
  personalInformation: SingleTaskPersonalInformation;
  purchaseInformation: SingleTaskPurchaseInformation;
  quoteAndAppointment: GetOrdersQuotesResponse;
  feedbackDetails?: FeedbackDetails[];
  appointmentDetails?: Appointment;
  script?: string;
  editable: boolean;
};

export type SingleTaskHeader = {
  campaignStartDate: string;
  campaignEndDate: string;
  campaignType: string;
  campaignName: string;
  campaignColor: string;
  callTime?: string | null;
  assignee?: SingleTaskAssignee | null;
};

export type SingleTaskAssignee = {
  id: string;
  luxId: string;
  banner?: string;
  country?: string;
  firstName: string;
  lastName: string;
};

export type SingleEyeExamInformation = {
  eyeExamScheduled: string;
  lastExamDate: string;
};

export type SingleTaskPersonalInformation = {
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
  home: string;
  birthDate: string;
  myAccount: boolean;
  address: SingleTaskAddress;
};

export type SingleTaskAddress = {
  address: string;
  zipCode: string;
  city: string;
  state: string;
};

export type SingleTaskPurchaseInformation = {
  customerSegment: string;
  lifeTimeValue: string;
  purchaseCategory: PURCHASE_CATEGORIES[];
  lastPurchase: string;
  lastProductsDetail?: ProductLastPurchase[];
};

/* LAST PURCHASE */

export type GetLastPurchaseParams = GetSingleTaskParams & {
  limit?: number;
};

export type GetLastPurchaseResponse = ProductLastPurchase[];

export type ProductLastPurchase = {
  productKind: string;
  specifyProductKind: string;
  quantity: string;
  productType: string;
  description: string;
  styleName: string;
  brand: string;
  model: string;
  moco: string;
  upc: string;
  gender: string;
  productHierarchy1: string;
  productHierarchy0: string;
  productHierarchy2: string;
  frameShapeLabel: string;
  frameType: string;
  frameFitting: string;
  lensTreatmentLabel: string;
  frameMaterialLabel: string;
  lensColorLabel: string;
  sizeOrdinal: string;
  size: string;
  bridgeSize: string;
  frameShape: string;
  modelCodeDisplay: string;
  frameMaterial: string;
  modelStylename: string;
  frameColorLabel: string;
  faceShape: string;
  clen: string;
  lensMaterial: string;
  lensType: string;
  transitionType: string;
  productId: string;
  dominace: string;
  baseCurve: string;
  diameter: number;
  materialSegFacet: string;
  packageQuantity: string;
  modalityFacet: string;
  geometry: string;
  materialSeg: string;
  photocromic: string;
  brandLine: string;
  uvProtection: string;
  gasPermeability: string;
  waterContent: string;
  productName: string;
  correctionType: string;
  supplier: string;
  sphereSteps: string;
  brandFamily: string;
  addition: string;
  spherePower: string;
  scope: string;
  axis: string;
  cylinder: string;
  index: string;
  image: string;
  lensTreatmentAntiBlue: string;
  lensTreatmentAntiReflective: string;
  transactionDate: string;
};

/* ASSINGED TASK  */

export type GetAssignedTasksParams = Pick<GetFiltersParams, 'storeId'> &
  BaseDateParams;

export type GetAssignedTasksResponse = {
  assignToMe: number;
  notAssigned: number;
  open: number;
};

// Last purchases

export type GetLastPurchasesParams = {
  storeId: string;
  taskId: string;
  banner: string;
  country: string;
};

export type GetLastPurchasesResponse = {
  purchaseInformation: LastPurchasesInformation;
  transactions: Array<TransactionDataProps>;
};

type LastPurchasesInformation = {
  customerSegment: string;
  lifeTimeValue: string;
  purchaseCategory: Array<PURCHASE_CATEGORIES>;
  lastPurchase: string;
};

// Script

export type GetScriptParams = {
  storeId: string;
  taskId: string;
};

export type GetScriptResponse = {
  script: string;
};

// Task header details

export type GetTaskHeaderDetailsParams = {
  taskId: string;
};

export type GetTaskHeaderDetailsResponse = SingleTaskHeader & {
  firstName: string;
  lastName: string;
  banner: string;
  country: string;
  storeId: string;
  email: string;
  customerId: string;
  taskIsEditable: boolean;
  taskStatus: TaskStatus;
  taskCategory: string;
};

export enum TaskStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
}
