import {useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetDashboardCardsResponse,
  GetCampaignTableParams,
  GetCampaignTableResponse,
  GetCampaignDetailParams,
  GetCampaignDetailResponse,
  GetDashboardCardsParams,
  GetFiltersParams,
  GetFiltersResponse,
  GetTasksParams,
  GetTasksResponse,
  TASK_VISIBILITY,
  GetCampaignDetailHeader,
  GetAllCampaignsTableParams,
  GetAllCampaignsTableResponse,
} from '@/store/campaigns/interfaces.d';
import {formatReportHeader} from '@/store/reports/utils';

import {
  GetReportsDetailHeaderParams,
  GetReportsDetailsHeaderResponse,
} from '../reports/interfaces';

export const useCampaigns = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getFilters = useCallback(
    async ({storeId, ...params}: GetFiltersParams) => {
      try {
        const {data} = await api.get<GetFiltersResponse>(
          getApiPath(APIS.STORES_STOREID_CAMPAIGNS, {storeId}),
          {
            params: {
              ...params,
              active: params?.visibility === TASK_VISIBILITY.ASSIGNTOME,
            },
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getTasks = useCallback(
    async ({storeId, ...params}: GetTasksParams) => {
      try {
        const {data} = await api.post<GetTasksResponse>(
          getApiPath(APIS.STORES_STOREID_TASKS, {storeId}),
          {
            ...params,
            active: params?.visibility === TASK_VISIBILITY.ASSIGNTOME,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getDashboardCards = useCallback(
    async ({storeId, ...params}: GetDashboardCardsParams) => {
      try {
        const {data} = await api.get<GetDashboardCardsResponse>(
          getApiPath(APIS.STORES_STOREID_CAMPAIGNS_TASKS_CARDS, {storeId}),
          {
            params: {
              ...params,
            },
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getCampaignDetail = useCallback(
    async ({storeId, campaignCode, ...params}: GetCampaignDetailParams) => {
      try {
        const {data} = await api.get<GetCampaignDetailResponse>(
          getApiPath(APIS.STORES_STOREID_CAMPAIGNS_CAMPAIGNCODE_DETAIL, {
            storeId,
            campaignCode,
          }),
          {
            params: {
              ...params,
            },
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getCampaignDetailHeader = useCallback(
    async ({storeId, campaignCode, ...params}: GetCampaignDetailParams) => {
      try {
        const {data} = await api.get<GetCampaignDetailHeader>(
          getApiPath(APIS.STORES_STOREID_CAMPAIGNS_CAMPAIGNCODE_HEADER, {
            storeId,
            campaignCode,
          }),
          {
            params: {
              ...params,
            },
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getCampaignsTable = useCallback(
    async ({storeId, campaignCode, ...params}: GetCampaignTableParams) => {
      try {
        const {data} = await api.get<GetCampaignTableResponse>(
          getApiPath(APIS.STORES_STOREID_CAMPAIGNS_CAMPAIGNCODE_TASKS, {
            storeId,
            campaignCode,
          }),
          {
            params: {
              ...params,
            },
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getAllCampaignsTable = useCallback(
    async ({storeId, ...params}: GetAllCampaignsTableParams) => {
      try {
        const {data} = await api.post<GetAllCampaignsTableResponse>(
          getApiPath(APIS.STORES_STOREID_TASKS_CAMPAIGNS, {
            storeId,
          }),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getAllCampaignsHeader = useCallback(
    async ({storeId, ...params}: GetReportsDetailHeaderParams) => {
      try {
        const {data} = await api.post<GetReportsDetailsHeaderResponse>(
          getApiPath(APIS.STORES_STOREID_TASKS_CAMPAIGNS_HEADER, {storeId}),
          {
            ...params,
          },
        );

        return formatReportHeader(data);
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return {
    getFilters,
    getTasks,
    getDashboardCards,
    getCampaignsTable,
    getCampaignDetail,
    getCampaignDetailHeader,
    getAllCampaignsTable,
    getAllCampaignsHeader,
  };
};
