import React from 'react';

import {Text} from '@/atoms/Typography/Text';
import {XSTextProps} from '@/atoms/XSText/interfaces';

const XSText = ({children, ...textProps}: XSTextProps) => {
  return (
    <Text variant="xs" lineHeight={1.2} {...textProps}>
      {children}
    </Text>
  );
};

export default XSText;
