import React, {useMemo} from 'react';

import {Box, CircularProgress, useTheme} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Text} from '@/atoms/Typography/Text';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';

import {useStyles} from '../styles';

const NoAppointmentsScreen = ({isLoading}: {isLoading: boolean}) => {
  const {getTranslationWithValue} = useBaseTranslation(
    'FeedbackBox.APPOINTMENT_SETTING',
  );
  const styles = useStyles();
  const theme = useTheme();

  const content = useMemo(() => {
    if (isLoading) return <CircularProgress />;

    return (
      <>
        <IconSVG
          icon={'search_no_results'}
          size={100}
          color={theme.palette.primary[200]}
        />
        <Text variant="headline5">
          {getTranslationWithValue(0, 'modal.notFound')}
        </Text>
      </>
    );
  }, [getTranslationWithValue, isLoading, theme.palette.primary]);

  return <Box sx={styles.contentInitial}>{content}</Box>;
};

export default React.memo(NoAppointmentsScreen);
