import React, {useMemo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {Stack} from '@mui/material';
import {MobileDatePicker} from '@mui/x-date-pickers';
import {useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {InputWrapper} from '@/atoms/InputWrapper';
import {Select} from '@/atoms/Select';
import {SelectOptions} from '@/atoms/Select/interfaces';
import {Text} from '@/atoms/Typography/Text';
import {BaseRouterParams} from '@/definitions/base';
import {useFilterDialogFunctions} from '@/hooks/useFilterDialogFunctions';
import {FilterDialogProps} from '@/hooks/useFilterDialogFunctions/interfaces';
import {useBenefits} from '@/store/benefits';
import {RewardsListFilter} from '@/store/loyaltyFilters/rewardsList.reducer';

import {useStyle} from '../styles';

const FilterRewardForm = ({
  filters,
  formCB,
  validCB,
}: FilterDialogProps<RewardsListFilter>) => {
  const {formData, handleChangeField, handleDate} =
    useFilterDialogFunctions<RewardsListFilter>(filters);
  const {getRewards, getStatus} = useBenefits();
  const {select, selectDropdown} = useStyle();
  const {t} = useTranslation();
  const {banner, country} = useParams<BaseRouterParams>();

  const minDate = useMemo(() => {
    return dayjs().subtract(24, 'month');
  }, []);

  const maxDate = useMemo(() => {
    return dayjs().add(12, 'month');
  }, []);

  const {data: Rewards} = useQuery(
    ['rewards', banner, country],
    () =>
      getRewards({
        banner: banner,
        country: country,
      }),
    {
      retry: false,
    },
  );

  const {data: Status} = useQuery(
    ['status', banner, country],
    () =>
      getStatus({
        banner: banner,
        country: country,
      }),
    {
      retry: false,
    },
  );

  const rewards: SelectOptions = useMemo(() => {
    if (Rewards) {
      return Rewards.map(reward => ({
        value: reward.code,
        label: reward.description,
      }));
    } else {
      return [];
    }
  }, [Rewards]);

  const status: SelectOptions = useMemo(() => {
    if (Status) {
      return Status.map(status => ({
        value: status.code,
        label: t(
          `CustomerProfile.tables.rewardList.statuses.${status.description}`,
          status.description,
        ),
      }));
    } else {
      return [];
    }
  }, [Status, t]);

  const isFormValid = useMemo(() => {
    let valid = false;

    const filtersChanged = JSON.stringify(filters) !== JSON.stringify(formData);
    const hasDates = Boolean(formData?.dateFrom) || Boolean(formData?.dateTo);
    const bothDatesCompiled =
      Boolean(formData?.dateFrom) && Boolean(formData?.dateTo);
    const datesValid = hasDates
      ? dayjs(formData?.dateFrom).isValid() && dayjs(formData?.dateTo).isValid()
      : true;

    if (filtersChanged) {
      if (hasDates) {
        valid = bothDatesCompiled && datesValid;
      } else {
        valid = true;
      }
    }

    return valid;
  }, [filters, formData]);

  useEffect(() => {
    formCB && formCB(formData);
    validCB && validCB(isFormValid);
  }, [formCB, formData, isFormValid, validCB]);

  return (
    <Stack width="100%" gap={5}>
      <Stack flexDirection="row" gap={1.5}>
        <InputWrapper
          label={t(
            'CustomerProfile.dataTable.dialog.filterReward.selectBenefitLabel',
          )}>
          <Select
            sx={select}
            menuStyle={selectDropdown}
            placeholder={t(
              'CustomerProfile.dataTable.dialog.filterReward.selectRewardPlaceholder',
            )}
            value={formData.benefit}
            options={rewards}
            onChange={e => handleChangeField(e as any, 'benefit')}
          />
        </InputWrapper>
        <InputWrapper
          label={t('CustomerProfile.dataTable.dialog.selectStatusLabel')}>
          <Select
            sx={select}
            placeholder={t(
              'CustomerProfile.dataTable.dialog.filterReward.selectRewardPlaceholder',
            )}
            value={formData.status}
            options={status}
            onChange={e => handleChangeField(e as any, 'status')}
          />
        </InputWrapper>
      </Stack>
      <Stack gap={1.5}>
        <Text variant="headline5" heavy uppercase>
          {t('CustomerProfile.dataTable.dialog.datesTitle')}
        </Text>
        <Stack flexDirection="row" gap={1.5}>
          <InputWrapper
            label={t('CustomerProfile.dataTable.dialog.filterPoints.from')}>
            <MobileDatePicker
              value={formData?.dateFrom}
              onAccept={(e: Date | null) => handleDate(e, 'dateFrom')}
              format="MM/DD/YYYY"
              minDate={minDate as unknown as Date}
              maxDate={
                (formData.dateTo
                  ? dayjs(formData.dateTo)
                  : maxDate) as unknown as Date
              }
              slotProps={{
                actionBar: {
                  actions: ['cancel', 'accept', 'clear'],
                },
                toolbar: {hidden: true},
              }}
            />
          </InputWrapper>
          <InputWrapper
            label={t('CustomerProfile.dataTable.dialog.filterPoints.to')}>
            <MobileDatePicker
              value={formData?.dateTo}
              onAccept={(e: Date | null) => handleDate(e, 'dateTo')}
              format="MM/DD/YYYY"
              minDate={
                (formData.dateFrom
                  ? dayjs(formData.dateFrom)
                  : minDate) as unknown as Date
              }
              maxDate={maxDate as unknown as Date}
              slotProps={{
                actionBar: {
                  actions: ['cancel', 'accept', 'clear'],
                },
                toolbar: {hidden: true},
              }}
            />
          </InputWrapper>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default React.memo(FilterRewardForm);
