import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Stack} from '@mui/material';

import {AvatarProps} from '@/atoms/Avatar/interfaces';
import {Colors} from '@/themes/variables';

import {Avatar} from '../../atoms/Avatar';
import {Text} from '../../atoms/Typography/Text';
import {useStyles} from './styles';
import {StoreAssociateProps} from './types';

const StoreAssociate = ({
  isSelected,
  isMe,
  onClick,
  withPadding = false,
  withId = false,
  alignment = 'center',
  ...member
}: StoreAssociateProps) => {
  const {t} = useTranslation();
  const {singleMemberContainer} = useStyles({
    withPadding,
    clickable: Boolean(onClick),
    alignment,
  });

  /* Compose avatar props based on isMe and isSelected props */
  const avatarProps: AvatarProps = useMemo(() => {
    let props: AvatarProps = {
      variant: isSelected ? 'green' : 'dark',
    };

    if (isMe || isSelected) {
      props = {
        ...props,
        icon: isSelected ? 'check_circle' : 'customer_profile',
      };

      return props;
    }

    props = {
      ...props,
      name: member?.firstName,
      surname: member?.lastName,
    };

    return props;
  }, [isMe, member, isSelected]);

  /* Display member data based on isMe prop  */
  const memberData = useMemo(() => {
    return (
      <>
        <Text
          variant="body"
          medium
          lineHeight="120%"
          textTransform="capitalize">
          {isMe
            ? 'Me'
            : `${member?.firstName?.toLocaleLowerCase()} ${member?.lastName?.toLocaleLowerCase()}`}
        </Text>
        <Text variant="xs" lineHeight="120%" color={Colors.GreyDarker}>
          {withId && (
            <Text
              variant="xs"
              uppercase
              lineHeight="120%"
              color={Colors.GreyDarker}>
              {t('MembersModal.memberId', {
                ...member,
              })}
            </Text>
          )}
          {t('MembersModal.memberInfo', {
            ...member,
          })}
        </Text>
      </>
    );
  }, [isMe, member, t, withId]);

  return (
    <Stack sx={singleMemberContainer} {...(onClick && {onClick: onClick})}>
      <Box sx={{width: 'max-content'}}>
        <Avatar {...avatarProps} />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        {memberData}
      </Box>
    </Stack>
  );
};

export default React.memo(StoreAssociate);
