import {useMemo} from 'react';

import {SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const selectIcon: SxProps<Theme> = useMemo(
    () => ({
      mr: '10px',
    }),
    [],
  );

  return {selectIcon};
};
