import React from 'react';
import {useTranslation} from 'react-i18next';

import {Box} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {Text} from '@/atoms/Typography/Text';

import {PageTitleProps} from './interfaces';
import {useStyle} from './styles';

const PageTitle = ({
  enableBack,
  backClick,
  title,
  subtitle,
  mediumTitle = true,
}: PageTitleProps) => {
  const {t} = useTranslation();
  const {titleContainer, titleText} = useStyle(enableBack, subtitle);

  return (
    <Box>
      <Box sx={titleContainer}>
        {enableBack && (
          <Link
            linkVariant="dark"
            textVariant="body"
            href="#"
            handleOnClick={backClick}
            iconStart={<IconSVG icon="arrow_left_2" size={16} color="#000" />}>
            {t('Generic.ctas.back')}
          </Link>
        )}
        {title && (
          <Text sx={titleText} medium={mediumTitle}>
            {title}
          </Text>
        )}
      </Box>
      {subtitle && subtitle}
    </Box>
  );
};

export default React.memo(PageTitle);
