import React, {ReactNode, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {Stack} from '@mui/material';

import {Colors} from '@/themes/variables';

import {IconSVG} from '../IconSVG';
import {IconNames} from '../IconSVG/interfaces';
import {Text} from '../Typography/Text';

import {PURCHASE_CATEGORIES, PurchaseCategoryProps} from './interfaces.d';

const PurchaseCategory = ({categories, color = Colors.Black}: PurchaseCategoryProps) => {
  const {t} = useTranslation();

  const renderSVG = useCallback((icon: IconNames, tooltip: string) => {
    return <IconSVG key={icon} icon={icon} size={20} tooltip={tooltip} color={color} />;
  }, [color]);

  /* Map category icons with fixed tooltip */
  const renderIcons = useCallback(() => {
    let node: Array<ReactNode> = [];
    categories.forEach(category => {
      switch (category.toLowerCase()) {
        case PURCHASE_CATEGORIES.LENSES:
        case PURCHASE_CATEGORIES.FRAMES:
        case PURCHASE_CATEGORIES.COMPLETE_PAIR_OPTICAL:
          node.push(
            renderSVG('lens_glasses', t('PurchaseCategoryTooltip.glasses')),
          );
          break;
        case PURCHASE_CATEGORIES.APPAREL:
        case PURCHASE_CATEGORIES.FOOTWEAR:
          node.push(renderSVG('clothes', t('PurchaseCategoryTooltip.clothes')));
          break;
        case PURCHASE_CATEGORIES.CONTACTS:
          node.push(renderSVG('contact', t('PurchaseCategoryTooltip.contact')));
          break;
        case PURCHASE_CATEGORIES.SUNGLASSES:
        case PURCHASE_CATEGORIES.SUN:
          node.push(
            renderSVG(
              'lens_sunglasses',
              t('PurchaseCategoryTooltip.sunglasses'),
            ),
          );
          break;
      }
    });
    if (node.length === 0) return <Text color={color}>-</Text>;
    return node;
  }, [categories, renderSVG, t, color]);

  return (
    <Stack spacing={1} direction="row">
      {renderIcons()}
    </Stack>
  );
};

export default React.memo(PurchaseCategory);
