import {useMemo} from 'react';

import {useTheme} from '@mui/material';

import {BaseProps} from './interfaces';

export const useStyle = (variant: BaseProps['variant']) => {
  const theme = useTheme();

  const wrapper = useMemo(
    () => ({
      height: '100dvh',
      '@-moz-document url-prefix()': {
        height: '100vh',
      },
      backgroundColor:
        variant === 'light'
          ? theme.palette.grey[600]
          : theme.palette.common.black,
      display: 'flex',
      flexFlow: 'column',
      paddingBottom: 'env(safe-area-inset-bottom)',
    }),
    [theme, variant],
  );

  const header = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }),
    [],
  );

  const main = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.4),
      flex: 1,
      overflow: 'auto',
      marginTop: theme.spacing(2.4),
      paddingX: theme.spacing(6),
      [theme.breakpoints.down('lg')]: {
        paddingX: theme.spacing(2),
      },
    }),
    [theme],
  );

  return {wrapper, header, main};
};
