export enum Colors {
  Primary = '#005291',
  Primary200 = '#CCCCCC',
  Primary300 = '#ACACAC',
  Primary700 = '#EDEDED',
  PrimaryDisabled = '#7FA8C8',

  Secondary = '#0B77B6',

  Tertiary = '#52BBE9',
  TertiaryLight = '#E5F5FC',
  TertiaryDark = '#5F8EBB',

  Backdrop = '#18181880',

  Error = '#E93030',
  ErrorLight = '#EF7A7A',
  Success = '#46BC69',
  SuccessLight = '#B6EAC9',
  Warning = '#FAF789',
  WarningYellow = '#FFEEA3',
  WarningDark = '#FFAC2F',
  WarningLight = '#FAF8C2',
  BlackOpacity70 = '#000000B3',

  Black = '#000000',
  White = '#ffffff',
  Text = '#000000',

  BackgroundDark = '#5E646E',

  Blue = '#005291',
  LightBlue = '#DEEBFF',

  GreyDark = '#8E8E93',
  GreyDarker = '#656565',
  GreyLight = '#F1F1F1',
  GreyText = '#808080',
  GreyText2 = '#8E8E93CC',
  GreyBackground = '#F2F2F7',
  GreyBackground2 = '#E3E3E3',

  Grey900 = '#686868',
  // 50%
  Grey800 = '#828282',
  Grey700 = '#7F7F7F',
  // Background
  Grey600 = '#F1F3F4',
  // Grey 5%
  Grey500 = '#F6F6F6',
  // Select focus background
  Grey400 = '#E4E4E4',
  // Gray 3%
  Grey300 = '#F9F9F9',
  Grey100Text = '#1C1C1E',
  Grey10 = '#E6E6E6',

  /** Shadow */
  Grey200 = '#4E4C4C0D',

  Grey100 = '#A6A6A6',

  Green = '#549768',

  GreenArrow = '#40A45D',

  NEWCOLLECTION = '#F2E2BA',
  CALLBACK = '#BAF2E9',
  BIRTHDAY = '#F2BAC9',
  QUOTE = '#BAD7F2',

  TagRed = '#FFCDB1',
  TagGreen = '#BDEFCC',
  BoxShadow = 'rgba(78, 76, 76, 0.05)',
}

export enum FontSizes {
  XXS = '10px',
  XS = '12px',
  Tiny = '14px',
  Body = '16px',
  Headline5 = '18px',
  Headline4 = '20px',
  Headline3 = '24px',
  Headline2 = '28px',
  Headline1 = '32px',
  TitleBig = '40px',
  Headline = '22px',
}
