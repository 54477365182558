import {Dayjs} from 'dayjs';

import {
  MembersReportFilters,
  MembersReportFiltersAction,
  MembersReportFiltersActions,
} from '@/store/membersReportFilters/definitions';

export const MembersReportFiltersChangeField = (
  key: keyof MembersReportFilters,
  value: any,
): MembersReportFiltersAction => ({
  type: MembersReportFiltersActions.changeField,
  payload: {
    key,
    value,
  },
});

export const MembersReportFiltersChangeDates = (
  startDate: Dayjs,
  endDate: Dayjs,
): MembersReportFiltersAction => ({
  type: MembersReportFiltersActions.changeDates,
  payload: {
    startDate,
    endDate,
  },
});

export const MembersReportFiltersChangePage = (
  page: number,
): MembersReportFiltersAction => ({
  type: MembersReportFiltersActions.changePage,
  payload: page,
});

export const MembersReportFiltersReset = (): MembersReportFiltersAction => ({
  type: MembersReportFiltersActions.resetFilters,
  payload: undefined,
});
