import React from 'react';
import Switch, {SwitchProps} from '@mui/material/Switch';
import {styled} from '@mui/material/styles';

import {Colors} from '@/themes/variables';

import {SwitchButtonProps} from './interfaces';

const SwitchButton = ({checked, onChange}: SwitchButtonProps) => {
  const CommonSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 2,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          backgroundColor: Colors.Black,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: Colors.White,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      backgroundColor: Colors.Black,
      width: 16,
      height: 16,
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      borderRadius: 12,
      backgroundColor: 'transparent',
      border: `1px solid ${Colors.Black}`,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <CommonSwitch checked={checked} onChange={onChange} />
  );
};

export default React.memo(SwitchButton);
