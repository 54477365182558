import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Container} from '@mui/material';

import {useCurrentModule} from '@/hooks/useCurrentModule';
import {PageFooter} from '@/molecules/PageFooter';
import {PageHeader} from '@/molecules/PageHeader';
import {useAuthentication} from '@/store/authentication';

import {BaseProps} from './interfaces';
import {useStyle} from './styles';

const Base = ({
  children,
  variant = 'light',
  disableNavbar,
  extendedNavbar,
  loginNavbar = false,
  noContainer,
  disableFooter = false,
  title,
  maxWidth = 'xl',
}: BaseProps) => {
  const styles = useStyle(variant);
  const {
    userData,
    userModules,
    userBanner,
    userRoles,
    isContactCenterAgent,
    isContactCenterSupervisor,
  } = useAuthentication();
  const {t} = useTranslation();
  const {currentModule} = useCurrentModule();

  const pageTitle = useMemo(() => {
    return title || t(`Routes.${currentModule}`);
  }, [currentModule, t, title]);

  return (
    <Box sx={styles.wrapper}>
      <>
        {!disableNavbar && (
          <PageHeader
            banner={userBanner}
            title={pageTitle}
            user={userData}
            extended={extendedNavbar}
            login={loginNavbar}
            noBanner={isContactCenterAgent || isContactCenterSupervisor}
          />
        )}
        {noContainer && noContainer}
        <Container sx={styles.main} component="main" maxWidth={maxWidth}>
          {children}
        </Container>
        {!loginNavbar && !disableFooter && (
          <PageFooter
            modules={userModules}
            roles={userRoles}
            loyaltyProgram={userData?.configuration?.loyaltyProgram}
          />
        )}
      </>
    </Box>
  );
};

export default React.memo(Base);
