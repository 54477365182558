import {useCallback} from 'react';
import {useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const chip = useCallback(
    (color: string) => {
      return {
        backgroundColor: color,
        color: theme.palette.common.black,
        '& .MuiChip-label': {
          opacity: 1,
        },
      };
    },
    [theme.palette.common.black],
  );

  return {chip};
};
