import {
  LoyaltyFiltersAction,
  LoyaltyFiltersActions,
} from '@/store/loyaltyFilters/interfaces.d';

export interface PointsHistoryFilter {
  activity: string;
  pointsMin: string;
  pointsMax: string;
  dateFrom?: Date;
  dateTo?: Date;
}

export const getInitialState = (): PointsHistoryFilter => ({
  activity: '',
  pointsMax: '',
  pointsMin: '',
  dateFrom: undefined,
  dateTo: undefined,
});

export const pointsHistoryReducer = (
  state = getInitialState(),
  action: LoyaltyFiltersAction<PointsHistoryFilter>,
) => {
  switch (action.type) {
    case LoyaltyFiltersActions.setPointsHistoryFilters:
      return {
        ...state,
        ...action.payload,
      };
    case LoyaltyFiltersActions.resetPointsHistoryFilters:
      return getInitialState();
    default:
      return state;
  }
};
