import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const modalContentContainer = useCallback(
    (isPromo: boolean): SxProps<Theme> => ({
      width: '100%',
      marginTop: theme.spacing(3.2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3.2),
      '.firstContainer': {
        width: '100%',
        ...(isPromo
          ? {
              display: 'grid',
              gridTemplateColumns: 'max-content 1fr',
            }
          : {
              display: 'flex',
              flexDirection: 'column',
            }),
        gap: theme.spacing(1.6),
        alignItems: 'start',
        '.iconContainer': {
          width: theme.spacing(8),
          height: theme.spacing(8),
          display: 'grid',
          placeContent: 'center',
          svg: {
            fill: Colors.GreyText,
          },
        },
        '.textContainer': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          transition: 'max-height 0.2s linear',
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
          '.expandBtn': {
            cursor: 'pointer',
            opacity: 1,
            transition: 'all 0.2s linear',
            ':hover': {
              opacity: 0.7,
            },
          },
        },
      },
      '.secondContainer': {
        flexFlow: 'column',
        '.infoContainer': {
          display: 'grid',
          gridAutoFlow: 'column',
          gridAutoColumns: '1fr',
          gap: theme.spacing(0.8),
          alignItems: 'center',
        },
        '.codeRow': {
          flexDirection: 'row',
          gap: theme.spacing(0.8),
          alignItems: 'start',
          justifyContent: 'space-between',
        },
        '.row': {
          paddingBottom: theme.spacing(1),
          marginBottom: theme.spacing(1),
          borderBottom: `${theme.spacing(0.05)} solid ${Colors.Primary200}`,
        },
      },
    }),
    [theme],
  );

  return useMemo(() => ({modalContentContainer}), [modalContentContainer]);
};
