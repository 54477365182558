import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Skeleton, Stack} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {DashboardContainer} from '@/atoms/DashboardContainer';
import {TaskListLinks} from '@/molecules/TaskListLinks';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useAuthentication} from '@/store/authentication';
import {TASK_VISIBILITY, TASK_STATUS} from '@/store/campaigns';
import {useTasks} from '@/store/tasks';

import {DashboardTaskListProps} from './types';

const DashboardTaskList = ({startDate, endDate}: DashboardTaskListProps) => {
  const {t} = useTranslation();
  const {goToPath} = useNavigation();
  const {getTaskCount} = useTasks();
  const {userData} = useAuthentication();

  const {data: TaskCount, isLoading: TasksCountLoading} = useQuery(
    ['tasks', startDate, endDate, userData?.currentStore?.id],
    () =>
      getTaskCount({
        storeId: userData?.currentStore?.id!,
        startDate,
        endDate,
      }),
    {
      retry: false,
    },
  );

  const tasksAssigned = useMemo(
    () => [
      {
        taskNumber: TaskCount?.open,
        label: t('Campaign.taskList.open'),
        path: AuthRoutes.CLIENTELING_TASK_LIST,
        search: `?visibility=${TASK_VISIBILITY.ALL}&status=${TASK_STATUS.ASSIGNED}`,
        tooltip: t('Campaign.taskList.taskSum'),
      },
      {
        taskNumber: TaskCount?.notAssigned,
        label: t('Campaign.taskList.notAssigned'),
        path: AuthRoutes.CLIENTELING_TASK_LIST,
        search: `?visibility=${TASK_VISIBILITY.ALL}&status=${TASK_STATUS.NOTASSIGNED}`,
        tooltip: undefined,
      },
    ],
    [TaskCount, t],
  );

  const assignedTaskRender = useMemo(() => {
    return tasksAssigned.map(({taskNumber, label, path, search, tooltip}) => {
      return TasksCountLoading || !TaskCount ? (
        <Skeleton
          key={label}
          animation="wave"
          variant="rounded"
          width="100%"
          height={66}
        />
      ) : (
        <TaskListLinks
          key={label}
          taskNumber={taskNumber}
          label={label}
          onClick={() => goToPath(path, search)}
          tooltip={tooltip}
        />
      );
    });
  }, [TaskCount, TasksCountLoading, goToPath, tasksAssigned]);

  return (
    <DashboardContainer title={t('StoreManager.dashboard.taskList')}>
      <Stack
        display="grid"
        direction="row"
        gridTemplateColumns="1fr 1fr"
        gap={2.4}>
        {assignedTaskRender}
      </Stack>
    </DashboardContainer>
  );
};

export default React.memo(DashboardTaskList);
