import {Button} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Dialog} from '../../atoms/Dialog';
import {CloseIconButton} from '../../atoms/DialogButtons';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const FilterDialog = ({
  dialogContent,
  confirmDisabled,
  open,
  onClose,
  onApply,
}: IProps) => {
  const {t} = useTranslation();

  const {button, dialog} = useStyle();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={dialog}
      dialogTitle={t('CustomerProfile.dataTable.dialog.filterPoints.title')}
      dialogContent={dialogContent}
      dialogActions={
        <>
          <CloseIconButton onClose={onClose} />
          <Button
            variant="primary"
            disabled={confirmDisabled}
            sx={button}
            onClick={onApply}>
            {t('CustomerProfile.dataTable.dialog.filterPoints.button')}
          </Button>
        </>
      }
    />
  );
};

export default React.memo(FilterDialog);
