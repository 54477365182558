import {Dayjs} from 'dayjs';

import {
  ReportFiltersAction,
  ReportFiltersActions,
  ReportFilters,
} from '@/store/reportFilters/interfaces.d';

export const ReportFiltersChangeField = (
  key: keyof ReportFilters,
  value: any,
): ReportFiltersAction => ({
  type: ReportFiltersActions.changeField,
  payload: {
    key,
    value,
  },
});

export const ReportFiltersChangeDates = (
  startDate: Dayjs,
  endDate: Dayjs,
): ReportFiltersAction => ({
  type: ReportFiltersActions.changeDates,
  payload: {
    startDate,
    endDate,
  },
});

export const ReportFiltersChangePage = (page: number): ReportFiltersAction => ({
  type: ReportFiltersActions.changePage,
  payload: page,
});

export const ReportFiltersReset = (): ReportFiltersAction => ({
  type: ReportFiltersActions.resetFilters,
  payload: undefined,
});
