import React, {useMemo} from 'react';
import {Redirect} from 'react-router-dom';

import {getRouteBasedOnRole} from '@/lib/utils';
import {useAuthentication} from '@/store/authentication';

import {AuthRoutes} from '../routes';

const ModuleRedirect = () => {
  const {userModules, userRoles} = useAuthentication();

  const route = useMemo(() => {
    /* Logout and return to login with toast error */
    if (!userRoles || !userModules) return AuthRoutes.MYACCOUNT;
    const userRole = userRoles[0];
    const firstModule = userModules![0];

    return getRouteBasedOnRole(userRole, firstModule);
  }, [userModules, userRoles]);

  return <Redirect to={route!} />;
};

export default React.memo(ModuleRedirect);
