import {FEEDBACK_FIELD_CODES, FEEDBACK_STATUS} from '@/store/feedback/v2';

export const statusDisplay = {
  [FEEDBACK_FIELD_CODES.C_DATE_TIME]: [
    FEEDBACK_STATUS.NEW,
    FEEDBACK_STATUS.NO_ANSWER,
    FEEDBACK_STATUS.WAITING,
  ],
  [FEEDBACK_FIELD_CODES.REFUSAL_REASON]: [FEEDBACK_STATUS.CONTACTED_NEGATIVE],
};
