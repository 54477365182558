import {useMemo} from 'react';
import {useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container = useMemo(
    () => ({
      width: '705px',
      minHeight: '155px',
      borderRadius: '5px',
      background: `linear-gradient(180deg, ${theme.palette.common.white} 0%, ${theme.palette.grey[600]} 100%)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        width: '930px',
      },
    }),
    [theme],
  );

  return {container};
};
