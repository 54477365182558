import {Box} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../atoms/Typography/Text';
import {useStyle} from './styles';

const EmptySearchResult = () => {
  const styles = useStyle();

  const {t} = useTranslation();

  return (
    <Box sx={styles.container}>
      <Text heavy variant="headline1">
        {t('SearchResult.noResult')}
      </Text>
    </Box>
  );
};

export default React.memo(EmptySearchResult);
