import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

import {ModalProps} from '@/atoms/Modal/interfaces.d';

export const useStyles = (maxWidth: ModalProps['maxWidth']) => {
  const theme = useTheme();

  const dialog: SxProps<Theme> = useMemo(
    () => ({
      // OVERLAY
      '.MuiBackdrop-root': {
        backgroundColor: theme.palette.dialog.main,
      },
      '.MuiDialog-paper': {
        borderRadius: theme.spacing(1.6),
        padding: theme.spacing(2.4),
        maxWidth: maxWidth ? theme.spacing(maxWidth) : 'calc(100vw - 64px)',
        width: '100%',
        maxHeight: 'calc(100dvh - 64px - env(safe-area-inset-bottom))',
        '@-moz-document url-prefix()': {
          maxHeight: 'calc(100vh - 64px - env(safe-area-inset-bottom))',
        },
      },
    }),
    [maxWidth, theme],
  );

  const closeIcon: SxProps<Theme> = useMemo(
    () => ({
      position: 'absolute',
      right: theme.spacing(2.4),
      top: theme.spacing(2.4),
      cursor: 'pointer',
    }),
    [theme],
  );

  const dialogTitle: SxProps<Theme> = useMemo(
    () => ({
      textAlign: 'left',
      padding: theme.spacing(0, 1.6, 0, 0),
      minHeight: theme.spacing(1.6),
      span: {
        fontSize: theme.typography.headline.fontSize,
        lineHeight: theme.typography.headline.fontSize,
      },
    }),
    [theme],
  );

  const dialogContent: SxProps<Theme> = useMemo(
    () => ({
      padding: 0,
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowX: 'hidden',
    }),
    [],
  );

  return useMemo(
    () => ({
      dialog,
      closeIcon,
      dialogTitle,
      dialogContent,
    }),
    [dialog, closeIcon, dialogTitle, dialogContent],
  );
};
