import {MissionsListFilter} from '@/store/loyaltyFilters/missionsList.reducer';
import {PointsHistoryFilter} from '@/store/loyaltyFilters/pointsHistory.reducer';
import {RewardsListFilter} from '@/store/loyaltyFilters/rewardsList.reducer';

export const selectorPointsHistory = ({
  pointsHistoryFilters,
}: {
  pointsHistoryFilters: PointsHistoryFilter;
}) => pointsHistoryFilters;

export const selectorRewardsList = ({
  rewardsListFilters,
}: {
  rewardsListFilters: RewardsListFilter;
}) => rewardsListFilters;

export const selectorMissionsList = ({
  missionsListFilter,
}: {
  missionsListFilter: MissionsListFilter;
}) => missionsListFilter;
