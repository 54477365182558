import React from 'react';

import {Box, useTheme} from '@mui/material';

import {Text} from '@/atoms/Typography/Text';

const SingleValue = React.memo(
  ({
    type = 'medium',
    value,
  }: {
    type: 'heavy' | 'medium';
    value: string | undefined;
  }) => {
    const theme = useTheme();

    return (
      <Text
        {...(type && {[type]: true})}
        variant="xs"
        color={theme.palette.grey[700]}>
        {value ?? '-'}
      </Text>
    );
  },
);

SingleValue.displayName = 'SingleVoice';

const LabelValue = React.memo(
  ({label, value}: {label: string; value: string | undefined}) => {
    return (
      <Box>
        <SingleValue type="heavy" value={label} />
        <SingleValue type="medium" value={value} />
      </Box>
    );
  },
);

LabelValue.displayName = 'KeyValue';

export {SingleValue, LabelValue};
