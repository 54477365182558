import React, {useCallback, useMemo, useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {BsUpcScan} from 'react-icons/bs/index';
import Webcam from 'react-webcam';

import {
  Box,
  Divider,
  DividerProps,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  BrowserMultiFormatReader,
  DecodeHintType,
  BarcodeFormat,
} from '@zxing/library';
import useScanDetection from 'use-scan-detection';

import {Dialog} from '@/atoms/Dialog';
import {IconSVG} from '@/atoms/IconSVG';
import {testEmail, testDigits} from '@/lib/utils';

import {HomepageSearchButton} from './HomepageSearchButton';
import {HomepageSelect} from './HomepageSelect';
import {HomepageFormBarProps} from './interfaces';
import {useStyle} from './styles';

const hints = new Map<DecodeHintType, BarcodeFormat[]>();
const formats = Object.values(BarcodeFormat).map(el => el as BarcodeFormat);
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
const codeReader = new BrowserMultiFormatReader(hints);

const HomepageFormbar = ({
  freeSearch,
  onFreeSearchChange,
  brand,
  brandOptions,
  onBrandChange,
  country,
  countryOptions,
  onCountryChange,
  onSubmit,
  disableSelect = false,
}: HomepageFormBarProps) => {
  const styles = useStyle();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [showModal, setShowModal] = useState(false);
  const webcamRef = useRef<Webcam>(null);

  const {t} = useTranslation();

  const handleFreeSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onFreeSearchChange(e.target.value);
    },
    [onFreeSearchChange],
  );

  const freeSearchInputProps = useMemo(
    () => ({
      startAdornment: (
        <InputAdornment position="start">
          <IconSVG icon="search" size={17} />
        </InputAdornment>
      ),
    }),
    [],
  );

  const handleBrandChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onBrandChange) {
        onBrandChange(e.target.value);
      }
    },
    [onBrandChange],
  );

  const handleCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onCountryChange) {
        onCountryChange(e.target.value);
      }
    },
    [onCountryChange],
  );

  /**
   * Check whether free search is cardNumber or email
   */
  const freeSearchValid = useMemo(() => {
    const isCardNumber = freeSearch.length === 13 && testDigits(freeSearch);
    const isEmail = testEmail(freeSearch);

    return isCardNumber || isEmail;
  }, [freeSearch]);

  const canSubmit = useMemo(() => {
    return freeSearchValid && brand && country;
  }, [brand, country, freeSearchValid]);

  const dividerProps: DividerProps = useMemo(
    () => ({
      orientation: 'vertical',
      flexItem: true,
    }),
    [],
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (canSubmit) {
      onSubmit();
    }
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (imageSrc) {
      codeReader
        .decodeFromImage(undefined, imageSrc)
        .then(result => {
          onFreeSearchChange(result.getText());
          setShowModal(false);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [onFreeSearchChange]);

  useEffect(() => {
    const interval = setInterval(capture, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [capture]);

  useScanDetection({
    onComplete: string => {
      onFreeSearchChange(string.toString());
    },
    minLength: 13, // EAN13
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={styles.formContainer}
      noValidate
      autoComplete="off">
      <Stack
        sx={styles.fields}
        spacing={0}
        direction="row"
        divider={<Divider {...dividerProps} />}>
        <TextField
          type="text"
          sx={styles.freeSearch}
          value={freeSearch}
          placeholder={t('HomeFormBar.freesearchPlaceholder')}
          onChange={handleFreeSearchChange}
          InputProps={freeSearchInputProps}
          focused={false}
          autoComplete="off"
          inputProps={{
            spellCheck: 'false',
            autoCorrect: 'off',
          }}
        />
        <HomepageSelect
          value={brand}
          placeholder={t('HomeFormBar.brandPlaceholder')}
          options={brandOptions}
          onChange={handleBrandChange}
          disabled={disableSelect || brandOptions.length === 0}
        />

        <HomepageSelect
          value={country}
          placeholder={t('HomeFormBar.countryPlaceholder')}
          options={countryOptions}
          onChange={handleCountryChange}
          disabled={disableSelect || !brand || countryOptions.length === 0}
        />

        <Stack
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="center"
          paddingLeft={1.6}
          paddingTop={1.6}
          paddingBottom={1.6}
          paddingRight={{sm: 0, md: 1.6}}
          sx={{cursor: 'pointer', ':hover': {opacity: 0.7}}}
          onClick={() => setShowModal(true)}>
          <BsUpcScan fontSize={24} />
        </Stack>
      </Stack>
      <HomepageSearchButton
        isDesktop={isDesktop}
        type="submit"
        disabled={!canSubmit}
      />
      {showModal && (
        <Dialog
          onClose={() => setShowModal(false)}
          closeIcon="close"
          open={showModal}
          dialogContent={
            <Box>
              <Webcam
                width={500}
                height={500}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                audio={false}
                videoConstraints={{
                  facingMode: 'environment',
                }}
                onUserMediaError={err => {
                  console.log(err);
                  setShowModal(false);
                }}
              />
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default React.memo(HomepageFormbar);
