import {Box, Button, ButtonProps, CircularProgress} from '@mui/material';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {IProps} from './interfaces';
import {useStyle} from './styles';
import {Text} from '@/atoms/Typography/Text';
import {IconSVG} from '@/atoms/IconSVG';
import {Colors} from '@/themes/variables';

const SearchButton = ({onClick, disabled, loading = false}: IProps) => {
  const {t} = useTranslation();
  const styles = useStyle();

  const buttonProps: ButtonProps = useMemo(
    () => ({
      sx: styles.button,
      variant: 'primary',
      onClick,
      disabled,
    }),
    [disabled, onClick, styles],
  );

  return (
    <Button {...buttonProps}>
      {
        loading ? (
          <CircularProgress color="primary" sx={{color: Colors.White}} size={22} />
        ) : (
          <>
            <Text variant="body" superHeavy color={Colors.White}>
              {t('Searchbar.submitButton')}
            </Text>
            <Box marginLeft="4px" marginTop="-4px">
              <IconSVG icon="search" size={16} color={Colors.White} />
            </Box>
          </>
        )
      }
    </Button>
  );
};

export default React.memo(SearchButton);
