import {useMemo} from 'react';

import {SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const customIconComponent = useMemo<SxProps<Theme>>(
    () => ({
      '&.MuiSelect-icon': {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      '&.MuiSelect-iconOpen': {
        top: '50%',
        transform: 'rotate(0deg) translateY(-50%)',
        svg: {
          transform: 'rotate(180deg)',
        },
      },
    }),
    [],
  );

  return useMemo(() => ({customIconComponent}), [customIconComponent]);
};
