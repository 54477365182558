import {useMemo, useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetPrescriptionByIdParams,
  GetPrescriptionByIdResponse,
} from '@/store/prescription/interfaces';

export const usePrescriptions = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  // POST v1/prescriptions/:prescriptionId
  const getPrescriptionById = useCallback(
    async ({prescriptionId, ...params}: GetPrescriptionByIdParams) => {
      try {
        const {data} = await api.post<GetPrescriptionByIdResponse>(
          getApiPath(APIS.PRESCRIPTIONS_PRESCRIPTIONID, {prescriptionId}),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getPrescriptionById,
    }),
    [getPrescriptionById],
  );
};
