import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Container, Stack, Chip} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {TaskListTabsProps} from '@/molecules/TaskListTabs/definitions';
import {useStyles} from '@/molecules/TaskListTabs/styles';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {TASK_VISIBILITY, TASK_STATUS} from '@/store/campaigns';

const TaskListTabs = ({
  primaryTab,
  subTab,
  tabs,
  onTabChange,
  showBack = false,
}: TaskListTabsProps) => {
  const {goToPath} = useNavigation();
  const {t} = useTranslation();
  const styles = useStyles();

  const subTabs = useMemo(() => {
    const currentTab = tabs.find(t => t?.id === primaryTab);
    return currentTab?.tabsSecondLevel;
  }, [primaryTab, tabs]);

  const handleTabChange = useCallback(
    ({
      type,
      value,
    }:
      | {type: 'main'; value: TASK_VISIBILITY}
      | {type: 'sub'; value: TASK_STATUS}) => {
      switch (type) {
        case 'main':
          const newTab = tabs.find(t => t?.id === value)!;
          onTabChange(newTab?.id, newTab?.tabsSecondLevel[0]?.id);
          break;
        default:
          onTabChange(primaryTab, value);
          break;
      }
    },
    [onTabChange, primaryTab, tabs],
  );

  return (
    <Box sx={styles.wrapper}>
      <Container maxWidth="xl">
        <Stack direction="column" gap={1.6}>
          {showBack && (
            <Link
              linkVariant="dark"
              textVariant="body"
              href="#"
              handleOnClick={() => goToPath(AuthRoutes.CLIENTELING_DASHBOARD)}
              iconStart={
                <IconSVG icon="arrow_left_2" size={16} color="#000" />
              }>
              {t('Generic.ctas.back')}
            </Link>
          )}
          <Stack direction="column" gap={0.8}>
            <Stack direction="row" sx={styles.primaryTabContainer}>
              {tabs.map(tab => {
                const variant =
                  primaryTab === tab?.id
                    ? 'firstLevelTabActive'
                    : 'firstLevelTabDefault';

                return (
                  <Chip
                    key={tab?.id}
                    label={tab?.label}
                    onClick={() =>
                      handleTabChange({type: 'main', value: tab?.id})
                    }
                    variant={variant}
                  />
                );
              })}
            </Stack>
            <Stack direction="row" gap={2.4}>
              {subTabs?.map(tab => {
                const variant =
                  subTab === tab?.id
                    ? 'secondLevelTabActive'
                    : 'secondLevelTabDefault';
                return (
                  <Chip
                    key={tab?.id}
                    label={tab?.label}
                    onClick={() =>
                      handleTabChange({type: 'sub', value: tab?.id})
                    }
                    variant={variant}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default React.memo(TaskListTabs);
