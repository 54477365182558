import {useCallback, useEffect, useState} from 'react';
import {useTheme} from '@mui/material';

export const useCheckDevice = () => {
  // Inner width constants
  const theme = useTheme();

  const WIDTH_MOBILE = theme.breakpoints.values.lg;

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleWindowResize = useCallback(() => {
    setIsMobile(window.matchMedia(`(max-width: ${WIDTH_MOBILE}px)`).matches);
  }, [WIDTH_MOBILE]);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return {isMobile};
};
