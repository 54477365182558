import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const infoContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(0.8),
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      borderBottomWidth: theme.spacing(0.1),
      borderStyle: 'solid',
      borderBottomColor: theme.palette.primary[200],
      paddingBottom: theme.spacing(1),
    }),
    [theme],
  );

  const transactionBoxContainer = useMemo<SxProps<Theme>>(
    () => ({
      gap: theme.spacing(2.4),
      backgroundColor: Colors.GreyBackground,
      padding: theme.spacing(1.6),
      borderRadius: theme.spacing(1.2),
      '.transactionStatus': {
        color: theme.palette.green.main,
        position: 'relative',
        paddingLeft: theme.spacing(1.8),
        '::before': {
          content: '""',
          position: 'absolute',
          top: theme.spacing(0.3),
          left: 0,
          backgroundColor: theme.palette.green.main,
          display: 'inline-block',
          width: theme.spacing(0.8),
          height: theme.spacing(0.8),
          marginRight: theme.spacing(0.4),
          borderRadius: '50%',
        },
      },
    }),
    [theme],
  );

  const productImage = useCallback(
    (clickable: boolean): SxProps<Theme> => ({
      height: theme.spacing(6.4),
      width: theme.spacing(6.4),
      display: 'grid',
      placeItems: 'center',
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: 'white !important',
      borderRadius: theme.spacing(0.8),
      ...(clickable && {
        cursor: 'pointer',
        '.hoverBox': {
          opacity: 0,
        },
        ':hover': {
          '.hoverBox': {
            opacity: 1,
          },
        },
      }),
    }),
    [theme],
  );

  const productImageCover = useMemo<SxProps<Theme>>(
    () => ({
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      transition: 'all 0.2s linear',
    }),
    [],
  );

  return useMemo(
    () => ({
      infoContainer,
      transactionBoxContainer,
      productImage,
      productImageCover,
    }),
    [infoContainer, transactionBoxContainer, productImage, productImageCover],
  );
};
