import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container: SxProps<Theme> = useMemo(
    () => ({
      backgroundColor: theme.palette.tertiary.light,
      borderRadius: theme.spacing(0.4),
      display: 'grid',
      gridTemplateColumns: `1fr max-content max-content`,
      gap: theme.spacing(1.2),
      alignItems: 'center',
      padding: theme.spacing(0.5, 1.1),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1.3, 1.1),
      },
      '.MuiBox-root': {
        '.MuiTypography-caption:first-of-type': {
          fontSize: theme.typography.headline4,
        },
      },
    }),
    [theme],
  );

  const divider: SxProps<Theme> = useMemo(
    () => ({
      my: '5px',
      [theme.breakpoints.up('md')]: {
        my: 'unset',
      },
    }),
    [theme],
  );

  return {container, divider};
};
