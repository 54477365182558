import {FunctionComponent, useState} from 'react';
import {BrowserRouter as Router, Redirect, Switch} from 'react-router-dom';

import {useQuery} from '@tanstack/react-query';
import TaskListPage from 'src/components/pages/Clienteling/SubPages/TaskListPage';

import {Modules, RoleName} from '@/definitions/users';
import Authenticated from '@/navigation/middleware/Authenticated';
import Guest from '@/navigation/middleware/Guest';
import {AnonymousRoutes, AuthRoutes} from '@/navigation/routes';
import {Clienteling} from '@/pages/Clienteling';
import {AreaManagerHome} from '@/pages/Clienteling/AreaManagerHome';
import CampaignReportDetail from '@/pages/Clienteling/SubPages/CampaignReportDetail';
import CampaignsPage from '@/pages/Clienteling/SubPages/CampaignsPage';
import {MembersReports} from '@/pages/Clienteling/SubPages/MembersReports';
import {TaskDetails} from '@/pages/Clienteling/SubPages/TaskDetails';
import {Components} from '@/pages/Components';
import {Login} from '@/pages/Login';
import {Loyalty} from '@/pages/Loyalty';
import {CustomerProfile} from '@/pages/Loyalty/CustomerProfile';
import {MyAccount} from '@/pages/MyAccount';
import {ClientResults} from '@/pages/MyAccount/ClientResults';
import {Customer} from '@/pages/MyAccount/Customer';
import {PurchaseList} from '@/pages/MyAccount/PurchaseList';
import {useAuthentication} from '@/store/authentication';
import {useLocale} from '@/store/locale';

export const Navigation: FunctionComponent = () => {
  useLocale();
  const {getAuth, userData} = useAuthentication();
  const [initialized, setInitialized] = useState<boolean>(false);

  useQuery({
    queryKey: ['profile'],
    queryFn: () => getAuth(),
    onSettled: () => {
      setInitialized(true);
    },
    enabled: !userData,
    retry: false,
  });

  if (initialized) {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Redirect exact from={AuthRoutes.MAIN} to={AnonymousRoutes.LOGIN} />
          <Guest exact path={AnonymousRoutes.LOGIN} component={Login} />
          {(!process.env.NODE_ENV ||
            process.env.NODE_ENV === 'development') && (
            <Guest exact path={AnonymousRoutes.AUTH} component={Login} />
          )}
          {(!process.env.NODE_ENV ||
            process.env.NODE_ENV === 'development') && (
            <Guest
              exact
              path={AnonymousRoutes.COMPONENTS}
              component={Components}
            />
          )}
          <Authenticated
            exact
            path={AuthRoutes.CLIENTELING}
            component={AreaManagerHome}
            roles={[RoleName.AREA_MANAGER]}
            module={Modules.CLIENTELING}
          />
          <Authenticated
            exact
            path={AuthRoutes.CLIENTELING_DASHBOARD}
            component={Clienteling}
            roles={[RoleName.STORE_MANAGER, RoleName.AREA_MANAGER]}
            module={Modules.CLIENTELING}
          />
          <Authenticated
            exact
            path={AuthRoutes.CLIENTELING_CAMPAIGNS}
            component={CampaignsPage}
            roles={[RoleName.STORE_MANAGER, RoleName.AREA_MANAGER]}
            module={Modules.CLIENTELING}
          />
          <Authenticated
            exact
            path={AuthRoutes.CLIENTELING_TASK_LIST}
            component={TaskListPage}
            roles={[
              RoleName.STORE_ASSOCIATE,
              RoleName.STORE_MANAGER,
              RoleName.AREA_MANAGER,
            ]}
            module={Modules.CLIENTELING}
          />
          <Authenticated
            exact
            path={AuthRoutes.CLIENTELING_TASK_DETAILS}
            component={TaskDetails}
            roles={[
              RoleName.STORE_ASSOCIATE,
              RoleName.STORE_MANAGER,
              RoleName.AREA_MANAGER,
              RoleName.CC_SUPERVISOR,
              RoleName.CC_AGENT,
            ]}
            module={Modules.CLIENTELING}
          />
          <Authenticated
            exact
            path={AuthRoutes.CLIENTELING_CAMPAIGNS_REPORT_DETAIL}
            component={CampaignReportDetail}
            roles={[RoleName.STORE_MANAGER, RoleName.AREA_MANAGER]}
            module={Modules.CLIENTELING}
          />
          <Authenticated
            exact
            path={AuthRoutes.CLIENTELING_MEMBERS_REPORTS}
            component={MembersReports}
            roles={[RoleName.STORE_MANAGER, RoleName.AREA_MANAGER]}
            module={Modules.CLIENTELING}
          />
          <Authenticated
            exact
            path={AuthRoutes.MYACCOUNT}
            component={MyAccount}
            roles={[
              RoleName.STORE_ASSOCIATE,
              RoleName.STORE_MANAGER,
              RoleName.AREA_MANAGER,
              RoleName.CC_SUPERVISOR,
              RoleName.CC_AGENT,
            ]}
            module={Modules.MYACCOUNT}
          />
          <Authenticated
            exact
            path={AuthRoutes.MYACCOUNT_CLIENT_RESULTS}
            component={ClientResults}
            roles={[
              RoleName.STORE_ASSOCIATE,
              RoleName.STORE_MANAGER,
              RoleName.AREA_MANAGER,
              RoleName.CC_SUPERVISOR,
              RoleName.CC_AGENT,
            ]}
            module={Modules.MYACCOUNT}
          />
          <Authenticated
            exact
            path={AuthRoutes.MYACCOUNT_CUSTOMER}
            component={Customer}
            roles={[
              RoleName.STORE_ASSOCIATE,
              RoleName.STORE_MANAGER,
              RoleName.AREA_MANAGER,
              RoleName.CC_SUPERVISOR,
              RoleName.CC_AGENT,
            ]}
            module={Modules.MYACCOUNT}
          />
          <Authenticated
            exact
            path={AuthRoutes.MYACCOUNT_PURCHASE_LIST}
            component={PurchaseList}
            roles={[
              RoleName.STORE_ASSOCIATE,
              RoleName.STORE_MANAGER,
              RoleName.AREA_MANAGER,
              RoleName.CC_SUPERVISOR,
              RoleName.CC_AGENT,
            ]}
            module={Modules.MYACCOUNT}
          />
          <Authenticated
            exact
            path={AuthRoutes.LOYALTY}
            component={Loyalty}
            roles={[
              RoleName.STORE_ASSOCIATE,
              RoleName.STORE_MANAGER,
              RoleName.AREA_MANAGER,
              RoleName.CC_SUPERVISOR,
              RoleName.CC_AGENT,
            ]}
            module={Modules.LOYALTY}
          />
          <Authenticated
            exact
            path={AuthRoutes.LOYALTY_PROFILE}
            component={CustomerProfile}
            roles={[
              RoleName.STORE_ASSOCIATE,
              RoleName.STORE_MANAGER,
              RoleName.AREA_MANAGER,
              RoleName.CC_SUPERVISOR,
              RoleName.CC_AGENT,
            ]}
            module={Modules.LOYALTY}
          />
        </Switch>
      </Router>
    );
  } else {
    return <></>;
  }
};
