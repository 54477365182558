import React from 'react';

import {Link as MuiLink} from '@mui/material';

import {Text} from '../Typography/Text';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const Link = ({
  children,
  linkVariant,
  textVariant,
  iconStart,
  iconEnd,
  handleOnClick,
  position = 'start',
  disabled = false,
  noDecoration = false,
  lineHeight,
}: IProps) => {
  const {linkStyle} = useStyle(position, disabled, linkVariant, noDecoration);
  return (
    <MuiLink
      sx={linkStyle}
      onClick={handleOnClick}
      component="button"
      disabled={disabled}
      {...(lineHeight && {lineHeight})}>
      {iconStart}
      <Text variant={textVariant}>{children}</Text>
      {iconEnd}
    </MuiLink>
  );
};

export default React.memo(Link);
