import React, {useMemo} from 'react';

import {Button} from '@mui/material';

import {Text} from '../../../atoms/Typography/Text';
import {CellProps} from '../interfaces';
import {useStyles} from './styles';

const ButtonCell = ({getValue}: CellProps) => {
  const styles = useStyles();

  const info = useMemo(() => getValue(), [getValue]);

  if (!info) {
    return null;
  }

  const buttonText = info.label;
  const buttonOnClick = info.onClick;

  return (
    <>
      {buttonText && (
        <Button variant="secondary" sx={styles.button} onClick={buttonOnClick}>
          <Text heavy uppercase>
            {buttonText}
          </Text>
        </Button>
      )}
    </>
  );
};

export default React.memo(ButtonCell);
