import React from 'react';
import {Route, Redirect, useLocation} from 'react-router-dom';

import {LoadingPage} from '@/atoms/LoadingPage';
import {useAuthentication} from '@/store/authentication';

import {AnonymousRoutes} from '../routes';
import CheckPermission, {CheckPermissionProps} from './CheckPermission';

interface AuthenticatedRouteProps extends CheckPermissionProps {}

const Authenticated = ({roles, module, ...props}: AuthenticatedRouteProps) => {
  const {isLoggedIn, isFetching} = useAuthentication();
  const {pathname, search} = useLocation();

  if (isFetching) return <LoadingPage />;

  if (isLoggedIn) {
    const redirect = localStorage.getItem('redirect');
    if (redirect) {
      localStorage.removeItem('redirect');
    }

    if (roles || module) {
      return <CheckPermission roles={roles} module={module} {...props} />;
    }
    return <Route {...props} />;
  }

  localStorage.setItem('redirect', `${pathname}${search}`);
  return <Redirect to={AnonymousRoutes.LOGIN} />;
};

export default React.memo(Authenticated);
