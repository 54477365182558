import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {DEFAULT_FEEDBACK_DATA} from '@/store/feedback/v2/constants';
import {
  GetFeedbackConfigurationParamsV2,
  GetFeedbackConfigurationResponseV2,
  GetFeedbackParamsV2,
  GetFeedbackResponseV2,
  PostFeedbackParamsV2,
} from '@/store/feedback/v2/interfaces.d';

export const useFeedbackV2 = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  /**
   * @brief Get feedback configuration
   * @param taskCategory
   * @returns GetFeedbackConfigurationResponseV2
   * @throws Error
   * @note This function is used to get feedback configuration for a specific task category
   */
  const getFeedbackConfiguration = useCallback(
    async ({taskCategory}: GetFeedbackConfigurationParamsV2) => {
      try {
        const {data} = await api.get<GetFeedbackConfigurationResponseV2>(
          getApiPath(
            APIS.V2_CONFIGS_CAMPAIGNCATEGORY_CAMPAIGNCATEGORY_FEEDBACK,
            {
              taskCategory,
            },
          ),
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  /**
   * @brief Get feedback
   * @param storeId
   * @param taskId
   * @returns GetFeedbackResponseV2
   * @throws Error
   * @note This function is used to get feedback for a specific task
   */
  const getFeedbackData = useCallback(
    async ({storeId, taskId}: GetFeedbackParamsV2) => {
      try {
        const {data} = await api.get<GetFeedbackResponseV2>(
          getApiPath(APIS.V2_STORES_STOREID_TASKS_TASKID_FEEDBACK, {
            storeId,
            taskId,
          }),
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        return DEFAULT_FEEDBACK_DATA;
      }
    },
    [api, getApiPath],
  );

  /**
   * @brief Post feedback
   * @param storeId
   * @param taskId
   * @param payload
   * @throws Error
   * @note This function is used to post feedback for a specific task
   */
  const postFeedback = useCallback(
    async ({storeId, taskId, ...payload}: PostFeedbackParamsV2) => {
      try {
        await api.post(
          getApiPath(APIS.V2_STORES_STOREID_TASKS_TASKID_FEEDBACK, {
            storeId,
            taskId,
          }),
          {
            ...payload,
          },
        );
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getFeedbackConfiguration,
      getFeedbackData,
      postFeedback,
    }),
    [getFeedbackConfiguration, getFeedbackData, postFeedback],
  );
};
