import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

const BORDER_RADIUS = '6px';

export const useStyle = () => {
  const theme = useTheme();

  const placeholderText: SxProps<Theme> = useMemo(() => ({opacity: '50%'}), []);

  const container: SxProps<Theme> = useMemo(
    () => ({
      minWidth: '20ch',
      maxWidth: '20ch',
      '.MuiInputBase-root': {
        cursor: 'pointer',
        '&.Mui-disabled': {
          '.MuiInputAdornment-root': {
            opacity: '0.3',
          },
        },
      },

      '& .MuiPopover-paper': {left: '0 !important'},
    }),
    [],
  );

  const menu: SxProps<Theme> = useMemo(
    () => ({
      marginTop: theme.spacing(2.8),
      borderRadius: BORDER_RADIUS,
      '.MuiPaper-root': {
        borderRadius: BORDER_RADIUS,
        boxShadow: `0px 7px 20px ${theme.palette.grey[200]}`,
        width: '177px',
      },
      '& .MuiList-root': {
        py: 0,
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.common.white,
      },
    }),
    [theme],
  );

  const menuItem: SxProps<Theme> = useMemo(
    () => ({
      px: theme.spacing(1),
      py: theme.spacing(0.8),
      color: theme.palette.common.black,
      '&:first-of-type': {
        borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
      },
      '&:last-of-type': {
        borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
      },
      '&:focus': {
        backgroundColor: theme.palette.grey[400],
      },
      '&.Mui-selected': {
        opacity: '50%',
        backgroundColor: theme.palette.common.white,
        '&:focus': {
          opacity: '100%',
          backgroundColor: theme.palette.grey[400],
        },
      },
    }),
    [theme],
  );

  return {placeholderText, container, menu, menuItem};
};
