import {faker} from '@faker-js/faker';

import {
  Communication,
  NOTIFICATION_TYPE,
  PROMO_TYPE,
  GetBenefitsResponse,
} from '@/store/benefit/interfaces.d';

const createCommunication = (
  notificationType: NOTIFICATION_TYPE,
): Communication => {
  return {
    notificationId: faker.string.uuid(),
    email: faker.internet.email(),
    notificationType: notificationType,
    startDate: faker.date.recent().toISOString(),
    endDate: faker.date.future().toISOString(),
    customerName: faker.name.firstName(),
    customerSurname: faker.name.lastName(),
    title: faker.lorem.sentence(),
    cardTitle: faker.lorem.sentence(),
    cardDescription: faker.lorem.paragraph({min: 10, max: 30}),
    termsAndConditions: faker.lorem.paragraph(),
    promoType: faker.helpers.arrayElement(Object.values(PROMO_TYPE)),
    promoCodeOnline: faker.string.fromCharacters('*', {min: 8, max: 8}),
    promoCodeRetail: faker.string.alphanumeric({length: 8}),
    promoCodeEquals: faker.helpers.arrayElement([
      faker.datatype.boolean(0.5),
      undefined,
    ]),
  };
};

export const getBenefitsMock = (): GetBenefitsResponse => {
  return {
    benefits: Array.from({length: 3}).map(() =>
      createCommunication(NOTIFICATION_TYPE.PROMO),
    ),
    notifications: Array.from({length: 12}).map(() =>
      createCommunication(NOTIFICATION_TYPE.COMMUNICATION),
    ),
  };
};

export const BenefitsMock = getBenefitsMock();
