import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetReportsCampaignsParams,
  GetReportsCampaignsResponse,
  GetReportsHeaderParams,
  GetReportsHeaderResponse,
  GetReportsDetailHeaderParams,
  GetReportsDetailsHeaderResponse,
  GetReportsDetailInfoParams,
  GetReportsDetailsInfoResponse,
  GetReportDetailCampaignsParams,
  GetReportDetailCampaignsResponse,
  GetReportsDetailUnassignedParams,
  GetReportsDetailUnassignedResponse,
} from '@/store/reports/interfaces.d';
import {formatReportHeader} from '@/store/reports/utils';

export const useReport = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getReportsHeader = useCallback(
    async ({...params}: GetReportsHeaderParams) => {
      try {
        const {data} = await api.post<GetReportsHeaderResponse>(
          getApiPath(APIS.REPORTS_CAMPAIGNS_HEADER),
          {
            ...params,
          },
        );

        return formatReportHeader(data);
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getReportsDetailInfo = useCallback(
    async ({storeId, campaignCode}: GetReportsDetailInfoParams) => {
      try {
        const {data} = await api.get<GetReportsDetailsInfoResponse>(
          getApiPath(APIS.REPORTS_STORES_STOREID_CAMPAIGNS_CAMPAIGNCODE_INFO, {
            storeId,
            campaignCode,
          }),
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getReportsDetailUnassigned = useCallback(
    async ({storeId, ...params}: GetReportsDetailUnassignedParams) => {
      try {
        const {data} = await api.post<GetReportsDetailUnassignedResponse>(
          getApiPath(APIS.REPORTS_STORES_STOREID_CAMPAIGNS_UNASSIGNED, {storeId}),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getReportsDetailHeader = useCallback(
    async ({storeId, ...params}: GetReportsDetailHeaderParams) => {
      try {
        const {data} = await api.post<GetReportsDetailsHeaderResponse>(
          getApiPath(APIS.REPORTS_STORES_STOREID_CAMPAIGNS_HEADER, {storeId}),
          {
            ...params,
          },
        );

        return formatReportHeader(data);
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getReportsCampaigns = useCallback(
    async ({...params}: GetReportsCampaignsParams) => {
      try {
        const {data} = await api.post<GetReportsCampaignsResponse>(
          getApiPath(APIS.REPORTS_CAMPAIGN),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getReportDetailCampaigns = useCallback(
    async ({storeId, ...params}: GetReportDetailCampaignsParams) => {
      try {
        const {data} = await api.post<GetReportDetailCampaignsResponse>(
          getApiPath(APIS.REPORTS_STORES_STOREID_CAMPAIGNS, {storeId}),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getReportsCampaigns,
      getReportsHeader,
      getReportsDetailUnassigned,
      getReportsDetailHeader,
      getReportsDetailInfo,
      getReportDetailCampaigns,
    }),
    [
      getReportsCampaigns,
      getReportsHeader,
      getReportsDetailUnassigned,
      getReportsDetailHeader,
      getReportsDetailInfo,
      getReportDetailCampaigns,
    ],
  );
};
