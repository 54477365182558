import React, {useState, useCallback, useMemo} from 'react';
import {Trans} from 'react-i18next';

import {Stack, Button} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Headline5} from '@/atoms/Headline5';
import {IconSVG} from '@/atoms/IconSVG';
import {LabelValue} from '@/atoms/LabelValue';
import {Modal} from '@/atoms/Modal';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {capitalizeWords} from '@/lib/utils';
import {AppointmentCardProps} from '@/molecules/AppointmentCard/interfaces';
import {useStyles} from '@/molecules/AppointmentCard/styles';
import {APPOINTMENT_SETTINGS} from '@/store/feedback/v2';

const AppointmentCard = ({
  openTabLite,
  onSelect,
  selected,
  onDisassociation,
  configuration,
  taskIsEditable = true,
  ...appointment
}: AppointmentCardProps) => {
  const {getTranslationWithValue} = useBaseTranslation(
    'FeedbackBox.APPOINTMENT_SETTING',
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const styles = useStyles(onSelect);

  const handleDisassociate = useCallback(() => {
    setShowModal(false);
    onDisassociation && onDisassociation();
  }, [onDisassociation]);

  const appointmentName = useMemo(() => {
    let fullName = '';

    if (appointment?.firstname) {
      fullName += `${appointment.firstname} `;
    }

    if (appointment?.lastname) {
      fullName += appointment.lastname;
    }

    return fullName ? capitalizeWords(fullName).trim() : '-';
  }, [appointment.firstname, appointment.lastname]);

  const canEditDeleteAppointment = useMemo(() => {
    if (typeof configuration === 'undefined') {
      return false;
    }

    return (
      configuration?.fieldAcceptedValue?.TAB_LITE ===
      APPOINTMENT_SETTINGS.READ_AND_WRITE
    );
  }, [configuration]);

  const appointmentDate = useMemo(() => {
    return appointment?.date
      ? capitalizeWords(dayjs(appointment?.date).utc().format('ll - LT'))
      : '-';
  }, [appointment?.date]);

  return (
    <>
      <Stack
        direction="column"
        gap={1.6}
        sx={styles.card(selected)}
        {...(onSelect && {onClick: onSelect})}>
        <Headline5 heavy>{appointmentName}</Headline5>
        <BodyText heavy>
          {getTranslationWithValue(
            0,
            `card.appointmentTypes.${appointment?.type}`,
          )}
        </BodyText>
        <LabelValue
          label={getTranslationWithValue(0, 'card.appointmentDate')}
          value={appointmentDate}
        />
        <LabelValue
          label={getTranslationWithValue(0, 'card.appointmentLocation')}
          value={appointment?.storeName ?? '-'}
        />
        <LabelValue
          label={getTranslationWithValue(0, 'card.contacts')}
          value={`${appointment?.email ?? '-'} | ${appointment?.phone ?? '-'} `}
        />
        {!onSelect && (
          <Stack direction="row" sx={styles.ctaContainer}>
            {canEditDeleteAppointment && (
              <Button
                disabled={!taskIsEditable}
                variant="outlinedInverted"
                endIcon={<IconSVG icon="bucket" size={16} />}
                {...(openTabLite &&
                  taskIsEditable && {onClick: () => openTabLite('DELETE')})}>
                {getTranslationWithValue(0, 'card.delete')}
              </Button>
            )}
            <Button
              disabled={!taskIsEditable}
              variant="outlinedInverted"
              endIcon={<IconSVG icon="detach" size={16} />}
              {...(onDisassociation &&
                taskIsEditable && {onClick: () => setShowModal(true)})}>
              {getTranslationWithValue(0, 'card.disassociate')}
            </Button>
            {canEditDeleteAppointment && (
              <Button
                disabled={!taskIsEditable}
                variant="outlinedInverted"
                endIcon={<IconSVG icon="edit" size={16} />}
                {...(openTabLite &&
                  taskIsEditable && {onClick: () => openTabLite('EDIT')})}>
                {getTranslationWithValue(0, 'card.edit')}
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      {showModal && (
        <Modal
          open={showModal}
          maxWidth={53.6}
          onClose={() => setShowModal(false)}
          dialogTitle={getTranslationWithValue(0, 'disassociateModal.title')}
          dialogContent={
            <Stack marginTop={3.2} direction="column" gap={3.2} width="100%">
              <Trans
                i18nKey="FeedbackBox.APPOINTMENT_SETTING.disassociateModal.subTitle"
                components={{
                  wrapper: <BodyText whiteSpace="pre-line" />,
                  heavy: <BodyText heavy />,
                }}
              />
              <Stack
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                gap={2.4}>
                <Button
                  variant="baseSecondary"
                  fullWidth
                  onClick={() => handleDisassociate()}>
                  {getTranslationWithValue(0, 'disassociateModal.disassociate')}
                </Button>
                <Button
                  variant="basePrimary"
                  fullWidth
                  onClick={() => setShowModal(false)}>
                  {getTranslationWithValue(
                    0,
                    'disassociateModal.mantainAppointment',
                  )}
                </Button>
              </Stack>
            </Stack>
          }
        />
      )}
    </>
  );
};

export default React.memo(AppointmentCard);
