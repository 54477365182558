import {useMemo} from 'react';
import {useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const container = useMemo(() => ({width: '100%'}), []);

  const labelText = useMemo(
    () => ({
      marginBottom: theme.spacing(1),
      paddingLeft: '3px',
    }),
    [theme],
  );

  const helperTextContainer = useMemo(
    () => ({
      color: theme.palette.error.main,
      fontSize: theme.typography.tiny.fontSize,
    }),
    [theme],
  );

  return {container, labelText, helperTextContainer};
};
