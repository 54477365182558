import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const editDescription: SxProps<Theme> = useMemo(
    () => ({textAlign: 'center'}),
    [],
  );

  const container: SxProps<Theme> = useMemo(
    () => ({
      maxWidth: '365px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: theme.spacing(3),
    }),
    [theme],
  );

  const select: SxProps<Theme> = useMemo(
    () => ({
      border: `1px solid ${theme.palette.grey[700]}`,
      borderRadius: theme.spacing(0.8),
      padding: theme.spacing(1),
    }),
    [theme],
  );

  const currentBalanceKey: SxProps<Theme> = useMemo(() => ({mr: '1ch'}), []);

  return {editDescription, container, currentBalanceKey, select};
};
