import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const headerBox = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: Colors.GreyBackground,
      padding: theme.spacing(1.6),
      borderRadius: theme.spacing(1.2),
    }),
    [theme],
  );

  const myAccountGrantContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: '1fr 80px',
      gap: theme.spacing(1.6),
      padding: theme.spacing(0.8, 0.8, 0.8, 1.6),
      alignItems: 'center',
      borderWidth: theme.spacing(0.05),
      borderStyle: 'solid',
      borderColor: Colors.GreyText,
      borderRadius: theme.spacing(1.6),
      cursor: 'pointer',
      transition: 'all 0.2s linear',
      opacity: 1,
      ':hover': {
        opacity: 0.9,
      },
      img: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        borderRadius: theme.spacing(0.8),
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr',
        justifyItems: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 80px',
      },
    }),
    [theme],
  );

  const myAccountGrantInnerContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(0.8),
      // textAlign: 'right',
      // '.iconLabel': {
      //   width: 'max-content',
      //   justifySelf: 'end',
      // },
      '.iconLabel': {
        width: 'max-content',
      },
      '.scanForRegistration': {
        whiteSpace: 'pre-line',
      },
      [theme.breakpoints.down('md')]: {
        textAlign: 'right',
        '.iconLabel': {
          width: 'max-content',
          justifySelf: 'end',
        },
      },
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
        '.iconLabel': {
          justifySelf: 'center',
        },
      },
      [theme.breakpoints.up('lg')]: {
        textAlign: 'right',
        '.iconLabel': {
          justifySelf: 'end',
        },
      },
    }),
    [theme],
  );

  const dialog = useMemo(
    () => ({
      '.MuiDialog-paper': {
        maxWidth: theme.spacing(40),
        width: '100%',
        borderRadius: '16px',
        padding: theme.spacing(2.4),
      },
      '.MuiDialogTitle-root': {
        textAlign: 'left',
        padding: theme.spacing(0, 1.6, 0, 0),
        span: {
          fontSize: theme.typography.headline.fontSize,
          lineHeight: theme.typography.headline.fontSize,
        },
      },
      '.closeIconDialog': {
        right: theme.spacing(2.4),
        top: theme.spacing(2.4),
      },
      '.MuiDialogContent-root': {
        margin: theme.spacing(3.2, 0),
        img: {
          width: theme.spacing(20),
          height: theme.spacing(20),
          borderRadius: theme.spacing(0.8),
        },
      },
      '.MuiDialogActions-root': {
        p: 0,
        '.MuiButtonBase-root': {
          width: theme.spacing(20),
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      headerBox,
      myAccountGrantContainer,
      myAccountGrantInnerContainer,
      dialog,
    }),
    [headerBox, myAccountGrantContainer, myAccountGrantInnerContainer, dialog],
  );
};
