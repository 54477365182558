import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {ColumnDef} from '@tanstack/react-table';

import {Data} from '../../atoms/DataTable/interfaces';
import {
  BaseCell,
  ButtonCell,
  DateCell,
  StatusCell,
  TooltipCell,
} from '../../molecules/TableCell';
import {useStyle} from './styles';

export const useColumnDef = () => {
  const {t} = useTranslation();
  const {chip} = useStyle();

  const RewardListColumnDef = useMemo<ColumnDef<Data>[]>(
    () => [
      {
        accessorKey: 'id',
        header: () => t('CustomerProfile.tables.rewardList.header.id'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'name',
        header: () => t('CustomerProfile.tables.rewardList.header.reward'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'description',
        header: () => t('CustomerProfile.tables.rewardList.header.details'),
        cell: info => <TooltipCell {...info} placement="top" />,
      },
      {
        accessorKey: 'promocodeEcommerce',
        header: () => t('CustomerProfile.tables.rewardList.header.coupon'),
        cell: info => {
          if (info.row.original.status === "EXPIRED" || info.row.original.status === 'USED') {
            return <></>
          }
          return <BaseCell {...info} />
        },
      },
      {
        accessorKey: 'upc',
        header: () => t('CustomerProfile.tables.rewardList.header.upc'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'status',
        header: () => t('CustomerProfile.tables.rewardList.header.status'),
        cell: info => (
          <StatusCell
            {...info}
            sx={chip(info.row.original.statusColor)}
            {...(info.row.original.statusAction && {
              onClick: info.row.original.statusAction,
            })}
          />
        ),
      },
      {
        accessorKey: 'lastUsage',
        header: () => t('CustomerProfile.tables.rewardList.header.lastUsageDT'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'remainingUsages',
        header: () =>
          t('CustomerProfile.tables.rewardList.header.remainingUsages'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'expirationDate',
        header: () =>
          t('CustomerProfile.tables.rewardList.header.expirationDate'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'actions',
        header: () => t('CustomerProfile.tables.rewardList.header.action'),
        cell: info => <ButtonCell {...info} />,
      },
    ],
    [chip, t],
  );

  return {RewardListColumnDef};
};
