import React, {useCallback, useMemo} from 'react';

import {
  TextField,
  InputProps,
  Stack,
  Divider,
  MenuItem,
  Select,
} from '@mui/material';

import {IProps} from './interfaces';
import {useStyle} from './style';

const SearchInputRestyle = ({
  placeholder,
  value,
  onChange,
  ...bannerCountryProps
}: IProps) => {
  const {search} = useStyle();

  const inputProps = useMemo<Partial<InputProps>>(
    () => ({
      disableUnderline: true,
      style: {
        marginTop: '-4px',
        marginBottom: '-5px',
      },
      inputProps: {
        style: {
          padding: 0,
          paddingTop: '4px',
        },
      },
      ...(!!bannerCountryProps?.selectedBanner && {
        startAdornment: <BannerCountrySelect {...bannerCountryProps} />,
      }),
    }),
    [bannerCountryProps],
  );

  // Stop propagation as the code with MUI structure passes the event to some element
  // and this results in the inability of the user to input the letter 'a'
  // Stopping the event propagation resolves the issue
  const stopPropagation = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <TextField
      sx={search}
      autoComplete="off"
      type="text"
      inputProps={{
        spellCheck: 'false',
        autoCorrect: 'off',
      }}
      value={value}
      onKeyDown={stopPropagation}
      onChange={onChange}
      fullWidth
      placeholder={placeholder}
      variant="standard"
      InputProps={inputProps}
    />
  );
};

export default React.memo(SearchInputRestyle);

interface BannerCountrySelectProps
  extends Omit<IProps, 'placeholder' | 'value' | 'onChange'> {}

const BannerCountrySelect = React.memo(
  ({
    selectedBanner,
    setSelectedBanner,
    selectedCountry,
    setSelectedCountry,
    banners,
    countries,
  }: BannerCountrySelectProps) => {
    const {customSelect} = useStyle();

    return (
      <Stack
        direction="row"
        gap={1.6}
        divider={<Divider orientation="vertical" flexItem />}>
        <Select
          sx={customSelect}
          disabled={!banners || banners?.length === 0}
          fullWidth
          labelId="banner"
          id="banner"
          value={selectedBanner!}
          onChange={event => setSelectedBanner!(event.target.value as string)}>
          {banners?.map(banner => (
            <MenuItem key={banner.name} value={banner.name}>
              {banner.name}
            </MenuItem>
          ))}
        </Select>
        <Select
          sx={customSelect}
          disabled={!countries || countries?.length === 0}
          fullWidth
          labelId="country"
          id="country"
          value={selectedCountry!}
          onChange={event => setSelectedCountry!(event.target.value as string)}>
          {countries?.map(country => (
            <MenuItem key={country.name} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    );
  },
);

BannerCountrySelect.displayName = 'BannerCountrySelect';
