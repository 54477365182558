import {useMemo} from 'react';

import {SxProps, Theme} from '@mui/material';

export const useStyle = () => {

  const baseCell: SxProps<Theme> = useMemo(
    () => ({
      span: {
        textTransform: 'capitalize',
      },
    }),
    [],
  );

  return {
    baseCell,
  };
};
