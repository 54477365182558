import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {ColumnDef} from '@tanstack/react-table';

import {Data} from '../../atoms/DataTable/interfaces';
import {
  BaseCell,
  ButtonCell,
  DateCell,
  StatusCell,
} from '../../molecules/TableCell';
import {useStyle} from './styles';

export const useColumnDef = () => {
  const {t} = useTranslation();
  const {chip} = useStyle();

  /* TODO: correct accessorKey based on api*/
  const MissionListColumnDef = useMemo<ColumnDef<Data>[]>(
    () => [
      {
        accessorKey: 'id',
        header: () => t('CustomerProfile.tables.missionList.header.id'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'name',
        header: () => t('CustomerProfile.tables.missionList.header.mission'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'description',
        header: () =>
          t('CustomerProfile.tables.missionList.header.description'),
        cell: info => <BaseCell {...info} />,
      },
      {
        accessorKey: 'status',
        header: () => t('CustomerProfile.tables.missionList.header.status'),
        cell: info => (
          <StatusCell {...info} sx={chip(info.row.original.statusColor)} />
        ),
      },
      {
        accessorKey: 'completedDate',
        header: () =>
          t('CustomerProfile.tables.missionList.header.accomplishmentDT'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'expirationDate',
        header: () =>
          t('CustomerProfile.tables.missionList.header.expirationDate'),
        cell: info => <DateCell {...info} />,
      },
      {
        accessorKey: 'actions',
        header: () => t('CustomerProfile.tables.missionList.header.action'),
        cell: info => <ButtonCell {...info} />,
      },
    ],
    [chip, t],
  );

  return {MissionListColumnDef};
};
