import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const notesContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(0.8),
      '.labelValueCtaContainer': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(0.8),
      },
      '.singleNote': {
        flexDirection: 'column',
        gap: theme.spacing(1.6),
        '.buttons': {
          flexDirection: 'row',
          gap: theme.spacing(0.8),
          alignSelf: 'end',
        },
      },
    }),
    [theme],
  );

  const addEditNoteContainer = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      gap: theme.spacing(1.6),
      width: '100%',
      marginTop: theme.spacing(1.6),
    }),
    [theme],
  );

  const buttonContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: theme.spacing(2.4),
      '.buttonCta': {
        textTransform: 'none',
      },
    }),
    [theme],
  );

  const deleteNoteContainer = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      gap: theme.spacing(3.2),
      width: '100%',
      '.noteText': {
        whiteSpace: 'pre-line',
        marginTop: theme.spacing(3.2),
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      notesContainer,
      buttonContainer,
      addEditNoteContainer,
      deleteNoteContainer,
    }),
    [
      notesContainer,
      buttonContainer,
      addEditNoteContainer,
      deleteNoteContainer,
    ],
  );
};
