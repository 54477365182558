import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Stack, Skeleton} from '@mui/material';

import {Dialog} from '@/atoms/Dialog';
import {CloseIconButton} from '@/atoms/DialogButtons';
import {Text} from '@/atoms/Typography/Text';

import {IBarCodeDialogProps} from './interfaces';

const BarCodeDialog = ({barCode, isLoading, ...props}: IBarCodeDialogProps) => {
  const {t} = useTranslation();

  const content = useMemo(() => {
    if (isLoading || !barCode) {
      return (
        <Skeleton
          height={140}
          width="100%"
          animation="wave"
          variant="rounded"
        />
      );
    }

    return (
      <Stack gap={4}>
        <Stack gap={1} alignItems="center">
          <Text variant="headline5" superHeavy>
            {t('CustomerProfile.tables.rewardList.dialog.barCodeTitle')}
          </Text>
          <Text>
            {t('CustomerProfile.tables.rewardList.dialog.barCodeDescription')}
          </Text>
        </Stack>
        <Stack alignItems="center" gap={1.5}>
          <img src={barCode.barCodeBase64BenefitCode} alt="barCode" />
          {/* <Text superHeavy textTransform="capitalize" variant="headline2"> */}
          {/*   {barCode.code} */}
          {/* </Text> */}
        </Stack>
      </Stack>
    );
  }, [barCode, isLoading, t]);

  return (
    <Dialog
      {...props}
      dialogContent={content}
      dialogActions={<CloseIconButton onClose={props.onClose} />}
    />
  );
};

export default React.memo(BarCodeDialog);
