import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const campaignsContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gap: theme.spacing(1.6),
      gridTemplateColumns: 'repeat(3, 1fr)',
      '.campaignCard:nth-of-type(5), .campaignCard:nth-of-type(6)': {
        display: 'block',
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        '.campaignCard:nth-of-type(5), .campaignCard:nth-of-type(6)': {
          display: 'none',
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      campaignsContainer,
    }),
    [campaignsContainer],
  );
};
