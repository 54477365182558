import {
  GetFeedbackResponseV2,
  FEEDBACK_STATUS,
} from '@/store/feedback/v2/interfaces.d';

export const DEFAULT_FEEDBACK_DATA: GetFeedbackResponseV2 = {
  status: FEEDBACK_STATUS.NEW,
  refusalReason: null,
  note: null,
  callBackDateTime: null,
  updatedNote: null,
};
