import {
  LoyaltyFiltersAction,
  LoyaltyFiltersActions,
} from '@/store/loyaltyFilters/interfaces.d';

export interface RewardsListFilter {
  benefit?: string;
  dateFrom?: Date;
  dateTo?: Date;
  status?: string;
}

export const getInitialState = (): RewardsListFilter => ({
  benefit: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  status: undefined,
});

export const rewardsListReducer = (
  state = getInitialState(),
  action: LoyaltyFiltersAction<RewardsListFilter>,
) => {
  switch (action.type) {
    case LoyaltyFiltersActions.setRewardsListFilters:
      return {
        ...state,
        ...action.payload,
      };
    case LoyaltyFiltersActions.resetRewardsListFilters:
      return getInitialState();
    default:
      return state;
  }
};
