import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container = useMemo(
    () => ({position: 'relative', display: 'inline-flex'}),
    [],
  );

  const baseCircle = useMemo(
    () => ({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      color: theme.palette.tertiary.light,
    }),
    [theme],
  );

  const circle = useMemo(() => ({strokeLinecap: 'round'}), []);

  const valueContainer = useMemo(
    () => ({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  );

  return {container, baseCircle, circle, valueContainer};
};
