import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const chip = useMemo<SxProps<Theme>>(
    () => ({
      width: 'fit-content',
      padding: theme.spacing(0.5, 0.8),
      '.MuiChip-label': {
        padding: theme.spacing(0),
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      chip,
    }),
    [chip],
  );
};
