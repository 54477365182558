import React, {useMemo} from 'react';

import {Box} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Text} from '@/atoms/Typography/Text';

import {AvatarProps} from './interfaces';
import {useStyle} from './styles';

const Avatar = ({
  name = '',
  surname = '',
  variant = 'light',
  big = false,
  icon,
}: AvatarProps) => {
  const {avatar} = useStyle(big, variant);

  const content = useMemo(() => {
    if (icon) {
      return (
        <IconSVG
          icon={icon}
          size={24}
          color={variant === 'dark' ? '#fff' : '#000'}
        />
      );
    }

    return <Text medium>{name[0] + surname[0]}</Text>;
  }, [icon, name, surname, variant]);

  return <Box sx={avatar}>{content}</Box>;
};

export default React.memo(Avatar);
