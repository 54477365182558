import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useApiPath, APIS} from '@/store/api/useApiPath';

import {useAPI} from '../api';
import {
  GetOrdersParams,
  GetOrdersResponse,
  GetOrdersQuotesParams,
  GetOrdersQuotesResponse,
  GetPurchaseDetailsParams,
  GetPurchaseDetailsResponse,
  GetOrdersV2Params,
  GetOrdersV2Response,
} from './interfaces';

/**
 * @brief Hook to post/get stores relative api
 */
export const useOrder = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getOrders = useCallback(
    async ({...params}: GetOrdersParams) => {
      try {
        const {data} = await api.post<GetOrdersResponse>(
          getApiPath(APIS.ORDERS),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getOrdersV2 = useCallback(
    async ({...params}: GetOrdersV2Params) => {
      try {
        const {data} = await api.post<GetOrdersV2Response>(
          getApiPath(APIS.V2_ORDERS),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getOrdersQuotes = useCallback(
    async ({storeId, taskId}: GetOrdersQuotesParams) => {
      try {
        const {data} = await api.get<GetOrdersQuotesResponse>(
          getApiPath(APIS.STORES_STOREID_TASKS_TASKID_QUOTES, {
            storeId,
            taskId,
          }),
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        return undefined;
      }
    },
    [api, getApiPath],
  );

  const getPurchaseDetails = useCallback(
    async ({...params}: GetPurchaseDetailsParams) => {
      try {
        const {data} = await api.post<GetPurchaseDetailsResponse>(
          getApiPath(APIS.ORDERS_PURCHASES),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getOrders,
      getOrdersQuotes,
      getPurchaseDetails,
      getOrdersV2,
    }),
    [getOrders, getOrdersQuotes, getPurchaseDetails, getOrdersV2],
  );
};
