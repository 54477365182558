import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyle = (
  position: 'start' | 'end' | 'center',
  disabled: boolean,
  variant?: 'light' | 'dark',
  noDecoration?: boolean,
) => {
  const theme = useTheme();
  const linkStyle = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      alignItems: 'flex-start',
      width: 'fit-content',
      gap: theme.spacing(1),
      alignSelf: position,
      ...(disabled && {pointerEvents: 'none'}),
      color:
        variant === 'light'
          ? theme.palette.common.white
          : theme.palette.common.black,
      textDecorationColor:
        variant === 'light'
          ? theme.palette.common.white
          : theme.palette.common.black,
      ...(noDecoration && {textDecoration: 'none'}),
      ':disabled': {
        color: theme.palette.primary[300],
        textDecorationColor: theme.palette.primary[300],
        svg: {
          fill: theme.palette.primary[300],
        },
      },
      ':hover': {
        opacity: 0.7,
      },
    }),
    [theme, position, disabled, variant, noDecoration],
  );

  return {linkStyle};
};
