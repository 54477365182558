import React, {useCallback, useMemo, useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Skeleton, Stack} from '@mui/material';
import {useMutation, useQuery} from '@tanstack/react-query';

import {Tabs} from '@/atoms/Tabs';
import {BaseRouterParams} from '@/definitions/base';
import {UserStatus} from '@/definitions/user';
import {useToast} from '@/hooks/toast';
import {BackToSearchButton} from '@/molecules/BackToSearchButton';
import {CustomerProfileActions} from '@/molecules/CustomerProfileActions';
import {CustomerBalance} from '@/organisms/CustomerBalance';
import {CustomerInfo} from '@/organisms/CustomerInfo';
import {MissionTable} from '@/organisms/MissionTable';
import {PointsHistoryTable} from '@/organisms/PointsHistoryTable';
import {RewardListTable} from '@/organisms/RewardListTable';
import {CUSTOMER_PROFILE_TABS} from '@/pages/Loyalty/CustomerProfile/interfaces.d';
import {useAuthentication} from '@/store/authentication';
import {useCustomerProfile} from '@/store/customerProfile';
import {useCustomerBalance} from '@/store/customerbalance';
import {useCustomerInfo} from '@/store/customersInfo';
import {
  PointsHistoryReset,
  RewardsListReset,
  MissionsListReset,
} from '@/store/loyaltyFilters/actions';
import {Base} from '@/templates/Base';

const tables: {[key: string]: any} = {
  [CUSTOMER_PROFILE_TABS.POINTS]: PointsHistoryTable,
  [CUSTOMER_PROFILE_TABS.REWARD]: RewardListTable,
  [CUSTOMER_PROFILE_TABS.MISSION]: MissionTable,
};

const CustomerProfile = () => {
  const {t} = useTranslation();

  const {postCustomerInfo} = useCustomerInfo();
  const {recoveryEmail} = useCustomerProfile();
  const {getCustomerTier, getTierInfo} = useCustomerBalance();
  const {showSuccessToast, showErrorToast} = useToast();
  const {cardNumber, banner, country} = useParams<BaseRouterParams>();
  const {isContactCenterAgent, isContactCenterSupervisor} = useAuthentication();
  const dispatch = useDispatch();

  const [updateFlag, setUpdateFlag] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<CUSTOMER_PROFILE_TABS>(
    CUSTOMER_PROFILE_TABS.POINTS,
  );

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Loyalty profile',
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(PointsHistoryReset());
      dispatch(RewardsListReset());
      dispatch(MissionsListReset());
    };
  }, [dispatch]);

  const tabs = useMemo(
    () => [
      {
        id: CUSTOMER_PROFILE_TABS.POINTS,
        label: t('CustomerProfile.tabs.pointsHistory'),
      },
      {
        id: CUSTOMER_PROFILE_TABS.REWARD,
        label: t('CustomerProfile.tabs.rewardList'),
      },
      {
        id: CUSTOMER_PROFILE_TABS.MISSION,
        label: t('CustomerProfile.tabs.mission'),
      },
    ],
    [t],
  );

  const {
    data: CustomersInfo,
    isLoading: CustomersInfoLoading,
    refetch: refetchCustomersInfo,
  } = useQuery(
    ['customers', cardNumber, country, banner],
    () =>
      postCustomerInfo({
        q: cardNumber,
        countrySearch: country,
        bannerSearch: banner,
      }),
    {},
  );

  const isSuspended = useMemo(() => {
    return Boolean(CustomersInfo?.userStatus === UserStatus.SUSPENDED);
  }, [CustomersInfo]);

  const enableApis = useMemo(() => {
    return Boolean(CustomersInfo) && Boolean(!isSuspended);
  }, [CustomersInfo, isSuspended]);

  const {
    data: CustomerTier,
    isLoading: CustomerTierLoading,
    refetch: refetchCustomerTier,
  } = useQuery(
    ['CustomerTier', cardNumber, country, banner],
    () =>
      getCustomerTier({
        cardNumberSearch: cardNumber,
        countrySearch: country,
        bannerSearch: banner,
      }),
    {
      retry: false,
    },
  );

  const {data: TierInfo, isLoading: TierInfoLoading} = useQuery(
    ['tierInfo', cardNumber, country, banner],
    () =>
      getTierInfo({
        cardNumberSearch: cardNumber,
        countrySearch: country,
        bannerSearch: banner,
      }),
    {
      retry: false,
    },
  );

  const {mutate: resendCardMutation, isLoading: loading} = useMutation(
    recoveryEmail,
    {
      networkMode: 'always',
      retry: false,
      onSuccess: () => {
        showSuccessToast(t('CustomerProfile.actions.resendCardToast.success'));
      },
      onError: () => {
        showErrorToast(t('CustomerProfile.actions.resendCardToast.error'));
      },
    },
  );

  const onCardResend = useCallback(() => {
    if (!loading) {
      resendCardMutation({
        email: CustomersInfo?.email!,
        bannerSearch: CustomersInfo?.brandId!,
        countrySearch: CustomersInfo?.country!,
      });
    }
  }, [CustomersInfo, loading, resendCardMutation]);

  const TableComponent = useMemo(() => {
    return tables[selectedTab];
  }, [selectedTab]);

  const tablesProps = useMemo(() => {
    return {
      enableApis: enableApis,
      updateFlag: updateFlag,
      canExport: isContactCenterAgent || isContactCenterSupervisor,
    };
  }, [enableApis, updateFlag, isContactCenterAgent, isContactCenterSupervisor]);

  const renderCustomerBalance = useMemo(() => {
    if (
      CustomerTierLoading ||
      !CustomerTier ||
      CustomersInfoLoading ||
      !CustomersInfo ||
      TierInfoLoading ||
      !TierInfo
    ) {
      return <Skeleton height="100%" animation="wave" variant="rounded" />;
    } else {
      return (
        <CustomerBalance
          points={CustomerTier.currentTier.currentBalanceValue}
          currentMin={CustomerTier.currentTier.configuration.minThreshold}
          nextMin={CustomerTier.nextTierConfig.minThreshold}
          pointsToNextTier={CustomerTier.currentTier.pointsToNextTier}
          lastUpdate={CustomerTier.currentTier.lastDateTransaction}
          tier={CustomerTier.currentTier.configuration.tierCode}
          tierLabel={CustomerTier.currentTier.configuration.tierName}
          cardNumber={CustomersInfo.cardNumber}
          refetchCustomersInfo={() => refetchCustomersInfo()}
          refetchTierInfos={() => refetchCustomerTier()}
          tierInfo={TierInfo}
          setUpdateFlag={setUpdateFlag}
        />
      );
    }
  }, [
    CustomerTier,
    CustomerTierLoading,
    CustomersInfo,
    CustomersInfoLoading,
    TierInfo,
    TierInfoLoading,
    refetchCustomerTier,
    refetchCustomersInfo,
  ]);

  const gridTemplateColumns = useMemo(() => {
    if (!isSuspended) {
      return {
        xs: '1fr 1fr',
        md: '1fr 392px',
      };
    }
  }, [isSuspended]);

  return (
    <Base>
      <Stack gap={1.5} flex={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <BackToSearchButton />
          {CustomersInfo && (
            <CustomerProfileActions
              onCardResend={onCardResend}
              refetch={() => refetchCustomersInfo()}
              isSuspended={isSuspended}
              isCardLoading={loading}
            />
          )}
        </Stack>
        <Stack
          display="grid"
          gap={1.5}
          gridTemplateColumns={gridTemplateColumns}>
          {CustomersInfoLoading || !CustomersInfo ? (
            <Skeleton height="100%" animation="wave" variant="rounded" />
          ) : (
            <CustomerInfo
              {...CustomersInfo}
              isSuspended={isSuspended}
              refetchCustomersInfo={() => refetchCustomersInfo()}
            />
          )}
          {!isSuspended && renderCustomerBalance}
        </Stack>
        {!isSuspended && (
          <Tabs
            mainTabId={selectedTab}
            tabs={tabs}
            isFilter
            onTabChange={route =>
              setSelectedTab(route[0] as CUSTOMER_PROFILE_TABS)
            }>
            <TableComponent {...tablesProps} />
          </Tabs>
        )}
      </Stack>
    </Base>
  );
};

export default React.memo(CustomerProfile);
