import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const tooltip = useMemo(
    () => ({
      backgroundColor: theme.palette.tertiary['light'],
      borderRadius: 0,
      border: 0,
      fontSize: '12px',
      width: 'auto',
      whiteSpace: 'pre-line',
      span: {
        color: theme.palette.tertiary['light'],
      },
    }),
    [theme],
  );

  return {tooltip};
};
