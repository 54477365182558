import {SingleFilter} from '@/atoms/FiltersChips/interfaces';

export interface TaskListFiltersProps {
  allFilterId: string;
  filters: SingleFilter[];
  selectedFilters: string[];
  onChange: (updatedFilters: string[]) => unknown;
  onOrderChange?: (order: ORDER_BY) => unknown;
  isLoading?: boolean;
  sort?: boolean;
  hasPadding?: boolean;
}

export enum ORDER_BY {
  DESC = 'DESC',
  ASC = 'ASC',
}
