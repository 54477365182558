import {useMemo} from 'react';
import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const wrapper: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }),
    [],
  );

  const button: SxProps<Theme> = useMemo(
    () => ({
      textTransform: 'uppercase',
    }),
    [],
  );

  const icon: SxProps<Theme> = useMemo(
    () => ({
      display: 'inline-block',
      marginRight: theme.spacing(1),
      cursor: 'pointer',
    }),
    [theme],
  );

  return {wrapper, button, icon};
};
