import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {FOOTER_HEIGHT} from '@/molecules/PageFooter/styles';
import {NAVBAR_HEIGHT} from '@/molecules/PageHeader/styles';
import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const headingGrid = useMemo(
    () => ({
      display: 'grid',
      gridTemplateColumns: `${theme.spacing(14)} 1fr`,
      columnGap: theme.spacing(1.2),
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: `${theme.spacing(22)} 1fr`,
      },
    }),
    [theme],
  );

  const grid = useCallback(
    (headerHeight: number): SxProps<Theme> => {
      const GAP_HEIGHT = theme.spacing(1.6);
      const CONTAINER_MARGIN = theme.spacing(2.4);
      const heightSubtract = `${headerHeight}px - ${NAVBAR_HEIGHT} - ${FOOTER_HEIGHT} - ${GAP_HEIGHT} - ${CONTAINER_MARGIN} - env(safe-area-inset-bottom)`;

      return {
        display: 'grid',
        gridTemplateColumns: `${theme.spacing(14)} 1fr`,
        columnGap: theme.spacing(1.2),
        height: `calc(100dvh - ${heightSubtract})`,
        '@-moz-document url-prefix()': {
          height: `calc(100vh - ${heightSubtract})`,
        },
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: `${theme.spacing(14)} 1fr 1fr`,
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: `${theme.spacing(22)} 1fr 1fr`,
        },
        '.feedbackRight': {
          overflowY: 'auto',
          scrollbarWidth: 'none',
        },
      };
    },
    [theme],
  );

  const anchors = useMemo(
    () => ({
      borderLeft: `${theme.spacing(0.1)} solid ${Colors.GreyDark}`,
      paddingLeft: theme.spacing(1.6),
      marginTop: theme.spacing(0.8),
    }),
    [theme],
  );

  const anchorBox = useMemo(
    () => ({
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    [],
  );

  return useMemo(
    () => ({
      headingGrid,
      grid,
      anchors,
      anchorBox,
    }),
    [anchorBox, anchors, grid, headingGrid],
  );
};
