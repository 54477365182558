import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '../api';
import {APIS, useApiPath} from '../api/useApiPath';
import {
  PatchCostumerInfoBody,
  PatchCostumerInfoResponse,
  PostCustomerInfoBody,
  PostCustomerInfoResponse,
  RecoveryEmailBody,
  RecoveryEmailResponse,
  PostCustomerinfoSubscribeBody,
  PostLoyaltyTiersInfoBody,
  PostLoyaltyTiersInfoResponse,
} from './interfaces';

export const useCustomerInfo = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const postCustomerInfo = useCallback(
    async ({...body}: PostCustomerInfoBody) => {
      try {
        const {data} = await api.post<PostCustomerInfoResponse>(
          getApiPath(APIS.LOYALTY_CUSTOMER_INFO),
          {
            ...body,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const patchCostumerInfo = useCallback(
    async (body: PatchCostumerInfoBody) => {
      try {
        const {data} = await api.patch<PatchCostumerInfoResponse>(
          getApiPath(APIS.LOYALTY_CUSTOMER_INFO),
          {
            ...body,
            gender: body?.gender || null,
          },
        );

        return data;
      } catch (error) {
        console.error('Costumer info', error);
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const verifyEmail = useCallback(
    async ({...body}: RecoveryEmailBody) => {
      try {
        const {data} = await api.post<RecoveryEmailResponse>(
          getApiPath(APIS.LOYALTY_VERIFY_EMAIL),
          {
            ...body,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const postCustomerinfoSubscribe = useCallback(
    async ({...body}: PostCustomerinfoSubscribeBody) => {
      try {
        await api.post(getApiPath(APIS.V1_LOYALTY_CUSTOMERINFO_SUBSCRIBE), {
          ...body,
        });
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const postLoyaltyTiersInfo = useCallback(
    async ({...body}: PostLoyaltyTiersInfoBody) => {
      try {
        const {data} = await api.post<PostLoyaltyTiersInfoResponse>(
          getApiPath(APIS.V1_LOYALTY_TIERS_LIST),
          {
            ...body,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        return null;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      patchCostumerInfo,
      postCustomerInfo,
      verifyEmail,
      postCustomerinfoSubscribe,
      postLoyaltyTiersInfo,
    }),
    [
      patchCostumerInfo,
      postCustomerInfo,
      verifyEmail,
      postCustomerinfoSubscribe,
      postLoyaltyTiersInfo,
    ],
  );
};
