import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Stack, useTheme} from '@mui/material';
import {GridColDef, GridValidRowModel} from '@mui/x-data-grid';
import {GridStateColDef} from '@mui/x-data-grid/internals';

import {Avatar} from '@/atoms/Avatar';
import {Badge} from '@/atoms/Badge';
import {CellPercentageArrow} from '@/atoms/CellPercentageArrow';
import {ContextMenu} from '@/atoms/ContextMenu';
import {ExpirationDot} from '@/atoms/Table/ExpirationDot';
import {TableColumnHeaderTooltip} from '@/atoms/TableColumnHeaderTooltip';
import {Text} from '@/atoms/Typography/Text';
import {RoleName} from '@/definitions/users';
import {RESOLUTION, calculateCampaignExpirationDays} from '@/lib/utils';
import {useNavigation} from '@/navigation/useNavigation';
import {useCampaignsContext} from '@/store/CampaignsContext';
import {TASK_STATUS, TASK_VISIBILITY} from '@/store/campaigns';
import {useLocale} from '@/store/locale';
import {Colors} from '@/themes/variables';

import {useStyle} from '../styles';

export type ExtendedColDef = GridStateColDef<GridValidRowModel, any, any> & {
  sortByKey: string;
};

export const useTableColumns = () => {
  const {t} = useTranslation();

  const theme = useTheme();
  const {formatDate} = useLocale();
  const {campaignExpiration} = useStyle();

  const {
    data: {status},
  } = useCampaignsContext();

  const {goToCampaignsReportDetail} = useNavigation();

  const taskColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'CUSTOMER',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('CustomerProfile.tables.storeAssociate.header.customer')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="tiny">{params.value}</Text>;
        },
        sortByKey: 'lastName',
        minWidth: 120 + 32,
      },
      {
        field: 'MOBILE',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('CustomerProfile.tables.storeAssociate.header.mobile')}
            />
          );
        },
        align: 'center',
        renderCell: params => {
          return (
            <Text variant="tiny" align="center">
              {params.value || '-'}
            </Text>
          );
        },
        sortByKey: 'mobile',
        minWidth: 100 + 32,
      },
      {
        field: 'ADDRESS_CITY',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t(
                'CustomerProfile.tables.storeAssociate.header.addressCity',
              )}
            />
          );
        },
        align: 'center',
        renderCell: params => {
          return (
            <Text variant="tiny" align="center">
              {params.value || '-'}
            </Text>
          );
        },
        sortByKey: 'addressCity',
        minWidth: 100 + 32,
      },
      {
        field: 'SEGMENT',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('CustomerProfile.tables.storeAssociate.header.segment')}
            />
          );
        },
        align: 'center',
        renderCell: params => {
          return (
            <Text variant="tiny" align="center">
              {params.value
                ? t(
                    `CustomerSegmentMapping.${params.value}.label`,
                    params.value,
                  )
                : '-'}
            </Text>
          );
        },
        sortByKey: 'segmentOrdering',
        minWidth: 100 + 32,
      },
      {
        field: 'LAST_VISIT_DATE',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('CustomerProfile.tables.storeAssociate.header.lastVisit')}
            />
          );
        },
        align: 'center',
        renderCell: params => {
          return (
            <Text variant="tiny" align="center">
              {params.value ? formatDate(params.value) : '-'}
            </Text>
          );
        },
        sortByKey: 'lastVisitDate',
        minWidth: 100 + 32,
      },
      {
        field: 'CURRENT_ASSIGNEE',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('CustomerProfile.tables.storeAssociate.header.assignee')}
            />
          );
        },
        align: 'center',
        renderCell: params => {
          if (params.value) {
            return (
              <Text
                variant="tiny"
                align="center"
                sx={{textTransform: 'capitalize'}}>
                {params.value.toLowerCase()}
              </Text>
            );
          }
          return (
            <Text italic color={theme.palette.error.main} variant="tiny">
              {t('CustomerProfile.tables.StoreManager.none')}
            </Text>
          );
        },
        sortable:
          status === TASK_STATUS.CONTACTED ||
          status === TASK_STATUS.ASSIGNED ||
          status === TASK_STATUS.ALL,
        sortByKey: 'assignee.lastname,assignee.firstname',
        minWidth: 100 + 32,
      },
      {
        field: 'CAMPAIGN',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('CustomerProfile.tables.storeAssociate.header.campaign')}
            />
          );
        },
        align: 'center',
        renderCell: params => {
          return (
            <Badge
              color={params?.value?.campaignColor}
              badgeLabel={params?.value?.campaignName}
            />
          );
        },
        sortByKey: 'campaignName',
        sortable: false,
        minWidth: 120 + 32,
      },
      {
        field: 'CALL_TIME',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('CustomerProfile.tables.storeAssociate.header.callTime')}
            />
          );
        },
        align: 'center',
        renderCell: params => {
          const remainingDays = calculateCampaignExpirationDays(params.value);
          return (
            <Box sx={campaignExpiration}>
              <Text variant="tiny">{formatDate(params.value)}</Text>
              {remainingDays <= 1 && (
                <ExpirationDot remainingDays={remainingDays} />
              )}
            </Box>
          );
        },
        sortByKey: 'dueDateTask',
        minWidth: 100 + 32,
      },
      {
        field: 'FEEDBACK_STATUS',
        flex: 1,
        headerAlign: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('CustomerProfile.tables.storeAssociate.header.status')}
            />
          );
        },
        align: 'center',
        renderCell: params => (
          <Text uppercase variant="tiny" align="center">
            {t(`SingleTaskDetails.feedback.STATUS.values.${params.value}`)}
          </Text>
        ),
        sortByKey: 'feedbackStatus',
        sortable: false,
        minWidth: 100 + 32,
      },
      // {
      //   field: 'action',
      //   headerName: t('CustomerProfile.tables.storeAssociate.header.action'),
      //   sortable: false,
      //   flex: 1,
      //   headerAlign: 'center',
      //   minWidth: 70 + 32,
      //   renderCell: params => {
      //     return (
      //       <ContextMenu
      //         subMenuItems={params.formattedValue}
      //         iconName="card_actions"
      //         iconSize={24}
      //       />
      //     );
      //   },
      // },
    ],
    [campaignExpiration, formatDate, status, t, theme],
  );

  const callbackColumns: GridColDef[] | any[] = useMemo(
    () => [
      {
        field: 'customer',
        headerName: t('CustomerProfile.tables.storeAssociate.header.customer'),
        sortable: false,
        flex: 1,
      },
      {
        field: 'mobile',
        headerName: t('CustomerProfile.tables.storeAssociate.header.mobile'),
        sortable: false,
        flex: 1,
      },
      {
        field: 'segment',
        headerName: t('CustomerProfile.tables.storeAssociate.header.segment'),
        flex: 1.1,
        sortByKey: 'segmentOrdering',
        valueFormatter: params =>
          params.value
            ? t(`CustomerSegmentMapping.${params.value}.label`, params.value)
            : params.value,
      },
      {
        field: 'assignee',
        headerName: t('CustomerProfile.tables.storeAssociate.header.assignee'),
        flex: 1.3,
        renderCell: params =>
          params.value ? (
            `${params.value.firstName} ${params.value.lastName}`
          ) : (
            <Text italic color={theme.palette.error.main} variant="tiny">
              {t('CustomerProfile.tables.StoreManager.none')}
            </Text>
          ),
        sortByKey: 'completedAssegnee',
      },
      {
        field: 'recall_time',
        headerName: t('CustomerProfile.tables.StoreManager.header.recallTime'),
        sortable: false,
        flex: 1,
        renderCell: params => (params.value ? formatDate(params.value) : '-'),
      },
      {
        field: 'feedbackStatus',
        headerName: t('CustomerProfile.tables.storeAssociate.header.status'),
        flex: 1,
        renderCell: params => (
          <Text italic uppercase variant="tiny">
            {t(`SingleTaskDetails.feedback.STATUS.values.${params.value}`)}
          </Text>
        ),
        sortByKey: 'feedbackStatus',
      },
      {
        field: 'action',
        headerName: t('CustomerProfile.tables.storeAssociate.header.action'),
        sortable: false,
        maxWidth: 70,
        renderCell: params => {
          return (
            <ContextMenu
              subMenuItems={params.formattedValue}
              iconName="card_actions"
              iconSize={24}
            />
          );
        },
      },
    ],
    [formatDate, t, theme],
  );

  const reportsColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'ZONE',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return <TableColumnHeaderTooltip text={t('ReportsTable.ZONE')} />;
        },
        renderCell: params => {
          return <Text variant="tiny">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 83,
      },
      {
        field: 'STORE',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return <TableColumnHeaderTooltip text={t('ReportsTable.STORE')} />;
        },
        renderCell: params => {
          return (
            <Box>
              <Text
                onClick={params.value.action}
                superHeavy
                uppercase
                underline
                variant="xs"
                sx={{cursor: 'pointer', ':hover': {opacity: 0.7}}}>
                {params.value.id}
              </Text>{' '}
              - <Text variant="xs">{params.value.name}</Text>
            </Box>
          );
        },
        flex: 2,
        minWidth: 168,
      },
      {
        field: 'FY_FW',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return <TableColumnHeaderTooltip text={t('ReportsTable.FY_FW')} />;
        },
        renderCell: params => {
          const bothNull =
            params.value?.fy === null && params.value?.fw === null;
          const stringToShow = bothNull
            ? '-'
            : `${params.value.fy}-${params.value.fw}`;
          return <Text variant="tiny">{stringToShow}</Text>;
        },
        flex: 1,
        minWidth: 91,
      },
      {
        field: 'NAME',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return <TableColumnHeaderTooltip text={t('ReportsTable.NAME')} />;
        },
        renderCell: params => {
          if (!params?.value?.color || !params?.value?.name) {
            return '-';
          }

          return (
            <Badge
              color={params?.value?.color}
              badgeLabel={params?.value?.name}
            />
          );
        },
        flex: 1,
        minWidth: 132,
      },
      {
        field: 'STATUS',
        disableReorder: true,
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return <TableColumnHeaderTooltip text={t('ReportsTable.STATUS')} />;
        },
        renderCell: params => {
          const isNull = params.value === null;
          const stringToShow = isNull
            ? '-'
            : t(`Campaign.card.${params.value ? 'active' : 'closed'}`);

          return (
            <Text variant="tiny" sx={{textTransform: 'capitalize'}}>
              {stringToShow}
            </Text>
          );
        },
        flex: 1,
        minWidth: 90,
      },
      {
        field: 'TASK_NUMBER',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip text={t('ReportsTable.TASK_NUMBER')} />
          );
        },
        renderCell: params => {
          return <Text variant="tiny">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 84,
      },
      {
        field: 'COMPLETED_TASK',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip text={t('ReportsTable.COMPLETED_TASK')} />
          );
        },
        renderCell: params => {
          return <Text variant="tiny">{params.value}</Text>;
        },
        flex: 1.5,
        minWidth: 120,
      },
      {
        field: 'MOM',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('ReportsTable.MOM')}
              tooltip={t('ReportsTable.MOM_TOOLTIP')}
            />
          );
        },
        renderCell: params => {
          return (
            <CellPercentageArrow textVariant="tiny" value={params.value} />
          );
        },
        flex: 1,
        minWidth: 110,
      },
      {
        field: 'POSITIVE_FEEDBACK',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('ReportsTable.POSITIVE_FEEDBACK')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="tiny">{params.value}</Text>;
        },
        flex: 1.5,
        minWidth: 120,
      },
      {
        field: 'APPOINTMENT_BOOKED',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('ReportsTable.APPOINTMENT_BOOKED')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="tiny">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 147,
      },
      {
        field: 'APPOINTMENT_CHECKIN',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('ReportsTable.APPOINTMENT_CHECKIN')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="tiny">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 147,
      },
    ],
    [t],
  );

  const reportDetailColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'ASSIGNEE',
        renderHeader: () => {
          return <TableColumnHeaderTooltip text={t('ReportsTable.ASSIGNEE')} />;
        },
        renderCell: params => {
          const {firstName, lastName, id} = params.value;
          return (
            <Box display="flex" flexDirection="row">
              <Box marginRight="8px">
                {id !== 'USER_UNASSIGNED' ? (
                  <Avatar name={firstName} surname={lastName} variant="dark" />
                ) : (
                  <Avatar name={firstName} surname={lastName} variant="light" />
                )}
              </Box>
              <Stack direction="column" justifyContent="center">
                <Text
                  variant="body"
                  medium
                  display="block"
                  sx={{
                    textTransform: 'capitalize',
                    lineHeight: '120%',
                  }}>
                  {`${firstName.toLocaleLowerCase()} ${lastName.toLocaleLowerCase()}`}
                </Text>
                {id !== 'USER_UNASSIGNED' && (
                  <Text
                    variant="xs"
                    display="block"
                    color={Colors.GreyDarker}
                    sx={{textTransform: 'capitalize'}}>
                    {`ID ${id}`}
                  </Text>
                )}
              </Stack>
            </Box>
          );
        },
        flex: 1.5,
        minWidth: 151 + 32,
      },
      {
        field: 'TASK_NUMBER',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip text={t('ReportsTable.TASK_NUMBER')} />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 0.5,
        minWidth: 58 + 32,
      },
      {
        field: 'NEW_TASK',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return <TableColumnHeaderTooltip text={t('ReportsTable.NEW_TASK')} />;
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 70 + 32,
      },
      {
        field: 'COMPLETED_TASK',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip text={t('ReportsTable.COMPLETED_TASK')} />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 100 + 32,
      },
      {
        field: 'MOM',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('ReportsTable.MOM')}
              tooltip={t('ReportsTable.MOM_TOOLTIP')}
            />
          );
        },
        renderCell: params => {
          if (params.value !== null) {
            return <CellPercentageArrow value={params.value} />;
          } else {
            return '-';
          }
        },
        flex: 1,
        minWidth: 74 + 32,
      },
      {
        field: 'POSITIVE_FEEDBACK',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('ReportsTable.POSITIVE_FEEDBACK')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 86 + 32,
      },
      {
        field: 'APPOINTMENT_BOOKED',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('ReportsTable.APPOINTMENT_BOOKED')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 115 + 32,
      },
      {
        field: 'APPOINTMENT_CHECKIN',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('ReportsTable.APPOINTMENT_CHECKIN')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 115 + 32,
      },
    ],
    [t],
  );

  const allCampaignsColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'NAME',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip text={t('AllCampaignsTable.NAME')} />
          );
        },
        renderCell: params => {
          const {title, color, code} = params.value;

          return (
            <Badge
              color={color}
              badgeLabel={title}
              {...(code && {onClick: () => goToCampaignsReportDetail(code)})}
            />
          );
        },
        flex: 1,
        minWidth: 100 + 32,
      },
      {
        field: 'TASK_NUMBER',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('AllCampaignsTable.TASK_NUMBER')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 0.5,
        minWidth: 58 + 32,
      },
      {
        field: 'NEW_TASK',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip text={t('AllCampaignsTable.NEW_TASK')} />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 70 + 32,
      },
      {
        field: 'COMPLETED_TASK',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('AllCampaignsTable.COMPLETED_TASK')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 100 + 32,
      },
      {
        field: 'MOM',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('AllCampaignsTable.MOM')}
              tooltip={t('AllCampaignsTable.MOM_TOOLTIP')}
            />
          );
        },
        renderCell: params => {
          return <CellPercentageArrow value={params.value} />;
        },
        flex: 1,
        minWidth: 74 + 32,
      },
      {
        field: 'POSITIVE_FEEDBACK',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('AllCampaignsTable.POSITIVE_FEEDBACK')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 86 + 32,
      },
      {
        field: 'APPOINTMENT_BOOKED',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('AllCampaignsTable.APPOINTMENT_BOOKED')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 115 + 32,
      },
      {
        field: 'APPOINTMENT_CHECKIN',
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => {
          return (
            <TableColumnHeaderTooltip
              text={t('AllCampaignsTable.APPOINTMENT_CHECKIN')}
            />
          );
        },
        renderCell: params => {
          return <Text variant="body">{params.value}</Text>;
        },
        flex: 1,
        minWidth: 115 + 32,
      },
    ],
    [goToCampaignsReportDetail, t],
  );

  return {
    taskColumns,
    callbackColumns,
    reportsColumns,
    reportDetailColumns,
    allCampaignsColumns,
  };
};

export const TaskListSortingKeys = new Map<string, string>([
  ['lastName', 'CUSTOMER'],
  ['mobile', 'MOBILE'],
  ['addressCity', 'ADDRESS_CITY'],
  ['segmentOrdering', 'SEGMENT'],
  ['lastVisitDate', 'LAST_VISIT_DATE'],
  ['assignee.lastname,assignee.firstname', 'CURRENT_ASSIGNEE'],
  ['campaignName', 'CAMPAIGN'],
  ['dueDateTask', 'CALL_TIME'],
  ['feedbackStatus', 'FEEDBACK_STATUS'],
]);

const taskListColumns = {
  CUSTOMER: true,
  MOBILE: true,
  ADDRESS_CITY: true,
  SEGMENT: true,
  LAST_VISIT_DATE: true,
  CURRENT_ASSIGNEE: true,
  CAMPAIGN: true,
  CALL_TIME: true,
  FEEDBACK_STATUS: true,
};

export const TaskListColumnsVisibility = new Map<
  TASK_VISIBILITY,
  Map<TASK_STATUS, {[key: string]: boolean}>
>([
  [
    TASK_VISIBILITY.ASSIGNTOME,
    new Map([
      [
        TASK_STATUS.NOTASSIGNED,
        {...taskListColumns, CURRENT_ASSIGNEE: false, FEEDBACK_STATUS: false},
      ],
      [TASK_STATUS.ASSIGNED, {...taskListColumns}],
      [TASK_STATUS.CONTACTED, {...taskListColumns, FEEDBACK_STATUS: false}],
      [TASK_STATUS.TODO, {...taskListColumns, CURRENT_ASSIGNEE: false}],
      [
        TASK_STATUS.COMPLETED,
        {...taskListColumns, CURRENT_ASSIGNEE: false, FEEDBACK_STATUS: false},
      ],
      [TASK_STATUS.ALL, {...taskListColumns, CURRENT_ASSIGNEE: false}],
    ]),
  ],
  [
    TASK_VISIBILITY.ALL,
    new Map([
      [
        TASK_STATUS.NOTASSIGNED,
        {...taskListColumns, CURRENT_ASSIGNEE: false, FEEDBACK_STATUS: false},
      ],
      [TASK_STATUS.ASSIGNED, {...taskListColumns}],
      [TASK_STATUS.CONTACTED, {...taskListColumns, FEEDBACK_STATUS: false}],
      [TASK_STATUS.TODO, {...taskListColumns, CURRENT_ASSIGNEE: false}],
      [
        TASK_STATUS.COMPLETED,
        {...taskListColumns, CURRENT_ASSIGNEE: false, FEEDBACK_STATUS: false},
      ],
      [TASK_STATUS.ALL, {...taskListColumns}],
    ]),
  ],
]);

export const ShowCheckbox = new Map<
  TASK_VISIBILITY,
  Map<TASK_STATUS, Map<RoleName, boolean>>
>([
  [
    TASK_VISIBILITY.ALL,
    new Map([
      [
        TASK_STATUS.ALL,
        new Map([
          [RoleName.STORE_ASSOCIATE, true],
          [RoleName.STORE_MANAGER, true],
          [RoleName.AREA_MANAGER, true],
        ]),
      ],
      [
        TASK_STATUS.NOTASSIGNED,
        new Map([
          [RoleName.STORE_ASSOCIATE, true],
          [RoleName.STORE_MANAGER, true],
          [RoleName.AREA_MANAGER, true],
        ]),
      ],
      [
        TASK_STATUS.ASSIGNED,
        new Map([
          [RoleName.STORE_ASSOCIATE, false],
          [RoleName.STORE_MANAGER, true],
          [RoleName.AREA_MANAGER, true],
        ]),
      ],
      [
        TASK_STATUS.CONTACTED,
        new Map([
          [RoleName.STORE_ASSOCIATE, false],
          [RoleName.STORE_MANAGER, false],
          [RoleName.AREA_MANAGER, false],
        ]),
      ],
    ]),
  ],
  [
    TASK_VISIBILITY.ASSIGNTOME,
    new Map([
      [
        TASK_STATUS.ALL,
        new Map([
          [RoleName.STORE_ASSOCIATE, false],
          [RoleName.STORE_MANAGER, true],
          [RoleName.AREA_MANAGER, true],
        ]),
      ],
      [
        TASK_STATUS.TODO,
        new Map([
          [RoleName.STORE_ASSOCIATE, false],
          [RoleName.STORE_MANAGER, true],
          [RoleName.AREA_MANAGER, true],
        ]),
      ],
      [
        TASK_STATUS.COMPLETED,
        new Map([
          [RoleName.STORE_ASSOCIATE, false],
          [RoleName.STORE_MANAGER, false],
          [RoleName.AREA_MANAGER, false],
        ]),
      ],
    ]),
  ],
]);

const singleCampaignColumns = {
  customer: true,
  mobile: true,
  segment: true,
  assignee: true,
  recall_time: true,
  feedbackStatus: true,
  action: true,
};

export const singleCampaignColumnVisibility: {
  [resolution in RESOLUTION]: {[field: string]: boolean};
} = {
  [RESOLUTION.DESKTOP]: {
    ...singleCampaignColumns,
  },
  [RESOLUTION.MOBILE]: {
    ...singleCampaignColumns,
    mobile: false,
  },
};
