import React from 'react';
import {useTranslation} from 'react-i18next';

import {Divider, Stack, Box} from '@mui/material';

import {Text} from '../../atoms/Typography/Text';
import {ValueCircle} from '../../atoms/ValueCircle';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const TierBlock = ({tier, tierLabel}: IProps) => {
  const styles = useStyle();

  const {t} = useTranslation();

  return (
    <Stack
      sx={styles.container}
      divider={<Divider flexItem orientation="vertical" sx={styles.divider} />}>
      <Text medium>{tierLabel}</Text>
      <Box height={40} width={40}>
        <ValueCircle unit={t('CustomerBalance.tier')} value={Number(tier)} />
      </Box>
    </Stack>
  );
};

export default React.memo(TierBlock);
