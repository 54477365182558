import React, {useMemo} from 'react';
import ReactImageGallery from 'react-image-gallery';

import {useTheme, Box, Stack, CircularProgress} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {SuspenseBackgroundImage} from '@/atoms/SuspenseBackgroundImage';
import {CarouselProps} from '@/molecules/Carousel/interfaces.d';
import {useStyles} from '@/molecules/Carousel/styles';

const Carousel = ({images, initialIndex, carouselHeight}: CarouselProps) => {
  const theme = useTheme();
  const styles = useStyles();

  const carouselHeightTheme = useMemo(
    () => (carouselHeight ? theme.spacing(carouselHeight) : undefined),
    [carouselHeight, theme],
  );

  return (
    <Box sx={styles.imageGalleryContainer(carouselHeightTheme)}>
      <ReactImageGallery
        items={images?.map(img => ({
          original: img,
          thumbnail: img,
          bulletClass: 'custom_bullet',
        }))}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={true}
        showBullets={true}
        startIndex={initialIndex ?? 0}
        infinite={false}
        renderRightNav={(onClick, disabled) => {
          return (
            <Stack
              className="right"
              sx={styles.navItem(disabled)}
              onClick={onClick}
              width={48}
              height={48}
              alignItems="center"
              justifyContent="center">
              <IconSVG icon="chevron_right" size={36} />
            </Stack>
          );
        }}
        renderLeftNav={(onClick, disabled) => {
          return (
            <Stack
              className="left"
              sx={styles.navItem(disabled)}
              onClick={onClick}
              width={48}
              height={48}
              alignItems="center"
              justifyContent="center">
              <IconSVG icon="chevron_left" size={36} />
            </Stack>
          );
        }}
        renderItem={item => {
          return (
            <React.Suspense
              key={item?.original}
              fallback={
                <Box sx={styles.imageGalleryItem(carouselHeightTheme)}>
                  <CircularProgress disableShrink color="primary" size={25} />
                </Box>
              }>
              <SuspenseBackgroundImage
                sx={styles.imageGalleryItem(carouselHeightTheme)}
                src={item?.original}></SuspenseBackgroundImage>
            </React.Suspense>
          );
        }}
      />
    </Box>
  );
};

export default React.memo(Carousel);
