import React from 'react';
import {Route, RouteProps, Redirect} from 'react-router-dom';

import ModuleRedirect from '@/navigation/middleware/ModuleRedirect';
import {useAuthentication} from '@/store/authentication';

const Guest = (props: RouteProps) => {
  const {isLoggedIn} = useAuthentication();

  if (isLoggedIn) {
    const redirect = localStorage.getItem('redirect');
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return <ModuleRedirect />;
  } else {
    return <Route {...props} />;
  }
};

export default React.memo(Guest);
