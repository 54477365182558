import React from 'react';
import {Typography} from '@mui/material';
import {IProps} from './definitions';
import {useStyles} from './styles';

const Text = ({
  variant = 'body',
  children,
  medium = false,
  heavy = false,
  uppercase = false,
  italic = false,
  underline = false,
  superHeavy = false,
  sx = [],
  ...props
}: IProps) => {
  const styles = useStyles({
    medium,
    heavy,
    superHeavy,
    uppercase,
    italic,
    underline,
  });
  const customSx = Array.isArray(sx) ? sx : [sx];
  return (
    <Typography variant={variant} {...props} sx={[styles.text, ...customSx]}>
      {children}
    </Typography>
  );
};

export default React.memo(Text);
