import {Modules} from '@/definitions/users';
import {GetSingleTaskParams} from '@/store/tasks/types';

export type PostUserFeedbackParams = {
  applicationName: Modules;
  feedback: string;
  imageData: string;
};

/* FEEDBACK CONFIG */

export type GetFeedbackConfigurationParams = {
  campaignCategory: string;
};

export type GetFeedbackConfigurationResponse = FeedbackField[];

export type FeedbackField = {
  campaignCategory: string;
  fieldCode: FIELD_CODE;
  fieldName: string;
  fieldType: FIELD_TYPE;
  fieldAcceptedValue?: FieldAcceptedValue;
  fieldOrder: number;
  feedbackTaskConfigId: number;
};

export enum FIELD_CODE {
  STATUS = 'STATUS',
  C_DATE_TIME = 'C_DATE_TIME',
  C_FEEDBACK = 'C_FEEDBACK',
  REFUSAL_REASON = 'REFUSAL_REASON',
  EE_APPOINTMENT_BOOKING = 'EE_APPOINTMENT_BOOKING',
  DATE_EE_APPOINTMENT = 'DATE_EE_APPOINTMENT',
  NOTE = 'NOTE',
  CUSTOM_APPOINTMENT = 'CUSTOM_APPOINTMENT',
}

export enum FIELD_TYPE {
  LIST = 'LIST',
  DATETIME = 'DATETIME',
  TEXTAREA = 'TEXTAREA',
  CUSTOM_APPOINTMENT = 'CUSTOM_APPOINTMENT',
}

export type FieldAcceptedValue = {
  [key: string]:
    | FEEDBACK_STATUS_VOICES
    | APPOINTMENT_BOOKING
    | CALL_FEEDBACK
    | REFUSAL_REASON;
};

export enum FEEDBACK_STATUS_VOICES {
  NEW = 'NEW',
  CONTACTED = 'CONTACTED',
  WAITING = 'WAITING',
  NO_ANSWER = 'NO_ANSWER',
  NOT_CONTACTABLE = 'NOT_CONTACTABLE',
  ALREADY_PURCHASED = 'ALREADY_PURCHASED',
}

export enum APPOINTMENT_BOOKING {
  NO_BOOKING = 'DWTB',
  BOOKED_ELSEWHERE = 'EBE',
  BOOKED_BANNER = 'EBIOB',
  APPOINTMENT_BOOKED = 'AB',
}

export enum CALL_FEEDBACK {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export enum REFUSAL_REASON {
  DO_NOT_CONTACT = 'DNC',
  ALREADY_BOUGHT_ELSEWHERE = 'ABE',
  NOT_INTERESTED_ANYMORE = 'NIA',
  NO_REASON_GIVEN = 'NRG',
}

export type GetFeedbackParams = GetSingleTaskParams;

export type GetFeedbackResponse = FeedbackDetails[];

export type PostFeedbackParams = GetSingleTaskParams & {
  assigneeId: string;
  feedbackObjects: FeedbackObject[];
  taskDate?: string;
};

export type PostFeedbackResponse = FeedbackObjectRes[];

export type FeedbackDetails = Pick<FeedbackField, 'fieldCode'> & {
  fieldValue:
    | FEEDBACK_STATUS_VOICES
    | APPOINTMENT_BOOKING
    | CALL_FEEDBACK
    | REFUSAL_REASON;
};

export type FeedbackObject = FeedbackDetails &
  Pick<FeedbackField, 'feedbackTaskConfigId'>;

export type FeedbackObjectRes = {
  taskId: number;
  feedbackCode: FIELD_CODE;
  feedbackValue:
    | FEEDBACK_STATUS_VOICES
    | APPOINTMENT_BOOKING
    | CALL_FEEDBACK
    | REFUSAL_REASON;
};
