import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const dialog: SxProps<Theme> = useMemo(
    () => ({
      // OVERLAY
      '.MuiBackdrop-root': {
        backgroundColor: theme.palette.dialog.main,
      },

      '.MuiPaper-root': {
        borderRadius: 0,
        p: theme.spacing(6),
        width: '700px',
        height: '680px',
        maxWidth: '700px',
      },

      '.MuiDialogContent-root': {
        '::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    }),
    [theme],
  );

  const dialogTitle: SxProps<Theme> = useMemo(
    () => ({
      textAlign: 'center',
    }),
    [],
  );

  const paragraphTitle: SxProps<Theme> = useMemo(
    () => ({
      padding: 0,
    }),
    [],
  );

  const list: SxProps<Theme> = useMemo(
    () => ({
      listStyle: 'disc !important',
      gap: theme.spacing(10),
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      rowGap: theme.spacing(1),
      marginLeft: theme.spacing(2),

      '& .MuiListItem-root': {
        display: 'list-item !important',
        wordBreak: 'break-word',
      },

      '& ::marker': {
        color: theme.palette.primary.main,
        fontSize: '25px',
      },
    }),
    [theme],
  );

  return {
    dialog,
    dialogTitle,
    paragraphTitle,
    list,
  };
};
