import React, {useState, useMemo, useRef, useEffect, useCallback} from 'react';
import {BsChevronRight} from 'react-icons/bs';

import {Skeleton, Stack, Box, Chip, Button} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {LabelValue} from '@/atoms/LabelValue';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useCommunicationModal} from '@/hooks/useCommunicationModal';
import {CustomAccordion} from '@/molecules/CustomAccordion';
import {useStyles} from '@/organisms/Benefits/styles';
import {
  GetBenefitsResponse,
  NOTIFICATION_TYPE,
  Communication,
  PROMO_TYPE,
} from '@/store/benefit';
import {Colors} from '@/themes/variables';

interface BenefitsProps {
  response?: GetBenefitsResponse;
}

const BASE_TRANSLATION = 'Benefits';

const Benefits = ({response}: BenefitsProps) => {
  const {communicationModal, toggleCommunicationModal} =
    useCommunicationModal();
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
  /**
   * State to manage the selected tab
   */
  const [selectedTab, setSelectedTab] = useState<NOTIFICATION_TYPE>(
    NOTIFICATION_TYPE.PROMO,
  );

  /**
   * State to manage the accordion open/close
   */
  const [isOpen, setIsOpen] = useState<boolean>(true);

  /**
   * Set the selected tab to promo when the accordion is closed
   */
  useEffect(() => {
    if (!isOpen) {
      setSelectedTab(NOTIFICATION_TYPE.PROMO);
    }
  }, [isOpen]);

  /**
   * Check if both tabs are empty
   * @returns boolean
   */
  const bothTabsEmpty = useMemo(() => {
    return (
      response?.benefits?.length === 0 && response?.notifications?.length === 0
    );
  }, [response?.benefits?.length, response?.notifications?.length]);

  /**
   * Check if the selected tab is empty
   * @returns boolean
   */
  const selectedTabEmpty = useMemo(() => {
    switch (selectedTab) {
      case NOTIFICATION_TYPE.PROMO:
        return response?.benefits?.length === 0;
      case NOTIFICATION_TYPE.COMMUNICATION:
        return response?.notifications?.length === 0;
      default:
        return false;
    }
  }, [
    response?.benefits?.length,
    response?.notifications?.length,
    selectedTab,
  ]);

  /**
   * Check if the accordion should be disabled
   */
  const disableClick = useMemo(() => {
    return typeof response === 'undefined' || bothTabsEmpty;
  }, [bothTabsEmpty, response]);

  const renderContent = useMemo(() => {
    if (!response) {
      return <Skeleton height="420px" animation="wave" variant="rounded" />;
    }

    if (bothTabsEmpty) {
      return (
        <Box textAlign="center">
          <BodyText>{getTranslationWithValue(0, 'emptyState.both')}</BodyText>
        </Box>
      );
    }

    if (selectedTabEmpty) {
      return (
        <Box textAlign="center">
          <BodyText>
            {getTranslationWithValue(0, `emptyState.${selectedTab}`)}
          </BodyText>
        </Box>
      );
    }

    switch (selectedTab) {
      case NOTIFICATION_TYPE.PROMO:
        return (
          <PromoCodeList
            communications={response?.benefits}
            toggleCommunicationModal={toggleCommunicationModal}
          />
        );
      case NOTIFICATION_TYPE.COMMUNICATION:
        return (
          <NotificationsList
            communications={response?.notifications}
            toggleCommunicationModal={toggleCommunicationModal}
          />
        );
      default:
        return null;
    }
  }, [
    bothTabsEmpty,
    getTranslationWithValue,
    response,
    selectedTab,
    selectedTabEmpty,
    toggleCommunicationModal,
  ]);

  const renderTabs = useMemo((): JSX.Element | undefined => {
    if (bothTabsEmpty) return undefined;

    return (
      <Stack direction="row" gap={0.8} alignItems="center">
        {(Object.keys(NOTIFICATION_TYPE) as Array<NOTIFICATION_TYPE>).map(
          key => {
            return (
              <Chip
                key={key}
                label={getTranslationWithValue(0, `tabs.${key}`)}
                onClick={() => setSelectedTab(NOTIFICATION_TYPE[key])}
                variant={
                  selectedTab === NOTIFICATION_TYPE[key]
                    ? 'secondLevelTabActive'
                    : 'secondLevelTabDefault'
                }
              />
            );
          },
        )}
      </Stack>
    );
  }, [bothTabsEmpty, getTranslationWithValue, selectedTab]);

  return (
    <>
      <Card>
        <CustomAccordion
          bottomContent
          labelAlign="center"
          disableClick={disableClick}
          hideAccordionLabel={bothTabsEmpty}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          accordionTitle={
            <HeadlineText superHeavy>
              {getTranslationWithValue(0, 'title')}
            </HeadlineText>
          }
          expandedContent={
            <Stack direction="column" gap={1.6}>
              {renderTabs && renderTabs}
              {renderContent}
            </Stack>
          }
        />
      </Card>
      {communicationModal}
    </>
  );
};

export default React.memo(Benefits);

interface CommunicationsListProps {
  communications: Array<Communication>;
  toggleCommunicationModal: (communication: Communication | undefined) => void;
}

const PromoCodeList = React.memo(
  ({communications, ...communicationModalProps}: CommunicationsListProps) => {
    const styles = useStyles();
    return (
      <Stack sx={styles.communicationListContainer}>
        {communications?.map(promoCode => {
          return (
            <CommunicationBox
              key={promoCode?.notificationId}
              communication={promoCode}
              toggleCommunicationModal={
                communicationModalProps.toggleCommunicationModal
              }
            />
          );
        })}
      </Stack>
    );
  },
);

PromoCodeList.displayName = 'PromoCodeList';

const NotificationsList = React.memo(
  ({communications, ...communicationModalProps}: CommunicationsListProps) => {
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
    /**
     * State to manage the offset of the notifications
     */
    const [offset, setOffset] = useState<number>(6);

    const styles = useStyles();

    /**
     * Ref to the notification list container
     */
    const notificationListContainerRef = useRef<HTMLDivElement | null>(null);

    /**
     * Ref to the notification boxes
     */
    const boxRefs = useRef<Array<HTMLDivElement>>([]);

    /**
     * Get the visible notifications based on the offset
     */
    const visibleNotifications = useMemo(() => {
      return communications.slice(0, offset);
    }, [communications, offset]);

    /**
     * Get the remaining notifications
     */
    const remainingNotifications = useMemo(() => {
      return communications.length - offset;
    }, [communications.length, offset]);

    /**
     * Set the max height of the notification list container so that max 6 notifications are visible
     */
    useEffect(() => {
      if (notificationListContainerRef.current && boxRefs.current) {
        if (boxRefs.current.length === 6) {
          notificationListContainerRef.current.style.maxHeight = `${notificationListContainerRef.current.clientHeight}px`;
        }
      }
    }, [boxRefs, visibleNotifications]);

    /**
     * Get the next offset based on the remaining notifications
     */
    const nextOffset = useMemo(() => {
      return remainingNotifications >= 3
        ? offset + 3
        : offset + remainingNotifications;
    }, [offset, remainingNotifications]);

    /**
     * Show more notifications
     */
    const showMoreNotifications = useCallback(() => {
      setOffset(nextOffset);
    }, [nextOffset]);

    /**
     * Scroll to the bottom of the notification list container when new notifications are added
     */
    useEffect(() => {
      if (notificationListContainerRef.current) {
        notificationListContainerRef.current.scrollTo({
          top: notificationListContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, [visibleNotifications]);

    return (
      <Stack sx={styles.communicationListContainer}>
        <Stack ref={notificationListContainerRef} className="notificationsList">
          {visibleNotifications?.map((notification, index) => {
            return (
              <Box
                ref={(el: HTMLDivElement) => (boxRefs.current[index] = el)}
                key={notification?.notificationId}>
                <CommunicationBox
                  communication={notification}
                  toggleCommunicationModal={
                    communicationModalProps.toggleCommunicationModal
                  }
                />
              </Box>
            );
          })}
        </Stack>
        {remainingNotifications > 0 && (
          <Box margin="0 auto">
            <Button variant="baseSecondary" onClick={showMoreNotifications}>
              {getTranslationWithValue(0, 'notificationsList.showMore', {
                remaining:
                  remainingNotifications >= 3 ? 3 : remainingNotifications,
                total: remainingNotifications,
              })}
            </Button>
          </Box>
        )}
      </Stack>
    );
  },
);

NotificationsList.displayName = 'NotificationsList';

interface BenefitIconProps {
  type: PROMO_TYPE;
  size: number;
}

export const BenefitIcon = React.memo(({type, size}: BenefitIconProps) => {
  return useMemo(() => {
    let icon: IconNames;
    switch (type) {
      case PROMO_TYPE.WELCOME:
        icon = 'welcome_benefit';
        break;
      case PROMO_TYPE.BIRTHDAY:
        icon = 'birthday_benefit';
        break;
      case PROMO_TYPE.ALL_RX:
        icon = 'glasses_benefit';
        break;
      case PROMO_TYPE.SUN_RX:
        icon = 'sunglasses_benefit';
        break;
      case PROMO_TYPE.CONTACTS:
        icon = 'contact_benefit';
        break;
      case PROMO_TYPE.ALL:
      default:
        icon = 'generic_benefit';
        break;
    }

    return <IconSVG icon={icon} size={size} color={Colors.GreyText} />;
  }, [size, type]);
});

BenefitIcon.displayName = 'BenefitIcon';

interface CommunicationBoxProps
  extends Pick<CommunicationsListProps, 'toggleCommunicationModal'> {
  communication: Communication;
}

const CommunicationBox = React.memo(
  ({communication, toggleCommunicationModal}: CommunicationBoxProps) => {
    const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);

    const styles = useStyles();

    /**
     * Check if the communication is a promo
     */
    const isPromo = useMemo(
      () => communication?.notificationType === NOTIFICATION_TYPE.PROMO,
      [communication?.notificationType],
    );

    return (
      <Stack
        key={communication?.notificationId}
        sx={styles.communicationBox}
        onClick={() => toggleCommunicationModal(communication)}>
        <Stack
          className={`iconContainer iconContainer__${
            isPromo ? 'big' : 'small'
          }`}>
          {isPromo ? (
            <BenefitIcon type={communication?.promoType!} size={62} />
          ) : (
            <IconSVG icon="notification" size={40} />
          )}
        </Stack>
        <Stack direction="column" gap={0.8}>
          <BodyText heavy>{communication?.cardTitle ?? '-'}</BodyText>
          <LabelValue
            value={communication?.email ?? '-'}
            label={getTranslationWithValue(0, 'communicationBox.sentTo')}
          />
          {isPromo && (
            <LabelValue
              value={
                communication?.endDate
                  ? dayjs(communication?.endDate).format(
                      dayjs.locale() === 'it' ? 'D MMM YYYY' : 'Do MMMM YYYY',
                    )
                  : '-'
              }
              label={getTranslationWithValue(0, 'communicationBox.expiringOn')}
            />
          )}
        </Stack>
        <BsChevronRight fontSize={24} />
      </Stack>
    );
  },
);

CommunicationBox.displayName = 'CommunicationBox';
