import React, {useCallback, useMemo, useState} from 'react';

import {MenuItem, SelectProps, TextField} from '@mui/material';

import {Text} from '@/atoms/Typography/Text';

import {IconComponent} from './IconComponent';
import {HomepageSelectProps} from './interfaces';
import {useStyle} from './styles';

const HomepageSelect = ({
  value,
  placeholder,
  options,
  ...props
}: HomepageSelectProps) => {
  const styles = useStyle();
  const [open, setOpen] = useState(false);

  const selectRenderValue = useCallback(
    (value: any) =>
      options.find(option => option.value === value)?.label ?? (
        <Text sx={styles.placeholderText}>{placeholder}</Text>
      ),
    [options, placeholder, styles],
  );

  const toggleOpen = useCallback((value?: boolean) => {
    setOpen(prevValue => {
      return typeof value !== 'undefined' ? value : !prevValue;
    });
  }, []);

  const selectProps: SelectProps = useMemo(
    () => ({
      displayEmpty: true,
      placeholder,
      open,
      renderValue: selectRenderValue,
      IconComponent: () => (
        <IconComponent
          {...(!props?.disabled && {onClick: () => toggleOpen()})}
        />
      ),
      MenuProps: {sx: styles.menu},
    }),
    [
      open,
      placeholder,
      props?.disabled,
      selectRenderValue,
      styles,
      toggleOpen,
    ],
  );

  return (
    <TextField
      {...props}
      {...(!props?.disabled && {onClick: () => toggleOpen()})}
      sx={styles.container}
      select
      value={value}
      SelectProps={selectProps}
      placeholder={placeholder}
      fullWidth
      focused={false}>
      {options.map(({value, label}) => (
        <MenuItem key={value} sx={styles.menuItem} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default React.memo(HomepageSelect);
