import React, {useMemo} from 'react';

import {Stack} from '@mui/material';

import {BodyText} from '@/atoms/BodyText';
import {XSText} from '@/atoms/XSText';
import {Colors} from '@/themes/variables';

interface LabelValueProps {
  label?: string | JSX.Element;
  value?: string | JSX.Element;
  secondValue?: string | JSX.Element;
  direction?: 'row' | 'column';
  className?: string;
}

const LabelValue = ({
  label,
  value,
  direction,
  secondValue,
  className = 'labelValue',
}: LabelValueProps) => {
  const printValue = useMemo(() => {
    if (typeof value === 'string') {
      return (
        <BodyText className="label" medium>
          {value}
        </BodyText>
      );
    }

    return value;
  }, [value]);

  const printSecondValue = useMemo(() => {
    if (typeof secondValue === 'string') {
      return (
        <BodyText className="label" medium>
          {secondValue}
        </BodyText>
      );
    }

    return secondValue;
  }, [secondValue]);

  const printLabel = useMemo(() => {
    if (typeof label === 'string') {
      return (
        <XSText className="value" color={Colors.GreyDark}>
          {label}
        </XSText>
      );
    }

    return label;
  }, [label]);

  return (
    <Stack
      className={className}
      direction={direction}
      gap={0.4}
      overflow="hidden"
      {...(direction === 'row' && {gap: 0.8, alignItems: 'center'})}>
      {printSecondValue}
      {printValue}
      {printLabel}
    </Stack>
  );
};

export default React.memo(LabelValue);
