import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {BodyText} from '@/atoms/BodyText';

import {OrderStatusProps} from '../interfaces';
import {useStyle} from './styles';

const ProductStatus = ({orderStatus}: {orderStatus: OrderStatusProps}) => {
  const BASE_TRANSLATION_PATH = 'Transaction.transactionInfo.orderStatuses';
  const styles = useStyle();
  const {t} = useTranslation();

  return useMemo(() => {
    if (orderStatus) {
      return (
        <BodyText heavy sx={styles.orderStatus(orderStatus)}>
          {t(`${BASE_TRANSLATION_PATH}.${orderStatus}`)}
        </BodyText>
      );
    }
    return null;
  }, [orderStatus, styles, t]);
};

export default React.memo(ProductStatus);
