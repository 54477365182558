import {Box, Stack} from '@mui/material';
import React from 'react';
import {Text} from '../Typography/Text';
import {StoreRowProps} from './interfaces';
import {useStyle} from './styles';

const StoreRow = ({store, onClick, activeStoreId}: StoreRowProps) => {
  const {select, select__active, bullet, bulletActive} = useStyle();

  const isActive = activeStoreId === store?.id;

  return (
    <Box sx={isActive ? select__active : select} onClick={onClick}>
      <Box sx={bullet}>
        {isActive ? <Box sx={bulletActive} /> : <></>}
      </Box>
      <Stack direction="row" alignItems="center" gap={0.6}>
        <Text uppercase superHeavy>
          {store?.storeId}
        </Text>
        <Text uppercase>{`- ${store?.storeName}`}</Text>
      </Stack>
    </Box>
  );
};

export default React.memo(StoreRow);
