import React, {useMemo} from 'react';

import {Chip} from '@mui/material';

import theme from '../../../../themes/theme';
import {Text} from '../../../atoms/Typography/Text';
import {IProps} from './interfaces';

const StatusCell = ({getValue, sx, onClick}: IProps) => {
  const type = useMemo(() => {
    let value = getValue();
    let bgColor;
    switch (value) {
      case 'Top Clients New Collection':
        bgColor = {
          backgroundColor: theme.palette.badge.newCollection,
        };
        break;
      case 'Top Clients Birthday':
        bgColor = {
          backgroundColor: theme.palette.badge.birthday,
        };
        break;
      case 'EE Callback':
        bgColor = {
          backgroundColor: theme.palette.badge.callback,
        };
        break;
      case 'Quote_Callback':
        bgColor = {
          backgroundColor: theme.palette.badge.quote,
        };
        break;
    }
    return bgColor;
  }, [getValue]);

  return (
    <Chip
      {...(onClick && {onClick: onClick})}
      label={<Text medium>{getValue()}</Text>}
      sx={sx || type}
    />
  );
};

export default React.memo(StatusCell);
