import {useCallback, useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const chip = useCallback(
    (color: string) => {
      return {
        backgroundColor: color,
        color: theme.palette.common.black,
        '& .MuiChip-label': {
          opacity: 1,
        },
      };
    },
    [theme],
  );

  const select: SxProps<Theme> = useMemo(
    () => ({
      border: `1px solid ${theme.palette.grey[700]}`,
      borderRadius: theme.spacing(0.8),
      padding: theme.spacing(1),

      '& .MuiPaper-root': {
        width: '200px',
      },
    }),
    [theme],
  );

  const selectDropdown: SxProps<Theme> = useMemo(
    () => ({
      width: '500px',

      li: {
        whiteSpace: 'normal',
      }
    }),
    [],
  );

  const bookDialogContent: SxProps<Theme> = useMemo(
    () => ({
      span: {
        fontWeight: '500',
      },
      textAlign: 'center',
    }),
    [],
  );

  return {chip, select, bookDialogContent, selectDropdown};
};
