import React from 'react';

import {HeadlineTextProps} from '@/atoms/HeadlineText/interfaces';
import {Text} from '@/atoms/Typography/Text';

const HeadlineText = ({children, ...textProps}: HeadlineTextProps) => {
  return (
    <Text variant="headline" lineHeight={1.2} {...textProps}>
      {children}
    </Text>
  );
};

export default HeadlineText;
