import React, {useMemo} from 'react';

import {Box, ButtonBase} from '@mui/material';

import {IconSVG} from '../IconSVG';
import {CardActionProps} from './interfaces';
import {useStyle} from './styles';

const CardAction = ({icon, onClick, disabled}: CardActionProps) => {
  const styles = useStyle();

  const boxStyle = useMemo(() => {
    const merge = [styles.container];

    if (disabled) {
      merge.push(styles.disabled);
    }

    return merge;
  }, [disabled, styles.container, styles.disabled]);

  return (
    <ButtonBase {...(!disabled && {onClick: onClick})} disabled={disabled}>
      <Box sx={boxStyle}>
        <IconSVG icon={icon} size={24} />
      </Box>
    </ButtonBase>
  );
};

export default React.memo(CardAction);
