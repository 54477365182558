import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {useTheme} from '@mui/material';

import {numberFormatter} from '@/lib/utils';

import {Text} from '../../../atoms/Typography/Text';
import {CellProps} from '../interfaces';

// @TODO: verify the actual points format from api
const PointsCell = ({getValue, row}: CellProps) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const value = useMemo(() => getValue(), [getValue]);
  const isPositive: boolean = useMemo(() => Number(value) >= 0, [value]);

  const expiration: string | undefined =
    row &&
    (row
      .getAllCells()
      .filter(cell => cell.column.id === 'expirationDate')[0]
      .getValue() as string);

  const color = () => {
    const now = new Date();
    const exp = expiration && new Date(expiration);

    // If the points are expired return grey
    if (exp && exp < now) {
      return theme.palette.grey[100];
    }

    // If the points are not expired return green or red based on the value (+/-)
    if (isPositive) {
      return theme.palette.success.main;
    } else if (!isPositive) {
      return theme.palette.error.main;
    }

    // If the points are not expired and the value is 0 return grey
    return theme.palette.text.primary;
  };

  return (
    <Text medium color={() => color()}>{`${
      isPositive ? '+' : '-'
    } ${numberFormatter.format(Math.abs(value))} ${t(
      'Table.Cell.points',
    )}`}</Text>
  );
};

export default React.memo(PointsCell);
