// imageCache.ts
const cache: Record<string, Promise<string> | boolean> = {};

function loadImage(src: string): boolean | never {
  if (!cache[src]) {
    cache[src] = new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(src);
      img.onerror = reject;
    }).then(
      src => {
        cache[src] = true;
        return src;
      },
      error => {
        cache[src] = false;
        throw error;
      },
    );
  }

  if (cache[src] instanceof Promise) {
    throw cache[src];
  }

  return cache[src] as boolean;
}

export default loadImage;
