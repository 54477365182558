import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, ButtonProps, Divider} from '@mui/material';

import {IProps} from './interfaces';
import {useStyle} from './styles';

const HomepageSearchButton = ({isDesktop, ...props}: IProps) => {
  const {t} = useTranslation();
  const styles = useStyle();

  const buttonProps: ButtonProps = useMemo(
    () => ({
      sx: styles.button,
      variant: 'primary',
      fullWidth: !isDesktop,
      ...props,
    }),
    [isDesktop, props, styles.button],
  );

  return (
    <>
      <Divider
        {...(isDesktop && {sx: {height: '100%', alignSelf: 'center', marginRight: '26px'}})}
        flexItem
        orientation={isDesktop ? 'vertical' : 'horizontal'}
      />
      <Button type={props.type} {...buttonProps}>{t('HomeFormBar.submitButton')}</Button>
    </>
  );
};

export default React.memo(HomepageSearchButton);
