import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = (clickable: boolean = false) => {
  const theme = useTheme();

  const card = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(1.2),
      boxShadow: '0px 7px 20px 0px rgba(78, 76, 76, 0.05)',
      paddingX: theme.spacing(2.4),
      paddingTop: theme.spacing(1.6),
      paddingBottom: theme.spacing(3),
      ...(clickable && {cursor: 'pointer'}),
    }),
    [theme, clickable],
  );

  const progressBar = useMemo<SxProps<Theme>>(
    () => ({
      width: '100%',
      height: 10,
      borderRadius: theme.spacing(10),
      border: `1px solid ${theme.palette.common.black}`,
    }),
    [theme],
  );

  const labelStyle = useMemo<SxProps<Theme>>(
    () => ({
      color: theme.palette.grey[700],
      lineHeight: theme.typography.tiny.fontSize,
      textTransform: 'capitalize',
      whiteSpace: 'pre-line',
    }),
    [theme],
  );

  return {card, progressBar, labelStyle};
};
