import dayjs from 'dayjs';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '../../../atoms/Typography/Text';
import {CellProps} from '../interfaces';

const DateCell = ({getValue, children}: CellProps) => {
  const {t} = useTranslation();

  const formattedValue = useMemo(() => {
    const value = getValue();
    if (value) {
      return dayjs(value).format(t('Date.formatShort'));
    }
    return '-';
  }, [getValue, t]);

  return (
    <Text medium>
      {formattedValue}
      {children}
    </Text>
  );
};

export default React.memo(DateCell);
