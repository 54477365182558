import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import axios from 'axios';

import {useToast} from '@/hooks/toast';
import {useAuthentication} from '@/store/authentication';

const AxiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    ...(process.env.REACT_APP_ENVIRONMENT === 'local' && {
      proxybff: 'bffService',
    }),
  },
});

export const useAPI = () => {
  const {logout} = useAuthentication();
  const {t} = useTranslation();
  const {showErrorToast} = useToast();

  const AxiosInstance = useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        ...(process.env.REACT_APP_ENVIRONMENT === 'local' && {
          proxybff: 'bffService',
        }),
      },
    });
  }, []);

  useEffect(() => {
    AxiosInstance.interceptors.request.use(request => {
      if (request.params) {
        Object.keys(request.params).forEach(key => {
          if (Array.isArray(request.params[key])) {
            request.params[key] = request.params[key].toString();
          }
        });
      }
      return request;
    });
    AxiosInstance.interceptors.response.use(
      response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        const errorStatus = error?.response?.status;
        if (errorStatus === 401) {
          logout();
          showErrorToast(t('Authentication.toast.error'), {
            toastId: 'authError',
          });
        }

        return Promise.reject(error);
      },
    );
  }, [
    logout,
    t,
    showErrorToast,
    AxiosInstance.interceptors.request,
    AxiosInstance.interceptors.response,
  ]);

  return {
    api: AxiosInstance,
  };
};

export const _api = AxiosApi;
