import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Card} from '@mui/material';

import {Text} from '@/atoms/Typography/Text';
import {Colors} from '@/themes/variables';

import {HeadingCardProps} from './interfaces';
import {useStyle} from './styles';

// Order of params to be listed in header card
const KEY_ORDER = [
  'taskNumber',           // 1
  'newTask',              // 2
  'contactedTask',        // 3
  'waitingForCallback',   // 4
  'noAnswer',             // 5
  'alreadyPurchased',     // 6
  'notContactable',       // 7
  'feedbackRating',       // 8
  'appointmentBooked',    // 9
  'appointmentCheckIn',   // 10
];

const HeadingCard = ({translationPath, items, ordered = true}: HeadingCardProps) => {
  const {t} = useTranslation();
  const styles = useStyle();

  // Render a boxed Value - Title element inside the heading banner card
  const renderHeadingBox = useCallback(
    (item: {title: string; value: string}, i: number) => {
      let title = translationPath
        ? t(`${translationPath}.${item?.title}`, item?.title)
        : item?.title;

      return (
        <Box key={`${i}-${item.title}-${item.value}`}>
          <Text variant="body" superHeavy display="block">
            {item.value}
          </Text>
          <Text variant="caption" color={Colors.GreyDarker}>
            {title}
          </Text>
        </Box>
      );
    },
    [t, translationPath],
  );

  const renderElements = useMemo(() => {
    if (ordered) {
      return KEY_ORDER.map((key, i) => {
        const orderedItem = items.find((item) => item.title === key);
        return renderHeadingBox(orderedItem!, i);
      });
    } else {
      return items.map((item, i) => {
        return renderHeadingBox(item, i);
      });
    }
  }, [items, ordered, renderHeadingBox]);

  return (
    <Card sx={styles.card}>
      <Box sx={styles.cardsGrid}>{renderElements}</Box>
    </Card>
  );
};

export default React.memo(HeadingCard);
