import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export function useBaseTranslation(...basePaths: Array<string>) {
  const {t} = useTranslation();

  const getTranslationWithValue = useCallback(
    (
      pathIndex: number,
      key: string,
      params: {[key: string]: any} = {},
      fallback?: string,
    ) => {
      return t(`${basePaths[pathIndex]}.${key}`, fallback ?? key, {...params});
    },
    [basePaths, t],
  );

  return useMemo(() => ({getTranslationWithValue}), [getTranslationWithValue]);
}
