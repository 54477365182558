import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Stack} from '@mui/material';

import {BodyText} from '@/atoms/BodyText';
import {IconSVG} from '@/atoms/IconSVG';
import {TinyText} from '@/atoms/TinyText';
import {RoleName} from '@/definitions/users';
import {formatToAssignee} from '@/lib/utils';
import {AssigneeActionProps, AssigneeProps} from '@/molecules/Assignee/types';
import {MembersModal} from '@/organisms/MembersModal';
import {Member} from '@/store/members/types';
import {SingleTaskAssignee, TaskStatus} from '@/store/tasks/types.d';

import {useStyles} from './styles';

const Assignee = ({
  assignee,
  userData,
  onChange,
  isTaskEditable,
  meAsAssignee,
  taskStatus,
  storeId,
}: AssigneeProps) => {
  const {t} = useTranslation();
  const {assigneeValue} = useStyles();
  const [openMembersDialog, setOpenMembersDialog] = useState<boolean>(false);

  const toggleMembers = useCallback(() => {
    setOpenMembersDialog(prevState => !prevState);
  }, []);

  /* Check if current logged-in user is Store Associate */
  const isStoreAssociate = useMemo(() => {
    return Boolean(
      userData?.roles?.find(role => role.name === RoleName.STORE_ASSOCIATE),
    );
  }, [userData]);

  /* Display assignee info if task is already assigned */
  const assigneeInfo = useMemo(() => {
    if (!assignee) return t('SingleTaskDetails.header.none');

    return `${assignee?.firstName.toLowerCase()} ${assignee?.lastName.toLowerCase()}`;
  }, [assignee, t]);

  /* Check whether task is assigned or not */
  const isAssigned = useMemo(() => {
    return Boolean(assignee);
  }, [assignee]);

  /* Pass new assign user data */
  const handleAssign = useCallback(
    (newAssign: SingleTaskAssignee) => {
      onChange(newAssign);
    },
    [onChange],
  );

  /* Pass new assign user data through modal */
  const handleMembersModalSave = useCallback(
    (member: Member) => {
      toggleMembers();
      handleAssign(formatToAssignee(member));
    },
    [handleAssign, toggleMembers],
  );

  /* Switch action based on user role */
  const handleActionChange = useCallback(() => {
    if (isStoreAssociate && meAsAssignee) {
      return handleAssign(meAsAssignee);
    } else {
      return toggleMembers();
    }
  }, [handleAssign, isStoreAssociate, meAsAssignee, toggleMembers]);

  const showAssignAction = useMemo(() => {
    return taskStatus !== TaskStatus.CLOSED;
  }, [taskStatus]);

  const modalStoreId = useMemo(() => {
    return storeId ?? userData?.currentStore?.id!;
  }, [storeId, userData?.currentStore?.id]);

  return (
    <>
      {/* <KeyValuePair */}
      {/*   keyLabel={t('SingleTaskDetails.header.assignee')} */}
      {/*   isFlex */}
      {/*   flexWrap="wrap"> */}
      {/*    */}
      {/* </KeyValuePair> */}
      <Stack direction="row" alignItems="baseline" gap={1}>
        {isAssigned && (
          <BodyText sx={assigneeValue} medium>
            {assigneeInfo}
          </BodyText>
        )}
        {showAssignAction && (
          <AssigneeAction
            isTaskEditable={isTaskEditable}
            isAssign={isAssigned}
            isStoreAssociate={isStoreAssociate}
            onClick={handleActionChange}
          />
        )}
      </Stack>
      {openMembersDialog && (
        <MembersModal
          currentAssignee={assignee}
          userLuxId={userData?.luxId!}
          banner={userData?.banner!}
          country={userData?.country!}
          onClose={toggleMembers}
          storeId={modalStoreId}
          onSave={handleMembersModalSave}
        />
      )}
    </>
  );
};

const AssigneeAction = React.memo(
  ({
    isAssign,
    isStoreAssociate,
    onClick,
    isTaskEditable,
  }: AssigneeActionProps) => {
    const {t} = useTranslation();
    const {assigneeActionContainer} = useStyles(isTaskEditable);
    /* Text to display based on user role and assign status */
    const text = useMemo(() => {
      if (isStoreAssociate) {
        return t('SingleTaskDetails.header.ctas.assignToMe');
      }

      return t(
        `SingleTaskDetails.header.ctas.${
          isAssign ? 'editAssignee' : 'assignTask'
        }`,
      );
    }, [isAssign, isStoreAssociate, t]);

    /* If task is assigned and user role is Store Associate display nothing */
    if (isAssign && isStoreAssociate) {
      return null;
    }

    return (
      <Stack
        direction="row"
        gap={0.4}
        sx={assigneeActionContainer}
        {...(onClick && {onClick: onClick})}>
        <TinyText superHeavy>{text}</TinyText>
        <IconSVG icon="assign_to_me" size={16} />
      </Stack>
    );
  },
);

AssigneeAction.displayName = 'AssigneeAction';

export default React.memo(Assignee);
