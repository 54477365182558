import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const pageWrapper = useMemo<SxProps<Theme>>(
    () => ({
      flexFlow: 'column',
      gap: theme.spacing(1.6),
      height: `calc(100dvh - ${theme.spacing(
        14.4,
      )} - env(safe-area-inset-bottom))`,
      '@-moz-document url-prefix()': {
        height: `calc(100vh - ${theme.spacing(
          14.4,
        )} - env(safe-area-inset-bottom))`,
      },
    }),
    [theme],
  );

  const headerWrapper = useMemo<SxProps<Theme>>(
    () => ({
      flexFlow: 'column',
      gap: theme.spacing(3.2),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(2.4),
      },
    }),
    [theme],
  );

  const cardsListWrapper = useMemo<SxProps<Theme>>(
    () => ({
      height: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
      '.emptyStateCard': {
        height: `calc(100% - ${theme.spacing(2.4)})`,
      },
      '.loadingSkeleton': {
        width: '100%',
        height: `calc(100% - ${theme.spacing(2.4)})`,
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      pageWrapper,
      headerWrapper,
      cardsListWrapper,
    }),
    [pageWrapper, headerWrapper, cardsListWrapper],
  );
};
