import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const singleColumn: SxProps<Theme> = useMemo(
    () => ({
      '&:last-child': {
        paddingLeft: theme.spacing(1.8),
        borderLeft: `1px solid ${theme.palette.grey[700]}`,
      },
    }),
    [theme],
  );

  return {singleColumn};
};
