import React, {useCallback, useMemo, useState, PropsWithChildren} from 'react';

import {Box, Chip, Container, Divider, Paper} from '@mui/material';

import {OneLevelTab, SingleTab, TabProps} from './interfaces';
import {useStyles} from './styles';

const Tabs = ({
  mainTabId,
  subTabId,
  tabs,
  onTabChange,
  children,
  isFilter = false,
}: PropsWithChildren<TabProps>) => {
  const {paper, tabLabel, firstLevelTabLabelContainer, divider} =
    useStyles(isFilter);

  const changeRoute = useCallback((tab: SingleTab) => {
    const route = [tab?.id];
    if (tab?.tabsSecondLevel) {
      const firstSubTab = tab?.tabsSecondLevel[0];
      route.push(firstSubTab?.id);
    }

    return route;
  }, []);

  const [currentRoute, setCurrentRoute] = useState<string[]>(() => {
    const route = [mainTabId];
    if (subTabId) {
      route.push(subTabId);
    }

    return route;
  });

  const handleSubTabClick = useCallback(
    (tab: OneLevelTab) => {
      setCurrentRoute(prevRoute => {
        const updatedRoute = [...prevRoute];
        updatedRoute.pop();
        updatedRoute.push(tab?.id);
        onTabChange && onTabChange(updatedRoute);
        return updatedRoute;
      });
    },
    [onTabChange],
  );

  const handleTabClick = useCallback(
    (tab: SingleTab) => {
      const newRoute = changeRoute(tab);
      setCurrentRoute(prevRoute => {
        onTabChange && onTabChange(newRoute);
        return newRoute;
      });
    },
    [changeRoute, onTabChange],
  );

  const subTabs = useMemo(() => {
    const currentMainTabId = currentRoute[0];
    const subTab = tabs.find(t => t?.id === currentMainTabId);

    if (!subTab || !subTab.tabsSecondLevel) {
      return null;
    }

    return (
      <>
        {subTab.tabsSecondLevel?.map(sub => (
          <Chip
            sx={tabLabel}
            label={sub.label}
            key={sub.id}
            onClick={() => handleSubTabClick(sub)}
            variant={
              currentRoute.includes(sub?.id)
                ? 'secondLevelTabActive'
                : 'secondLevelTabDefault'
            }
          />
        ))}
      </>
    );
  }, [currentRoute, handleSubTabClick, tabLabel, tabs]);

  return (
    <Paper sx={paper}>
      <Container maxWidth="xl">
        <Box sx={firstLevelTabLabelContainer}>
          {tabs.map((tab, index) => {
            return (
              <Chip
                sx={tabLabel}
                label={tab.label}
                key={tab.id}
                onClick={() => handleTabClick(tab)}
                variant={
                  isFilter
                    ? currentRoute.includes(tab?.id)
                      ? 'filterDefaultTableActive'
                      : 'filterDefaultTable'
                    : currentRoute.includes(tab?.id)
                    ? 'firstLevelTabActive'
                    : 'firstLevelTabDefault'
                }
              />
            );
          })}
        </Box>
        <Divider sx={divider} />
        <Box>{subTabs}</Box>
        {children}
      </Container>
    </Paper>
  );
};

export default React.memo(Tabs);
