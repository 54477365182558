import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();
  const tableContainer = useMemo(
    () => ({
      borderSpacing: `0 ${theme.spacing(0.8)}`,
      backgroundColor: theme.palette.common.white,
      borderRadius: '12px',

      '& th': {
        whiteSpace: 'nowrap',
      },

      '& thead > tr': {
        position: 'relative',
        top: '-8px',
      },
    }),
    [theme],
  );

  const tableHeadCell = useMemo(
    () => ({
      padding: `${theme.spacing(1)}`,
      borderBottom: `0.5px solid ${theme.palette.grey['700']}`,
      backgroundColor: theme.palette.common.white,

      '&:first-of-type': {
        paddingLeft: theme.spacing(3),
      },
    }),
    [theme],
  );

  const tableBodyRow = useMemo(
    () => ({
      backgroundColor: theme.palette.grey[500],
      borderRadius: '6px',
    }),
    [theme.palette.grey],
  );

  const tableBodyCell = useMemo(
    () => ({
      borderBottom: 'unset',
      padding: `${theme.spacing(1.5)} 1`,

      '&:first-of-type': {
        paddingLeft: theme.spacing(3),
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
      },

      '&:last-of-type': {
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
      },
    }),
    [theme],
  );

  const flexBox = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'flex-end',
    }),
    [],
  );

  const button = useCallback(
    (canResetFilters = true): SxProps<Theme> => ({
      opacity: canResetFilters ? 1 : 0.5,
      pointerEvents: canResetFilters ? 'auto' : 'none',
    }),
    [],
  );

  const text = useMemo(
    () => ({
      paddingBottom: 0,
      margin: '10px',
      color: theme.palette.common.black,
      fontWeight: 'bold',
      borderBottom: `2px solid ${theme.palette.common.black}`,
    }),
    [theme],
  );

  const rightSticky = useMemo(
    () => ({
      position: 'sticky',
      right: 0,
    }),
    [],
  );

  const leftSticky = useMemo(
    () => ({
      position: 'sticky',
      left: 0,
    }),
    [],
  );

  const baseCellContainer = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      '& .sorting': {
        marginLeft: '10px',
      },
    }),
    [],
  );

  return {
    tableContainer,
    tableHeadCell,
    tableBodyCell,
    tableBodyRow,
    flexBox,
    button,
    text,
    rightSticky,
    leftSticky,
    baseCellContainer,
  };
};
