import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

import {IBadge} from './interfaces';

export const useStyle = (
  color: IBadge['color'],
  variant: IBadge['variant'],
  clickable: boolean,
) => {
  const theme = useTheme();

  const badge: SxProps<Theme> = useMemo(() => {
    let backgroundColor;
    let fontSize;

    if (color) {
      backgroundColor = color;
    }

    switch (variant) {
      case 'headline5':
        fontSize = theme.typography.headline5;
        break;
      case 'tiny':
        fontSize = theme.typography.tiny;
        break;
      case 'xxs':
        fontSize = theme.typography.xxs;
        break;
    }

    return {
      maxWidth: '100%',
      width: 'fit-content',
      color: theme.palette.common.black,
      padding:
        variant === 'headline5'
          ? theme.spacing(0.4, 0.8, 0.4, 0.8)
          : theme.spacing(0.3, 0.6, 0.2, 0.6),
      borderRadius: theme.spacing(10),
      backgroundColor: backgroundColor,
      textTransform: 'capitalize',
      fontSize: fontSize,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cursor: clickable ? 'pointer' : 'default',
    };
  }, [color, theme, variant, clickable]);

  return {badge};
};
