import { SxProps, Theme, useTheme } from '@mui/material';
import {useMemo} from 'react';

export const useStyles = () => {
  const theme = useTheme();

  const container = useMemo(() => ({alignItems: 'center'}), []);

  const select: SxProps<Theme> = useMemo(
    () => ({
      border: `1px solid ${theme.palette.grey[700]}`,
      borderRadius: theme.spacing(0.8),
      padding: theme.spacing(1),
    }),
    [theme],
  );

  return {container, select};
};
