import {useMemo, useCallback} from 'react';

import {useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const imageGalleryContainer = useCallback(
    (containerHeight = theme.spacing(55)) => ({
      width: '100%',
      height: containerHeight,
      backgroundColor: theme.palette.grey[300],
      borderRadius: theme.spacing(0.8),
      '.custom_bullet': {
        backgroundColor: theme.palette.primary[200],
        border: 'none',
        boxShadow: 'none',
        transform: `scale(1)`,
        margin: `0 ${theme.spacing(0.8)}`,
        padding: theme.spacing(0.4),
        '&:hover': {
          transform: `scale(1)`,
          backgroundColor: theme.palette.common.black,
          border: 'none',
        },
        '&.active': {
          transform: `scale(1)`,
          border: 'none',
          backgroundColor: theme.palette.common.black,
          '&:hover': {
            transform: `scale(1)`,
            backgroundColor: theme.palette.common.black,
            border: 'none',
          },
        },
      },
    }),
    [theme],
  );

  const navItem = useCallback(
    (disabled: boolean) => ({
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 999,
      cursor: 'pointer',
      backgroundColor: theme.palette.common.white,
      borderStyle: 'solid',
      borderWidth: theme.spacing(0.1),
      borderRadius: theme.spacing(0.4),
      borderColor: theme.palette.primary[200],
      transition: 'all 0.2s linear',
      ...(!disabled
        ? {
            opacity: 1,
          }
        : {
            opacity: 0,
            cursor: 'not-allowed',
            pointerEvents: 'none',
          }),
      '&.left': {
        left: theme.spacing(1.2),
      },
      '&.right': {
        right: theme.spacing(1.2),
      },
    }),
    [theme],
  );

  const imageGalleryItem = useCallback(
    (imageHeight = theme.spacing(55)) => ({
      height: imageHeight,
      width: `calc(100% - ${theme.spacing(12)})`,
      margin: '0 auto',
      display: 'grid',
      placeItems: 'center',
    }),
    [theme],
  );

  return useMemo(
    () => ({imageGalleryContainer, navItem, imageGalleryItem}),
    [imageGalleryContainer, navItem, imageGalleryItem],
  );
};
