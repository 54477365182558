import {FEEDBACK_STATUS, FEEDBACK_FIELD_CODES} from '@/store/feedback/v2';

type FeedbackDisplayConfigurationProps = {
  [key in FEEDBACK_STATUS]: Array<FEEDBACK_FIELD_CODES>;
};

export const feedbackDisplayConfiguration: FeedbackDisplayConfigurationProps = {
  [FEEDBACK_STATUS.NEW]: [
    FEEDBACK_FIELD_CODES.C_DATE_TIME,
    FEEDBACK_FIELD_CODES.NOTE,
    FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING,
  ],
  [FEEDBACK_STATUS.NO_ANSWER]: [
    FEEDBACK_FIELD_CODES.C_DATE_TIME,
    FEEDBACK_FIELD_CODES.NOTE,
    FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING,
  ],
  [FEEDBACK_STATUS.WAITING]: [
    FEEDBACK_FIELD_CODES.C_DATE_TIME,
    FEEDBACK_FIELD_CODES.NOTE,
    FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING,
  ],
  [FEEDBACK_STATUS.CONTACTED_NEGATIVE]: [
    FEEDBACK_FIELD_CODES.REFUSAL_REASON,
    FEEDBACK_FIELD_CODES.NOTE,
    FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING,
  ],
  [FEEDBACK_STATUS.ALREADY_PURCHASED]: [
    FEEDBACK_FIELD_CODES.NOTE,
    FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING,
  ],
  [FEEDBACK_STATUS.CONTACTED_POSITIVE]: [
    FEEDBACK_FIELD_CODES.NOTE,
    FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING,
  ],
  [FEEDBACK_STATUS.NOT_CONTACTABLE]: [
    FEEDBACK_FIELD_CODES.NOTE,
    FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING,
  ],
};
