import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {GoAlert} from 'react-icons/go/index';

import {Box, Button, Checkbox, FormControlLabel, Stack} from '@mui/material';

import {Text} from '@/atoms/Typography/Text';

import {ISubscribeForm} from './interfaces';
import {useStyle} from './styles';

const SubscribeForm = ({
  isDisabled,
  isChecked,
  setIsChecked,
  name,
  onConfirm,
  disableCheck,
}: ISubscribeForm) => {
  const {t} = useTranslation();
  const {formControl, container, warning, button} = useStyle();

  useEffect(() => {
    isChecked && onConfirm();
  }, [isChecked, onConfirm]);

  return (
    <Stack sx={container} spacing={1.5} alignItems="center">
      <FormControlLabel
        sx={formControl}
        name={name}
        control={
          <Checkbox
            disabled={disableCheck}
            disableRipple={true}
            checked={isChecked}
            onChange={setIsChecked}
          />
        }
        label={
          <Text variant="headline5">
            {t('CustomerInfo.subscribeForm.checkboxLabel')}
          </Text>
        }
      />
      {!isChecked ? (
        <>
          <Box sx={warning}>
            <GoAlert fontSize={24} />
            <Text>{t('CustomerInfo.subscribeForm.warning')}</Text>
          </Box>
          <Button
            variant="primary"
            sx={button}
            disabled={isDisabled}
            onClick={onConfirm}>
            {t('CustomerInfo.subscribeForm.button')}
          </Button>
        </>
      ) : (
        <Box height={60.4} />
      )}
    </Stack>
  );
};

export default React.memo(SubscribeForm);
