import {ChangeEvent, useCallback, useReducer, useMemo} from 'react';

import {SelectChangeEvent} from '@mui/material';

import {MissionsListFilter} from '@/store/loyaltyFilters/missionsList.reducer';
import {PointsHistoryFilter} from '@/store/loyaltyFilters/pointsHistory.reducer';
import {RewardsListFilter} from '@/store/loyaltyFilters/rewardsList.reducer';

import {DateFilters, FilterActionTypes} from './interfaces';

type Filters = PointsHistoryFilter | RewardsListFilter | MissionsListFilter;

export const useFilterDialogFunctions = <FilterType extends Filters>(
  sessionState = {},
) => {
  const initialState = useMemo(() => {
    return {
      ...sessionState,
    } as Partial<FilterType>;
  }, [sessionState]);

  function reducer(
    state: Partial<FilterType>,
    action: FilterActionTypes<FilterType>,
  ): Partial<FilterType> {
    switch (action.type) {
      case 'RESET':
        return initialState;
      case 'CHANGE_FIELD':
        return {...state, ...action.payload};
      case 'CHANGE_DATE':
        return {
          ...state,
          ...action.payload,
        };
      case 'SET_FILTERS':
        return {
          ...action.payload,
        };
      default:
        return state;
    }
  }

  const [formData, dispatch] = useReducer(reducer, initialState);

  const handleChangeField = useCallback(
    (
      e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | undefined>,
      fieldName: keyof FilterType,
    ) => {
      const newData = {
        [fieldName]: e.target.value,
      } as unknown as Partial<FilterType>;
      dispatch({
        type: 'CHANGE_FIELD',
        payload: {...newData},
      });
    },
    [dispatch],
  );

  const handleDate = useCallback(
    (date: Date | null, fieldName: keyof DateFilters) => {
      const newData = {
        [fieldName]: date ? date : undefined,
      } as unknown as Partial<FilterType>;
      dispatch({
        type: 'CHANGE_DATE',
        payload: {...newData},
      });
    },
    [dispatch],
  );

  const handleReset = useCallback(() => {
    dispatch({
      type: 'RESET',
      payload: initialState,
    });
  }, [initialState]);

  const handleState = useCallback((filters: Partial<FilterType>) => {
    dispatch({
      type: 'SET_FILTERS',
      payload: {...filters},
    });
  }, []);

  return {
    formData,
    dispatch,
    handleChangeField,
    handleDate,
    handleReset,
    handleState,
    initialState,
  };
};
