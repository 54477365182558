import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const header = useMemo(
    () => ({
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      gap: theme.spacing(2.4),
      '& .close': {
        cursor: 'pointer',
      },
    }),
    [theme],
  );

  const contentStoreWrapper = useMemo(() => {
    return {
      width: '100%',
    };
  }, []);

  const contentStores = useMemo(
    () => ({
      width: '100%',
      marginTop: theme.spacing(2),
      overflowY: 'auto',
      height: 370,
      paddingBottom: '40px',
      paddingRight: theme.spacing(0.8),
    }),
    [theme],
  );

  const dialog = useMemo(
    () => ({
      '.MuiPaper-root': {
        padding: theme.spacing(2.4),
        width: '600px',
        borderRadius: '16px',
      },
      '.MuiDialogContent-root': {
        marginBottom: theme.spacing(0.8),
      },
      '.MuiDialogActions-root': {
        p: 0,
      },
    }),
    [theme],
  );

  const contentInitial = useMemo(
    () => ({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(2.4),
      height: 370,
      position: 'absolute',
      right: 0,
      left: 0,
    }),
    [theme],
  );

  const bottomGradient = useMemo(
    () => ({
      width: '100%',
      height: '110px',
      backgroundColor: '#ffffff',
      position: 'absolute',
      left: '0px',
      bottom: '30px',
      background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%)',
    }),
    [],
  );

  const cta = useMemo(
    () => ({
      fontSize: '14px',
      padding: '8px 16px 8px 16px',
      borderRadius: '6px',
      marginTop: '32px',
    }),
    [],
  );

  const noResultText = useMemo(
    () => ({
      textAlign: 'center',
      padding: theme.spacing(0, 1.6),
      maxWidth: theme.spacing(32.6),
    }),
    [theme],
  );


  return {
    header,
    contentStoreWrapper,
    contentStores,
    dialog,
    contentInitial,
    bottomGradient,
    cta,
    noResultText
  };
};
