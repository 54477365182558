import dayjs from 'dayjs';

import {
  MembersReportFilters,
  MembersReportFiltersActions,
  MembersReportFiltersAction,
} from '@/store/membersReportFilters/definitions';

export const membersReportFiltersInitialStartDate = dayjs().startOf('month');
export const membersReportFiltersInitialEndDate = dayjs();

export const getInitialState = (): MembersReportFilters => ({
  assignees: [],
  codes: [],
  startDate: membersReportFiltersInitialStartDate.format('YYYY-MM-DD'),
  endDate: membersReportFiltersInitialEndDate.format('YYYY-MM-DD'),
  page: 1,
  orderBy: 'DESC',
  sortBy: 'COMPLETED_TASK',
});

export const membersReportFiltersReducer = (
  state = getInitialState(),
  action: MembersReportFiltersAction,
) => {
  switch (action.type) {
    case MembersReportFiltersActions.changeField:
      const {key, value} = action?.payload;
      return {
        ...state,
        [key]: value,
        page: 1,
      };
    case MembersReportFiltersActions.changeDates:
      const hasDiffStart = !dayjs(state.startDate).isSame(
        action?.payload?.startDate,
        'date',
      );
      const hasDiffEnd = !dayjs(state.endDate).isSame(
        action?.payload?.endDate,
        'date',
      );
      return {
        ...state,
        ...((hasDiffStart || hasDiffEnd) && {
          codes: [],
        }),
        startDate: action?.payload?.startDate?.format('YYYY-MM-DD'),
        endDate: action?.payload?.endDate?.format('YYYY-MM-DD'),
        page: 1,
      };
    case MembersReportFiltersActions.changePage:
      return {
        ...state,
        page: action?.payload,
      };
    case MembersReportFiltersActions.resetFilters:
      return getInitialState();
    default:
      return state;
  }
};
