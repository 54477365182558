import {useMemo} from 'react';
import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const button: SxProps<Theme> = useMemo(
    () => ({
      alignSelf: 'center',
      minWidth: 'unset',
      textTransform: 'uppercase',

      ':hover': {
        backgroundColor: theme.palette.common.black,
      }
    }),
    [theme],
  );

  return {button};
};
