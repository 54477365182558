import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useDashboardStyle = () => {
  const theme = useTheme();

  const mainContainer: SxProps<Theme> = useMemo(
    () => ({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(3.2),
      paddingBottom: theme.spacing(2.4),
    }),
    [theme],
  );

  const container: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      flexFlow: 'column',
      gap: theme.spacing(2.4),
      margin: '0 auto',
      width: theme.spacing(70.4),
      maxWidth: theme.spacing(70.4),
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(79.8),
        maxWidth: theme.spacing(79.8),
      },
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(86.6),
        maxWidth: theme.spacing(86.6),
      },
    }),
    [theme],
  );

  return {
    mainContainer,
    container,
  };
};
