import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const prescriptionContentWrapper = useMemo<SxProps<Theme>>(
    () => ({
      marginTop: theme.spacing(1.6),
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: theme.spacing(1.6),
      width: '100%',
      '.prescriptionHeader': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.6),
        padding: theme.spacing(1.6),
        backgroundColor: Colors.GreyBackground,
        borderRadius: theme.spacing(1.2),
      },
      '.prescriptionInfoTable': {
        '.prescriptionInfoHeader': {
          display: 'grid',
          gridTemplateColumns: `${theme.spacing(13.6)} repeat(4,1fr)`,
          padding: theme.spacing(1.6, 0.8),
          borderRadius: theme.spacing(0.6),
        },
        '.prescriptionInfoRow': {
          display: 'grid',
          gridTemplateColumns: `${theme.spacing(13.6)} repeat(4,1fr)`,
          padding: theme.spacing(0.8),
          borderRadius: theme.spacing(0.6),
          '&:nth-child(even)': {
            backgroundColor: Colors.GreyLight,
          },
        },
      },
    }),
    [theme],
  );

  const prescriptionRowItem = useCallback(
    (justifySelf: string, gridColumn: string): SxProps<Theme> => ({
      justifySelf,
      gridColumn,
    }),
    [],
  );

  return useMemo(
    () => ({
      prescriptionContentWrapper,
      prescriptionRowItem,
    }),
    [prescriptionContentWrapper, prescriptionRowItem],
  );
};
