import React, {useMemo, useState, useCallback, ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Box} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {useDebounce} from 'use-debounce';

import Dialog from '@/atoms/Dialog/Dialog';
import {IconSVG} from '@/atoms/IconSVG';
import {StoreAssociateRow} from '@/atoms/StoreAssociateRow';
import {Text} from '@/atoms/Typography/Text';
import SearchInputRestyle from '@/molecules/SearchInputRestyle';
import {NoResultScreenScreen} from '@/molecules/SearchStore/Screens';
import {MembersModalProps} from '@/organisms/MembersModal/types';
import {Member} from '@/store/members/types';
import {useMembersV2} from '@/store/members/v2';
import {Colors} from '@/themes/variables';

import {useStyles} from './styles';

const MembersModal = ({
  userLuxId,
  storeId,
  onClose,
  onSave,
  currentAssignee,
  includeMe = true,
  banner,
  country,
}: MembersModalProps) => {
  const {t} = useTranslation();
  const {header, dialog, contentStoreWrapper, bottomGradient, contentStores} =
    useStyles();
  const {getMembers} = useMembersV2();
  const [selectedMember, setSelectedMember] = useState<Member | undefined>(
    currentAssignee ? (currentAssignee as Member) : undefined,
  );

  const [searchString, setSearchString] = useState<string>('');
  const [debouncedString] = useDebounce(searchString, 300);

  /**
   * Handle input search
   */
  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(_ => e.target.value);
  }, []);

  const {data: Members, isLoading: MembersLoading} = useQuery(
    ['members', storeId, debouncedString, banner, country],
    () =>
      getMembers({
        storeId,
        includeMe,
        page: 1,
        limit: 100,
        q: debouncedString,
        banner,
        country,
      }),
    {
      retry: false,
    },
  );

  /*  Toggle members modal  */
  const toggleMember = useCallback((member: Member) => {
    setSelectedMember(prevMember => {
      return prevMember?.id === member?.id ? undefined : member;
    });
  }, []);

  /*  Display members list or loading */
  const membersList = useMemo(() => {
    return (
      !MembersLoading &&
      Members?.data.map(member => {
        return (
          <StoreAssociateRow
            key={member?.id}
            withPadding
            {...member}
            isSelected={selectedMember?.id === member?.id}
            isMe={member?.luxId === userLuxId}
            onClick={() => toggleMember(member)}
          />
        );
      })
    );
  }, [
    Members?.data,
    MembersLoading,
    selectedMember?.id,
    toggleMember,
    userLuxId,
  ]);

  /* Display dialog content */
  const dialogContent = useMemo(() => {
    return (
      <>
        <Box sx={header}>
          <Box>
            <Box>
              <Text variant="body" sx={{fontSize: '22px'}} superHeavy>
                {t('MembersModal.title')}
              </Text>
            </Box>
            <Box sx={{marginTop: '8px'}}>
              <Text variant="body" color={Colors.GreyText}>
                {t('MembersModal.subTitle')}
              </Text>
            </Box>
          </Box>
          <Box onClick={onClose}>
            <IconSVG icon="close" size={16} className="close" />
          </Box>
        </Box>
        <Box sx={{width: '100%', marginTop: '32px'}}>
          <SearchInputRestyle
            placeholder={t('Stores.search.search')}
            value={searchString}
            onChange={handleSearch}
          />
        </Box>
        <Box sx={contentStoreWrapper}>
          <Box sx={contentStores}>
            {!MembersLoading && Boolean(Members?.data?.length) ? (
              membersList
            ) : (
              <NoResultScreenScreen
                isLoading={MembersLoading}
                results={Boolean(Members?.data?.length)}
                taskList
              />
            )}
          </Box>
          <Box sx={bottomGradient} />
        </Box>
      </>
    );
  }, [
    Members?.data?.length,
    MembersLoading,
    bottomGradient,
    contentStoreWrapper,
    contentStores,
    handleSearch,
    header,
    membersList,
    onClose,
    searchString,
    t,
  ]);

  /* Handle assign click */
  const handleSave = useCallback(() => {
    selectedMember && onSave(selectedMember);
  }, [onSave, selectedMember]);

  /* Enable save button if a member is selected and it's not the currentAssignee */
  const canSave = useMemo(() => {
    return selectedMember && currentAssignee?.luxId !== selectedMember?.luxId;
  }, [currentAssignee, selectedMember]);

  return (
    <Dialog
      sx={dialog}
      open={true}
      dialogContent={dialogContent}
      dialogActions={
        <Button
          variant="primary"
          disabled={!canSave}
          {...(canSave && {onClick: handleSave})}>
          {t('MembersModal.ctas.assign')}
        </Button>
      }
    />
  );
};

export default React.memo(MembersModal);
