import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const SEARCHBAR_HEIGHT = '50px';

export const useStyles = () => {
  const theme = useTheme();

  const filterRowContainer: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.8),
      [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: `1fr ${theme.spacing(40)}`,
        alignItems: 'center',
      },
    }),
    [theme],
  );

  const formContainerBasic: SxProps<Theme> = useMemo(
    () => ({
      mx: 'auto',
      width: '100%',
      height: SEARCHBAR_HEIGHT,
      borderRadius: theme.spacing(1.2),
      border: `${theme.spacing(0.1)} solid ${Colors.GreyText}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(0.8),
      p: theme.spacing(0.8, 1.2),
      input: {
        p: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      backgroundColor: theme.palette.common.white,
    }),
    [theme],
  );

  const searchIcon: SxProps<Theme> = useMemo(
    () => ({
      marginRight: theme.spacing(2),
    }),
    [theme],
  );

  const freeSearch: SxProps<Theme> = useMemo(
    () => ({
      '.MuiInputBase-root': {
        p: 0,
      },
      '.MuiInputBase-input': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }),
    [],
  );

  return useMemo(
    () => ({
      filterRowContainer,
      formContainerBasic,
      searchIcon,
      freeSearch,
    }),
    [filterRowContainer, formContainerBasic, searchIcon, freeSearch],
  );
};
