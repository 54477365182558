import React from 'react';
import {useTranslation} from 'react-i18next';

import {useTheme} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {DashboardContainer} from '@/atoms/DashboardContainer';
import {Link} from '@/atoms/Link';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {TeamMembers} from '@/organisms/TeamMembers';
import {useAuthentication} from '@/store/authentication';
import {useMembers} from '@/store/members';

const DashboardTeamMembers = () => {
  const {t} = useTranslation();
  const {userData} = useAuthentication();
  const {getYourTeam} = useMembers();
  const {goToPath} = useNavigation();
  const theme = useTheme();

  const {data: Members, isLoading: MembersLoading} = useQuery(
    ['getYourTeam', userData?.currentStore?.id],
    () =>
      getYourTeam({
        storeId: userData?.currentStore?.id!,
        limit: 3,
      }),
    {
      retry: false,
    },
  );

  return (
    <DashboardContainer
      title={t('StoreManager.dashboard.teamMembers')}
      action={
        <Link
          position="center"
          linkVariant="dark"
          textVariant="body"
          lineHeight={theme.typography.body.fontSize}
          href="#"
          handleOnClick={() =>
            goToPath(AuthRoutes.CLIENTELING_MEMBERS_REPORTS)
          }>
          {t('StoreManager.dashboard.viewAll')}
        </Link>
      }>
      <TeamMembers isLoading={MembersLoading} members={Members} />
    </DashboardContainer>
  );
};

export default React.memo(DashboardTeamMembers);
