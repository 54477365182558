export enum LoyaltyFiltersActions {
  setPointsHistoryFilters = 'set points history filters',
  resetPointsHistoryFilters = 'reset points history filters',
  setRewardsListFilters = 'set rewards list filters',
  resetRewardsListFilters = 'reset rewards list filters',
  setMissionsListFilters = 'set mission list filters',
  resetMissionsListFilters = 'reset mission list filters',
}

export type LoyaltyFiltersAction<T> =
  | {
      type:
        | LoyaltyFiltersActions.setPointsHistoryFilters
        | LoyaltyFiltersActions.setRewardsListFilters
        | LoyaltyFiltersActions.setMissionsListFilters;
      payload: Partial<T>;
    }
  | {
      type:
        | LoyaltyFiltersActions.resetPointsHistoryFilters
        | LoyaltyFiltersActions.resetRewardsListFilters
        | LoyaltyFiltersActions.resetMissionsListFilters;
      payload: undefined;
    };
