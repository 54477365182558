import {AxiosError} from 'axios';

import {LoginResponse} from '@/definitions/authentication';

import {AuthenticationAction, AuthenticationActions} from './types.d';

export const LoginLoading = (): AuthenticationAction => ({
  type: AuthenticationActions.loginLoading,
});

export const LoginSuccess = (data: LoginResponse): AuthenticationAction => ({
  type: AuthenticationActions.loginSuccess,
  payload: data,
});

export const LoginFail = (error: AxiosError): AuthenticationAction => ({
  type: AuthenticationActions.loginFail,
  payload: error,
});

export const Logout = (): AuthenticationAction => ({
  type: AuthenticationActions.logout,
});
