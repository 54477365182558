import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {Box, SelectChangeEvent, Stack} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {CustomerBalanceSelect} from '@/atoms/CustomerBalanceSelect';
import {InputWrapper} from '@/atoms/InputWrapper';
import {BaseRouterParams} from '@/definitions/base';
import {numberFormatter} from '@/lib/utils';
import {useCustomerBalance} from '@/store/customerbalance';

import {Text} from '../../../atoms/Typography/Text';
import {PointsInput} from '../../PointsInput';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const EditForm = ({points, onChange, cardNumber, onError}: IProps) => {
  const {t} = useTranslation();
  const {getReasons} = useCustomerBalance();
  const {banner, country} = useParams<BaseRouterParams>();

  const styles = useStyle();

  const [pointsToUpdate, setPointsToUpdate] = useState<number | null>(null);
  const [reasonForUpdate, setReasonForUpdate] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');
  const [minPoints, setMinPoints] = useState<number>(0);
  const [maxPoints, setMaxPoints] = useState<number>(0);

  const {data: Reasons} = useQuery(
    ['reasons', banner, country],
    () =>
      getReasons({
        banner,
        country,
      }),
    {
      retry: false,
      enabled: Boolean(banner && country),
    },
  );

  const handlePointsChange = useCallback((newValue: number | null) => {
    setPointsToUpdate(newValue);
  }, []);

  const handleReasonChange = useCallback(
    (e: SelectChangeEvent<string | undefined>) =>
      setReasonForUpdate(e.target.value ?? null),
    [],
  );

  useEffect(() => {
    if (pointsToUpdate && reasonForUpdate) {
      onChange({
        points: pointsToUpdate,
        reasonId: reasonForUpdate,
        bannerSearch: banner,
        countrySearch: country,
        cardNumberSearch: cardNumber,
      });
    } else {
      onChange(null);
    }
  }, [cardNumber, onChange, pointsToUpdate, reasonForUpdate, banner, country]);

  return (
    <Stack
      component="form"
      noValidate
      autoComplete="off"
      spacing={3}
      alignItems="center">
      <Text sx={styles.editDescription}>
        {t('CustomerBalance.editDescription')}
      </Text>
      <Box sx={styles.container}>
        <div>
          <Text
            medium
            uppercase
            variant="headline5"
            sx={styles.currentBalanceKey}>
            {t('CustomerBalance.currentBalance')}:
          </Text>
          <Text heavy variant="headline5">
            {numberFormatter.format(points)}
          </Text>
        </div>
        <InputWrapper label={t('CustomerBalance.reason')}>
          <CustomerBalanceSelect
            sx={styles.select}
            setDescription={setDescription}
            setMinPoints={setMinPoints}
            setMaxPoints={setMaxPoints}
            description={description}
            minPoints={minPoints}
            maxPoints={maxPoints}
            options={Reasons}
            placeholder={t('CustomerBalance.reasonPlaceholder')}
            onChange={handleReasonChange}
            reason={Boolean(reasonForUpdate)}
          />
        </InputWrapper>
        <PointsInput
          max={maxPoints}
          min={minPoints}
          currentPoints={points}
          value={pointsToUpdate}
          onChange={handlePointsChange}
          isReason={Boolean(reasonForUpdate)}
          onError={onError}
        />
      </Box>
    </Stack>
  );
};

export default React.memo(EditForm);
