import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = (big: boolean, variant: string) => {
  const theme = useTheme();

  const colorVariants: {[key: string]: SxProps<Theme>} = useMemo(
    () => ({
      light: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
      dark: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      green: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.common.black,
      },
    }),
    [
      theme.palette.common.black,
      theme.palette.common.white,
      theme.palette.success.light,
    ],
  );

  const avatar: SxProps<Theme> = useMemo(
    () => ({
      width: big ? theme.spacing(6) : theme.spacing(4),
      height: big ? theme.spacing(6) : theme.spacing(4),
      ...colorVariants[variant],
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '.MuiTypography-root': {
        fontSize: `${
          big
            ? theme.typography.headline3.fontSize
            : theme.typography.body.fontSize
        }`,
      },
    }),
    [big, theme, colorVariants, variant],
  );

  return {avatar};
};
