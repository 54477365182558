import React, {useMemo, useState, useCallback, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {PiCaretUpBold, PiCaretDownBold} from 'react-icons/pi';

import {
  Chip,
  Menu,
  Stack,
  Box,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Button,
  InputAdornment,
  Input,
  Divider,
} from '@mui/material';

import {
  ChipDropdownProps,
  DropdownMenuProps,
  DropdownOption,
} from '@/atoms/ChipDropdown/interfaces';
import {useStyles} from '@/atoms/ChipDropdown/styles';
import {IconSVG} from '@/atoms/IconSVG';
import {TinyText} from '@/atoms/TinyText';

const ChipDropdown = ({
  filterLabel,
  allFilterLabel,
  selectedValues,
  options,
  onApply,
  disabled = false,
  disableEmpty = false,
  searchPlaceholder = 'Search',
  capitalize = false,
  disableDefaultCount = false,
}: ChipDropdownProps) => {
  const styles = useStyles(capitalize);

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const menuOpen = useMemo<boolean>(() => Boolean(anchorEl), [anchorEl]);

  const toggleMenu = useCallback(
    (event?: React.MouseEvent<HTMLDivElement> | any) => {
      setAnchorEl(event?.currentTarget);
      event?.stopPropagation();
    },
    [],
  );

  const label = useMemo<ReactNode>(() => {
    let labelString = `${allFilterLabel}${
      !disableDefaultCount ? ` (${options.length})` : ''
    }`;

    if (disabled || options.length === 0) {
      labelString = allFilterLabel;
    } else if (selectedValues.length === 1) {
      labelString = `${
        options?.find(opt => selectedValues.includes(opt?.value))?.label
      }`;
    } else if (selectedValues.length > 1) {
      labelString = `${filterLabel} (${selectedValues.length})`;
    }

    return (
      <Stack
        display="grid"
        gridTemplateColumns="1fr max-content"
        gap={0.4}
        alignItems="center">
        <TinyText overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {labelString}
        </TinyText>
        {menuOpen ? (
          <PiCaretUpBold fontSize={20} />
        ) : (
          <PiCaretDownBold fontSize={20} />
        )}
      </Stack>
    );
  }, [
    allFilterLabel,
    disableDefaultCount,
    disabled,
    filterLabel,
    menuOpen,
    options,
    selectedValues,
  ]);

  return (
    <>
      <Box {...(!disabled && {onClick: toggleMenu})} paddingY={0.8}>
        <Chip
          sx={styles.chip}
          disabled={disabled}
          label={label}
          clickable={!disabled}
          variant={
            selectedValues?.length === 0 ? 'filterDefault' : 'filterActive'
          }
        />
      </Box>
      {anchorEl && (
        <DropdownMenu
          anchorEl={anchorEl}
          options={options}
          selectedValues={selectedValues}
          onApply={onApply}
          toggleMenu={toggleMenu}
          disableEmpty={disableEmpty}
          searchPlaceholder={searchPlaceholder}
          capitalize={capitalize}
        />
      )}
    </>
  );
};

const DropdownMenu = React.memo(
  ({
    anchorEl,
    options,
    selectedValues,
    onApply,
    toggleMenu,
    disableEmpty = false,
    searchPlaceholder,
    capitalize,
  }: DropdownMenuProps) => {
    const {t} = useTranslation();
    const styles = useStyles(capitalize);

    const [searchInput, setSearchInput] = useState<string>('');

    const [values, setValues] = useState<DropdownOption[]>(() => {
      return options?.filter(opt => selectedValues.includes(opt?.value));
    });

    const [hasChanges, setHasChanges] = useState(false);

    const filteredOptions = useMemo(() => {
      return options?.filter(opt => {
        const isNotSelected =
          values?.findIndex(val => val?.value === opt?.value) === -1;

        if (isNotSelected && searchInput !== '') {
          return opt?.label
            ?.toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase());
        }

        return isNotSelected;
      });
    }, [options, searchInput, values]);

    const toggleValue = useCallback((value: DropdownOption) => {
      setValues(prevValues => {
        setHasChanges(true);
        setSearchInput('');
        const valueIndex = prevValues.findIndex(
          val => val.value === value?.value,
        );
        if (valueIndex !== -1) {
          const updatedArray = [...prevValues];
          updatedArray.splice(valueIndex, 1);
          return updatedArray;
        }

        return [...prevValues, value];
      });
    }, []);

    const canSubmit = useMemo(() => {
      if (!disableEmpty && hasChanges) {
        return true;
      }

      return values.length > 0 && hasChanges;
    }, [disableEmpty, hasChanges, values.length]);

    return (
      <Menu
        sx={styles.menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={true}
        onClose={() => toggleMenu()}>
        <Stack
          display="flex"
          direction="column"
          gap={1.2}
          width="100%"
          divider={<Divider orientation="horizontal" />}>
          <Box paddingTop={1.2} paddingX={1.6}>
            <Input
              sx={styles.menuTextField}
              type="text"
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <IconSVG icon="search" size={17} />
                </InputAdornment>
              }
              spellCheck={false}
              autoCorrect="off"
              autoComplete="off"
              placeholder={searchPlaceholder}
              value={searchInput}
              onChange={event => setSearchInput(event.target.value)}
            />
          </Box>
          {values?.length > 0 && (
            <Stack sx={styles.menuSelected}>
              {values?.map((val, i) => {
                return (
                  <Chip
                    key={`${val?.value}_${i}`}
                    sx={{width: 'max-content', lineHeight: '16px'}}
                    variant="filterDefault"
                    label={val?.label}
                    deleteIcon={<IconSVG icon="close" size={14} />}
                    onDelete={() => toggleValue(val)}
                  />
                );
              })}
            </Stack>
          )}
          <List sx={styles.menuList}>
            {filteredOptions?.map((opt, i) => {
              return (
                <ListItem disablePadding key={`${opt?.value}_${i}`}>
                  <ListItemButton
                    sx={styles.menuListItem}
                    onClick={() => toggleValue(opt)}>
                    <ListItemText
                      className="listItemText"
                      primary={opt?.label}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Stack
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingBottom={1.2}>
            <Button
              variant="primary"
              disabled={!canSubmit}
              {...(canSubmit && {
                onClick: () => {
                  onApply(values.map(val => val?.value));
                  toggleMenu();
                },
              })}
              autoFocus>
              {t('ChipFilters.applyFilters')}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    );
  },
);

DropdownMenu.displayName = 'DropdownMenu';

export default React.memo(ChipDropdown);
