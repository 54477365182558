import React from 'react';
import {Button} from '@mui/material';
import {noop} from '../Utils/noop';
import {IconSVG} from '../IconSVG';
import {useStyle} from './styles';
import {IProps} from './interfaces';

const CloseIconButton = ({onClose = noop}: IProps) => {
  const styles = useStyle();

  return (
    <Button onClick={onClose} sx={styles.closeIconButton}>
      <IconSVG icon="close" size={22} />
    </Button>
  );
};

export default React.memo(CloseIconButton);
