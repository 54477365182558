import React, {useMemo} from 'react';

import {Box, CircularProgress, CircularProgressProps} from '@mui/material';

import {ValueCircle} from '../../atoms/ValueCircle';
import {IProps} from './definitions';
import {useStyle} from './styles';

const circleProps: CircularProgressProps = {
  variant: 'determinate',
  size: 120,
  thickness: 5,
};

const ProgressWheel = ({
  value,
  currentMin,
  nextMin,
  pointsToNextTier,
  ...props
}: IProps) => {
  const styles = useStyle();

  const currentValue = useMemo(() => {
    return value;
  }, [value]);

  const totalValue = useMemo(() => {
    return pointsToNextTier > 0 ? nextMin : currentValue;
  }, [currentValue, nextMin, pointsToNextTier]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.container}>
        {/* Background circle */}
        <CircularProgress
          sx={styles.baseCircle}
          value={100}
          {...circleProps}
          {...props}
        />
        {/* Progress circle */}
        <CircularProgress
          sx={styles.circle}
          color="tertiary"
          value={(currentValue * 100) / totalValue}
          {...circleProps}
          {...props}
        />
      </Box>
      <Box sx={styles.valueContainer}>
        <Box width={70} height={70}>
          <ValueCircle value={value} unit="points" />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(ProgressWheel);
