import React from 'react';
import {useTranslation} from 'react-i18next';

import {Divider, Stack} from '@mui/material';

import {useLocale} from '@/store/locale';

import {Dialog} from '../../atoms/Dialog';
import {CloseIconButton} from '../../atoms/DialogButtons';
import {Text} from '../../atoms/Typography/Text';
import {IProps} from './interfaces';
import {useStyle} from './styles';

const TierInfo = ({open, onClose, tierInfo}: IProps) => {
  const {container, row} = useStyle();
  const {t} = useTranslation();
  const {formatDate} = useLocale();

  return (
    <Dialog
      open={open}
      dialogTitle={t('CustomerBalance.tierInfoTitle')}
      dialogContent={
        <Stack width="100%" gap={1.5}>
          <Stack sx={container}>
            <Text superHeavy>
              {t('CustomerBalance.tierInfoSubtitles.name')}
            </Text>
            <Text superHeavy>
              {t('CustomerBalance.tierInfoSubtitles.updateDate')}
            </Text>
          </Stack>
          <Divider />
          {tierInfo?.map(({tier, tierName, updateDate}) => (
            <Stack sx={row}>
              <Text>{`${tier} - ${tierName}`}</Text>
              <Text>{formatDate(String(updateDate))}</Text>
            </Stack>
          ))}
        </Stack>
      }
      dialogActions={<CloseIconButton onClose={onClose} />}
    />
  );
};

export default React.memo(TierInfo);
