import {useCallback, useMemo} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetMembersParamsV2,
  GetMembersResponseV2,
} from '@/store/members/v2/types';

export const useMembersV2 = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getMembers = useCallback(
    async ({storeId, ...params}: GetMembersParamsV2) => {
      try {
        const {data} = await api.get<GetMembersResponseV2>(
          getApiPath(APIS.V2_STORES_STOREID_MEMBERS, {storeId}),
          {
            params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getMembers,
    }),
    [getMembers],
  );
};
