import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import * as Sentry from '@sentry/react';
import {useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {TokenResponse} from '@/definitions/authentication';
import {AuthenticatedUser} from '@/definitions/user';
import {Modules, Role, RoleName} from '@/definitions/users';
import {useToast} from '@/hooks/toast';
import {useParamsAndClean} from '@/hooks/useParamsAndClean';
import {generateNamLoginUrl, generateNamLogoutUrl} from '@/lib/nam';
import {cookie} from '@/lib/utils';
import {_api as api} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {useLocale} from '@/store/locale';

import {LoginFail, LoginLoading, LoginSuccess, Logout} from './actions';
import {
  selectorBanner,
  selectorError,
  selectorIsFetching,
  selectorIsLoggedIn,
  selectorModules,
  selectorRoles,
  selectorUser,
  selectorStoreId,
  selectorNamToken,
} from './selectors';
import {
  AuthenticationParams,
  AuthenticationState,
  AuthenticationResponse,
} from './types';

export const useAuthentication = () => {
  const {getApiPath} = useApiPath();
  const {t} = useTranslation();
  const {showErrorToast} = useToast();
  const {setLocale} = useLocale();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector<
    {authentication: AuthenticationState},
    boolean
  >(selectorIsLoggedIn);

  const namToken = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorNamToken);

  const userData = useSelector<
    {authentication: AuthenticationState},
    AuthenticatedUser | undefined
  >(selectorUser);

  const userRoles = useSelector<
    {authentication: AuthenticationState},
    Role[] | undefined
  >(selectorRoles);

  const userModules = useSelector<
    {authentication: AuthenticationState},
    Modules[] | undefined
  >(selectorModules);

  const userBanner = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorBanner);

  const userStoreId = useSelector<
    {authentication: AuthenticationState},
    number | undefined
  >(selectorStoreId);

  const error = useSelector<
    {authentication: AuthenticationState},
    string | undefined
  >(selectorError);

  const isFetching = useSelector<
    {authentication: AuthenticationState},
    boolean
  >(selectorIsFetching);

  const checkRoles = useCallback(
    (roles: RoleName[]): boolean => {
      const match = userRoles?.find(role => roles.includes(role?.name));

      return Boolean(match);
    },
    [userRoles],
  );

  const isStoreManager = checkRoles([RoleName.STORE_MANAGER]);

  const isStoreAssociate = checkRoles([RoleName.STORE_ASSOCIATE]);

  const isAreaManager = checkRoles([RoleName.AREA_MANAGER]);

  const isContactCenterSupervisor = checkRoles([RoleName.CC_SUPERVISOR]);

  const isContactCenterAgent = checkRoles([RoleName.CC_AGENT]);

  const removeAuth = useCallback((namToken: string) => {
    localStorage.clear();
    sessionStorage.clear();
    const namUrl = generateNamLogoutUrl(namToken);
    window.open(namUrl, '_self', 'noopener, noreferrer');
  }, []);

  const logout = useCallback(
    async (namToken?: string) => {
      try {
        await api.get(getApiPath(APIS.LOGOUT), {
          baseURL: process.env.REACT_APP_AUTH_URL,
          ...(process.env.REACT_APP_ENVIRONMENT === 'local' && {
            headers: {
              proxybff: 'authService',
            },
          }),
        });

        if (namToken) {
          removeAuth(namToken);
        } else {
          dispatch(Logout());
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [dispatch, getApiPath, removeAuth],
  );

  const getAuth = useCallback(async () => {
    try {
      const {data} = await api.get<AuthenticationResponse>(
        getApiPath(APIS.PROFILE),
      );
      setLocale(data?.language);

      dispatch(LoginSuccess({...data}));
      return data;
    } catch (error) {
      dispatch(LoginFail(error as AxiosError));
      Sentry.captureException(error);
      throw error;
    }
  }, [dispatch, getApiPath, setLocale]);

  const login = useCallback(
    async (params: AuthenticationParams) => {
      try {
        dispatch(LoginLoading());
        const {data} = await api.post<TokenResponse>(
          getApiPath(APIS.AUTH_ME),
          {
            ...params,
          },
          {
            baseURL: process.env.REACT_APP_AUTH_URL,
            ...(process.env.REACT_APP_ENVIRONMENT === 'local' && {
              headers: {
                proxybff: 'authService',
              },
            }),
          },
        );

        cookie.remove('code_verifier');
        localStorage.removeItem('code');
        localStorage.removeItem('state');

        await getAuth();
        return data;
      } catch (error) {
        if (error instanceof AxiosError) {
          dispatch(LoginFail(error));
          const token = error?.response?.headers['x-auth-nam'];
          if (token) {
            cookie.set('permission-denied', true);
            removeAuth(token);
          } else {
            showErrorToast(t('Login.SignIn.permissionDenied'));
          }
          Sentry.captureException(error);
          throw error;
        }
      }
    },
    [dispatch, getApiPath, getAuth, removeAuth, showErrorToast, t],
  );

  const goToSso = useCallback(async () => {
    cookie.remove('permission-denied');
    const namUrl = await generateNamLoginUrl();
    window.open(namUrl, '_self', 'noopener, noreferrer');
  }, []);

  return {
    userData,
    isLoggedIn,
    namToken,
    checkRoles,
    userRoles,
    userModules,
    userBanner,
    userStoreId,
    error,
    login,
    getAuth,
    isFetching,
    isStoreAssociate,
    isStoreManager,
    isAreaManager,
    isContactCenterSupervisor,
    isContactCenterAgent,
    logout,
    removeAuth,
    goToSso,
  };
};

export const useNamVerification = () => {
  const params = useParamsAndClean<{code: string; state: string}>(
    'code',
    'state',
  );
  const codeVerifier = cookie.get('code_verifier');
  const {login, isFetching} = useAuthentication();

  const canFetch = useMemo(() => {
    return (
      Object.keys(params).length > 0 &&
      'code' in params &&
      'state' in params &&
      !!codeVerifier &&
      !isFetching
    );
  }, [codeVerifier, isFetching, params]);

  useQuery(
    ['userAuth'],
    () =>
      login({
        authCode: `${params.code}`,
        codeVerifier: `${codeVerifier}`,
        state: `${params.state}`,
        ...((!process.env.NODE_ENV ||
          process.env.NODE_ENV === 'development') && {
          redirectUrl: process.env.REACT_APP_NAM_REDIRECT_URI,
        }),
      }),
    {
      enabled: canFetch,
      retry: false,
    },
  );
};
