import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const searchBarWrapper = useMemo(
    () => ({
      paddingX: theme.spacing(2.0),
      [theme.breakpoints.up('md')]: {
        paddingX: theme.spacing(0),
      },
    }),
    [theme],
  );

  const searchBar = useMemo(
    () => ({
      width: '100%',
      paddingY: theme.spacing(1.6),
      backgroundColor: theme.palette.common.black,
      paddingX: theme.spacing(3.0),
      [theme.breakpoints.up('md')]: {
        paddingX: 0,
      },
    }),
    [theme],
  );

  const content = useMemo<SxProps<Theme>>(
    () => ({
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
    }),
    [],
  );

  const cardsWrapper = useMemo(() => {
    return {
      flex: 1,
      overflowY: 'auto',
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(1),
      },
    };
  }, [theme]);

  const cardsContainer = useMemo(() => {
    return {
      marginBottom: theme.spacing(11),
    };
  }, [theme]);

  const centerBox = useMemo(() => {
    return {
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex',
    };
  }, []);
  return {
    searchBarWrapper,
    searchBar,
    content,
    cardsWrapper,
    cardsContainer,
    centerBox,
  };
};
