import React from 'react';

import {Stack} from '@mui/material';

import {IconLabelProps} from '@/atoms/IconLabel/interfaces';
import {IconSVG} from '@/atoms/IconSVG';
import {XSText} from '@/atoms/XSText';

const IconLabel = ({icon, label}: IconLabelProps) => {
  return (
    <Stack direction="row" gap={0.4} alignItems="center">
      <IconSVG icon={icon} size={16} />
      <XSText paddingTop={0.2}>{label}</XSText>
    </Stack>
  );
};

export default IconLabel;
