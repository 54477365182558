import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {useNavigation} from '@/navigation/useNavigation';

interface ParamsState<T> {
  paramsState: T;
  setParamsState: React.Dispatch<React.SetStateAction<T>>;
}

export function useParamsState<T extends {[key: string]: any}>(
  defaultFields: {[key in keyof T]: T[keyof T]} | null,
  arrFields: Array<keyof T>,
  ...params: Array<keyof T>
): ParamsState<T> {
  const location = useLocation();
  const {updateQueryParams} = useNavigation();

  const [paramsState, setParamsState] = useState<T>(() => {
    const searchParams = new URLSearchParams(location.search);
    return params.reduce((acc, param) => {
      const val = searchParams.get(param as string);

      if (!val && defaultFields !== null && param in defaultFields) {
        acc[param] = defaultFields[param];
        return acc;
      }

      if (val !== null) {
        if (arrFields.includes(param)) {
          acc[param] = val.split(',') as T[keyof T];
        } else {
          acc[param] = val as T[keyof T];
        }
      }
      return acc;
    }, {} as T);
  });

  useEffect(() => {
    updateQueryParams(paramsState);
  }, [paramsState, updateQueryParams]);

  return {
    paramsState,
    setParamsState,
  };
}
