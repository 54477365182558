import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container: SxProps<Theme> = useMemo(
    () => ({display: 'flex', alignItems: 'center', gap: theme.spacing(3)}),
    [theme],
  );

  const button: SxProps<Theme> = useMemo(
    () => ({
      '& .MuiButton-root': {
        width: '44px',
        height: '44px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
        minWidth: 'revert',
        '&:hover': {
          opacity: '80%',
        },
      },
    }),
    [],
  );

  const addButton: SxProps<Theme> = useMemo(
    () => ({
      '& .MuiButton-root': {
        backgroundColor: theme.palette.success.light,
      },
    }),
    [theme],
  );

  const removeButton: SxProps<Theme> = useMemo(
    () => ({
      '& .MuiButton-root': {
        backgroundColor: theme.palette.error.light,
        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
      },
    }),
    [theme],
  );

  const textField: SxProps<Theme> = useMemo(
    () => ({
      '& .MuiOutlinedInput-root': {
        borderRadius: '5px',
        height: '44px',
        '& .MuiOutlinedInput-input': {
          textAlign: 'center',
        },
      },
    }),
    [],
  );

  const inputWrapper: SxProps<Theme> = useMemo(
    () => ({
      '& .MuiFormHelperText-root': {
        textAlign: 'left',
      },
    }),
    [],
  );

  return {container, button, addButton, removeButton, textField, inputWrapper};
};
