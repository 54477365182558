import {Theme} from '@mui/material';
import {useMemo} from 'react';

export const useStyles = () => {
  const button = useMemo(
    () => ({
      py: (theme: Theme) => theme.spacing(0.5),
      px: (theme: Theme) => theme.spacing(0.8),
      height: 'unset',
      minHeight: '32px',
      minWidth: '135px',
    }),
    [],
  );

  return {button};
};
