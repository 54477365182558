import Base64 from 'crypto-js/enc-base64url';
import sha256 from 'crypto-js/sha256';

import {generateRandomString, cookie} from './utils';

async function generateNamCodes() {
  const state = generateRandomString(8);
  const code_verifier = generateRandomString(128);
  const code_challenge = Base64.stringify(sha256(code_verifier));
  cookie.set('code_verifier', code_verifier);

  return {
    state,
    challenge: code_challenge,
  };
}

function getNamLoginParams(state: string, codeChallenge: string) {
  return new URLSearchParams({
    client_id: `${process.env.REACT_APP_NAM_CLIENT_ID}`,
    response_type: 'code',
    redirect_uri: `${process.env.REACT_APP_NAM_REDIRECT_URI}`,
    state: state,
    scope: 'loyalty openid',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  });
}

export async function generateNamLoginUrl() {
  const {state, challenge} = await generateNamCodes();

  const baseUrl = process.env.REACT_APP_NAM_AUTHORIZATION_URL;
  const params = getNamLoginParams(state, challenge);

  return `${baseUrl}?${params}`;
}

function getNamLogoutParams(token: string) {
  return new URLSearchParams({
    id_token_hint: token,
    post_logout_redirect_uri: `${process.env.REACT_APP_NAM_REDIRECT_URI}`,
  });
}

export function generateNamLogoutUrl(token: string) {
  const baseUrl = process.env.REACT_APP_NAM_END_SESSION_URL;
  const params = getNamLogoutParams(token);

  return `${baseUrl}?${params}`;
}
