import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const labelValueCtaContainer = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(0.8),
    }),
    [theme],
  );

  const buttonContainer = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: theme.spacing(1.6),
      '.buttonCta': {
        textTransform: 'none',
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      labelValueCtaContainer,
      buttonContainer,
    }),
    [labelValueCtaContainer, buttonContainer],
  );
};
