import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Button, Stack} from '@mui/material';

import {Text} from '@/atoms/Typography/Text';
import {SearchStore} from '@/molecules/SearchStore';

import {StoreCardProps} from './interfaces';
import {useStyle} from './styles';

const StoreCard = ({user}: StoreCardProps) => {
  const {t} = useTranslation();
  const {card, info} = useStyle();
  const {currentStore, banner, country, roles} = user;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Box sx={card}>
        <Stack direction="column" alignItems="center" gap={1.6} sx={info}>
          <Box>
            <Text superHeavy variant="tiny" uppercase>
              {currentStore?.storeId}
            </Text>
            {' - '}
            <Text variant="tiny" uppercase>
              {currentStore?.storeName}
            </Text>
          </Box>
          <Button variant="outlinedInverted" onClick={() => setOpen(true)}>
            <Text variant="tiny" superHeavy>
              {t('Stores.search.changeStore')}
            </Text>
          </Button>
        </Stack>
      </Box>
      {open && (
        <SearchStore
          open={true}
          setOpen={setOpen}
          currentStoreId={currentStore?.id}
          currentBanner={banner}
          currentCountry={country}
          role={roles[0]}
        />
      )}
    </>
  );
};

export default React.memo(StoreCard);
