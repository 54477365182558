import React from 'react';

import {Box} from '@mui/material';

import {Text} from '../Typography/Text';
import {IBadge} from './interfaces';
import {useStyle} from './style';

const Badge = ({variant = 'tiny', color, badgeLabel, onClick}: IBadge) => {
  const {badge} = useStyle(color, variant, !!onClick);

  return (
    <Box sx={badge} {...(onClick && {onClick})}>
      <Text variant={variant}>{badgeLabel}</Text>
    </Box>
  );
};

export default React.memo(Badge);
