import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Button, useTheme, Stack} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {KeyValuePair} from '@/molecules/KeyValuePair';
import {useNavigation} from '@/navigation/useNavigation';

import {IProps} from './interfaces';
import {useStyle} from './styles';

const SearchResult = ({
  cardNumber,
  email,
  firstName,
  lastName,
  brandId,
  country,
  callback,
}: IProps) => {
  const {t} = useTranslation();
  const {goToLoyaltyProfile} = useNavigation();
  const theme = useTheme();
  const styles = useStyle();

  const info = useMemo(
    () => [
      {label: t('SearchResult.cardNumber'), value: cardNumber},
      {label: t('SearchResult.email'), value: email},
      {label: t('SearchResult.cardOwner'), value: `${firstName} ${lastName}`},
      {label: t('SearchResult.brand'), value: brandId.toString()},
      {label: t('SearchResult.country'), value: country},
    ],
    [brandId, cardNumber, country, email, firstName, lastName, t],
  );

  const endIcon = useMemo(
    () => (
      <IconSVG
        icon="arrow_right"
        size={23}
        color={theme.palette.common.white}
      />
    ),
    [theme],
  );

  const handleClick = useCallback(() => {
    callback && callback();
    goToLoyaltyProfile(cardNumber, brandId, country, 'search');
  }, [brandId, cardNumber, country, goToLoyaltyProfile, callback]);

  return (
    <Box sx={styles.container}>
      <Stack direction="column" gap={1}>
        {info.map(({label, value}) => (
          <KeyValuePair key={label} keyLabel={label} value={value} />
        ))}
      </Stack>
      <Button variant="primary" endIcon={endIcon} onClick={handleClick}>
        {t('SearchResult.button')}
      </Button>
    </Box>
  );
};

export default React.memo(SearchResult);
