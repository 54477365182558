import {useMemo} from 'react';

import {useTheme} from '@mui/material';

import {PageHeaderProps} from './interfaces';

export const NAVBAR_HEIGHT = '60px';

export const useStyle = ({title, extended}: PageHeaderProps) => {
  const theme = useTheme();

  const navbar = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      px: 3,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      height: NAVBAR_HEIGHT,
    }),
    [theme],
  );

  const wrapper = useMemo(() => {
    return {
      display: 'grid',
      gridTemplateColumns:
        extended || !Boolean(title) ? 'repeat(2, 1fr)' : '1fr 2fr 1fr',
      alignItems: 'center',
    };
  }, [extended, title]);

  const logo = useMemo(
    () => ({
      height: '18px',
      width: '162px',
      [theme.breakpoints.up('md')]: {
        height: '22px',
        width: '202px',
      },
    }),
    [theme],
  );

  const extendedNavbar = useMemo(
    () => ({
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      height: `calc(50dvh - ${NAVBAR_HEIGHT} - env(safe-area-inset-bottom))`,
      '@-moz-document url-prefix()': {
        height: `calc(50vh - ${NAVBAR_HEIGHT} - env(safe-area-inset-bottom))`,
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [theme],
  );

  const extendedNavbarTitle = useMemo(
    () => ({
      fontSize: '48px',
      letterSpacing: '0.48px',
      [theme.breakpoints.up('md')]: {
        fontSize: '60px',
        letterSpacing: '0.6px',
      },
    }),
    [theme],
  );

  const iconUser = useMemo(
    () => ({
      display: 'flex',
      justifySelf: 'end',
      gap: theme.spacing(0.8),

      button: {
        cursor: 'pointer',
        height: '100%',
      },
    }),
    [theme],
  );

  return {navbar, wrapper, logo, extendedNavbar, extendedNavbarTitle, iconUser};
};
