import {createContext, useCallback, useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Container, Stack} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {TASK_STATUS, TASK_VISIBILITY, useCampaigns} from 'src/store/campaigns';

import {ChipDropdown} from '@/atoms/ChipDropdown';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {useQueryString} from '@/hooks/useQueryString';
import {TaskListTabs} from '@/molecules/TaskListTabs';
import {useNavigation} from '@/navigation/useNavigation';
import {ORDER_BY} from '@/organisms/TaskListFilters/interfaces.d';
import {defaultCampaignsContextState} from '@/store/CampaignsContext/constants';
import {
  CampaignsProviderProps,
  CampaignsProviderState,
} from '@/store/CampaignsContext/types';
import {useAuthentication} from '@/store/authentication';
import {useReportFilters} from '@/store/reportFilters/hooks';
import {Base} from '@/templates/Base';

const CampaignsContext = createContext<CampaignsProviderState>({
  ...defaultCampaignsContextState,
});

export const CampaignsProvider = ({
  currentRoute,
  tabs,
  defaultVisibility,
  children,
  isTask = false,
  sort = true,
  defaultPage = undefined,
  withPagination = false,
  ...props
}: CampaignsProviderProps) => {
  const {t} = useTranslation();
  const {updateQueryParams} = useNavigation();
  const {userData, isStoreAssociate} = useAuthentication();
  const {getFilters} = useCampaigns();
  const {getFilterAssignees} = useReportFilters();
  const visibility = useQueryString(
    'visibility',
    defaultVisibility,
  ) as TASK_VISIBILITY;

  const defaultStatus = useMemo(() => {
    const currentTab = tabs?.find(tab => tab?.id === visibility);
    if (currentTab?.tabsSecondLevel) {
      return currentTab?.tabsSecondLevel[0].id ?? '';
    }

    return '';
  }, [tabs, visibility]);

  const status = useQueryString('status', defaultStatus) as TASK_STATUS;
  const code = useQueryString('code');
  const assignees = useQueryString('assignees');
  const orderBy = useQueryString('orderBy', ORDER_BY.DESC);
  const sortBy = useQueryString('sortBy', 'dueDateTask');
  const page = useQueryString('page', defaultPage ?? '1');

  /**
   * Fetch filters
   */
  const {
    data: CampaignFilters,
    isFetching: CampaignFiltersLoading,
    refetch: CampaignFiltersRefetch,
  } = useQuery(
    ['filters', visibility, status, userData?.currentStore.id],
    () =>
      getFilters({
        storeId: userData?.currentStore.id!,
        status: TASK_STATUS.ALL,
        visibility,
      }),
    {
      retry: false,
    },
  );

  /**
   * Compose filters array
   */
  const campaignFiltersOptions = useMemo(() => {
    if (!CampaignFilters) return [];

    return CampaignFilters?.map(campaign => {
      return {
        label: campaign?.name,
        value: campaign?.code,
      };
    });
  }, [CampaignFilters]);

  // Assignees filter
  const {
    data: AssigneesFilter,
    isFetching: AssigneesFilterLoading,
    refetch: AssigneesFilterRefetch,
  } = useQuery(
    [
      'campaignAssigneesFilters',
      userData?.currentStore?.id,
      visibility,
      status,
    ],
    () =>
      getFilterAssignees({
        storeId: userData?.currentStore?.id!,
      }),
  );

  const assigneesOptions = useMemo(() => {
    if (!AssigneesFilter) return [];

    return AssigneesFilter?.map(assignee => {
      return {
        label: `${assignee?.assigneeFirstname?.toLowerCase()} ${assignee?.assigneeLastname?.toLowerCase()}`,
        value: assignee?.uuid,
      };
    });
  }, [AssigneesFilter]);

  /**
   * Handle tab change
   */
  const handleTabChange = useCallback(
    (primaryTab: TASK_VISIBILITY, subTab: TASK_STATUS) => {
      const search = {
        visibility: primaryTab,
        status: subTab,
        code: '',
        page: 1,
      };
      updateQueryParams(search);
    },
    [updateQueryParams],
  );

  const handleFilterReset = useCallback(() => {
    const search = {
      visibility,
      status,
      page: 1,
      orderBy,
      sortBy,
    };
    updateQueryParams(search);
  }, [visibility, status, orderBy, sortBy, updateQueryParams]);

  /**
   * Handle filter change
   */
  const handleFilterChange = useCallback(
    (key: 'code' | 'assignees', filters: string[]) => {
      const search = {
        visibility,
        status,
        ...(key === 'code' && {
          code: filters.join(','),
          assignees,
        }),
        ...(key === 'assignees' && {
          assignees: filters.join(','),
          code,
        }),
        page: 1,
        orderBy,
        sortBy,
      };
      updateQueryParams(search);
    },
    [visibility, status, assignees, code, orderBy, sortBy, updateQueryParams],
  );

  const changePage = useCallback(
    (newPage: number) => {
      const search = {
        visibility,
        status,
        code,
        assignees,
        page: newPage,
        orderBy,
        sortBy,
      };
      updateQueryParams(search);
    },
    [assignees, code, orderBy, sortBy, status, updateQueryParams, visibility],
  );

  const handleOrderChange = useCallback(
    (sort: string, order: ORDER_BY) => {
      const search = {
        visibility,
        status,
        code,
        assignees,
        page,
        sortBy: sort,
        orderBy: order,
      };
      updateQueryParams(search);
    },
    [visibility, status, code, assignees, page, updateQueryParams],
  );

  const hasFilters = useMemo(
    () => Boolean(code) || Boolean(assignees),
    [code, assignees],
  );

  const refetchFilters = useCallback(async () => {
    handleFilterReset();
    await Promise.all([CampaignFiltersRefetch(), AssigneesFilterRefetch()]);
  }, [AssigneesFilterRefetch, CampaignFiltersRefetch, handleFilterReset]);

  const renderFilters = useMemo(() => {
    return (
      <>
        <ChipDropdown
          filterLabel={t('ReportsFilters.CAMPAIGNS.multiple')}
          allFilterLabel={t('ReportsFilters.CAMPAIGNS.all')}
          searchPlaceholder={t('ReportsFilters.CAMPAIGNS.placeholder')}
          selectedValues={code ? code.split(',') : []}
          options={campaignFiltersOptions}
          onApply={codes => handleFilterChange('code', codes)}
          disabled={CampaignFiltersLoading || !CampaignFilters}
        />
        {visibility === TASK_VISIBILITY.ALL &&
          status !== TASK_STATUS.NOTASSIGNED && (
            <ChipDropdown
              filterLabel={t('ReportsFilters.ASSIGNEES.multiple')}
              allFilterLabel={t('ReportsFilters.ASSIGNEES.all')}
              searchPlaceholder={t('ReportsFilters.ASSIGNEES.placeholder')}
              selectedValues={assignees ? assignees.split(',') : []}
              options={assigneesOptions}
              onApply={assignees => handleFilterChange('assignees', assignees)}
              disabled={AssigneesFilterLoading || !AssigneesFilter}
              capitalize
            />
          )}
      </>
    );
  }, [
    t,
    code,
    campaignFiltersOptions,
    CampaignFiltersLoading,
    CampaignFilters,
    visibility,
    status,
    assignees,
    assigneesOptions,
    AssigneesFilterLoading,
    AssigneesFilter,
    handleFilterChange,
  ]);

  const context = useMemo<CampaignsProviderState>(() => {
    return {
      data: {
        code,
        assignees,
        visibility,
        status,
        orderBy,
        sortBy,
        page,
      },
      api: {
        changePage,
        handleOrderChange,
        refetchFilters,
      },
    };
  }, [
    code,
    assignees,
    visibility,
    status,
    orderBy,
    sortBy,
    page,
    changePage,
    handleOrderChange,
    refetchFilters,
  ]);

  return (
    <CampaignsContext.Provider value={context}>
      <Base
        title={props?.title}
        noContainer={
          <Stack gap={0.8}>
            <TaskListTabs
              primaryTab={visibility}
              subTab={status}
              tabs={tabs}
              onTabChange={handleTabChange}
              showBack={!isStoreAssociate}
            />
            <Container maxWidth="xl" sx={{marginBottom: '-16px'}}>
              <Stack
                gap={1.6}
                direction="row"
                alignItems="center"
                gridTemplateColumns="max-content max-content">
                <Stack
                  direction="row"
                  alignItems="center"
                  columnGap={0.8}
                  flexWrap="wrap">
                  {renderFilters}
                </Stack>
                <Link
                  position="center"
                  linkVariant="dark"
                  textVariant="body"
                  href="#"
                  iconStart={<IconSVG icon="reset" size={16} />}
                  handleOnClick={() => {
                    handleFilterReset();
                  }}
                  disabled={!hasFilters}
                  noDecoration>
                  {t('PurchaseHistory.filters.reset')}
                </Link>
              </Stack>
            </Container>
          </Stack>
        }>
        <>{children}</>
      </Base>
    </CampaignsContext.Provider>
  );
};

export function useCampaignsContext() {
  return useContext<CampaignsProviderState>(CampaignsContext);
}
