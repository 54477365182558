import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const SEARCHBAR_HEIGHT = '96px';
export const MOBILE_SEARCHBAR_HEIGHT = '170px';

export const useStyle = () => {
  const theme = useTheme();

  const formContainer: SxProps<Theme> = useMemo(
    () => ({
      mx: 'auto',
      maxWidth: '705px',
      height: MOBILE_SEARCHBAR_HEIGHT,
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiTextField-root': {
        justifyContent: 'center',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
      boxShadow: `0px 7px 20px ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1.2, 1.8),
      gap: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(0),
        maxWidth: '930px',
        height: SEARCHBAR_HEIGHT,
        flexDirection: 'unset',
        padding: theme.spacing(1.2, 2.6),
      },
    }),
    [theme],
  );

  const fields: SxProps<Theme> = useMemo(
    () => ({
      flex: 1,
      display: 'grid',
      gridTemplateColumns:
        '2fr max-content 1fr max-content 1fr max-content max-content',
      '.MuiTextField-root, .MuiStack-root': {
        height: '72px',
      },
      [theme.breakpoints.down('md')]: {
        '.MuiTextField-root, .MuiStack-root': {
          height: '83px',
        },
      },
      '.MuiInputBase-root': {
        paddingLeft: 0,
      },
    }),
    [theme],
  );

  const freeSearch: SxProps<Theme> = useMemo(
    () => ({
      input: {
        fontSize: '16px',
      },
    }),
    [],
  );

  const submitButton: SxProps<Theme> = useMemo(
    () => ({
      alignSelf: 'center',
      minWidth: 'unset',
      textTransform: 'uppercase',
    }),
    [],
  );

  return {formContainer, fields, freeSearch, submitButton};
};
