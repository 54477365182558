import {useMemo} from 'react';
import {SxProps, Theme, useTheme} from '@mui/material';
import {Colors} from '@/themes/variables';

export const useStyle = () => {
  const theme = useTheme();

  const select = useMemo<SxProps<Theme>>(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1.6),
      borderBottom: `1px solid #C7C7CC`,
      cursor: 'pointer',
    }),
    [theme],
  );

  const select__active = useMemo<SxProps<Theme>>(
    () => ({
      ...select,
      backgroundColor: Colors.Grey10,
    }),
    [select],
  );

  const bullet = useMemo<SxProps<Theme>>(
    () => ({
      marginRight: '16px',
      width: '16px',
      height: '16px',
      borderRadius: '16px',
      border: '1px solid black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  );

  const bulletActive = useMemo<SxProps<Theme>>(
    () => ({
      width: '10px',
      height: '10px',
      borderRadius: '10px',
      backgroundColor: '#000000',
    }),
    [],
  );

  return {select, select__active, bullet, bulletActive};
};
