import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const container = useMemo(
    () => ({
      width: '100%',
      maxWidth: '705px',
      borderRadius: '5px',
      boxShadow: `0px 7px 20px ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        maxWidth: '930px',
      },
    }),
    [theme],
  );

  return {container};
};
