import {SxProps, Theme, useTheme} from '@mui/material';
import {useMemo} from 'react';

export const useStyle = () => {
  const theme = useTheme();

  const menu: SxProps<Theme> = useMemo(
    () => ({
      '& .MuiPaper-root': {
        boxShadow: '0px 7px 20px rgba(78, 76, 76, 0.05)',
        border: `1px solid ${theme.palette.primary[200]}`,
        borderRadius: theme.spacing(1.2),

        '& .MuiMenuItem-divider': {
          backgroundColor: 'transparent',
          ':last-child': {
            border: 'none',
          },
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      menu,
    }),
    [menu],
  );
};
