import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyle = () => {
  const theme = useTheme();

  const card: SxProps<Theme> = useMemo(
    () => ({
      borderRadius: theme.spacing(1.2),
      cursor: 'pointer',
    }),
    [theme],
  );

  const heading: SxProps<Theme> = useMemo(
    () => ({
      backgroundColor: Colors.BackgroundDark,
      padding: theme.spacing(1.6, 2.4, 1.6, 2.4),
      borderTopLeftRadius: theme.spacing(1.2),
      borderTopRightRadius: theme.spacing(1.2),
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '50% 1fr 1fr',
      },
    }),
    [theme],
  );

  const purchaseCategories: SxProps<Theme> = useMemo(
    () => ({
      height: theme.spacing(2.6),
    }),
    [theme],
  );

  const contentBox: SxProps<Theme> = useMemo(
    () => ({
      backgroundColor: Colors.White,
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.spacing(5.6)}`,
      borderBottom: `1px solid ${Colors.GreyText}`,
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: `1fr ${theme.spacing(4.8)}`,
      },
    }),
    [theme],
  );

  const contentBoxAlt: SxProps<Theme> = useMemo(
    () => ({
      backgroundColor: Colors.Grey10,
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.spacing(5.6)}`,
      borderBottom: `1px solid ${Colors.GreyText}`,
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: `1fr ${theme.spacing(4.8)}`,
      },
    }),
    [theme],
  );

  const content: SxProps<Theme> = useMemo(
    () => ({
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      paddingTop: theme.spacing(0.8),
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr',
      },
    }),
    [theme],
  );

  const chevron: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [],
  );

  const cardRow: SxProps<Theme> = useMemo(
    () => ({
      marginLeft: theme.spacing(2.4),
      paddingBottom: theme.spacing(1.5),
      marginTop: theme.spacing(0.8),
      ':nth-of-type(-n + 3)': {
        borderBottom: '1px solid #C7C7CC',
      },
      [theme.breakpoints.down('lg')]: {
        ':nth-of-type(-n + 4)': {
          borderBottom: '1px solid #C7C7CC',
        },
      },
    }),
    [theme],
  );

  const bottom: SxProps<Theme> = useMemo(
    () => ({
      backgroundColor: Colors.White,
      borderBottomLeftRadius: theme.spacing(1.2),
      borderBottomRightRadius: theme.spacing(1.2),
    }),
    [theme],
  );

  const bottomAction: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(1.6),
      paddingBottom: theme.spacing(1.6),
    }),
    [theme],
  );

  const emptyBottom: SxProps<Theme> = useMemo(
    () => ({
      paddingTop: theme.spacing(1.6),
      paddingBottom: theme.spacing(1.6),
    }),
    [theme],
  );

  const consentRow: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    }),
    [],
  );

  return {
    card,
    heading,
    purchaseCategories,
    contentBox,
    contentBoxAlt,
    content,
    chevron,
    cardRow,
    bottom,
    bottomAction,
    emptyBottom,
    consentRow,
  };
};
