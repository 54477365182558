import React, {useMemo} from 'react';

import {Box} from '@mui/material';

import {IconSVG} from '@/atoms/IconSVG';
import {Text} from '@/atoms/Typography/Text';
import {Colors} from '@/themes/variables';

import {CellPercentageArrowProps} from './interfaces';

const CellPercentageArrow = ({
  value,
  textVariant = 'body',
}: CellPercentageArrowProps) => {
  const renderArrow = useMemo(() => {
    // No icon for value = 0
    if (value === 0) {
      return <></>;
    }
    // Green arrow up for positive
    return value > 0 ? (
      <Box sx={{transform: 'rotate(90deg)'}} marginLeft="4px" marginTop="-4px">
        <IconSVG icon="arrow_left_2" size={12} color={Colors.GreenArrow} />
      </Box>
    ) : (
      // Red arrow down for negative
      <Box sx={{transform: 'rotate(270deg)'}} marginLeft="4px" marginTop="-4px">
        <IconSVG icon="arrow_left_2" size={12} color={Colors.Error} />
      </Box>
    );
  }, [value]);

  return (
    <Box display="flex" flexDirection="row" width="fit-content">
      <Text variant={textVariant}>{value}%</Text>
      {renderArrow}
    </Box>
  );
};

export default React.memo(CellPercentageArrow);
