import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = (small?: boolean) => {
  const theme = useTheme();

  const keyValue: SxProps<Theme> = useMemo(
    () => ({
      fontSize: theme.typography.body,
      [theme.breakpoints.up('md')]: {
        fontSize: small ? theme.typography.body : theme.typography.headline5,
      },
    }),
    [small, theme],
  );

  const key: SxProps<Theme> = useMemo(
    () => ({
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(0.5),
      ...keyValue,
    }),
    [theme, keyValue],
  );

  const value: SxProps<Theme> = useMemo(
    () => ({
      ...keyValue,
    }),
    [keyValue],
  );

  return {key, value};
};

export const useTaskListStyles = () => {
  const theme = useTheme();

  const keyValue: SxProps<Theme> = useMemo(
    () => ({
      fontSize: theme.typography.xs,
    }),
    [theme],
  );

  const key: SxProps<Theme> = useMemo(
    () => ({
      whiteSpace: 'nowrap',
      ...keyValue,
    }),
    [keyValue],
  );

  const value: SxProps<Theme> = useMemo(
    () => ({
      marginLeft: theme.spacing(0.5),
      ...keyValue,
    }),
    [theme, keyValue],
  );

  const taskListContainer: SxProps<Theme> = useMemo(
    () => ({
      lineHeight: 1.1,
    }),
    [],
  );

  return {key, value, taskListContainer};
};

export const useFlexStyles = () => {
  const directionRowStyle: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
    }),
    [],
  );

  return {directionRowStyle};
};
