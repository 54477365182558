import {AxiosError} from 'axios';

import {LoginResponse} from '@/definitions/authentication';
import {AuthenticatedUser} from '@/definitions/user';

export type AuthenticationState = {
  isFetched: boolean;
  isFetching: boolean;
  error?: string;
  user?: AuthenticatedUser;
};

export enum AuthenticationActions {
  loginLoading = 'login loading',
  loginSuccess = 'login success',
  loginFail = 'login fail',
  logout = 'logout',
}

export type AuthenticationResponse = LoginResponse;

export type AuthenticationAction =
  | {
      type: AuthenticationActions.loginLoading | AuthenticationActions.logout;
    }
  | {
      type: AuthenticationActions.loginSuccess;
      payload: AuthenticationResponse;
    }
  | {
      type: AuthenticationActions.loginFail;
      payload: AxiosError;
    };

export type AuthenticationParams = {
  authCode: string;
  codeVerifier: string;
  state: string;
  redirectUrl?: string;
};
