import React from 'react';
import {
  PiCaretUpDownBold,
  PiCaretDownBold,
  PiCaretUpBold,
} from 'react-icons/pi';

import {Box} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';

import {CustomPagination} from './Pagination';
import {ITable} from './interfaces';
import {useStyle} from './styles';

const Table = ({checkbox = true, ...props}: ITable) => {
  const {container} = useStyle();

  return (
    <Box sx={container}>
      <DataGrid
        {...props}
        getRowHeight={() => 'auto'}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        disableVirtualization
        sortingMode="server"
        checkboxSelection={checkbox}
        slots={{
          baseTooltip: params => params.children.props.children,
          pagination: () => <CustomPagination {...props} />,
          columnSortedAscendingIcon: () => <PiCaretUpBold fontSize={14} />,
          columnSortedDescendingIcon: () => <PiCaretDownBold fontSize={14} />,
          columnUnsortedIcon: () => <PiCaretUpDownBold fontSize={16} />,
        }}
        slotProps={{
          baseCheckbox: {
            disableRipple: true,
          },
          baseIconButton: {
            disableRipple: true,
          },
        }}
      />
    </Box>
  );
};

export default React.memo(Table);
