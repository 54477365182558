import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const actions: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      gap: theme.spacing(1.6),
      height: '44px',
      width: '44px',
      borderRadius: '5px',
      border: `2px solid ${theme.palette.common.black}`,
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [theme],
  );

  const externalContainer: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
      },
    }),
    [theme],
  );

  const container: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    }),
    [theme],
  );

  const menuItems: SxProps<Theme> = useMemo(
    () => ({
      '& .MuiMenuItem-root': {
        paddingY: theme.spacing(1),
      },
      '& .MuiMenuItem-divider': {
        border: 'none',
      },
    }),
    [theme],
  );

  return {actions, externalContainer, container, menuItems};
};
