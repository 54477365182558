import React, {useCallback, useMemo} from 'react';

import {Box} from '@mui/material';

import {StoreRow} from '@/atoms/StoreRow';
import {LuxStore} from '@/store/stores';

import {NoResultScreenScreen} from '.';
import {useStyles} from '../styles';
import {StoreListProps} from './interfaces';

const StoreList = ({
  stores,
  activeStoreId,
  onStoreSelect,
  isLoading,
}: StoreListProps) => {
  const styles = useStyles();

  const handleActive = useCallback(
    (store: LuxStore) => {
      onStoreSelect(store?.id);
    },
    [onStoreSelect],
  );

  //Render Content based on results length and searchBar value
  const renderContent = useMemo(() => {
    if (!isLoading && stores && stores?.length > 0) {
      return stores.map(store => (
        <StoreRow
          key={store?.storeId}
          store={store}
          activeStoreId={activeStoreId}
          onClick={() => handleActive(store)}
        />
      ));
    }

    return (
      <NoResultScreenScreen
        isLoading={isLoading}
        results={Boolean(stores?.length)}
      />
    );
  }, [activeStoreId, handleActive, isLoading, stores]);

  return <Box sx={styles.contentStores}>{renderContent}</Box>;
};

export default React.memo(StoreList);
