import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const wrapper: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(3),

      button: {
        textTransform: 'none',
      },
    }),
    [theme],
  );

  const dialogTitle: SxProps<Theme> = useMemo(
    () => ({
      '.MuiDialogTitle-root': {
        padding: '10px 24px 35px',
        span: {
          fontSize: '24px',
          fontWeight: 500,
        },
      },
    }),
    [],
  );

  return {wrapper, dialogTitle};
};
