import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = (communication?: boolean) => {
  const theme = useTheme();

  const container: SxProps<Theme> = useMemo(
    () => ({
      position: 'relative',
      height: '100%',
      padding: `${theme.spacing(1.6, 2.4, 2.4, 2.4)}`,
      borderRadius: '5px',
      boxShadow: `0px 7px 20px ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.common.white,
    }),
    [theme],
  );

  const title: SxProps<Theme> = useMemo(
    () => ({
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3),
      },
    }),
    [theme],
  );

  const tag: SxProps<Theme> = useMemo(
    () => ({
      textTransform: 'uppercase',
      paddingTop: theme.spacing(0.5),
      paddingRight: theme.spacing(1.6),
      paddingBottom: theme.spacing(0.2),
      paddingLeft: theme.spacing(1.6),
      borderRadius: '100px',
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: communication
        ? theme.palette.tag.green
        : theme.palette.tag.red,
    }),
    [communication, theme],
  );

  return {container, title, tag};
};
