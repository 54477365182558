import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const chip = useMemo<SxProps<Theme>>(
    () => ({
      width: 'fit-content',
      padding: theme.spacing(0.5, 0.8),
      '.MuiChip-label': {
        padding: theme.spacing(0),
      },
    }),
    [theme],
  );

  const menu = useMemo<SxProps<Theme>>(
    () => ({
      '.MuiList-root': {
        padding: theme.spacing(0),
        width: 'max-content',
        '.MuiChip-root': {
          padding: theme.spacing(0.5, 0.8),
          '.MuiChip-label': {
            padding: theme.spacing(0, 0.4, 0, 0),
          },
        },
        '.MuiMenuItem-root': {
          cursor: 'default',
        },
        '.Mui-focusVisible': {
          backgroundColor: 'transparent',
        },
      },
    }),
    [theme],
  );

  const menuItem = useMemo<SxProps<Theme>>(
    () => ({
      padding: theme.spacing(0),
      ':hover': {
        backgroundColor: 'transparent',
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      chip,
      menu,
      menuItem,
    }),
    [chip, menu, menuItem],
  );
};
