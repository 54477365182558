import React from 'react';

import {Box, Tooltip} from '@mui/material';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../themes/assets/selection.json';
import {IProps} from './interfaces';

const IconSVG = ({
  className,
  icon,
  size,
  color,
  tooltip,
  onClick,
}: IProps): JSX.Element => {
  const iconComponent = (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      onClick={onClick}
    />
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top" arrow enterTouchDelay={0}>
        <Box>{iconComponent}</Box>
      </Tooltip>
    );
  }
  return iconComponent;
};
export default React.memo(IconSVG);
