import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const FOOTER_HEIGHT = '60px';

export const useStyle = () => {
  const theme = useTheme();

  const footer = useMemo(
    () => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.common.white,
      borderTop: `1px solid ${theme.palette.primary[300]}`,
      height: FOOTER_HEIGHT,
    }),
    [theme],
  );

  const navItems = useMemo(
    () => ({
      display: 'flex',
      gap: theme.spacing(16),
    }),
    [theme],
  );

  return {footer, navItems};
};
