import {useCallback, useMemo} from 'react';
import {Slide, ToastOptions, toast} from 'react-toastify';

import {useTheme} from '@mui/material';

import {IconSVG} from '../../components/atoms/IconSVG';

export const useToast = () => {
  const theme = useTheme();

  const commonProps: ToastOptions = useMemo(
    () => ({
      hideProgressBar: true,
      pauseOnHover: true,
      toastId: 1,
      transition: Slide,
      closeButton: (
        <IconSVG icon="close" size={17} color={theme.palette.common.black} />
      ),
      position: toast.POSITION.TOP_CENTER,
      icon: false,
      style: {
        marginTop: '100px',
        borderRadius: '12px',
      },
      autoClose: 1000,
    }),
    [theme],
  );

  /**
   * @brief Show error
   */
  const showErrorToast = useCallback(
    (message: string, optionalProps: ToastOptions = {}) => {
      toast.error(<span>{message}</span>, {
        ...commonProps,
        ...optionalProps,
      });
    },
    [commonProps],
  );

  /**
   * @brief Show message
   */
  const showSuccessToast = useCallback(
    (message: string, optionalProps: ToastOptions = {}) => {
      toast.success(<span>{message}</span>, {
        ...commonProps,
        ...optionalProps,
      });
    },
    [commonProps],
  );

  /**
   * @brief Show message fro task assigned
   */
  const showSuccessToastAssignTask = useCallback(
    (message: string, optionalProps: ToastOptions = {}) => {
      toast.success(
        <span>
          <strong>{`${message}`}</strong>
        </span>,
        {
          ...commonProps,
          ...optionalProps,
        },
      );
    },
    [commonProps],
  );

  /**
   * @brief Show message for store change
   */
  const showSuccessStoreChangeToast = useCallback(
    (
      message: string,
      storeId: string,
      storeName: string,
      optionalProps: ToastOptions = {},
    ) => {
      toast.success(
        <div>
          <span>
            <strong>{message}</strong>
          </span>
          <span>
            <strong>{` ${storeId} `}</strong>
          </span>
          <span>{` - ${storeName}`}</span>
        </div>,
        {
          ...commonProps,
          ...optionalProps,
        },
      );
    },
    [commonProps],
  );

  return {
    showErrorToast,
    showSuccessToast,
    showSuccessToastAssignTask,
    showSuccessStoreChangeToast,
  };
};
