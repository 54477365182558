import {useMemo} from 'react';

import {useTheme} from '@mui/material';

export const useStyles = (error: boolean) => {
  const theme = useTheme();

  const input = useMemo(
    () => ({
      '& .MuiInputBase-input': {
        position: 'relative',
        borderRadius: '6px',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${
          error ? theme.palette.error.main : theme.palette.grey['800']
        }`,
        color: error ? theme.palette.error.main : theme.palette.common.black,
        fontSize: theme.typography.body.fontSize,
        width: '100%',
        padding: theme.spacing(1),
      },
    }),
    [error, theme],
  );

  return {input};
};
