import React from 'react';
import {FormControl, FormHelperText} from '@mui/material';
import {Text} from '../Typography/Text';
import {IProps} from './interfaces';
import {useStyles} from './styles';

const InputWrapper = ({children, error = '', label, sx}: IProps) => {
  const {container, labelText, helperTextContainer} = useStyles();
  return (
    <FormControl variant="standard" sx={[container, sx]}>
      <Text variant="headline5" sx={labelText} medium>
        {label}:
      </Text>
      {children}
      <FormHelperText sx={helperTextContainer}>{error}</FormHelperText>
    </FormControl>
  );
};

export default React.memo(InputWrapper);
