import {useState, useEffect, useMemo} from 'react';

export function useMatchMedia() {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia('(orientation: portrait)').matches,
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');

    const handleOrientationChange = (event: MediaQueryListEvent) => {
      setIsPortrait(event.matches);
    };

    // Add the event listener
    mediaQuery.addEventListener('change', handleOrientationChange);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return useMemo(
    () => ({
      isPortrait,
    }),
    [isPortrait],
  );
}
