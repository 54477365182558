import {useMemo, useCallback} from 'react';

import * as Sentry from '@sentry/react';

import {useAPI} from '@/store/api';
import {useApiPath, APIS} from '@/store/api/useApiPath';
import {
  GetFilterAreasParams,
  GetFilterAreasResponse,
  GetFilterStoresParams,
  GetFilterStoresResponse,
  GetFilterCampaignsParams,
  GetFilterCampaignsResponse,
  GetFilterStatusResponse,
  GetFilterStatusParams,
  GetFilterCampaignDetailParams,
  GetFilterCampaignDetailResponse,
  GetFilterAssigneesParams,
  GetFilterAssigneesResponse,
} from '@/store/reportFilters/interfaces';

export const useReportFilters = () => {
  const {api} = useAPI();
  const {getApiPath} = useApiPath();

  const getFilterAreas = useCallback(
    async ({...params}: GetFilterAreasParams) => {
      try {
        const {data} = await api.post<GetFilterAreasResponse>(
          getApiPath(APIS.REPORTS_CAMPAIGNS_FILTER_AREAS),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getFilterStores = useCallback(
    async ({...params}: GetFilterStoresParams) => {
      try {
        const {data} = await api.post<GetFilterStoresResponse>(
          getApiPath(APIS.REPORTS_CAMPAIGNS_FILTER_STORES),
          {
            ...params,
          },
        );
        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getFilterCampaigns = useCallback(
    async (params?: GetFilterCampaignsParams) => {
      try {
        const {data} = await api.post<GetFilterCampaignsResponse>(
          getApiPath(APIS.REPORTS_CAMPAIGNS_FILTER_CAMPAIGNS),
          {
            ...(params && {...params}),
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getFilterCampaignDetail = useCallback(
    async ({storeId, ...params}: GetFilterCampaignDetailParams) => {
      try {
        const {data} = await api.post<GetFilterCampaignDetailResponse>(
          getApiPath(APIS.REPORTS_STORES_STOREID_CAMPAIGNS_FILTER_CAMPAIGNS, {
            storeId,
          }),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getFilterStatus = useCallback(
    async (params?: GetFilterStatusParams) => {
      try {
        const {data} = await api.post<GetFilterStatusResponse>(
          getApiPath(APIS.REPORTS_CAMPAIGNS_FILTER_STATUS),
          {
            ...(params && {...params}),
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  const getFilterAssignees = useCallback(
    async ({storeId, ...params}: GetFilterAssigneesParams) => {
      try {
        const {data} = await api.post<GetFilterAssigneesResponse>(
          getApiPath(APIS.REPORTS_STORES_STOREID_CAMPAIGNS_FILTER_ASSIGNEES, {
            storeId,
          }),
          {
            ...params,
          },
        );

        return data;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    [api, getApiPath],
  );

  return useMemo(
    () => ({
      getFilterAreas,
      getFilterStores,
      getFilterCampaigns,
      getFilterStatus,
      getFilterCampaignDetail,
      getFilterAssignees,
    }),
    [
      getFilterAreas,
      getFilterStores,
      getFilterCampaigns,
      getFilterStatus,
      getFilterCampaignDetail,
      getFilterAssignees,
    ],
  );
};
