import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  const accordionContainer = useCallback(
    (expanded: boolean, hasBaseContent: boolean): SxProps<Theme> => ({
      overflow: 'hidden',
      transition: 'all 0.2s linear',
      display: 'grid',
      gridTemplateColumns: '1fr',
      ...((expanded || hasBaseContent) && {gap: theme.spacing(0.8)}),
    }),
    [theme],
  );

  const accordionTitle = useCallback(
    (disableClick: boolean, hasTitle: boolean): SxProps<Theme> => ({
      ...(!disableClick && {
        cursor: 'pointer',
        pointerEvents: 'auto',
        opacity: 1,
        transition: 'all 0.2s linear',
        ':hover': {
          opacity: 0.7,
        },
      }),
      gap: theme.spacing(0.8),
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      alignItems: 'center',
      ...(hasTitle && {
        minHeight: theme.spacing(4),
      }),
      '.accordionTitleBox': {
        gridColumn: 'span 3',
      },
    }),
    [theme],
  );

  const accordionLabelContainer = useCallback(
    (centerAccordionLabel: boolean, labelAlign: string): SxProps<Theme> => ({
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      alignItems: 'center',
      ...(centerAccordionLabel
        ? {
            gridColumn: 'span 4',
            justifySelf: 'start',
          }
        : {
            justifySelf: 'end',
            alignSelf: labelAlign,
          }),
      gap: theme.spacing(0.4),
    }),
    [theme],
  );

  const detailIcon = useCallback(
    (expanded: boolean): SxProps<Theme> => ({
      height: theme.spacing(2),
      transition: 'all 0.2s linear',
      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    [theme],
  );

  const accordionContent = useCallback(
    (maxHeight: number, expanded: boolean): SxProps<Theme> => ({
      maxHeight: `${maxHeight}px`,
      height: `${maxHeight}px`,
      overflow: 'hidden',
      transition: 'all 0.2s linear',
      '.baseContent': {
        display: expanded ? 'none' : 'block',
      },
      '.fullContent': {
        display: expanded ? 'block' : 'none',
      },
    }),
    [],
  );

  return useMemo(
    () => ({
      accordionContainer,
      accordionTitle,
      accordionLabelContainer,
      detailIcon,
      accordionContent,
    }),
    [
      accordionContainer,
      accordionTitle,
      accordionLabelContainer,
      detailIcon,
      accordionContent,
    ],
  );
};
