import {createStore, Store, compose, applyMiddleware} from 'redux';

import {appReducer} from './reducers';
import {AppStore} from './types';

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose();

export const initializeStore = (): Store<AppStore> =>
  createStore(appReducer, composeEnhancers(applyMiddleware()));
