import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const communicationBox = useMemo<SxProps<Theme>>(
    () => ({
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr max-content',
      alignItems: 'center',
      gap: theme.spacing(1.6),
      padding: theme.spacing(0.8),
      backgroundColor: Colors.GreyBackground,
      borderRadius: theme.spacing(1.2),
      wordBreak: 'break-word',
      cursor: 'pointer',
      transition: 'all 0.2s linear',
      opacity: 1,
      '&:hover': {
        opacity: 0.7,
      },
      '.iconContainer': {
        display: 'grid',
        placeContent: 'center',
        borderRadius: theme.spacing(0.4),
        backgroundColor: Colors.White,
        svg: {
          fill: Colors.GreyText,
        },
        '&__big': {
          width: theme.spacing(8),
          minHeight: theme.spacing(8),
          [theme.breakpoints.up('lg')]: {
            width: theme.spacing(12),
            minHeight: theme.spacing(12),
          },
        },
        '&__small': {
          width: theme.spacing(8),
          minHeight: theme.spacing(8),
        },
      },
    }),
    [theme],
  );

  const communicationListContainer = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      gap: theme.spacing(0.8),
      width: '100%',
      '.notificationsList': {
        flexDirection: 'column',
        gap: theme.spacing(0.8),
        overflowY: 'auto',
        scrollbarWidth: 'none',
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({communicationBox, communicationListContainer}),
    [communicationBox, communicationListContainer],
  );
};
