import {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';

interface IProps {
  medium: boolean;
  heavy: boolean;
  superHeavy: boolean;
  uppercase: boolean;
  italic: boolean;
  underline: boolean;
}

export const useStyles = ({
  medium,
  heavy,
  superHeavy,
  uppercase,
  italic,
  underline,
}: IProps) => {
  const text: SxProps<Theme> = useMemo(
    () => ({
      fontWeight: (theme: Theme) => {
        if (medium) {
          return theme.typography.fontWeightMedium;
        }

        if (heavy) {
          return theme.typography.fontWeightBold;
        }

        if (superHeavy) {
          return 800;
        }

        return theme.typography.fontWeightRegular;
      },
      textTransform: uppercase ? 'uppercase' : undefined,
      fontStyle: italic ? 'italic' : undefined,
      textDecoration: underline ? 'underline' : undefined,
    }),
    [heavy, medium, superHeavy, uppercase, italic, underline],
  );
  return {text};
};
