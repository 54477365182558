import React, {useMemo, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';

import {TASK_STATUS, TASK_VISIBILITY} from 'src/store/campaigns';

import {TaskListTabsProps} from '@/molecules/TaskListTabs/definitions';
import {AuthRoutes} from '@/navigation/routes';
import {TaskListTable} from '@/pages/Clienteling/SubPages/TaskListPage/TaskListTable';
import {CampaignsProvider} from '@/store/CampaignsContext';
import {useAuthentication} from '@/store/authentication';

const TaskListPage = () => {
  const {t} = useTranslation();
  const {isStoreManager, isAreaManager} = useAuthentication();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Task lists',
    });
  }, []);

  const tabs = useMemo(
    (): TaskListTabsProps['tabs'] => [
      {
        id: TASK_VISIBILITY.ASSIGNTOME,
        label: t('TaskList.assignToMe'),
        tabsSecondLevel: [
          {
            id: TASK_STATUS.ALL,
            label: t('TaskList.all'),
          },
          {
            id: TASK_STATUS.TODO,
            label: t('TaskList.toDo'),
          },
          {
            id: TASK_STATUS.COMPLETED,
            label: t('TaskList.completed'),
          },
        ],
      },
      {
        id: TASK_VISIBILITY.ALL,
        label: t('TaskList.allTasks'),
        tabsSecondLevel: [
          {
            id: TASK_STATUS.ALL,
            label: t('TaskList.all'),
          },
          {
            id: TASK_STATUS.NOTASSIGNED,
            label: t('TaskList.notAssigned'),
          },
          {
            id: TASK_STATUS.ASSIGNED,
            label: t('TaskList.assigned'),
          },
          {
            id: TASK_STATUS.CONTACTED,
            label: t('TaskList.contacted'),
          },
        ],
      },
    ],
    [t],
  );

  return (
    <CampaignsProvider
      title={t('TaskList.title')}
      currentRoute={AuthRoutes.CLIENTELING_TASK_LIST}
      tabs={tabs}
      defaultVisibility={TASK_VISIBILITY.ASSIGNTOME}
      defaultPage="1"
      withPagination
      isTask
      sort={false}
      enableBack={isStoreManager || isAreaManager}>
      <TaskListTable />
    </CampaignsProvider>
  );
};

export default React.memo(TaskListPage);
