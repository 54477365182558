import React, {useMemo, useCallback, useState} from 'react';
import {BsChevronRight} from 'react-icons/bs';
import {PiSealCheckLight} from 'react-icons/pi';

import {
  Stack,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {Headline5} from '@/atoms/Headline5';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces.d';
import {LabelValue} from '@/atoms/LabelValue';
import {Modal} from '@/atoms/Modal';
import {SuspenseBackgroundImage} from '@/atoms/SuspenseBackgroundImage';
import {TinyText} from '@/atoms/TinyText';
import {XSText} from '@/atoms/XSText';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useCurrencyFormat} from '@/hooks/useCurrencyFormat';
import {useParamsAndClean} from '@/hooks/useParamsAndClean';
import {usePrescriptionModal} from '@/hooks/usePrescriptionModal';
import {composeAddress, composeStoreAddress} from '@/lib/utils';
import {CarouselModal} from '@/molecules/CarouselModal';
import {CustomAccordion} from '@/molecules/CustomAccordion';
import {TransactionProps} from '@/organisms/Transaction/interfaces.d';
import {useStyles} from '@/organisms/Transaction/styles';
import {ProductStatus} from '@/organisms/TransactionCard/ProductStatus';
import {ProductProps} from '@/organisms/TransactionCard/interfaces.d';
import {GetOrdersV2Params} from '@/store/order';
import {PrescriptionHeader} from '@/store/prescription';
import {ReactComponent as SmartShopper} from '@/themes/assets/smart-shopper.svg';
import {Colors} from '@/themes/variables';

const Transaction = ({transaction, isQuote = false}: TransactionProps) => {
  const styles = useStyles();

  return (
    <Box sx={styles.transactionCardContainer(isQuote)}>
      <Card className="transactionCard">
        <TransactionHeader transaction={transaction} isQuote={isQuote} />
        <Stack
          direction="column"
          gap={1.6}
          padding={1.6}
          divider={<Divider orientation="horizontal" />}>
          <TransactionInfoHeader transaction={transaction} isQuote={isQuote} />
          {transaction?.products?.length > 0 && (
            <TransactionBody transaction={transaction} isQuote={isQuote} />
          )}
          {!!transaction?.prescription && !isQuote && (
            <TransactionDocuments transaction={transaction} />
          )}
        </Stack>
      </Card>
    </Box>
  );
};

export default React.memo(Transaction);

interface TransactionHeaderProps {
  transaction: TransactionProps['transaction'];
  isQuote: boolean;
}

const TransactionHeader = React.memo(
  ({transaction, isQuote}: TransactionHeaderProps) => {
    const {getTranslationWithValue} = useBaseTranslation(
      'Transaction.transactionHeader',
    );

    const styles = useStyles();
    const {formatToCurrency} = useCurrencyFormat();

    const isReturned = useMemo(
      () => transaction?.orderStatus === 'RETURNED',
      [transaction?.orderStatus],
    );

    return (
      <Stack sx={styles.transactionCardHeaderContainer(isReturned, isQuote)}>
        <HeadlineText heavy color={Colors.White}>
          {!!transaction?.transactionDate
            ? dayjs(transaction?.transactionDate).format(
                dayjs().locale() === 'it' ? 'D MMM YYYY' : 'Do MMMM YYYY',
              )
            : '-'}
        </HeadlineText>
        <Stack className="headerInfo">
          <LabelValue
            label={
              <XSText className="headerText">
                {getTranslationWithValue(
                  0,
                  !isQuote ? 'orderNumber' : 'quoteId',
                )}
              </XSText>
            }
            value={
              <Headline5 heavy className="headerText">
                {transaction?.orderId ?? '-'}
              </Headline5>
            }
          />
          <LabelValue
            label={
              <XSText className="headerText">
                {getTranslationWithValue(
                  0,
                  !isQuote ? 'transactionId' : 'validUntil',
                )}
              </XSText>
            }
            value={
              <Headline5 heavy className="headerText">
                {!isQuote
                  ? transaction?.transactionId ?? '-'
                  : transaction?.quotationExiprationDate
                  ? dayjs(transaction?.quotationExiprationDate).format(
                      dayjs().locale() === 'it' ? 'D MMM YYYY' : 'Do MMMM YYYY',
                    )
                  : '-'}
              </Headline5>
            }
          />
          <LabelValue
            label={
              <XSText className="headerText">
                {getTranslationWithValue(0, 'totalAmount')}
              </XSText>
            }
            value={
              <Headline5 className="tenderValue" heavy>
                {!!transaction?.totalTenderValue
                  ? formatToCurrency(transaction?.totalTenderValue)
                  : '-'}
              </Headline5>
            }
          />
        </Stack>
      </Stack>
    );
  },
);

TransactionHeader.displayName = 'TransactionHeader';

interface TransactionInfoHeaderProps {
  transaction: TransactionProps['transaction'];
  isQuote: boolean;
}

const TransactionInfoHeader = React.memo(
  ({transaction, isQuote}: TransactionInfoHeaderProps) => {
    const {getTranslationWithValue} = useBaseTranslation(
      'Transaction.transactionInfo',
    );
    const styles = useStyles();

    const isOnline = useMemo(
      () => transaction?.channel === 'ONLINE',
      [transaction?.channel],
    );

    const isSmartShopper = useMemo(
      () => transaction?.isSmartShopper,
      [transaction?.isSmartShopper],
    );

    const addressLabel = useMemo(() => {
      const key = isOnline ? 'deliveryAddress' : 'storeAddress';
      return getTranslationWithValue(0, key);
    }, [getTranslationWithValue, isOnline]);

    const composedAddress = useMemo(() => {
      if (isOnline) {
        return !!transaction?.shippingAddress
          ? composeAddress(transaction?.shippingAddress)
          : '-';
      }
      return !!transaction?.store
        ? composeStoreAddress(transaction?.store)
        : '-';
    }, [isOnline, transaction?.shippingAddress, transaction?.store]);

    const renderIconTransactionType = useMemo(() => {
      let icon = 'shop';
      let label = 'origin.store';

      if (isOnline) {
        icon = 'web';
        label = 'origin.online';
      }
      if (isSmartShopper) {
        label = 'origin.smartShopper';
      }

      label = getTranslationWithValue(0, label);

      return (
        <Stack
          display="grid"
          gridTemplateColumns="max-content 1fr"
          alignItems="center"
          gap={0.8}>
          <BodyText medium>{label}</BodyText>
          {isSmartShopper || isOnline ? (
            <Stack
              width="100%"
              direction="row"
              alignItems="center"
              gap={0.8}
              justifyContent="space-between">
              {isSmartShopper && <SmartShopper height={20} />}
              {isOnline && <IconSVG icon={icon as IconNames} size={20} />}
              {!!transaction?.trackerOrderUrl && (
                <BodyText medium underline>
                  {getTranslationWithValue(0, 'tracking')}
                </BodyText>
              )}
            </Stack>
          ) : (
            <IconSVG icon={icon as IconNames} size={20} />
          )}
        </Stack>
      );
    }, [
      getTranslationWithValue,
      isOnline,
      isSmartShopper,
      transaction?.trackerOrderUrl,
    ]);

    return (
      <Stack sx={styles.transactionCardInfoHeaderContainer(isQuote)}>
        <ProductStatus orderStatus={transaction?.orderStatus} />
        <Stack
          className="headerInfo"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap={1.6}>
          <Stack
            className="columnInfo"
            direction="column"
            gap={1.2}
            divider={<Divider orientation="horizontal" />}>
            <LabelValue
              label={getTranslationWithValue(0, 'customerId')}
              value={transaction?.customerId ?? '-'}
            />
            <LabelValue
              label={getTranslationWithValue(0, 'contact')}
              value={transaction?.customerPhone ?? '-'}
              secondValue={transaction?.customerEmail ?? '-'}
            />
          </Stack>
          <Stack
            className="columnInfo"
            direction="column"
            gap={1.2}
            divider={<Divider orientation="horizontal" />}>
            <LabelValue
              label={getTranslationWithValue(0, 'orderOrigin')}
              value={renderIconTransactionType}
            />
            <LabelValue label={addressLabel} value={composedAddress} />
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

TransactionInfoHeader.displayName = 'TransactionInfoHeader';

interface TransactionBodyProps {
  transaction: TransactionProps['transaction'];
  isQuote: boolean;
}

const TransactionBody = React.memo(
  ({transaction, isQuote}: TransactionBodyProps) => {
    const {getTranslationWithValue} = useBaseTranslation(
      'Transaction.transactionContent',
    );
    const styles = useStyles();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const accordionLabel = useMemo(() => {
      let key = isOpen ? 'hideDetails' : 'showDetails';
      return getTranslationWithValue(0, key);
    }, [getTranslationWithValue, isOpen]);

    return (
      <>
        {isQuote ? (
          <Stack direction="column" gap={1.6}>
            <HeadlineText heavy>
              {getTranslationWithValue(
                0,
                !isQuote ? 'purchasedItems' : 'items',
                {
                  size: transaction?.products?.length,
                },
              )}
            </HeadlineText>
            <Stack direction="column" gap={1.6}>
              {transaction?.products?.map((product, index) => {
                return (
                  <BigProductCard
                    key={`${transaction?.transactionId}_${product?.productName}_${index}`}
                    product={product}
                    isQuote={isQuote}
                  />
                );
              })}
            </Stack>
          </Stack>
        ) : (
          <CustomAccordion
            bottomContent
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            accordionLabel={accordionLabel}
            accordionTitle={
              <HeadlineText heavy>
                {getTranslationWithValue(0, 'purchasedItems', {
                  size: transaction?.products?.length,
                })}
              </HeadlineText>
            }
            baseContent={
              <Stack sx={styles.smallCardList}>
                {transaction?.products?.map((product, index) => {
                  return (
                    <SmallProductCard
                      key={`${transaction?.transactionId}_${product?.productName}_${index}`}
                      product={product}
                    />
                  );
                })}
              </Stack>
            }
            expandedContent={
              <Stack direction="column" gap={1.6}>
                {transaction?.products?.map((product, index) => {
                  return (
                    <BigProductCard
                      key={`${transaction?.transactionId}_${product?.productName}_${index}`}
                      product={product}
                      isQuote={isQuote}
                    />
                  );
                })}
              </Stack>
            }
          />
        )}
      </>
    );
  },
);

TransactionBody.displayName = 'TransactionBody';

interface SmallProductCardProps {
  product: ProductProps;
}

const SmallProductCard = React.memo(({product}: SmallProductCardProps) => {
  const styles = useStyles();

  const productKind = useMemo(
    () => product?.specifyProductKind,
    [product?.specifyProductKind],
  );
  const productImage = useMemo(() => {
    if (!product?.images) return undefined;

    return `${product?.images[0].split('?')[0]}?imwidth=48`;
  }, [product?.images]);

  const productIcon = useMemo<JSX.Element>(() => {
    let icon: IconNames;

    switch (productKind) {
      case 'SUN':
        icon = 'lens_sunglasses';
        break;
      case 'LENSES':
        icon = 'lens_glasses';
        break;
      case 'FRAMES':
        icon = 'glasses';
        break;
      case 'CONTACTS':
        icon = 'lens_contact';
        break;
      case 'APPAREL':
        icon = 'clothes';
        break;
      case 'READERS':
        icon = 'readers';
        break;
      case 'WARRANTY':
        return <PiSealCheckLight color={Colors.GreyText} fontSize={32} />;
      case 'ACCESSORIES':
        icon = 'retail';
        break;
      default:
        icon = 'no_kind';
        break;
    }

    return <IconSVG icon={icon} color={Colors.GreyText} size={28} />;
  }, [productKind]);

  return (
    <Stack sx={styles.smallCardContainer}>
      <Stack sx={styles.productImageContainer}>
        {productImage ? (
          <React.Suspense
            key={`${productImage}_small`}
            fallback={
              <CircularProgress disableShrink color="primary" size={25} />
            }>
            <SuspenseBackgroundImage
              sx={styles.productImageContainer}
              src={productImage}
            />
          </React.Suspense>
        ) : (
          productIcon
        )}
      </Stack>
      <LabelValue
        value={
          <BodyText
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            heavy>
            {product?.styleName ?? '-'}
          </BodyText>
        }
        label={<TinyText>{product?.upc ?? '-'}</TinyText>}
      />
    </Stack>
  );
});

SmallProductCard.displayName = 'SmallProductCard';

interface BigProductCardProps {
  product: ProductProps;
  isQuote: boolean;
}

const BigProductCard = React.memo(({product, isQuote}: BigProductCardProps) => {
  const {getTranslationWithValue} = useBaseTranslation(
    'Transaction.bigProductCard',
  );
  const styles = useStyles();

  const [showModal, setShowModal] = useState(false);

  const toggleGallery = useCallback((open: boolean) => {
    setShowModal(open);
  }, []);

  const productKind = useMemo(
    () => product?.specifyProductKind,
    [product?.specifyProductKind],
  );

  const productImage = useMemo(() => {
    if (!product?.images) return undefined;

    return `${product?.images[0].split('?')[0]}?imwidth=600`;
  }, [product?.images]);

  const productIcon = useMemo<JSX.Element>(() => {
    let icon: IconNames;

    switch (productKind) {
      case 'SUN':
        icon = 'lens_sunglasses';
        break;
      case 'LENSES':
        icon = 'lens_glasses';
        break;
      case 'FRAMES':
        icon = 'glasses';
        break;
      case 'CONTACTS':
        icon = 'lens_contact';
        break;
      case 'APPAREL':
        icon = 'clothes';
        break;
      case 'READERS':
        icon = 'readers';
        break;
      case 'WARRANTY':
        return <PiSealCheckLight color={Colors.GreyText} fontSize={32} />;
      case 'ACCESSORIES':
        icon = 'retail';
        break;
      default:
        icon = 'no_kind';
        break;
    }

    return <IconSVG icon={icon} color={Colors.GreyText} size={28} />;
  }, [productKind]);

  return (
    <>
      <Stack sx={styles.bigCardContainer(isQuote)}>
        <Stack className="brandQuantityContainer">
          <Stack className="brandContainer">
            <Stack sx={styles.productImageContainer}>{productIcon}</Stack>
            <LabelValue
              value={
                <BodyText
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  heavy>
                  {product?.styleName ?? '-'}
                </BodyText>
              }
              {...(!!product?.brand && {
                label: <BodyText>{product?.brand ?? '-'}</BodyText>,
              })}
            />
          </Stack>
          <LabelValue
            value={product?.quantity ?? '-'}
            label={getTranslationWithValue(0, 'quantity')}
          />
        </Stack>
        <Stack className="infoImageContainer">
          <Stack className="infoContainer">
            <LabelValue
              value={product?.upc ?? '-'}
              label={getTranslationWithValue(0, 'upc')}
            />
            {(productKind === 'SUN' || productKind === 'FRAMES') && (
              <>
                <LabelValue
                  value={product?.modelCodeDisplay ?? '-'}
                  label={getTranslationWithValue(0, 'modelColor')}
                />
                <LabelValue
                  value={product?.frameColorLabel ?? '-'}
                  label={getTranslationWithValue(0, 'frameColor')}
                />
                <LabelValue
                  value={`${product?.sizeOrdinal ?? '-'} (${
                    product?.size ?? '-'
                  }/${product?.bridgeSize ?? '-'})`}
                  label={getTranslationWithValue(0, 'measure')}
                />
                {!isQuote && (
                  <ProductDetails product={product} isQuote={isQuote} />
                )}
              </>
            )}
            {(productKind === 'LENSES' || productKind === 'CONTACTS') && (
              <>
                {!isQuote && (
                  <LensParameters product={product} isQuote={isQuote} />
                )}
              </>
            )}
          </Stack>
          {productImage && (
            <Stack
              className="imageContainer"
              {...(!isQuote && {onClick: () => toggleGallery(true)})}>
              <React.Suspense
                key={`${productImage}_big`}
                fallback={
                  <CircularProgress disableShrink color="primary" size={25} />
                }>
                <SuspenseBackgroundImage
                  sx={styles.bigImage}
                  src={productImage}
                />
              </React.Suspense>
              {!isQuote && (
                <Stack className="imageCounter">
                  <IconSVG icon="view_on" size={18} color={Colors.White} />
                  <TinyText medium>{product?.images?.length}</TinyText>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
        <Box paddingTop={0.8}>
          {(productKind === 'SUN' || productKind === 'FRAMES') && isQuote && (
            <ProductDetails product={product} isQuote={isQuote} />
          )}
          {(productKind === 'LENSES' || productKind === 'CONTACTS') &&
            isQuote && <LensParameters product={product} isQuote={isQuote} />}
        </Box>
      </Stack>
      {showModal && (
        <CarouselModal
          open={showModal}
          onClose={() => toggleGallery(false)}
          images={product?.images!}
          dialogTitle={product?.styleName ?? '-'}
          carouselTitle={getTranslationWithValue(0, 'gallery')}
        />
      )}
    </>
  );
});

BigProductCard.displayName = 'BigProductCard';

interface ProductDetailsProps {
  product: ProductProps;
  isQuote: boolean;
}

const ProductDetails = React.memo(({product, isQuote}: ProductDetailsProps) => {
  const {getTranslationWithValue} = useBaseTranslation(
    'Transaction.productDetails',
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleModal = useCallback((value?: boolean) => {
    setShowModal(prevValue => value ?? !prevValue);
  }, []);

  return (
    <>
      {isQuote ? (
        <Box paddingLeft={5.6}>
          <CustomAccordion
            centerAccordionLabel
            topContent
            isOpen={showModal}
            setIsOpen={setShowModal}
            accordionLabel={getTranslationWithValue(
              0,
              showModal ? 'hide' : 'show',
            )}
            expandedContent={
              <ProductDetailsModalContent product={product} isQuote={isQuote} />
            }
          />
        </Box>
      ) : (
        <>
          <Stack
            direction="row"
            gap={0.4}
            className="detailsCTA"
            onClick={() => toggleModal(true)}>
            <TinyText heavy>{getTranslationWithValue(0, 'title')}</TinyText>
            <IconSVG icon="open_external" size={16} />
          </Stack>
          {showModal && (
            <Modal
              open={showModal}
              onClose={() => toggleModal(false)}
              maxWidth={72}
              dialogTitle={product?.styleName}
              dialogContent={
                <ProductDetailsModalContent
                  product={product}
                  isQuote={isQuote}
                />
              }
            />
          )}
        </>
      )}
    </>
  );
});

ProductDetails.displayName = 'ProductDetails';

const ProductDetailsModalContent = React.memo(
  ({product, isQuote}: ProductDetailsProps) => {
    const {getTranslationWithValue} = useBaseTranslation(
      'Transaction.productDetails',
      'Transaction.lensParameters',
    );
    const styles = useStyles();

    const prodDetails = useMemo(() => {
      return [
        {
          label: getTranslationWithValue(0, 'brand'),
          value: product?.brand ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'name'),
          value: product?.model ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'upc'),
          value: product?.upc ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'modelColor'),
          value: product?.moco ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'frameShape'),
          value: product?.frameShapeLabel ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'frameMaterial'),
          value: product?.frameMaterialLabel ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'frameColor'),
          value: product?.frameColorLabel ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'lensMaterial'),
          value: product?.lensMaterial ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'lensColor'),
          value: product?.lensColorLabel ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'suitableForPrescription'),
          value: !!product?.roxable ? (product?.roxable ? 'Si' : 'No') : '-',
        },
        {
          label: getTranslationWithValue(0, 'suitableForProgressive'),
          value: !!product?.progressiveFriendly
            ? product?.progressiveFriendly
              ? getTranslationWithValue(0, 'yes')
              : getTranslationWithValue(0, 'no')
            : '-',
        },
      ];
    }, [
      getTranslationWithValue,
      product?.brand,
      product?.frameColorLabel,
      product?.frameMaterialLabel,
      product?.frameShapeLabel,
      product?.lensColorLabel,
      product?.lensMaterial,
      product?.moco,
      product?.model,
      product?.progressiveFriendly,
      product?.roxable,
      product?.upc,
    ]);

    const lensDetails = useMemo(() => {
      const labelValues = [
        {
          label: getTranslationWithValue(0, 'size'),
          value: `${product?.sizeOrdinal ?? '-'} (${product?.size ?? '-'}/${
            product?.bridgeSize ?? '-'
          })`,
        },
        {
          label: getTranslationWithValue(0, 'hingeToHinge'),
          value: product?.hingeDistance ? `${product?.hingeDistance}mm` : '-',
        },
        {
          label: getTranslationWithValue(0, 'lensHeight'),
          value: product?.lensHeight ? product?.lensHeight?.toString() : '-',
        },
      ];

      return labelValues;
    }, [
      getTranslationWithValue,
      product?.bridgeSize,
      product?.hingeDistance,
      product?.lensHeight,
      product?.size,
      product?.sizeOrdinal,
    ]);

    return (
      <Stack sx={styles.productDetailsModalContent(isQuote)}>
        {!isQuote && (
          <Headline5 heavy>{getTranslationWithValue(0, 'title')}</Headline5>
        )}
        <Stack className="content">
          <Stack className="infoContent">
            {prodDetails?.map(detail => {
              return (
                <Box key={detail.label} className="singleBox">
                  <LabelValue {...detail} />
                </Box>
              );
            })}
          </Stack>
          <Stack direction="column" gap={1.6}>
            <Headline5 heavy>{getTranslationWithValue(0, 'lenses')}</Headline5>
            <Stack className="infoContent">
              {lensDetails?.map(detail => {
                return (
                  <Box key={detail.label} className="singleBox">
                    <LabelValue {...detail} />
                  </Box>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

ProductDetailsModalContent.displayName = 'ProductDetailsModalContent';

interface LensParametersProps {
  product: ProductProps;
  isQuote: boolean;
}

const LensParameters = React.memo(({product, isQuote}: LensParametersProps) => {
  const {getTranslationWithValue} = useBaseTranslation(
    'Transaction.lensParameters',
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleModal = useCallback((value?: boolean) => {
    setShowModal(prevValue => value ?? !prevValue);
  }, []);

  return (
    <>
      {isQuote ? (
        <Box paddingLeft={5.6}>
          <CustomAccordion
            centerAccordionLabel
            topContent
            isOpen={showModal}
            setIsOpen={setShowModal}
            accordionLabel={getTranslationWithValue(
              0,
              showModal ? 'hide' : 'show',
            )}
            expandedContent={
              <LensParametersModalContent product={product} isQuote={isQuote} />
            }
          />
        </Box>
      ) : (
        <>
          <Stack
            direction="row"
            gap={0.4}
            className="detailsCTA"
            onClick={() => toggleModal(true)}>
            <TinyText heavy>{getTranslationWithValue(0, 'title')}</TinyText>
            <IconSVG icon="open_external" size={16} />
          </Stack>
          {showModal && (
            <Modal
              open={showModal}
              onClose={() => toggleModal(false)}
              maxWidth={72}
              dialogTitle={product?.styleName}
              dialogContent={
                <LensParametersModalContent
                  product={product}
                  isQuote={isQuote}
                />
              }
            />
          )}
        </>
      )}
    </>
  );
});

LensParameters.displayName = 'LensParameters';

const LensParametersModalContent = React.memo(
  ({product, isQuote}: LensParametersProps) => {
    const {getTranslationWithValue} = useBaseTranslation(
      'Transaction.lensParameters',
    );
    const styles = useStyles();

    const conctactDetails = useMemo(() => {
      return [
        {
          label: getTranslationWithValue(0, 'spherePower'),
          value: product?.spherePower ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'addition'),
          value: product?.addition ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'baseCurve'),
          value: product?.baseCurve ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'diameter'),
          value: product?.diameter ? product?.diameter?.toString() : '-',
        },
        {
          label: getTranslationWithValue(0, 'cylinder'),
          value: product?.cylinder ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'axis'),
          value: product?.axis ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'packageQuantity'),
          value: product?.packageQuantity ?? '-',
        },
      ];
    }, [
      getTranslationWithValue,
      product?.addition,
      product?.axis,
      product?.baseCurve,
      product?.cylinder,
      product?.diameter,
      product?.packageQuantity,
      product?.spherePower,
    ]);

    const renderMaterialValue = useMemo(() => {
      if (product?.index || product?.lensMaterial) {
        return (
          (product?.index ? `${product?.index} ` : '') +
          (product?.lensMaterial ? product?.lensMaterial : '')
        );
      }
      return undefined;
    }, [product?.index, product?.lensMaterial]);

    const renderTreatmentsValue = useMemo(() => {
      if (
        product?.lensTreatmentAntiBlue ||
        product?.lensTreatmentAntiReflective
      ) {
        return (
          (product?.lensTreatmentAntiBlue
            ? `${product?.lensTreatmentAntiBlue} `
            : '') +
          (product?.lensTreatmentAntiReflective
            ? product?.lensTreatmentAntiReflective
            : '')
        );
      }
      if (product?.lensTreatments) {
        return product?.lensTreatments.join(', ');
      }
      return undefined;
    }, [
      product?.lensTreatmentAntiBlue,
      product?.lensTreatmentAntiReflective,
      product?.lensTreatments,
    ]);

    const lensDetails = useMemo(() => {
      return [
        {
          label: getTranslationWithValue(0, 'visualNeed'),
          value: product?.visionNeed ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'lensType'),
          value: product?.lensType ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'lensBrand'),
          value: product?.styleName ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'material'),
          value: renderMaterialValue ?? '-',
        },
        {
          label: getTranslationWithValue(0, 'treatments'),
          value: renderTreatmentsValue ?? '-',
        },
      ];
    }, [
      getTranslationWithValue,
      product?.lensType,
      product?.styleName,
      product?.visionNeed,
      renderMaterialValue,
      renderTreatmentsValue,
    ]);

    const itemToMap = useMemo(() => {
      return product?.specifyProductKind === 'LENSES'
        ? lensDetails
        : conctactDetails;
    }, [conctactDetails, lensDetails, product?.specifyProductKind]);

    return (
      <Stack sx={styles.productDetailsModalContent(isQuote)}>
        {!isQuote && (
          <Headline5 heavy>{getTranslationWithValue(0, 'title')}</Headline5>
        )}
        <Stack className="content">
          <Stack className="infoContent">
            {itemToMap?.map(detail => {
              return (
                <Box key={detail.label} className="singleBox">
                  <LabelValue {...detail} />
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

LensParametersModalContent.displayName = 'LensParametersModalContent';

interface TransactionDocumentsProps {
  transaction: TransactionProps['transaction'];
}

const TransactionDocuments = React.memo(
  ({transaction}: TransactionDocumentsProps) => {
    const params = useParamsAndClean<
      Pick<
        GetOrdersV2Params,
        'name' | 'email' | 'bannerSearch' | 'countrySearch'
      >
    >('name', 'email', 'bannerSearch', 'countrySearch');
    const {prescriptionModal, showPrescription} = usePrescriptionModal();

    const paramsRetrieved = useMemo(() => {
      return (
        Object.keys(params).length > 0 &&
        'bannerSearch' in params &&
        'countrySearch' in params
      );
    }, [params]);

    const {getTranslationWithValue} = useBaseTranslation(
      'Transaction.transactionDocuments',
    );
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isOpen, setIsOpen] = useState(false);

    const availableDocuments = useMemo(() => {
      let documents: Array<TransactionDocumentElement> = [];

      if (transaction?.prescription && paramsRetrieved) {
        documents.push({
          type: 'prescription',
          value: transaction?.prescription,
          onClickCb: () =>
            showPrescription({
              prescriptionId: transaction?.prescription?.prescriptionId!,
              email: transaction?.customerEmail!,
              channel: transaction?.prescriptionChannel!,
              countrySearch: params?.countrySearch,
              bannerSearch: params?.bannerSearch,
            }),
        });
      }

      return documents;
    }, [
      params?.bannerSearch,
      params?.countrySearch,
      paramsRetrieved,
      showPrescription,
      transaction?.customerEmail,
      transaction?.prescription,
      transaction?.prescriptionChannel,
    ]);

    const hasDocuments = useMemo(
      () => availableDocuments?.length > 0,
      [availableDocuments],
    );

    const hasManyDocuments = useMemo(
      () => availableDocuments?.length > 1,
      [availableDocuments],
    );

    const documentsTitle = useMemo(() => {
      if (hasManyDocuments) {
        return getTranslationWithValue(0, 'documentsCount', {
          size: availableDocuments?.length,
        });
      }

      return getTranslationWithValue(0, 'documents');
    }, [availableDocuments?.length, getTranslationWithValue, hasManyDocuments]);

    const renderDocumentsTitle = useMemo(() => {
      if (!hasDocuments) return null;

      return (
        <Stack sx={styles.transactionDocumentsWrapper(hasManyDocuments)}>
          <HeadlineText heavy>{documentsTitle}</HeadlineText>
          {(!isMobile || !hasManyDocuments) && (
            <Stack className="firstTransactionDocument">
              <TransactionDocument document={availableDocuments[0]} />
            </Stack>
          )}
        </Stack>
      );
    }, [
      availableDocuments,
      documentsTitle,
      hasDocuments,
      hasManyDocuments,
      isMobile,
      styles,
    ]);

    const accordionLabel = useMemo(() => {
      if (!hasManyDocuments) return undefined;

      return getTranslationWithValue(0, isOpen ? 'hide' : 'showAll');
    }, [getTranslationWithValue, hasManyDocuments, isOpen]);

    if (!hasDocuments) return null;

    if (!hasManyDocuments) {
      return (
        <>
          {renderDocumentsTitle}
          {prescriptionModal}
        </>
      );
    }

    return (
      <>
        <CustomAccordion
          bottomContent
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          accordionLabel={accordionLabel}
          accordionTitle={renderDocumentsTitle!}
          {...(isMobile && {
            baseContent: (
              <TransactionDocument document={availableDocuments[0]} />
            ),
          })}
          expandedContent={
            <Stack sx={styles.transactionDocumentAccordionContainer}>
              {availableDocuments.slice(1).map((document, index) => {
                return (
                  <Box
                    className="singleDocument"
                    key={`${document?.type}_${index}`}>
                    <TransactionDocument document={document} />
                  </Box>
                );
              })}
            </Stack>
          }
        />
        {prescriptionModal}
      </>
    );
  },
);

TransactionDocuments.displayName = 'TransactionDocuments';

interface TransactionDocumentElement {
  type: 'prescription' | 'receipt' | 'warranty' | string;
  value: any;
  onClickCb: () => any;
}

interface TransactionDocumentProps {
  document: TransactionDocumentElement;
}

const TransactionDocument = React.memo(
  ({document}: TransactionDocumentProps) => {
    const {getTranslationWithValue} = useBaseTranslation(
      'Transaction.transactionDocuments',
    );
    const styles = useStyles();

    const documentIcon = useMemo(() => {
      let icon: IconNames;

      switch (document.type) {
        case 'prescription':
          icon = 'lens_glasses';
          break;
        case 'receipt':
          icon = 'receipt';
          break;
        case 'warranty':
          icon = 'warranty';
          break;
        default:
          icon = 'receipt';
          break;
      }

      return <IconSVG icon={icon} size={24} />;
    }, [document.type]);

    const documentLabel = useMemo(() => {
      switch (document.type) {
        case 'prescription':
          const value = document?.value as PrescriptionHeader;
          return `${getTranslationWithValue(0, 'prescriptionDocument', {
            patientLastName: value.patientLastName?.toLowerCase(),
          })} ${value?.examDate ? `${dayjs(value.examDate).format('L')}` : ''}`;
        case 'receipt':
          return 'receipt';
        case 'warranty':
          return 'warranty';
        default:
          return 'default';
      }
    }, [document.type, document?.value, getTranslationWithValue]);

    return (
      <Stack
        sx={styles.transactionDocumentContainer}
        onClick={e => {
          e.stopPropagation();
          document?.onClickCb();
        }}>
        <Stack className="iconLabel">
          {documentIcon}
          <BodyText heavy className="label">
            {documentLabel}
          </BodyText>
        </Stack>
        <BsChevronRight fontSize={24} />
      </Stack>
    );
  },
);

TransactionDocument.displayName = 'TransactionDocument';
