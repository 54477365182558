import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

import {Colors} from '@/themes/variables';

export const useStyles = () => {
  const theme = useTheme();

  const chip = useMemo<SxProps<Theme>>(
    () => ({
      width: 'fit-content',
      padding: theme.spacing(0.5, 0.8),
      '.MuiChip-label': {
        padding: theme.spacing(0),
      },
    }),
    [theme],
  );

  const profileModalContainer = useMemo<SxProps<Theme>>(
    () => ({
      maxHeight: theme.spacing(37),
      overflowY: 'auto',
      gap: theme.spacing(0.8),
      // paddingRight: theme.spacing(0.8),
    }),
    [theme],
  );

  const selectCta = useCallback(
    (enabled: boolean): SxProps<Theme> => ({
      ...(enabled
        ? {
            cursor: 'pointer',
            transition: 'all 0.2s linear',
            opacity: 1,
            ':hover': {
              opacity: 0.9,
            },
          }
        : {
            cursor: 'not-allowed',
            pointerEvents: 'none',
            opacity: 0.7,
          }),
    }),
    [],
  );

  const personaBox = useMemo<SxProps<Theme>>(
    () => ({
      flexDirection: 'column',
      gap: theme.spacing(1.6),
      backgroundColor: Colors.GreyBackground,
      padding: theme.spacing(1.6),
      borderRadius: theme.spacing(1.2),
      '.formControlLabel': {
        marginLeft: theme.spacing(-0.2),
        '.checkbox': {
          marginBottom: theme.spacing(0.4),
          padding: theme.spacing(0, 0.4, 0, 0),
        },
      },
      '.personaName': {
        textTransform: 'capitalize',
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      chip,
      profileModalContainer,
      selectCta,
      personaBox,
    }),
    [chip, profileModalContainer, selectCta, personaBox],
  );
};
