import React from 'react';

import {InputBase} from '@mui/material';

import {InputWrapper} from '../../atoms/InputWrapper';
import {IProps} from './interfaces';
import {useStyles} from './styles';

const TextInput = ({label, value, error = '', ...props}: IProps) => {
  const {input} = useStyles(!!error);
  return (
    <InputWrapper label={props.required ? label + '*' : label} error={error}>
      <InputBase
        {...props}
        error={Boolean(error)}
        defaultValue={value}
        id={`input-${label}`}
        sx={input}
      />
    </InputWrapper>
  );
};

export default React.memo(TextInput);
