import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Button, CircularProgress, Stack} from '@mui/material';
import * as Sentry from '@sentry/react';
import {useMutation} from '@tanstack/react-query';
import html2canvas from 'html2canvas';

import {useCurrentModule, useToast} from '../../../hooks';
import {useFeedback} from '../../../store/feedback';
import {Dialog} from '../../atoms/Dialog';
import {NoteInput} from '../../atoms/Note';
import {FeedbackModalProps} from './interfaces';
import {useStyle} from './styles';

const FeedbackModal = ({children}: FeedbackModalProps) => {
  const {t} = useTranslation();
  const {canSubmitFeedback, postUserFeedback} = useFeedback();
  const {showSuccessToast, showErrorToast} = useToast();
  const {currentModule} = useCurrentModule();
  const [open, setOpen] = useState<boolean>(false);
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [value, setValue] = useState('');

  /**
   * Handle note value
   */
  const handleValueChange = useCallback((val: string) => {
    setValue(_ => val);
  }, []);

  /**
   * Check if feedback can be submitted
   */
  const canSubmit = useMemo(() => {
    return canSubmitFeedback(value, screenshot);
  }, [canSubmitFeedback, screenshot, value]);

  /**
   * Clear modal state when closing
   */
  const closeModal = useCallback(() => {
    setOpen(false);
    setScreenshot(null);
    setValue('');
  }, []);

  /**
   * Mutation to submit feedback
   */
  const {mutate: SendFeedback, isLoading} = useMutation(postUserFeedback, {
    onSuccess: () => {
      showSuccessToast(t('Feedback.toast.success'));
      closeModal();
    },
    onError: () => {
      showErrorToast(t('Feedback.toast.error'));
      closeModal();
    },
  });

  /**
   * Open modal and take screenshot
   */
  const handleCaptureScreenshot = useCallback(async () => {
    setOpen(true);

    try {
      const base64 = (await html2canvas(document.documentElement)).toDataURL(
        'image/jpeg',
        1.0,
      );
      setScreenshot(base64);
    } catch (error) {
      closeModal();
      console.error('Taking screenshot', error);
      Sentry.captureException(error);
    }
  }, [closeModal]);

  const {screenshotBox, button} = useStyle(screenshot);

  const screenshotDiv = useMemo(
    () => (
      <Box sx={screenshotBox}>
        {!screenshot ? (
          <CircularProgress />
        ) : (
          <img src={screenshot} alt="feedback" />
        )}
      </Box>
    ),
    [screenshotBox, screenshot],
  );

  const dialogActions = useMemo(() => {
    if (isLoading) return <CircularProgress />;

    return (
      <Button
        sx={button}
        variant="primary"
        disabled={!canSubmit}
        onClick={() =>
          SendFeedback({
            imageData: screenshot!,
            feedback: value,
            applicationName: currentModule!,
          })
        }>
        {t('Feedback.modal.button')}
      </Button>
    );
  }, [
    SendFeedback,
    button,
    canSubmit,
    currentModule,
    isLoading,
    screenshot,
    t,
    value,
  ]);

  return (
    <>
      <Box onClick={handleCaptureScreenshot}>{children}</Box>
      <Dialog
        open={open}
        closeIcon="close"
        onClose={closeModal}
        dialogContent={
          <Stack
            sx={{width: '85%', maxWidth: '85%', margin: '0 auto'}}
            spacing={3}>
            {screenshotDiv}
            <NoteInput
              label={t('Feedback.modal.input.label')}
              placeholder={t('Feedback.modal.input.placeholder')}
              characterLimit={10000}
              size={6}
              onChange={handleValueChange}
              value={value}
            />
          </Stack>
        }
        dialogActions={dialogActions}
      />
    </>
  );
};

export default React.memo(FeedbackModal);
