import React from 'react';
import {Route, RouteProps} from 'react-router-dom';

import {LoadingPage} from '@/atoms/LoadingPage';
import {Modules, RoleName} from '@/definitions/users';
import ModuleRedirect from '@/navigation/middleware/ModuleRedirect';
import {useAuthentication} from '@/store/authentication';

export interface CheckPermissionProps extends RouteProps {
  roles?: RoleName[];
  module?: Modules;
}

const CheckPermission = ({roles, module, ...props}: CheckPermissionProps) => {
  const {userRoles, checkRoles, userModules, isFetching} = useAuthentication();
  const roleCheck = roles && userRoles && checkRoles(roles);
  const moduleCheck = module && userModules && userModules.includes(module);

  if (isFetching) return <LoadingPage />;

  if (roles && module && roleCheck && moduleCheck) {
    return <Route {...props} />;
  }

  if (roles && roleCheck && !module) {
    return <Route {...props} />;
  }

  return <ModuleRedirect />;
};

export default React.memo(CheckPermission);
