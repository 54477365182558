import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const filterContainer: SxProps<Theme> = useMemo(
    () => ({
      display: 'grid',
      gap: theme.spacing(1.6),
      gridTemplateColumns: 'max-content max-content',
      '.MuiLink-root': {
        alignSelf: 'center !important',
        gap: theme.spacing(0.4),
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr max-content',
        '.MuiLink-root': {
          alignSelf: 'end !important',
          marginBottom: theme.spacing(0.8),
        },
      },
    }),
    [theme],
  );

  return useMemo(() => ({filterContainer}), [filterContainer]);
};
