import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import {Trans, useTranslation} from 'react-i18next';

import {Box, Button, CircularProgress, Container} from '@mui/material';

import {useCheckDevice} from '@/hooks/checkDevice';
import {useToast} from '@/hooks/toast';
import {cookie} from '@/lib/utils';
import {useAuthentication, useNamVerification} from '@/store/authentication';

import {Text} from '../../atoms/Typography/Text';
import {Base} from '../../templates/Base';
import {useStyles} from './styles';

const Login = (): JSX.Element => {
  const {t} = useTranslation();
  const {container, signIn, signInText} = useStyles();
  const {goToSso, isFetching} = useAuthentication();
  const {showErrorToast} = useToast();

  useEffect(() => {
    const value = cookie.get('permission-denied');
    if (value) {
      cookie.remove('permission-denied');
      showErrorToast(t('Login.SignIn.permissionDenied'));
    }
  }, [showErrorToast, t]);

  useNamVerification();
  const {isMobile} = useCheckDevice();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Login',
    });
  }, []);

  return (
    <Base variant="dark" disableNavbar loginNavbar>
      <Container sx={container}>
        <Box sx={signIn}>
          <Trans
            i18nKey="Login.SignIn.title"
            components={{
              wrapper: (
                <Text
                  variant={`${isMobile ? 'headline5' : 'headline3'}`}
                  sx={signInText}
                />
              ),
              heavy: (
                <Text
                  heavy
                  variant={`${isMobile ? 'headline3' : 'titleBig'}`}
                />
              ),
              medium: (
                <Text
                  medium
                  variant={`${isMobile ? 'headline3' : 'titleBig'}`}
                />
              ),
            }}
          />
          {!isFetching ? (
            <Button variant="primary" onClick={goToSso}>
              <Text variant="headline5">{t('Login.SignIn.button')}</Text>
            </Button>
          ) : (
            <CircularProgress disableShrink color="inherit" />
          )}
        </Box>
      </Container>
    </Base>
  );
};

export default React.memo(Login);
