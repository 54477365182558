import {CampaignsProviderState} from '@/store/CampaignsContext/types';

export const defaultCampaignsContextState: CampaignsProviderState = {
  data: {
    code: '',
    assignees: '',
    visibility: undefined,
    status: undefined,
    orderBy: '',
    sortBy: '',
    page: '1',
  },
  api: {
    changePage: () => null,
    handleOrderChange: () => null,
    refetchFilters: () => null,
  },
};
