import {useMemo, useCallback} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

const BORDER_RADIUS = '6px';

export const useStyles = () => {
  const theme = useTheme();

  const customSelect = useMemo<SxProps<Theme>>(
    () => ({
      marginTop: theme.spacing(0.4),
      borderRadius: BORDER_RADIUS,
      '.MuiPaper-root': {
        borderRadius: BORDER_RADIUS,
      },
      '& .MuiList-root': {
        maxHeight: '200px',
        overflowY: 'auto',
        py: 0,
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    }),
    [theme],
  );

  const selectList = useCallback(
    (value?: any): SxProps<Theme> => ({
      px: theme.spacing(1),
      py: theme.spacing(0.5),
      color: theme.palette.common.black,
      textTransform: 'capitalize',
      ...(!value && {
        fontStyle: 'italic',
      }),
      '&:first-of-type': {
        borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
      },
      '&:last-of-type': {
        borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
      },
      '&:focus': {
        backgroundColor: theme.palette.grey[400],
      },
      '&.Mui-selected': {
        opacity: '50%',
        backgroundColor: theme.palette.common.white,
        '&:focus': {
          opacity: '100%',
          backgroundColor: theme.palette.grey[400],
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({customSelect, selectList}),
    [customSelect, selectList],
  );
};
