import {useMemo} from 'react';

import {useTheme, SxProps, Theme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const search = useMemo<SxProps<Theme>>(
    () => ({
      border: `1px solid #4D4D4D`,
      borderRadius: '12px',
      padding: '16px',
      '.MuiInputBase-root': {
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        gap: theme.spacing(2),
      },
    }),
    [theme],
  );

  const customSelect = useMemo<SxProps<Theme>>(
    () => ({
      ':before, :after': {
        display: 'none',
      },
      '.MuiInputBase-input': {
        padding: 0,
        paddingRight: theme.spacing(1.2),
        marginTop: theme.spacing(0.4),
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    }),
    [theme],
  );

  return useMemo(
    () => ({
      search,
      customSelect,
    }),
    [search, customSelect],
  );
};
