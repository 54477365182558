import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Skeleton, Stack} from '@mui/material';

import {Card} from '@/atoms/Card';
import {Text} from '@/atoms/Typography/Text';
import {StoreAssociate} from '@/molecules/StoreAssociate';
import {TeamMembersProps} from '@/organisms/TeamMembers/types.d';

const TeamMembers = ({isLoading, members}: TeamMembersProps) => {
  const {t} = useTranslation();

  const renderData = useMemo(() => {
    if (!members || isLoading) {
      return Array(3)
        .fill('')
        .map((_, index) => {
          return (
            <Skeleton
              key={index}
              animation="wave"
              variant="rounded"
              width="100%"
              height={40}
            />
          );
        });
    }

    return members?.map(member => (
      <StoreAssociate key={member?.id} {...member} alignment="flex-start" />
    ));
  }, [isLoading, members]);

  return (
    <Card>
      <Stack display="flex" direction="column" gap={1.6}>
        <Text variant="body">
          {t('Campaign.teamMembers.bestMonthPerformer')}
        </Text>
        <Stack
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          alignItems="flex-start"
          gap={3.2}>
          {renderData}
        </Stack>
      </Stack>
    </Card>
  );
};

export default React.memo(TeamMembers);
