import {faker} from '@faker-js/faker';

import {GetScriptResponse} from '@/store/tasks/types';

const generateScriptBoxMock = (): GetScriptResponse => {
  return {
    script: faker.lorem.paragraph(10),
  };
};

export const scriptBoxMock = generateScriptBoxMock();
