import {useMemo} from 'react';

import {SxProps, Theme, useTheme} from '@mui/material';

export const useStyle = () => {
  const theme = useTheme();

  const numberInput: SxProps<Theme> = useMemo(
    () => ({
      input: {
        padding: theme.spacing(1),
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },

      '.Mui-error': {
        fieldset: {
          borderColor: `${theme.palette.error.main} !important`,
        }
      }
    }),
    [theme],
  );

  return {
    numberInput,
  };
};
