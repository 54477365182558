import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Divider, Stack} from '@mui/material';

import {Avatar} from '@/atoms/Avatar';
import {IconSVG} from '@/atoms/IconSVG';
import {StoreCard} from '@/atoms/StoreCard';
import {Text} from '@/atoms/Typography/Text';
import {RoleName} from '@/definitions/users';
import {Colors} from '@/themes/variables';

import {AvatarDropdownProps} from './interfaces';

const AvatarDropdown = ({user, onLogout}: AvatarDropdownProps) => {
  const {t} = useTranslation();
  const {roles, firstName, lastName, currentStore} = user;

  const firstRole = roles[0];

  const isCC = useMemo(() => {
    return (
      firstRole.name === RoleName.CC_AGENT ||
      firstRole.name === RoleName.CC_SUPERVISOR
    );
  }, [firstRole.name]);

  return (
    <Stack direction="column" gap={1.6} width="100%">
      <Stack direction="column" alignItems="center" gap={1.6}>
        <Stack direction="column" alignItems="center" gap={0.4}>
          <Avatar name={firstName} surname={lastName} variant="dark" />
          <Stack direction="column" alignItems="center">
            <Text medium variant="body">
              {firstName} {lastName}
            </Text>
            <Text variant="xs" color={Colors.GreyText}>
              {firstRole?.description}
            </Text>
          </Stack>
        </Stack>
        {currentStore && !isCC && <StoreCard user={user} />}
      </Stack>
      <Divider />
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="center">
        <Text variant="tiny" superHeavy onClick={onLogout}>
          {t('Generic.logout')}
        </Text>
        <Box sx={{marginLeft: '-4px', marginTop: '-4px'}}>
          <IconSVG icon="logout" size={14} />
        </Box>
      </Stack>
    </Stack>
  );
};

export default React.memo(AvatarDropdown);
