import React, {useMemo} from 'react';

import {Stack, Skeleton} from '@mui/material';

import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {HeadlineText} from '@/atoms/HeadlineText';
import {ScriptBoxProps} from '@/organisms/ScriptBox/interfaces';

const ScriptBox = ({response}: ScriptBoxProps) => {
  const renderScript = useMemo(() => {
    if (!response) {
      return <Skeleton height="155px" animation="wave" variant="rounded" />;
    }

    return <BodyText>{response?.script}</BodyText>;
  }, [response]);

  return (
    <Stack direction="column" gap={1.6}>
      <HeadlineText superHeavy>Scripts</HeadlineText>
      <Card>{renderScript}</Card>
    </Stack>
  );
};

export default React.memo(ScriptBox);
