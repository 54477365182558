import {useEffect, useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';

export function useParamsAndClean<T extends {[key: string]: any}>(
  ...params: string[]
) {
  const location = useLocation();
  const history = useHistory();
  const [queryParams, setQueryParams] = useState<T>(() => {
    const initialParams: T = {} as T;
    // Take params from localStorage if no query parameters are present
    // if (location.search === '') {
    if (params.findIndex(param => location.search.includes(param)) === -1) {
      params.forEach((param: keyof T) => {
        const val = localStorage.getItem(param as string);
        if (val) initialParams[param] = val as T[keyof T];
      });
    }

    return initialParams;
  });

  useEffect(() => {
    // Check if query parameters are present and save them to localStorage, then remove them from URL
    // if (location.search !== '') {
    if (params.findIndex(param => location.search.includes(param)) !== -1) {
      const searchParams = new URLSearchParams(location.search);
      const mappedParams: T = {} as T;

      params.forEach((param: keyof T) => {
        const val = searchParams.get(param as string);
        if (val) {
          mappedParams[param] = val as T[keyof T];
          localStorage.setItem(param as string, val);
        }
      });

      if (Object.keys(mappedParams).length > 0) {
        setQueryParams(mappedParams);
        const newUrl = location.pathname;
        history.replace(newUrl);
      }
    }
  }, [history, location.pathname, location.search, params]);

  return queryParams;
}
