import React, {useState, useCallback, useMemo, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {PiCaretUpBold, PiCaretDownBold} from 'react-icons/pi';

import {Box, Chip, Stack, ChipProps, Menu, Button} from '@mui/material';
import {Dayjs} from 'dayjs';

import {Calendar} from '@/atoms/Calendar';
import {
  ChipDatepickerRangeProps,
  ChipDatepickerRangeMenuProps,
} from '@/atoms/ChipDatepickerRange/interfaces';
import {useStyles} from '@/atoms/ChipDatepickerRange/styles';
import {TinyText} from '@/atoms/TinyText';
import {useLocale} from '@/store/locale';

const BASE_TRANSLATION_PATH = 'DatePickerRange';

const ChipDatepickerRange = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  onSetDateRange,
}: ChipDatepickerRangeProps) => {
  const {t} = useTranslation();
  const styles = useStyles();
  const {formatDate} = useLocale();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const toggleMenu = useCallback(
    (event?: React.MouseEvent<HTMLDivElement> | any) => {
      setAnchorEl(event?.currentTarget);
      event?.stopPropagation();
    },
    [],
  );

  const hasDates = useMemo<boolean>(() => {
    return !!startDate && !!endDate;
  }, [endDate, startDate]);

  const label = useMemo<ReactNode>(() => {
    return (
      <Stack
        display="grid"
        gridTemplateColumns="1fr max-content"
        gap={0.4}
        alignItems="center">
        <TinyText overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {hasDates
            ? `${formatDate(startDate!)} - ${formatDate(endDate!)}`
            : t(`${BASE_TRANSLATION_PATH}.placeholder`)}
        </TinyText>
        {!!anchorEl ? (
          <PiCaretUpBold fontSize={20} />
        ) : (
          <PiCaretDownBold fontSize={20} />
        )}
      </Stack>
    );
  }, [anchorEl, endDate, formatDate, hasDates, startDate, t]);

  const variant = useMemo<ChipProps['variant']>(() => {
    return hasDates ? 'filterActive' : 'filterDefault';
  }, [hasDates]);

  return (
    <>
      <Box onClick={toggleMenu} paddingY={0.8}>
        <Chip
          sx={styles.chip}
          disabled={false}
          label={label}
          clickable={true}
          variant={variant}
        />
      </Box>
      {anchorEl && (
        <DatePickerMenu
          anchorEl={anchorEl}
          toggleMenu={toggleMenu}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onSetDateRange={onSetDateRange}
        />
      )}
    </>
  );
};

export default React.memo(ChipDatepickerRange);

const DatePickerMenu = React.memo(
  ({
    startDate: StartDate,
    endDate: EndDate,
    minDate,
    maxDate,
    onSetDateRange,
    anchorEl,
    toggleMenu,
  }: ChipDatepickerRangeMenuProps) => {
    const {t} = useTranslation();
    const styles = useStyles();

    const [startDate, setStartDate] = useState<Dayjs | undefined>(StartDate);
    const [endDate, setEndDate] = useState<Dayjs | undefined>(EndDate);

    const bothDefined = useMemo(
      () => typeof startDate !== 'undefined' && typeof endDate !== 'undefined',
      [endDate, startDate],
    );
    const bothUndefined = useMemo(
      () => typeof startDate === 'undefined' && typeof endDate === 'undefined',
      [endDate, startDate],
    );
    const datesAreDifferentFromInitial = useMemo(() => {
      if (bothDefined) {
        return (
          !startDate?.isSame(StartDate, 'date') ||
          !endDate?.isSame(EndDate, 'date')
        );
      }

      return (
        typeof StartDate !== typeof startDate &&
        typeof EndDate !== typeof endDate
      );
    }, [EndDate, StartDate, bothDefined, endDate, startDate]);

    const canSubmit = useMemo(() => {
      return datesAreDifferentFromInitial && (bothDefined || bothUndefined);
    }, [bothDefined, bothUndefined, datesAreDifferentFromInitial]);

    return (
      <Menu
        sx={styles.menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={true}
        onClose={() => toggleMenu()}>
        <Stack
          direction="column"
          gap={3.2}
          alignItems="center"
          width={400}
          paddingTop={3.2}
          paddingBottom={2.4}>
          <Calendar
            minDate={startDate ?? minDate}
            maxDate={maxDate}
            onSelect={date => {
              if (!startDate) {
                setStartDate(date);
                return;
              }

              setEndDate(date);
            }}
            selectedStart={startDate}
            selectedEnd={endDate}
            selectedDate={endDate ?? startDate}
            defaultCurrentMonth={endDate ?? startDate}
          />
          <Stack
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap={1.6}
            width="100%"
            paddingBottom={1.2}
            paddingLeft={2.4}
            paddingRight={2.4}>
            <Button
              variant="baseSecondary"
              disabled={bothUndefined}
              {...(!bothUndefined && {
                onClick: () => {
                  setEndDate(undefined);
                  setStartDate(undefined);
                },
              })}
              autoFocus>
              {t('ChipFilters.reset')}
            </Button>
            <Button
              variant="basePrimary"
              disabled={!canSubmit}
              {...(canSubmit && {
                onClick: () => {
                  onSetDateRange(startDate, endDate);
                  toggleMenu();
                },
              })}
              autoFocus>
              {t('ChipFilters.apply')}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    );
  },
);

DatePickerMenu.displayName = 'DatePickerMenu';
